import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { FormActions } from "@/components/forms/form-utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

import type { QuickAddContentStepFormProps } from "./quick-add-content-dialog";
import { SiteMeta } from "./url-analyzer";

export const FormSchema = z.object({
  typeOfContent: z.enum(["channel", "work"]),
  canonicalUrl: z.string().url("Invalid web address"),
  name: z.string().min(4),
  description: z.string().optional(),
  keywords: z.array(z.string()).optional(),
});

export type FormValueType = z.infer<typeof FormSchema>;

export function QuickAddContentFormStep1({ onBack, onNext, addNew }: QuickAddContentStepFormProps) {
  const form = useForm<FormValueType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      typeOfContent: "channel",
      canonicalUrl: "",
      name: "",
    },
    mode: "onChange",
  });

  const {
    formState: { isValid, errors },
    setValue,
    reset,
    watch,
  } = form;

  const { typeOfContent, canonicalUrl } = watch();

  useEffect(() => {
    // if cannonicalUrl doesn't start with http:// or https://, append https://
    if (
      canonicalUrl &&
      !canonicalUrl.startsWith("http://") &&
      !canonicalUrl.startsWith("https://") &&
      !canonicalUrl.match(/^h?t?t?p?s?:?\/?\/?$/) // the user is trying to type h t t p s : / /, don't interrupt, lol
    ) {
      setValue("canonicalUrl", "https://" + canonicalUrl, { shouldValidate: true });
    }
  }, [canonicalUrl, setValue]);

  useEffect(() => {
    if (addNew) {
      reset({
        typeOfContent: "channel",
        canonicalUrl: "",
        name: "",
      });
    }
  }, [addNew, reset]);

  function onSubmit(data: FormValueType) {
    onNext && onNext(data);
  }

  function onUseUrlAnalyzerResult(siteMeta: SiteMeta) {
    const { name, description, keywords, media } = siteMeta;

    if (name !== "") setValue("name", name);
    if (description !== "") setValue("description", description);
    const newKeywords = keywords
      .split(",")
      .map((kw) => kw.trim().toLowerCase().replace(/\s+/g, "_"))
      .filter((kw) => kw.length > 0);
    if (newKeywords.length > 0) setValue("keywords", newKeywords);
    // if (media.length > 0) setValue("media", media);

    // proceed to the next step
    form.handleSubmit(onSubmit)();
  }

  function onIgnoreUrlAnalyzerResult() {
    // name field is on step1, so we can't proceed
    // form.handleSubmit(onSubmit)();
  }

  // console.log("quick add content step 1", {
  //   errors,
  //   values: watch(),
  //   isValid,
  // });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4 space-y-8">
        {/* type of content */}
        <FormField
          control={form.control}
          name="typeOfContent"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>This creation is a:</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="channel" />
                    </FormControl>
                    <FormLabel className="flex-1 font-normal leading-tight">
                      <span className="font-semibold">Channel</span> (e.g. blog, podcast, or any
                      medium you keep on contributing new material to)
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="work" />
                    </FormControl>
                    <FormLabel className="flex-1 font-normal leading-tight">
                      <span className="font-semibold">Work</span> (e.g. a book, a painting, a film,
                      or any format that you can "complete")
                    </FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* url */}
        <FormField
          control={form.control}
          name="canonicalUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Web address *</FormLabel>
              <FormControl>
                <div className="flex w-full items-center space-x-4">
                  <Input {...field} placeholder="https:// or http://" autoComplete="no" />
                  {/* <UrlAnalyzer
                    url={canonicalUrl}
                    typeOfContent={typeOfContent}
                    onUse={onUseUrlAnalyzerResult}
                    onCancel={onIgnoreUrlAnalyzerResult}
                  /> */}
                </div>
              </FormControl>
              {/* <FormDescription>
                Enter the web address of your {typeOfContent}, then press Analyze, we’ll try to fill
                in the rest of the information.
              </FormDescription> */}
              <FormMessage />
            </FormItem>
          )}
        />

        {/* name */}
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name *</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormActions disabled={!isValid} onBack={onBack} className="" />
      </form>
    </Form>
  );
}
