import {
  useGetOwnerByAccountQuery,
  useGetOwnerContactsQuery,
  useGetProfileQuery,
  useUpdateOwnerMutation,
} from "@/generated-types";
import { RefetchOnFocus } from "@/utils/query";
import first from "lodash/first";
import { useNavigate } from "react-router-dom";
import { ContentLibrary } from "../content-page/content-library";
import { useTagEditor } from "./tags-editor";
import { ViewOwnerProfile } from "./view-owner-profile";

export default function OwnerProfilePage() {
  const navigate = useNavigate();

  const {
    data: profileData,
    loading: loadingProfile,
    refetch: refectchProfile,
  } = useGetProfileQuery();

  const {
    data: ownerData,
    loading: loadingOwner,
    refetch: refectchOwner,
  } = useGetOwnerByAccountQuery({
    variables: { accountId: profileData?.me?.account?.id || "" },
    skip: !profileData,
  });

  const [updateOwner, { loading: updatingOwner }] = useUpdateOwnerMutation();
  const { handleUpdateTags } = useTagEditor();

  const subscriptionPlan = profileData?.me?.account?.subscription?.plan;

  const owner = first(ownerData?.owners);

  const { data: contactsData, loading: loadingContacts } = useGetOwnerContactsQuery({
    variables: { ownerId: owner?.id || "" },
    skip: !owner,
  });

  /* load address from firstContact on load, then on update it should requery on update */
  const firstContact = first(contactsData?.ownerContacts);

  const handlePicture = async (picture: string) => {
    await updateOwner({
      variables: {
        id: owner?.id!,
        data: {
          profilePicture: picture,
        },
      },
    });
  };

  const handleSaveTags = async (tags: string | undefined) => {
    const titles = tags
      ? tags
          .split(", ")
          .map((tag) => tag.trim().toLowerCase())
          .filter((tag) => tag.length > 1)
      : [];

    const tagsAfterUpdate = await handleUpdateTags("owner", owner?.id!, titles, owner?.tags);
    return tagsAfterUpdate
      ?.filter((tag) => !tag.blocked)
      .map((tag) => tag.title)
      .join(", ");
  };

  const handleUpdateOwner = async (field: string, value: string | undefined) => {
    const result = await updateOwner({
      variables: {
        id: owner?.id!, // ID must exist
        data: {
          [field]: value,
        },
      },
    });
    const updatedOwner = first(result.data?.updateOwners.owners);
    return updatedOwner
      ? String(updatedOwner[field as keyof typeof updatedOwner]) || undefined
      : undefined;
  };

  return (
    <>
      <RefetchOnFocus refetchers={[refectchProfile, refectchOwner]} />
      <main className="container mx-auto">
        <h1 id="page-title" className="text-3xl font-bold tracking-tight text-gray-900">
          Your Information as Rights Owner
        </h1>
        <ViewOwnerProfile
          loading={loadingProfile || loadingOwner}
          user={profileData?.me}
          plan={subscriptionPlan}
          owner={owner}
          contact={firstContact}
          onSavePicture={handlePicture}
          onSaveField={handleUpdateOwner}
          onSaveTags={handleSaveTags}
        />
        <ContentLibrary profile={profileData?.me} />
      </main>
    </>
  );
}
