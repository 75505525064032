import { FormActions } from "@/components/forms/form-utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useTypeTags } from "@/utils/tag-helpers";
import { CategoryEditor } from "./category-editor";
import { Undefinedable } from "./types";

export type FormValueType = {
  typeOfContent: "channel" | "work";
  typeTag: string | undefined;
  secondaryTypeTag: string | undefined;
  tertiaryTypeTag: string | undefined;
  categories: { id: string; title: string | undefined | null; icon: string | undefined | null }[];
};

type AddContentStepFormProps = {
  value: Undefinedable<FormValueType>;
  onNext: (value: FormValueType) => void;
  onBack?: () => void;
};

export function AddContentFormStep3({ value, onBack, onNext }: AddContentStepFormProps) {
  const { typeTags, typeTagsMap } = useTypeTags();

  const FormSchema = z
    .object({
      typeOfContent: z.enum(["channel", "work"]),
      typeTag: z.string().optional(),
      secondaryTypeTag: z.string().optional(),
      tertiaryTypeTag: z.string().optional(),
      categories: z
        .array(
          z.object({
            id: z.string(),
            title: z.string().optional().nullable(),
            // optional() and nullable() are for displaying existing channel/work
            icon: z.string().optional().nullable(),
          })
        )
        .min(1, { message: "Select at least one category" }),
    })
    .superRefine((data, ctx) => {
      // try enforce the rule that `type` is required
      if (!data.typeTag) {
        // if type is not selected
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please select a type for your creation",
          path: ["typeTag"],
        });
      } else if (!data.secondaryTypeTag) {
        // if secondary type is not selected, check if it is needed
        // the secondary type is required only if the *currently selected* type has children
        if (typeTagsMap[data.typeTag].hasChildren)
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please select a type for your creation",
            path: ["secondaryTypeTag"],
          });
      } else if (!data.tertiaryTypeTag) {
        // if tertiary type is not selected, check if it is needed
        // the tertiary type is only required if the *currently selected* secondary type has children
        if (typeTagsMap[data.secondaryTypeTag].hasChildren)
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please select a type for your creation",
            path: ["tertiaryTypeTag"],
          });
      }
    });

  const form = useForm<FormValueType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      typeTag: "",
      secondaryTypeTag: "",
      tertiaryTypeTag: "",
      categories: [],
    },
    mode: "onChange",
  });

  const {
    formState: { isValid, errors },
    reset,
    watch,
    setValue,
  } = form;

  const { typeOfContent, typeTag, secondaryTypeTag, tertiaryTypeTag } = watch();

  useEffect(() => {
    reset(value);
  }, [value, reset]);

  useEffect(() => {
    // when the user selects a new typeTag, if the secondaryTypeTag is not a child of the new typeTag, set it to undefined
    if (secondaryTypeTag && typeTagsMap[secondaryTypeTag]?.parentId !== typeTag) {
      setValue("secondaryTypeTag", undefined, { shouldValidate: true });
    }
    if (tertiaryTypeTag && typeTagsMap[tertiaryTypeTag]?.parentId !== secondaryTypeTag) {
      setValue("tertiaryTypeTag", undefined, { shouldValidate: true });
    }
  }, [typeTag, secondaryTypeTag, tertiaryTypeTag, setValue, typeTagsMap]);

  function onSubmit(data: FormValueType) {
    onNext(data);
  }

  const tier0Types = typeTags.filter((tag) => tag.tier === 0);
  const tier1Types = typeTags.filter((tag) => tag.tier === 1 && tag.parentId === typeTag);
  const tier2Types = typeTags.filter((tag) => tag.tier === 2 && tag.parentId === secondaryTypeTag);

  const typeTagTitle = typeTag && typeTagsMap[typeTag]?.title;
  const secondaryTypeTagTitle = secondaryTypeTag && typeTagsMap[secondaryTypeTag]?.title;

  const showSecondaryTypeField = tier1Types.length > 0;
  const showTertiaryTypeField = tier2Types.length > 0;

  // console.log("add content step 3", {
  //   errors,
  //   values: watch(),
  //   isValid,
  // });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4 space-y-8">
        {/* type of content */}
        <FormField
          control={form.control}
          name="typeTag"
          render={({ field }) => (
            <FormItem>
              <FormLabel>What type of content? *</FormLabel>
              <FormControl>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger>
                    <SelectValue placeholder="Please select a type" />
                  </SelectTrigger>
                  <SelectContent>
                    {tier0Types.map((item) => (
                      <SelectItem key={item.id} value={item.id}>
                        {item.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* secondary type */}
        {showSecondaryTypeField && (
          <FormField
            control={form.control}
            name="secondaryTypeTag"
            render={({ field }) => (
              <FormItem>
                <FormLabel>What type of {typeTagTitle}? *</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Please select a type" />
                    </SelectTrigger>
                    <SelectContent>
                      {tier1Types.map((item) => (
                        <SelectItem key={item.id} value={item.id}>
                          {item.title}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {/* tertiary type */}
        {showTertiaryTypeField && (
          <FormField
            control={form.control}
            name="tertiaryTypeTag"
            render={({ field }) => (
              <FormItem>
                <FormLabel>What type of {secondaryTypeTagTitle}? *</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Please select a type" />
                    </SelectTrigger>
                    <SelectContent>
                      {tier2Types.map((item) => (
                        <SelectItem key={item.id} value={item.id}>
                          {item.title}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {/* category tags */}
        <FormField
          control={form.control}
          name="categories"
          render={({ field }) => (
            <FormItem>
              <FormLabel>What topics does this {typeOfContent} cover? *</FormLabel>
              <FormControl>
                <CategoryEditor
                  typeOfContent={typeOfContent}
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormActions disabled={!isValid} onBack={onBack} className="" />
      </form>
    </Form>
  );
}
