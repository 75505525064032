import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

/** Account */
export type Account = {
  __typename?: 'Account';
  /** The status of an Account */
  accountStatus?: Maybe<AccountStatus>;
  /** Account's Channels */
  channels: Array<Channel>;
  channelsAggregate?: Maybe<AccountChannelChannelsAggregationSelection>;
  channelsConnection: AccountChannelsConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Deprecated, should use 'creators' instead, keep it until data migration is done
   * creator: Creator @relationship(type: "ACCOUNT_HAS_CREATOR", direction: OUT)
   *
   * Deprecated, should use 'users' instead, keep it until data migration is done
   * user: User @relationship(type: "USER_HAS_ACCOUNT", direction: IN)
   *
   * Account's Creators
   */
  creators: Array<Creator>;
  creatorsAggregate?: Maybe<AccountCreatorCreatorsAggregationSelection>;
  creatorsConnection: AccountCreatorsConnection;
  /** Account's Editions */
  editions: Array<Edition>;
  editionsAggregate?: Maybe<AccountEditionEditionsAggregationSelection>;
  editionsConnection: AccountEditionsConnection;
  /** System generated GUID */
  id: Scalars['ID']['output'];
  /** Date the creator joined the platform. */
  joinedDate?: Maybe<Scalars['String']['output']>;
  /** Last date the creator was active. */
  lastActive?: Maybe<Scalars['String']['output']>;
  /** The status of the Onboarding flow */
  onboardingStatus?: Maybe<OnboardingStatus>;
  /** Account's Rights Owner */
  owner?: Maybe<Owner>;
  ownerAggregate?: Maybe<AccountOwnerOwnerAggregationSelection>;
  ownerConnection: AccountOwnerConnection;
  /** Direct link to the creator's profile. */
  profileURL?: Maybe<Scalars['String']['output']>;
  /** Account's Subscription */
  subscription?: Maybe<AccountSubscription>;
  subscriptionAggregate?: Maybe<AccountAccountSubscriptionSubscriptionAggregationSelection>;
  subscriptionConnection: AccountSubscriptionConnection;
  /** Tax ID Number */
  taxId?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Creators platform username or handle. */
  username?: Maybe<Scalars['String']['output']>;
  /** Users who can access this Account */
  users: Array<User>;
  usersAggregate?: Maybe<AccountUserUsersAggregationSelection>;
  usersConnection: AccountUsersConnection;
  /** Account's Works */
  works: Array<Work>;
  worksAggregate?: Maybe<AccountWorkWorksAggregationSelection>;
  worksConnection: AccountWorksConnection;
};


/** Account */
export type AccountChannelsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<ChannelOptions>;
  where?: InputMaybe<ChannelWhere>;
};


/** Account */
export type AccountChannelsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ChannelWhere>;
};


/** Account */
export type AccountChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AccountChannelsConnectionSort>>;
  where?: InputMaybe<AccountChannelsConnectionWhere>;
};


/** Account */
export type AccountCreatorsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CreatorOptions>;
  where?: InputMaybe<CreatorWhere>;
};


/** Account */
export type AccountCreatorsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CreatorWhere>;
};


/** Account */
export type AccountCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AccountCreatorsConnectionSort>>;
  where?: InputMaybe<AccountCreatorsConnectionWhere>;
};


/** Account */
export type AccountEditionsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<EditionOptions>;
  where?: InputMaybe<EditionWhere>;
};


/** Account */
export type AccountEditionsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<EditionWhere>;
};


/** Account */
export type AccountEditionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AccountEditionsConnectionSort>>;
  where?: InputMaybe<AccountEditionsConnectionWhere>;
};


/** Account */
export type AccountOwnerArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<OwnerOptions>;
  where?: InputMaybe<OwnerWhere>;
};


/** Account */
export type AccountOwnerAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<OwnerWhere>;
};


/** Account */
export type AccountOwnerConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AccountOwnerConnectionSort>>;
  where?: InputMaybe<AccountOwnerConnectionWhere>;
};


/** Account */
export type AccountSubscriptionArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<AccountSubscriptionOptions>;
  where?: InputMaybe<AccountSubscriptionWhere>;
};


/** Account */
export type AccountSubscriptionAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccountSubscriptionWhere>;
};


/** Account */
export type AccountSubscriptionConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AccountSubscriptionConnectionSort>>;
  where?: InputMaybe<AccountSubscriptionConnectionWhere>;
};


/** Account */
export type AccountUsersArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};


/** Account */
export type AccountUsersAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserWhere>;
};


/** Account */
export type AccountUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AccountUsersConnectionSort>>;
  where?: InputMaybe<AccountUsersConnectionWhere>;
};


/** Account */
export type AccountWorksArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<WorkOptions>;
  where?: InputMaybe<WorkWhere>;
};


/** Account */
export type AccountWorksAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<WorkWhere>;
};


/** Account */
export type AccountWorksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AccountWorksConnectionSort>>;
  where?: InputMaybe<AccountWorksConnectionWhere>;
};

export type AccountAccountSubscriptionSubscriptionAggregationSelection = {
  __typename?: 'AccountAccountSubscriptionSubscriptionAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<AccountAccountSubscriptionSubscriptionNodeAggregateSelection>;
};

export type AccountAccountSubscriptionSubscriptionNodeAggregateSelection = {
  __typename?: 'AccountAccountSubscriptionSubscriptionNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  displayPrice: FloatAggregateSelectionNullable;
  priceId: StringAggregateSelectionNullable;
  promoCode: StringAggregateSelectionNullable;
  subscriptionId: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type AccountAggregateSelection = {
  __typename?: 'AccountAggregateSelection';
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  joinedDate: StringAggregateSelectionNullable;
  lastActive: StringAggregateSelectionNullable;
  profileURL: StringAggregateSelectionNullable;
  taxId: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  username: StringAggregateSelectionNullable;
};

export type AccountChannelChannelsAggregationSelection = {
  __typename?: 'AccountChannelChannelsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<AccountChannelChannelsNodeAggregateSelection>;
};

export type AccountChannelChannelsNodeAggregateSelection = {
  __typename?: 'AccountChannelChannelsNodeAggregateSelection';
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type AccountChannelsAggregateInput = {
  AND?: InputMaybe<Array<AccountChannelsAggregateInput>>;
  NOT?: InputMaybe<AccountChannelsAggregateInput>;
  OR?: InputMaybe<Array<AccountChannelsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<AccountChannelsNodeAggregationWhereInput>;
};

export type AccountChannelsConnectFieldInput = {
  connect?: InputMaybe<Array<ChannelConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<ChannelConnectWhere>;
};

export type AccountChannelsConnectOrCreateFieldInput = {
  onCreate: AccountChannelsConnectOrCreateFieldInputOnCreate;
  where: ChannelConnectOrCreateWhere;
};

export type AccountChannelsConnectOrCreateFieldInputOnCreate = {
  node: ChannelOnCreateInput;
};

export type AccountChannelsConnection = {
  __typename?: 'AccountChannelsConnection';
  edges: Array<AccountChannelsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountChannelsConnectionSort = {
  node?: InputMaybe<ChannelSort>;
};

export type AccountChannelsConnectionWhere = {
  AND?: InputMaybe<Array<AccountChannelsConnectionWhere>>;
  NOT?: InputMaybe<AccountChannelsConnectionWhere>;
  OR?: InputMaybe<Array<AccountChannelsConnectionWhere>>;
  node?: InputMaybe<ChannelWhere>;
};

export type AccountChannelsCreateFieldInput = {
  node: ChannelCreateInput;
};

export type AccountChannelsDeleteFieldInput = {
  delete?: InputMaybe<ChannelDeleteInput>;
  where?: InputMaybe<AccountChannelsConnectionWhere>;
};

export type AccountChannelsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChannelDisconnectInput>;
  where?: InputMaybe<AccountChannelsConnectionWhere>;
};

export type AccountChannelsFieldInput = {
  connect?: InputMaybe<Array<AccountChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AccountChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AccountChannelsCreateFieldInput>>;
};

export type AccountChannelsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AccountChannelsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AccountChannelsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AccountChannelsNodeAggregationWhereInput>>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountChannelsRelationship = {
  __typename?: 'AccountChannelsRelationship';
  cursor: Scalars['String']['output'];
  node: Channel;
};

export type AccountChannelsUpdateConnectionInput = {
  node?: InputMaybe<ChannelUpdateInput>;
};

export type AccountChannelsUpdateFieldInput = {
  connect?: InputMaybe<Array<AccountChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AccountChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AccountChannelsCreateFieldInput>>;
  delete?: InputMaybe<Array<AccountChannelsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AccountChannelsDisconnectFieldInput>>;
  update?: InputMaybe<AccountChannelsUpdateConnectionInput>;
  where?: InputMaybe<AccountChannelsConnectionWhere>;
};

export type AccountConnectInput = {
  channels?: InputMaybe<Array<AccountChannelsConnectFieldInput>>;
  creators?: InputMaybe<Array<AccountCreatorsConnectFieldInput>>;
  editions?: InputMaybe<Array<AccountEditionsConnectFieldInput>>;
  owner?: InputMaybe<AccountOwnerConnectFieldInput>;
  subscription?: InputMaybe<AccountSubscriptionConnectFieldInput>;
  users?: InputMaybe<Array<AccountUsersConnectFieldInput>>;
  works?: InputMaybe<Array<AccountWorksConnectFieldInput>>;
};

export type AccountConnectOrCreateInput = {
  channels?: InputMaybe<Array<AccountChannelsConnectOrCreateFieldInput>>;
  creators?: InputMaybe<Array<AccountCreatorsConnectOrCreateFieldInput>>;
  editions?: InputMaybe<Array<AccountEditionsConnectOrCreateFieldInput>>;
  owner?: InputMaybe<AccountOwnerConnectOrCreateFieldInput>;
  users?: InputMaybe<Array<AccountUsersConnectOrCreateFieldInput>>;
  works?: InputMaybe<Array<AccountWorksConnectOrCreateFieldInput>>;
};

export type AccountConnectOrCreateWhere = {
  node: AccountUniqueWhere;
};

export type AccountConnectWhere = {
  node: AccountWhere;
};

export type AccountCreateInput = {
  accountStatus?: InputMaybe<AccountStatus>;
  channels?: InputMaybe<AccountChannelsFieldInput>;
  creators?: InputMaybe<AccountCreatorsFieldInput>;
  editions?: InputMaybe<AccountEditionsFieldInput>;
  joinedDate?: InputMaybe<Scalars['String']['input']>;
  lastActive?: InputMaybe<Scalars['String']['input']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  owner?: InputMaybe<AccountOwnerFieldInput>;
  profileURL?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<AccountSubscriptionFieldInput>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<AccountUsersFieldInput>;
  works?: InputMaybe<AccountWorksFieldInput>;
};

export type AccountCreatorCreatorsAggregationSelection = {
  __typename?: 'AccountCreatorCreatorsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<AccountCreatorCreatorsNodeAggregateSelection>;
};

export type AccountCreatorCreatorsNodeAggregateSelection = {
  __typename?: 'AccountCreatorCreatorsNodeAggregateSelection';
  bannerImage: StringAggregateSelectionNullable;
  bio: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  creatorID: StringAggregateSelectionNullable;
  dateOfBirth: StringAggregateSelectionNullable;
  firstName: StringAggregateSelectionNullable;
  firstNameLC: StringAggregateSelectionNullable;
  genderOther: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  lastName: StringAggregateSelectionNullable;
  lastNameLC: StringAggregateSelectionNullable;
  middleName: StringAggregateSelectionNullable;
  nationality: StringAggregateSelectionNullable;
  nicknames: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  pronouns: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type AccountCreatorsAggregateInput = {
  AND?: InputMaybe<Array<AccountCreatorsAggregateInput>>;
  NOT?: InputMaybe<AccountCreatorsAggregateInput>;
  OR?: InputMaybe<Array<AccountCreatorsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<AccountCreatorsNodeAggregationWhereInput>;
};

export type AccountCreatorsConnectFieldInput = {
  connect?: InputMaybe<Array<CreatorConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CreatorConnectWhere>;
};

export type AccountCreatorsConnectOrCreateFieldInput = {
  onCreate: AccountCreatorsConnectOrCreateFieldInputOnCreate;
  where: CreatorConnectOrCreateWhere;
};

export type AccountCreatorsConnectOrCreateFieldInputOnCreate = {
  node: CreatorOnCreateInput;
};

export type AccountCreatorsConnection = {
  __typename?: 'AccountCreatorsConnection';
  edges: Array<AccountCreatorsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountCreatorsConnectionSort = {
  node?: InputMaybe<CreatorSort>;
};

export type AccountCreatorsConnectionWhere = {
  AND?: InputMaybe<Array<AccountCreatorsConnectionWhere>>;
  NOT?: InputMaybe<AccountCreatorsConnectionWhere>;
  OR?: InputMaybe<Array<AccountCreatorsConnectionWhere>>;
  node?: InputMaybe<CreatorWhere>;
};

export type AccountCreatorsCreateFieldInput = {
  node: CreatorCreateInput;
};

export type AccountCreatorsDeleteFieldInput = {
  delete?: InputMaybe<CreatorDeleteInput>;
  where?: InputMaybe<AccountCreatorsConnectionWhere>;
};

export type AccountCreatorsDisconnectFieldInput = {
  disconnect?: InputMaybe<CreatorDisconnectInput>;
  where?: InputMaybe<AccountCreatorsConnectionWhere>;
};

export type AccountCreatorsFieldInput = {
  connect?: InputMaybe<Array<AccountCreatorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreatorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AccountCreatorsCreateFieldInput>>;
};

export type AccountCreatorsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AccountCreatorsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AccountCreatorsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AccountCreatorsNodeAggregationWhereInput>>;
  bannerImage_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bio_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  bio_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creatorID_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  creatorID_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  firstName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  genderOther_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  middleName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nationality_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nicknames_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  pronouns_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountCreatorsRelationship = {
  __typename?: 'AccountCreatorsRelationship';
  cursor: Scalars['String']['output'];
  node: Creator;
};

export type AccountCreatorsUpdateConnectionInput = {
  node?: InputMaybe<CreatorUpdateInput>;
};

export type AccountCreatorsUpdateFieldInput = {
  connect?: InputMaybe<Array<AccountCreatorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreatorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AccountCreatorsCreateFieldInput>>;
  delete?: InputMaybe<Array<AccountCreatorsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AccountCreatorsDisconnectFieldInput>>;
  update?: InputMaybe<AccountCreatorsUpdateConnectionInput>;
  where?: InputMaybe<AccountCreatorsConnectionWhere>;
};

export type AccountDeleteInput = {
  channels?: InputMaybe<Array<AccountChannelsDeleteFieldInput>>;
  creators?: InputMaybe<Array<AccountCreatorsDeleteFieldInput>>;
  editions?: InputMaybe<Array<AccountEditionsDeleteFieldInput>>;
  owner?: InputMaybe<AccountOwnerDeleteFieldInput>;
  subscription?: InputMaybe<AccountSubscriptionDeleteFieldInput>;
  users?: InputMaybe<Array<AccountUsersDeleteFieldInput>>;
  works?: InputMaybe<Array<AccountWorksDeleteFieldInput>>;
};

export type AccountDisconnectInput = {
  channels?: InputMaybe<Array<AccountChannelsDisconnectFieldInput>>;
  creators?: InputMaybe<Array<AccountCreatorsDisconnectFieldInput>>;
  editions?: InputMaybe<Array<AccountEditionsDisconnectFieldInput>>;
  owner?: InputMaybe<AccountOwnerDisconnectFieldInput>;
  subscription?: InputMaybe<AccountSubscriptionDisconnectFieldInput>;
  users?: InputMaybe<Array<AccountUsersDisconnectFieldInput>>;
  works?: InputMaybe<Array<AccountWorksDisconnectFieldInput>>;
};

export type AccountEdge = {
  __typename?: 'AccountEdge';
  cursor: Scalars['String']['output'];
  node: Account;
};

export type AccountEditionEditionsAggregationSelection = {
  __typename?: 'AccountEditionEditionsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<AccountEditionEditionsNodeAggregateSelection>;
};

export type AccountEditionEditionsNodeAggregateSelection = {
  __typename?: 'AccountEditionEditionsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type AccountEditionsAggregateInput = {
  AND?: InputMaybe<Array<AccountEditionsAggregateInput>>;
  NOT?: InputMaybe<AccountEditionsAggregateInput>;
  OR?: InputMaybe<Array<AccountEditionsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<AccountEditionsNodeAggregationWhereInput>;
};

export type AccountEditionsConnectFieldInput = {
  connect?: InputMaybe<Array<EditionConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<EditionConnectWhere>;
};

export type AccountEditionsConnectOrCreateFieldInput = {
  onCreate: AccountEditionsConnectOrCreateFieldInputOnCreate;
  where: EditionConnectOrCreateWhere;
};

export type AccountEditionsConnectOrCreateFieldInputOnCreate = {
  node: EditionOnCreateInput;
};

export type AccountEditionsConnection = {
  __typename?: 'AccountEditionsConnection';
  edges: Array<AccountEditionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountEditionsConnectionSort = {
  node?: InputMaybe<EditionSort>;
};

export type AccountEditionsConnectionWhere = {
  AND?: InputMaybe<Array<AccountEditionsConnectionWhere>>;
  NOT?: InputMaybe<AccountEditionsConnectionWhere>;
  OR?: InputMaybe<Array<AccountEditionsConnectionWhere>>;
  node?: InputMaybe<EditionWhere>;
};

export type AccountEditionsCreateFieldInput = {
  node: EditionCreateInput;
};

export type AccountEditionsDeleteFieldInput = {
  delete?: InputMaybe<EditionDeleteInput>;
  where?: InputMaybe<AccountEditionsConnectionWhere>;
};

export type AccountEditionsDisconnectFieldInput = {
  disconnect?: InputMaybe<EditionDisconnectInput>;
  where?: InputMaybe<AccountEditionsConnectionWhere>;
};

export type AccountEditionsFieldInput = {
  connect?: InputMaybe<Array<AccountEditionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AccountEditionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AccountEditionsCreateFieldInput>>;
};

export type AccountEditionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AccountEditionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AccountEditionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AccountEditionsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountEditionsRelationship = {
  __typename?: 'AccountEditionsRelationship';
  cursor: Scalars['String']['output'];
  node: Edition;
};

export type AccountEditionsUpdateConnectionInput = {
  node?: InputMaybe<EditionUpdateInput>;
};

export type AccountEditionsUpdateFieldInput = {
  connect?: InputMaybe<Array<AccountEditionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AccountEditionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AccountEditionsCreateFieldInput>>;
  delete?: InputMaybe<Array<AccountEditionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AccountEditionsDisconnectFieldInput>>;
  update?: InputMaybe<AccountEditionsUpdateConnectionInput>;
  where?: InputMaybe<AccountEditionsConnectionWhere>;
};

export type AccountOnCreateInput = {
  accountStatus?: InputMaybe<AccountStatus>;
  joinedDate?: InputMaybe<Scalars['String']['input']>;
  lastActive?: InputMaybe<Scalars['String']['input']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  profileURL?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type AccountOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more AccountSort objects to sort Accounts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AccountSort>>;
};

export type AccountOwnerAggregateInput = {
  AND?: InputMaybe<Array<AccountOwnerAggregateInput>>;
  NOT?: InputMaybe<AccountOwnerAggregateInput>;
  OR?: InputMaybe<Array<AccountOwnerAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<AccountOwnerNodeAggregationWhereInput>;
};

export type AccountOwnerConnectFieldInput = {
  connect?: InputMaybe<OwnerConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<OwnerConnectWhere>;
};

export type AccountOwnerConnectOrCreateFieldInput = {
  onCreate: AccountOwnerConnectOrCreateFieldInputOnCreate;
  where: OwnerConnectOrCreateWhere;
};

export type AccountOwnerConnectOrCreateFieldInputOnCreate = {
  node: OwnerOnCreateInput;
};

export type AccountOwnerConnection = {
  __typename?: 'AccountOwnerConnection';
  edges: Array<AccountOwnerRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountOwnerConnectionSort = {
  node?: InputMaybe<OwnerSort>;
};

export type AccountOwnerConnectionWhere = {
  AND?: InputMaybe<Array<AccountOwnerConnectionWhere>>;
  NOT?: InputMaybe<AccountOwnerConnectionWhere>;
  OR?: InputMaybe<Array<AccountOwnerConnectionWhere>>;
  node?: InputMaybe<OwnerWhere>;
};

export type AccountOwnerCreateFieldInput = {
  node: OwnerCreateInput;
};

export type AccountOwnerDeleteFieldInput = {
  delete?: InputMaybe<OwnerDeleteInput>;
  where?: InputMaybe<AccountOwnerConnectionWhere>;
};

export type AccountOwnerDisconnectFieldInput = {
  disconnect?: InputMaybe<OwnerDisconnectInput>;
  where?: InputMaybe<AccountOwnerConnectionWhere>;
};

export type AccountOwnerFieldInput = {
  connect?: InputMaybe<AccountOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<AccountOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<AccountOwnerCreateFieldInput>;
};

export type AccountOwnerNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AccountOwnerNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AccountOwnerNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AccountOwnerNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  nameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountOwnerOwnerAggregationSelection = {
  __typename?: 'AccountOwnerOwnerAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<AccountOwnerOwnerNodeAggregateSelection>;
};

export type AccountOwnerOwnerNodeAggregateSelection = {
  __typename?: 'AccountOwnerOwnerNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  nameLC: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type AccountOwnerRelationship = {
  __typename?: 'AccountOwnerRelationship';
  cursor: Scalars['String']['output'];
  node: Owner;
};

export type AccountOwnerUpdateConnectionInput = {
  node?: InputMaybe<OwnerUpdateInput>;
};

export type AccountOwnerUpdateFieldInput = {
  connect?: InputMaybe<AccountOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<AccountOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<AccountOwnerCreateFieldInput>;
  delete?: InputMaybe<AccountOwnerDeleteFieldInput>;
  disconnect?: InputMaybe<AccountOwnerDisconnectFieldInput>;
  update?: InputMaybe<AccountOwnerUpdateConnectionInput>;
  where?: InputMaybe<AccountOwnerConnectionWhere>;
};

export type AccountRelationInput = {
  channels?: InputMaybe<Array<AccountChannelsCreateFieldInput>>;
  creators?: InputMaybe<Array<AccountCreatorsCreateFieldInput>>;
  editions?: InputMaybe<Array<AccountEditionsCreateFieldInput>>;
  owner?: InputMaybe<AccountOwnerCreateFieldInput>;
  subscription?: InputMaybe<AccountSubscriptionCreateFieldInput>;
  users?: InputMaybe<Array<AccountUsersCreateFieldInput>>;
  works?: InputMaybe<Array<AccountWorksCreateFieldInput>>;
};

/** Fields to sort Accounts by. The order in which sorts are applied is not guaranteed when specifying many fields in one AccountSort object. */
export type AccountSort = {
  accountStatus?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  joinedDate?: InputMaybe<SortDirection>;
  lastActive?: InputMaybe<SortDirection>;
  onboardingStatus?: InputMaybe<SortDirection>;
  profileURL?: InputMaybe<SortDirection>;
  taxId?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
  username?: InputMaybe<SortDirection>;
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Hidden = 'HIDDEN',
  Inactive = 'INACTIVE',
  Setup = 'SETUP',
  Suspended = 'SUSPENDED'
}

export type AccountSubscription = {
  __typename?: 'AccountSubscription';
  /** The Account this AccountSubscription belongs to */
  account?: Maybe<Account>;
  accountAggregate?: Maybe<AccountSubscriptionAccountAccountAggregationSelection>;
  accountConnection: AccountSubscriptionAccountConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The price shown to users */
  displayPrice?: Maybe<Scalars['Float']['output']>;
  /** plan type */
  plan?: Maybe<PlanTypes>;
  /** Stripe price ID */
  priceId?: Maybe<Scalars['String']['output']>;
  /** Promotion code */
  promoCode?: Maybe<Scalars['String']['output']>;
  /** Stripe subscription ID */
  subscriptionId?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type AccountSubscriptionAccountArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<AccountOptions>;
  where?: InputMaybe<AccountWhere>;
};


export type AccountSubscriptionAccountAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccountWhere>;
};


export type AccountSubscriptionAccountConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AccountSubscriptionAccountConnectionSort>>;
  where?: InputMaybe<AccountSubscriptionAccountConnectionWhere>;
};

export type AccountSubscriptionAccountAccountAggregationSelection = {
  __typename?: 'AccountSubscriptionAccountAccountAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<AccountSubscriptionAccountAccountNodeAggregateSelection>;
};

export type AccountSubscriptionAccountAccountNodeAggregateSelection = {
  __typename?: 'AccountSubscriptionAccountAccountNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  joinedDate: StringAggregateSelectionNullable;
  lastActive: StringAggregateSelectionNullable;
  profileURL: StringAggregateSelectionNullable;
  taxId: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  username: StringAggregateSelectionNullable;
};

export type AccountSubscriptionAccountAggregateInput = {
  AND?: InputMaybe<Array<AccountSubscriptionAccountAggregateInput>>;
  NOT?: InputMaybe<AccountSubscriptionAccountAggregateInput>;
  OR?: InputMaybe<Array<AccountSubscriptionAccountAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<AccountSubscriptionAccountNodeAggregationWhereInput>;
};

export type AccountSubscriptionAccountConnectFieldInput = {
  connect?: InputMaybe<AccountConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<AccountConnectWhere>;
};

export type AccountSubscriptionAccountConnectOrCreateFieldInput = {
  onCreate: AccountSubscriptionAccountConnectOrCreateFieldInputOnCreate;
  where: AccountConnectOrCreateWhere;
};

export type AccountSubscriptionAccountConnectOrCreateFieldInputOnCreate = {
  node: AccountOnCreateInput;
};

export type AccountSubscriptionAccountConnection = {
  __typename?: 'AccountSubscriptionAccountConnection';
  edges: Array<AccountSubscriptionAccountRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountSubscriptionAccountConnectionSort = {
  node?: InputMaybe<AccountSort>;
};

export type AccountSubscriptionAccountConnectionWhere = {
  AND?: InputMaybe<Array<AccountSubscriptionAccountConnectionWhere>>;
  NOT?: InputMaybe<AccountSubscriptionAccountConnectionWhere>;
  OR?: InputMaybe<Array<AccountSubscriptionAccountConnectionWhere>>;
  node?: InputMaybe<AccountWhere>;
};

export type AccountSubscriptionAccountCreateFieldInput = {
  node: AccountCreateInput;
};

export type AccountSubscriptionAccountDeleteFieldInput = {
  delete?: InputMaybe<AccountDeleteInput>;
  where?: InputMaybe<AccountSubscriptionAccountConnectionWhere>;
};

export type AccountSubscriptionAccountDisconnectFieldInput = {
  disconnect?: InputMaybe<AccountDisconnectInput>;
  where?: InputMaybe<AccountSubscriptionAccountConnectionWhere>;
};

export type AccountSubscriptionAccountFieldInput = {
  connect?: InputMaybe<AccountSubscriptionAccountConnectFieldInput>;
  connectOrCreate?: InputMaybe<AccountSubscriptionAccountConnectOrCreateFieldInput>;
  create?: InputMaybe<AccountSubscriptionAccountCreateFieldInput>;
};

export type AccountSubscriptionAccountNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AccountSubscriptionAccountNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AccountSubscriptionAccountNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AccountSubscriptionAccountNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  joinedDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountSubscriptionAccountRelationship = {
  __typename?: 'AccountSubscriptionAccountRelationship';
  cursor: Scalars['String']['output'];
  node: Account;
};

export type AccountSubscriptionAccountUpdateConnectionInput = {
  node?: InputMaybe<AccountUpdateInput>;
};

export type AccountSubscriptionAccountUpdateFieldInput = {
  connect?: InputMaybe<AccountSubscriptionAccountConnectFieldInput>;
  connectOrCreate?: InputMaybe<AccountSubscriptionAccountConnectOrCreateFieldInput>;
  create?: InputMaybe<AccountSubscriptionAccountCreateFieldInput>;
  delete?: InputMaybe<AccountSubscriptionAccountDeleteFieldInput>;
  disconnect?: InputMaybe<AccountSubscriptionAccountDisconnectFieldInput>;
  update?: InputMaybe<AccountSubscriptionAccountUpdateConnectionInput>;
  where?: InputMaybe<AccountSubscriptionAccountConnectionWhere>;
};

export type AccountSubscriptionAggregateInput = {
  AND?: InputMaybe<Array<AccountSubscriptionAggregateInput>>;
  NOT?: InputMaybe<AccountSubscriptionAggregateInput>;
  OR?: InputMaybe<Array<AccountSubscriptionAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<AccountSubscriptionNodeAggregationWhereInput>;
};

export type AccountSubscriptionAggregateSelection = {
  __typename?: 'AccountSubscriptionAggregateSelection';
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  displayPrice: FloatAggregateSelectionNullable;
  priceId: StringAggregateSelectionNullable;
  promoCode: StringAggregateSelectionNullable;
  subscriptionId: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type AccountSubscriptionConnectFieldInput = {
  connect?: InputMaybe<AccountSubscriptionConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<AccountSubscriptionConnectWhere>;
};

export type AccountSubscriptionConnectInput = {
  account?: InputMaybe<AccountSubscriptionAccountConnectFieldInput>;
};

export type AccountSubscriptionConnectOrCreateInput = {
  account?: InputMaybe<AccountSubscriptionAccountConnectOrCreateFieldInput>;
};

export type AccountSubscriptionConnectWhere = {
  node: AccountSubscriptionWhere;
};

export type AccountSubscriptionConnection = {
  __typename?: 'AccountSubscriptionConnection';
  edges: Array<AccountSubscriptionRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountSubscriptionConnectionSort = {
  node?: InputMaybe<AccountSubscriptionSort>;
};

export type AccountSubscriptionConnectionWhere = {
  AND?: InputMaybe<Array<AccountSubscriptionConnectionWhere>>;
  NOT?: InputMaybe<AccountSubscriptionConnectionWhere>;
  OR?: InputMaybe<Array<AccountSubscriptionConnectionWhere>>;
  node?: InputMaybe<AccountSubscriptionWhere>;
};

export type AccountSubscriptionCreateFieldInput = {
  node: AccountSubscriptionCreateInput;
};

export type AccountSubscriptionCreateInput = {
  account?: InputMaybe<AccountSubscriptionAccountFieldInput>;
  displayPrice?: InputMaybe<Scalars['Float']['input']>;
  plan?: InputMaybe<PlanTypes>;
  priceId?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
};

export type AccountSubscriptionDeleteFieldInput = {
  delete?: InputMaybe<AccountSubscriptionDeleteInput>;
  where?: InputMaybe<AccountSubscriptionConnectionWhere>;
};

export type AccountSubscriptionDeleteInput = {
  account?: InputMaybe<AccountSubscriptionAccountDeleteFieldInput>;
};

export type AccountSubscriptionDisconnectFieldInput = {
  disconnect?: InputMaybe<AccountSubscriptionDisconnectInput>;
  where?: InputMaybe<AccountSubscriptionConnectionWhere>;
};

export type AccountSubscriptionDisconnectInput = {
  account?: InputMaybe<AccountSubscriptionAccountDisconnectFieldInput>;
};

export type AccountSubscriptionEdge = {
  __typename?: 'AccountSubscriptionEdge';
  cursor: Scalars['String']['output'];
  node: AccountSubscription;
};

export type AccountSubscriptionFieldInput = {
  connect?: InputMaybe<AccountSubscriptionConnectFieldInput>;
  create?: InputMaybe<AccountSubscriptionCreateFieldInput>;
};

export type AccountSubscriptionNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AccountSubscriptionNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AccountSubscriptionNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AccountSubscriptionNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  displayPrice_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_MAX_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_MAX_GT?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_MAX_GTE?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_MAX_LT?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_MAX_LTE?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_MIN_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_MIN_GT?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_MIN_GTE?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_MIN_LT?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_MIN_LTE?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_SUM_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_SUM_GT?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_SUM_GTE?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_SUM_LT?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_SUM_LTE?: InputMaybe<Scalars['Float']['input']>;
  priceId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  priceId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  priceId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  priceId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  priceId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  priceId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  priceId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  priceId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  priceId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  priceId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  priceId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  priceId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  priceId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  priceId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  priceId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  promoCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  promoCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  promoCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  promoCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  promoCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  promoCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  promoCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  promoCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  promoCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  promoCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  promoCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  promoCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  promoCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  promoCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  promoCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  subscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  subscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  subscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  subscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  subscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountSubscriptionOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more AccountSubscriptionSort objects to sort AccountSubscriptions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AccountSubscriptionSort>>;
};

export type AccountSubscriptionRelationInput = {
  account?: InputMaybe<AccountSubscriptionAccountCreateFieldInput>;
};

export type AccountSubscriptionRelationship = {
  __typename?: 'AccountSubscriptionRelationship';
  cursor: Scalars['String']['output'];
  node: AccountSubscription;
};

/** Fields to sort AccountSubscriptions by. The order in which sorts are applied is not guaranteed when specifying many fields in one AccountSubscriptionSort object. */
export type AccountSubscriptionSort = {
  createdAt?: InputMaybe<SortDirection>;
  displayPrice?: InputMaybe<SortDirection>;
  plan?: InputMaybe<SortDirection>;
  priceId?: InputMaybe<SortDirection>;
  promoCode?: InputMaybe<SortDirection>;
  subscriptionId?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type AccountSubscriptionUpdateConnectionInput = {
  node?: InputMaybe<AccountSubscriptionUpdateInput>;
};

export type AccountSubscriptionUpdateFieldInput = {
  connect?: InputMaybe<AccountSubscriptionConnectFieldInput>;
  create?: InputMaybe<AccountSubscriptionCreateFieldInput>;
  delete?: InputMaybe<AccountSubscriptionDeleteFieldInput>;
  disconnect?: InputMaybe<AccountSubscriptionDisconnectFieldInput>;
  update?: InputMaybe<AccountSubscriptionUpdateConnectionInput>;
  where?: InputMaybe<AccountSubscriptionConnectionWhere>;
};

export type AccountSubscriptionUpdateInput = {
  account?: InputMaybe<AccountSubscriptionAccountUpdateFieldInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayPrice?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_ADD?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_DIVIDE?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_MULTIPLY?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_SUBTRACT?: InputMaybe<Scalars['Float']['input']>;
  plan?: InputMaybe<PlanTypes>;
  priceId?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
};

export type AccountSubscriptionWhere = {
  AND?: InputMaybe<Array<AccountSubscriptionWhere>>;
  NOT?: InputMaybe<AccountSubscriptionWhere>;
  OR?: InputMaybe<Array<AccountSubscriptionWhere>>;
  account?: InputMaybe<AccountWhere>;
  accountAggregate?: InputMaybe<AccountSubscriptionAccountAggregateInput>;
  accountConnection?: InputMaybe<AccountSubscriptionAccountConnectionWhere>;
  accountConnection_NOT?: InputMaybe<AccountSubscriptionAccountConnectionWhere>;
  account_NOT?: InputMaybe<AccountWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  displayPrice?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_GT?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_GTE?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_IN?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  displayPrice_LT?: InputMaybe<Scalars['Float']['input']>;
  displayPrice_LTE?: InputMaybe<Scalars['Float']['input']>;
  plan?: InputMaybe<PlanTypes>;
  plan_IN?: InputMaybe<Array<InputMaybe<PlanTypes>>>;
  priceId?: InputMaybe<Scalars['String']['input']>;
  priceId_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  priceId_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  priceId_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  priceId_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  promoCode_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  promoCode_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  promoCode_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  promoCode_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  subscriptionId_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  subscriptionId_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  subscriptionId_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subscriptionId_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountSubscriptionsConnection = {
  __typename?: 'AccountSubscriptionsConnection';
  edges: Array<AccountSubscriptionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type AccountUpdateInput = {
  accountStatus?: InputMaybe<AccountStatus>;
  channels?: InputMaybe<Array<AccountChannelsUpdateFieldInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creators?: InputMaybe<Array<AccountCreatorsUpdateFieldInput>>;
  editions?: InputMaybe<Array<AccountEditionsUpdateFieldInput>>;
  joinedDate?: InputMaybe<Scalars['String']['input']>;
  lastActive?: InputMaybe<Scalars['String']['input']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  owner?: InputMaybe<AccountOwnerUpdateFieldInput>;
  profileURL?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<AccountSubscriptionUpdateFieldInput>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<AccountUsersUpdateFieldInput>>;
  works?: InputMaybe<Array<AccountWorksUpdateFieldInput>>;
};

export type AccountUserUsersAggregationSelection = {
  __typename?: 'AccountUserUsersAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<AccountUserUsersNodeAggregateSelection>;
};

export type AccountUserUsersNodeAggregateSelection = {
  __typename?: 'AccountUserUsersNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  email: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  nickname: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  picture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  userId: IdAggregateSelectionNonNullable;
};

export type AccountUsersAggregateInput = {
  AND?: InputMaybe<Array<AccountUsersAggregateInput>>;
  NOT?: InputMaybe<AccountUsersAggregateInput>;
  OR?: InputMaybe<Array<AccountUsersAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<AccountUsersNodeAggregationWhereInput>;
};

export type AccountUsersConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserConnectWhere>;
};

export type AccountUsersConnectOrCreateFieldInput = {
  onCreate: AccountUsersConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type AccountUsersConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type AccountUsersConnection = {
  __typename?: 'AccountUsersConnection';
  edges: Array<AccountUsersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountUsersConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type AccountUsersConnectionWhere = {
  AND?: InputMaybe<Array<AccountUsersConnectionWhere>>;
  NOT?: InputMaybe<AccountUsersConnectionWhere>;
  OR?: InputMaybe<Array<AccountUsersConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type AccountUsersCreateFieldInput = {
  node: UserCreateInput;
};

export type AccountUsersDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<AccountUsersConnectionWhere>;
};

export type AccountUsersDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<AccountUsersConnectionWhere>;
};

export type AccountUsersFieldInput = {
  connect?: InputMaybe<Array<AccountUsersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AccountUsersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AccountUsersCreateFieldInput>>;
};

export type AccountUsersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AccountUsersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AccountUsersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AccountUsersNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nickname_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  picture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  picture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountUsersRelationship = {
  __typename?: 'AccountUsersRelationship';
  cursor: Scalars['String']['output'];
  node: User;
};

export type AccountUsersUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type AccountUsersUpdateFieldInput = {
  connect?: InputMaybe<Array<AccountUsersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AccountUsersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AccountUsersCreateFieldInput>>;
  delete?: InputMaybe<Array<AccountUsersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AccountUsersDisconnectFieldInput>>;
  update?: InputMaybe<AccountUsersUpdateConnectionInput>;
  where?: InputMaybe<AccountUsersConnectionWhere>;
};

export type AccountWhere = {
  AND?: InputMaybe<Array<AccountWhere>>;
  NOT?: InputMaybe<AccountWhere>;
  OR?: InputMaybe<Array<AccountWhere>>;
  accountStatus?: InputMaybe<AccountStatus>;
  accountStatus_IN?: InputMaybe<Array<InputMaybe<AccountStatus>>>;
  channelsAggregate?: InputMaybe<AccountChannelsAggregateInput>;
  /** Return Accounts where all of the related AccountChannelsConnections match this filter */
  channelsConnection_ALL?: InputMaybe<AccountChannelsConnectionWhere>;
  /** Return Accounts where none of the related AccountChannelsConnections match this filter */
  channelsConnection_NONE?: InputMaybe<AccountChannelsConnectionWhere>;
  /** Return Accounts where one of the related AccountChannelsConnections match this filter */
  channelsConnection_SINGLE?: InputMaybe<AccountChannelsConnectionWhere>;
  /** Return Accounts where some of the related AccountChannelsConnections match this filter */
  channelsConnection_SOME?: InputMaybe<AccountChannelsConnectionWhere>;
  /** Return Accounts where all of the related Channels match this filter */
  channels_ALL?: InputMaybe<ChannelWhere>;
  /** Return Accounts where none of the related Channels match this filter */
  channels_NONE?: InputMaybe<ChannelWhere>;
  /** Return Accounts where one of the related Channels match this filter */
  channels_SINGLE?: InputMaybe<ChannelWhere>;
  /** Return Accounts where some of the related Channels match this filter */
  channels_SOME?: InputMaybe<ChannelWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creatorsAggregate?: InputMaybe<AccountCreatorsAggregateInput>;
  /** Return Accounts where all of the related AccountCreatorsConnections match this filter */
  creatorsConnection_ALL?: InputMaybe<AccountCreatorsConnectionWhere>;
  /** Return Accounts where none of the related AccountCreatorsConnections match this filter */
  creatorsConnection_NONE?: InputMaybe<AccountCreatorsConnectionWhere>;
  /** Return Accounts where one of the related AccountCreatorsConnections match this filter */
  creatorsConnection_SINGLE?: InputMaybe<AccountCreatorsConnectionWhere>;
  /** Return Accounts where some of the related AccountCreatorsConnections match this filter */
  creatorsConnection_SOME?: InputMaybe<AccountCreatorsConnectionWhere>;
  /** Return Accounts where all of the related Creators match this filter */
  creators_ALL?: InputMaybe<CreatorWhere>;
  /** Return Accounts where none of the related Creators match this filter */
  creators_NONE?: InputMaybe<CreatorWhere>;
  /** Return Accounts where one of the related Creators match this filter */
  creators_SINGLE?: InputMaybe<CreatorWhere>;
  /** Return Accounts where some of the related Creators match this filter */
  creators_SOME?: InputMaybe<CreatorWhere>;
  editionsAggregate?: InputMaybe<AccountEditionsAggregateInput>;
  /** Return Accounts where all of the related AccountEditionsConnections match this filter */
  editionsConnection_ALL?: InputMaybe<AccountEditionsConnectionWhere>;
  /** Return Accounts where none of the related AccountEditionsConnections match this filter */
  editionsConnection_NONE?: InputMaybe<AccountEditionsConnectionWhere>;
  /** Return Accounts where one of the related AccountEditionsConnections match this filter */
  editionsConnection_SINGLE?: InputMaybe<AccountEditionsConnectionWhere>;
  /** Return Accounts where some of the related AccountEditionsConnections match this filter */
  editionsConnection_SOME?: InputMaybe<AccountEditionsConnectionWhere>;
  /** Return Accounts where all of the related Editions match this filter */
  editions_ALL?: InputMaybe<EditionWhere>;
  /** Return Accounts where none of the related Editions match this filter */
  editions_NONE?: InputMaybe<EditionWhere>;
  /** Return Accounts where one of the related Editions match this filter */
  editions_SINGLE?: InputMaybe<EditionWhere>;
  /** Return Accounts where some of the related Editions match this filter */
  editions_SOME?: InputMaybe<EditionWhere>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  joinedDate?: InputMaybe<Scalars['String']['input']>;
  joinedDate_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  joinedDate_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  joinedDate_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  joinedDate_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  lastActive?: InputMaybe<Scalars['String']['input']>;
  lastActive_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  lastActive_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  lastActive_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastActive_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  onboardingStatus_IN?: InputMaybe<Array<InputMaybe<OnboardingStatus>>>;
  owner?: InputMaybe<OwnerWhere>;
  ownerAggregate?: InputMaybe<AccountOwnerAggregateInput>;
  ownerConnection?: InputMaybe<AccountOwnerConnectionWhere>;
  ownerConnection_NOT?: InputMaybe<AccountOwnerConnectionWhere>;
  owner_NOT?: InputMaybe<OwnerWhere>;
  profileURL?: InputMaybe<Scalars['String']['input']>;
  profileURL_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  profileURL_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  profileURL_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  profileURL_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<AccountSubscriptionWhere>;
  subscriptionAggregate?: InputMaybe<AccountSubscriptionAggregateInput>;
  subscriptionConnection?: InputMaybe<AccountSubscriptionConnectionWhere>;
  subscriptionConnection_NOT?: InputMaybe<AccountSubscriptionConnectionWhere>;
  subscription_NOT?: InputMaybe<AccountSubscriptionWhere>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  taxId_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  taxId_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  taxId_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taxId_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  username_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  username_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  username_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  username_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  usersAggregate?: InputMaybe<AccountUsersAggregateInput>;
  /** Return Accounts where all of the related AccountUsersConnections match this filter */
  usersConnection_ALL?: InputMaybe<AccountUsersConnectionWhere>;
  /** Return Accounts where none of the related AccountUsersConnections match this filter */
  usersConnection_NONE?: InputMaybe<AccountUsersConnectionWhere>;
  /** Return Accounts where one of the related AccountUsersConnections match this filter */
  usersConnection_SINGLE?: InputMaybe<AccountUsersConnectionWhere>;
  /** Return Accounts where some of the related AccountUsersConnections match this filter */
  usersConnection_SOME?: InputMaybe<AccountUsersConnectionWhere>;
  /** Return Accounts where all of the related Users match this filter */
  users_ALL?: InputMaybe<UserWhere>;
  /** Return Accounts where none of the related Users match this filter */
  users_NONE?: InputMaybe<UserWhere>;
  /** Return Accounts where one of the related Users match this filter */
  users_SINGLE?: InputMaybe<UserWhere>;
  /** Return Accounts where some of the related Users match this filter */
  users_SOME?: InputMaybe<UserWhere>;
  worksAggregate?: InputMaybe<AccountWorksAggregateInput>;
  /** Return Accounts where all of the related AccountWorksConnections match this filter */
  worksConnection_ALL?: InputMaybe<AccountWorksConnectionWhere>;
  /** Return Accounts where none of the related AccountWorksConnections match this filter */
  worksConnection_NONE?: InputMaybe<AccountWorksConnectionWhere>;
  /** Return Accounts where one of the related AccountWorksConnections match this filter */
  worksConnection_SINGLE?: InputMaybe<AccountWorksConnectionWhere>;
  /** Return Accounts where some of the related AccountWorksConnections match this filter */
  worksConnection_SOME?: InputMaybe<AccountWorksConnectionWhere>;
  /** Return Accounts where all of the related Works match this filter */
  works_ALL?: InputMaybe<WorkWhere>;
  /** Return Accounts where none of the related Works match this filter */
  works_NONE?: InputMaybe<WorkWhere>;
  /** Return Accounts where one of the related Works match this filter */
  works_SINGLE?: InputMaybe<WorkWhere>;
  /** Return Accounts where some of the related Works match this filter */
  works_SOME?: InputMaybe<WorkWhere>;
};

export type AccountWorkWorksAggregationSelection = {
  __typename?: 'AccountWorkWorksAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<AccountWorkWorksNodeAggregateSelection>;
};

export type AccountWorkWorksNodeAggregateSelection = {
  __typename?: 'AccountWorkWorksNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  workUrl: StringAggregateSelectionNullable;
};

export type AccountWorksAggregateInput = {
  AND?: InputMaybe<Array<AccountWorksAggregateInput>>;
  NOT?: InputMaybe<AccountWorksAggregateInput>;
  OR?: InputMaybe<Array<AccountWorksAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<AccountWorksNodeAggregationWhereInput>;
};

export type AccountWorksConnectFieldInput = {
  connect?: InputMaybe<Array<WorkConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<WorkConnectWhere>;
};

export type AccountWorksConnectOrCreateFieldInput = {
  onCreate: AccountWorksConnectOrCreateFieldInputOnCreate;
  where: WorkConnectOrCreateWhere;
};

export type AccountWorksConnectOrCreateFieldInputOnCreate = {
  node: WorkOnCreateInput;
};

export type AccountWorksConnection = {
  __typename?: 'AccountWorksConnection';
  edges: Array<AccountWorksRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountWorksConnectionSort = {
  node?: InputMaybe<WorkSort>;
};

export type AccountWorksConnectionWhere = {
  AND?: InputMaybe<Array<AccountWorksConnectionWhere>>;
  NOT?: InputMaybe<AccountWorksConnectionWhere>;
  OR?: InputMaybe<Array<AccountWorksConnectionWhere>>;
  node?: InputMaybe<WorkWhere>;
};

export type AccountWorksCreateFieldInput = {
  node: WorkCreateInput;
};

export type AccountWorksDeleteFieldInput = {
  delete?: InputMaybe<WorkDeleteInput>;
  where?: InputMaybe<AccountWorksConnectionWhere>;
};

export type AccountWorksDisconnectFieldInput = {
  disconnect?: InputMaybe<WorkDisconnectInput>;
  where?: InputMaybe<AccountWorksConnectionWhere>;
};

export type AccountWorksFieldInput = {
  connect?: InputMaybe<Array<AccountWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AccountWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AccountWorksCreateFieldInput>>;
};

export type AccountWorksNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AccountWorksNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AccountWorksNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AccountWorksNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  workUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountWorksRelationship = {
  __typename?: 'AccountWorksRelationship';
  cursor: Scalars['String']['output'];
  node: Work;
};

export type AccountWorksUpdateConnectionInput = {
  node?: InputMaybe<WorkUpdateInput>;
};

export type AccountWorksUpdateFieldInput = {
  connect?: InputMaybe<Array<AccountWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AccountWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AccountWorksCreateFieldInput>>;
  delete?: InputMaybe<Array<AccountWorksDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AccountWorksDisconnectFieldInput>>;
  update?: InputMaybe<AccountWorksUpdateConnectionInput>;
  where?: InputMaybe<AccountWorksConnectionWhere>;
};

export type AccountsConnection = {
  __typename?: 'AccountsConnection';
  edges: Array<AccountEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum AutoCorrectToolsTypes {
  NotUsed = 'NOT_USED',
  UsedInWritingEditing = 'USED_IN_WRITING_EDITING'
}

/** Categories */
export type CategoryTag = {
  __typename?: 'CategoryTag';
  /** Certification Requests in this category */
  certificationRequests: Array<CertificationRequest>;
  certificationRequestsAggregate?: Maybe<CategoryTagCertificationRequestCertificationRequestsAggregationSelection>;
  certificationRequestsConnection: CategoryTagCertificationRequestsConnection;
  /** Channels in this category */
  channels: Array<Channel>;
  channelsAggregate?: Maybe<CategoryTagChannelChannelsAggregationSelection>;
  channelsConnection: CategoryTagChannelsConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Category Description */
  description?: Maybe<Scalars['String']['output']>;
  /** Editions in this category */
  editions: Array<Edition>;
  editionsAggregate?: Maybe<CategoryTagEditionEditionsAggregationSelection>;
  editionsConnection: CategoryTagEditionsConnection;
  /** Category Icon Name */
  icon?: Maybe<Scalars['String']['output']>;
  /** Code generated GUID */
  id: Scalars['ID']['output'];
  /** Parent Category */
  parentTag: Array<CategoryTag>;
  parentTagAggregate?: Maybe<CategoryTagCategoryTagParentTagAggregationSelection>;
  parentTagConnection: CategoryTagParentTagConnection;
  /** Children Categories */
  tags: Array<CategoryTag>;
  tagsAggregate?: Maybe<CategoryTagCategoryTagTagsAggregationSelection>;
  tagsConnection: CategoryTagTagsConnection;
  /** Category Tier (Root is 0) */
  tier?: Maybe<Scalars['Int']['output']>;
  /** Category title */
  title?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Works in this category */
  works: Array<Work>;
  worksAggregate?: Maybe<CategoryTagWorkWorksAggregationSelection>;
  worksConnection: CategoryTagWorksConnection;
};


/** Categories */
export type CategoryTagCertificationRequestsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CertificationRequestOptions>;
  where?: InputMaybe<CertificationRequestWhere>;
};


/** Categories */
export type CategoryTagCertificationRequestsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CertificationRequestWhere>;
};


/** Categories */
export type CategoryTagCertificationRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CategoryTagCertificationRequestsConnectionSort>>;
  where?: InputMaybe<CategoryTagCertificationRequestsConnectionWhere>;
};


/** Categories */
export type CategoryTagChannelsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<ChannelOptions>;
  where?: InputMaybe<ChannelWhere>;
};


/** Categories */
export type CategoryTagChannelsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ChannelWhere>;
};


/** Categories */
export type CategoryTagChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CategoryTagChannelsConnectionSort>>;
  where?: InputMaybe<CategoryTagChannelsConnectionWhere>;
};


/** Categories */
export type CategoryTagEditionsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<EditionOptions>;
  where?: InputMaybe<EditionWhere>;
};


/** Categories */
export type CategoryTagEditionsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<EditionWhere>;
};


/** Categories */
export type CategoryTagEditionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CategoryTagEditionsConnectionSort>>;
  where?: InputMaybe<CategoryTagEditionsConnectionWhere>;
};


/** Categories */
export type CategoryTagParentTagArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CategoryTagOptions>;
  where?: InputMaybe<CategoryTagWhere>;
};


/** Categories */
export type CategoryTagParentTagAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CategoryTagWhere>;
};


/** Categories */
export type CategoryTagParentTagConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CategoryTagParentTagConnectionSort>>;
  where?: InputMaybe<CategoryTagParentTagConnectionWhere>;
};


/** Categories */
export type CategoryTagTagsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CategoryTagOptions>;
  where?: InputMaybe<CategoryTagWhere>;
};


/** Categories */
export type CategoryTagTagsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CategoryTagWhere>;
};


/** Categories */
export type CategoryTagTagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CategoryTagTagsConnectionSort>>;
  where?: InputMaybe<CategoryTagTagsConnectionWhere>;
};


/** Categories */
export type CategoryTagWorksArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<WorkOptions>;
  where?: InputMaybe<WorkWhere>;
};


/** Categories */
export type CategoryTagWorksAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<WorkWhere>;
};


/** Categories */
export type CategoryTagWorksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CategoryTagWorksConnectionSort>>;
  where?: InputMaybe<CategoryTagWorksConnectionWhere>;
};

export type CategoryTagAggregateSelection = {
  __typename?: 'CategoryTagAggregateSelection';
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  icon: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CategoryTagCategoryTagParentTagAggregationSelection = {
  __typename?: 'CategoryTagCategoryTagParentTagAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CategoryTagCategoryTagParentTagNodeAggregateSelection>;
};

export type CategoryTagCategoryTagParentTagNodeAggregateSelection = {
  __typename?: 'CategoryTagCategoryTagParentTagNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  icon: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CategoryTagCategoryTagTagsAggregationSelection = {
  __typename?: 'CategoryTagCategoryTagTagsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CategoryTagCategoryTagTagsNodeAggregateSelection>;
};

export type CategoryTagCategoryTagTagsNodeAggregateSelection = {
  __typename?: 'CategoryTagCategoryTagTagsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  icon: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CategoryTagCertificationRequestCertificationRequestsAggregationSelection = {
  __typename?: 'CategoryTagCertificationRequestCertificationRequestsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CategoryTagCertificationRequestCertificationRequestsNodeAggregateSelection>;
};

export type CategoryTagCertificationRequestCertificationRequestsNodeAggregateSelection = {
  __typename?: 'CategoryTagCertificationRequestCertificationRequestsNodeAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  channelDescription: StringAggregateSelectionNullable;
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  email: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  name: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  requestSignature: StringAggregateSelectionNullable;
  result: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CategoryTagCertificationRequestsAggregateInput = {
  AND?: InputMaybe<Array<CategoryTagCertificationRequestsAggregateInput>>;
  NOT?: InputMaybe<CategoryTagCertificationRequestsAggregateInput>;
  OR?: InputMaybe<Array<CategoryTagCertificationRequestsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CategoryTagCertificationRequestsNodeAggregationWhereInput>;
};

export type CategoryTagCertificationRequestsConnectFieldInput = {
  connect?: InputMaybe<Array<CertificationRequestConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CertificationRequestConnectWhere>;
};

export type CategoryTagCertificationRequestsConnectOrCreateFieldInput = {
  onCreate: CategoryTagCertificationRequestsConnectOrCreateFieldInputOnCreate;
  where: CertificationRequestConnectOrCreateWhere;
};

export type CategoryTagCertificationRequestsConnectOrCreateFieldInputOnCreate = {
  node: CertificationRequestOnCreateInput;
};

export type CategoryTagCertificationRequestsConnection = {
  __typename?: 'CategoryTagCertificationRequestsConnection';
  edges: Array<CategoryTagCertificationRequestsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryTagCertificationRequestsConnectionSort = {
  node?: InputMaybe<CertificationRequestSort>;
};

export type CategoryTagCertificationRequestsConnectionWhere = {
  AND?: InputMaybe<Array<CategoryTagCertificationRequestsConnectionWhere>>;
  NOT?: InputMaybe<CategoryTagCertificationRequestsConnectionWhere>;
  OR?: InputMaybe<Array<CategoryTagCertificationRequestsConnectionWhere>>;
  node?: InputMaybe<CertificationRequestWhere>;
};

export type CategoryTagCertificationRequestsCreateFieldInput = {
  node: CertificationRequestCreateInput;
};

export type CategoryTagCertificationRequestsDeleteFieldInput = {
  delete?: InputMaybe<CertificationRequestDeleteInput>;
  where?: InputMaybe<CategoryTagCertificationRequestsConnectionWhere>;
};

export type CategoryTagCertificationRequestsDisconnectFieldInput = {
  disconnect?: InputMaybe<CertificationRequestDisconnectInput>;
  where?: InputMaybe<CategoryTagCertificationRequestsConnectionWhere>;
};

export type CategoryTagCertificationRequestsFieldInput = {
  connect?: InputMaybe<Array<CategoryTagCertificationRequestsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryTagCertificationRequestsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CategoryTagCertificationRequestsCreateFieldInput>>;
};

export type CategoryTagCertificationRequestsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CategoryTagCertificationRequestsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CategoryTagCertificationRequestsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CategoryTagCertificationRequestsNodeAggregationWhereInput>>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  result_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryTagCertificationRequestsRelationship = {
  __typename?: 'CategoryTagCertificationRequestsRelationship';
  cursor: Scalars['String']['output'];
  node: CertificationRequest;
};

export type CategoryTagCertificationRequestsUpdateConnectionInput = {
  node?: InputMaybe<CertificationRequestUpdateInput>;
};

export type CategoryTagCertificationRequestsUpdateFieldInput = {
  connect?: InputMaybe<Array<CategoryTagCertificationRequestsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryTagCertificationRequestsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CategoryTagCertificationRequestsCreateFieldInput>>;
  delete?: InputMaybe<Array<CategoryTagCertificationRequestsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CategoryTagCertificationRequestsDisconnectFieldInput>>;
  update?: InputMaybe<CategoryTagCertificationRequestsUpdateConnectionInput>;
  where?: InputMaybe<CategoryTagCertificationRequestsConnectionWhere>;
};

export type CategoryTagChannelChannelsAggregationSelection = {
  __typename?: 'CategoryTagChannelChannelsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CategoryTagChannelChannelsNodeAggregateSelection>;
};

export type CategoryTagChannelChannelsNodeAggregateSelection = {
  __typename?: 'CategoryTagChannelChannelsNodeAggregateSelection';
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CategoryTagChannelsAggregateInput = {
  AND?: InputMaybe<Array<CategoryTagChannelsAggregateInput>>;
  NOT?: InputMaybe<CategoryTagChannelsAggregateInput>;
  OR?: InputMaybe<Array<CategoryTagChannelsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CategoryTagChannelsNodeAggregationWhereInput>;
};

export type CategoryTagChannelsConnectFieldInput = {
  connect?: InputMaybe<Array<ChannelConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<ChannelConnectWhere>;
};

export type CategoryTagChannelsConnectOrCreateFieldInput = {
  onCreate: CategoryTagChannelsConnectOrCreateFieldInputOnCreate;
  where: ChannelConnectOrCreateWhere;
};

export type CategoryTagChannelsConnectOrCreateFieldInputOnCreate = {
  node: ChannelOnCreateInput;
};

export type CategoryTagChannelsConnection = {
  __typename?: 'CategoryTagChannelsConnection';
  edges: Array<CategoryTagChannelsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryTagChannelsConnectionSort = {
  node?: InputMaybe<ChannelSort>;
};

export type CategoryTagChannelsConnectionWhere = {
  AND?: InputMaybe<Array<CategoryTagChannelsConnectionWhere>>;
  NOT?: InputMaybe<CategoryTagChannelsConnectionWhere>;
  OR?: InputMaybe<Array<CategoryTagChannelsConnectionWhere>>;
  node?: InputMaybe<ChannelWhere>;
};

export type CategoryTagChannelsCreateFieldInput = {
  node: ChannelCreateInput;
};

export type CategoryTagChannelsDeleteFieldInput = {
  delete?: InputMaybe<ChannelDeleteInput>;
  where?: InputMaybe<CategoryTagChannelsConnectionWhere>;
};

export type CategoryTagChannelsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChannelDisconnectInput>;
  where?: InputMaybe<CategoryTagChannelsConnectionWhere>;
};

export type CategoryTagChannelsFieldInput = {
  connect?: InputMaybe<Array<CategoryTagChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryTagChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CategoryTagChannelsCreateFieldInput>>;
};

export type CategoryTagChannelsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CategoryTagChannelsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CategoryTagChannelsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CategoryTagChannelsNodeAggregationWhereInput>>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryTagChannelsRelationship = {
  __typename?: 'CategoryTagChannelsRelationship';
  cursor: Scalars['String']['output'];
  node: Channel;
};

export type CategoryTagChannelsUpdateConnectionInput = {
  node?: InputMaybe<ChannelUpdateInput>;
};

export type CategoryTagChannelsUpdateFieldInput = {
  connect?: InputMaybe<Array<CategoryTagChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryTagChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CategoryTagChannelsCreateFieldInput>>;
  delete?: InputMaybe<Array<CategoryTagChannelsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CategoryTagChannelsDisconnectFieldInput>>;
  update?: InputMaybe<CategoryTagChannelsUpdateConnectionInput>;
  where?: InputMaybe<CategoryTagChannelsConnectionWhere>;
};

export type CategoryTagConnectInput = {
  certificationRequests?: InputMaybe<Array<CategoryTagCertificationRequestsConnectFieldInput>>;
  channels?: InputMaybe<Array<CategoryTagChannelsConnectFieldInput>>;
  editions?: InputMaybe<Array<CategoryTagEditionsConnectFieldInput>>;
  parentTag?: InputMaybe<Array<CategoryTagParentTagConnectFieldInput>>;
  tags?: InputMaybe<Array<CategoryTagTagsConnectFieldInput>>;
  works?: InputMaybe<Array<CategoryTagWorksConnectFieldInput>>;
};

export type CategoryTagConnectOrCreateInput = {
  certificationRequests?: InputMaybe<Array<CategoryTagCertificationRequestsConnectOrCreateFieldInput>>;
  channels?: InputMaybe<Array<CategoryTagChannelsConnectOrCreateFieldInput>>;
  editions?: InputMaybe<Array<CategoryTagEditionsConnectOrCreateFieldInput>>;
  parentTag?: InputMaybe<Array<CategoryTagParentTagConnectOrCreateFieldInput>>;
  tags?: InputMaybe<Array<CategoryTagTagsConnectOrCreateFieldInput>>;
  works?: InputMaybe<Array<CategoryTagWorksConnectOrCreateFieldInput>>;
};

export type CategoryTagConnectOrCreateWhere = {
  node: CategoryTagUniqueWhere;
};

export type CategoryTagConnectWhere = {
  node: CategoryTagWhere;
};

export type CategoryTagCreateInput = {
  certificationRequests?: InputMaybe<CategoryTagCertificationRequestsFieldInput>;
  channels?: InputMaybe<CategoryTagChannelsFieldInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  editions?: InputMaybe<CategoryTagEditionsFieldInput>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  parentTag?: InputMaybe<CategoryTagParentTagFieldInput>;
  tags?: InputMaybe<CategoryTagTagsFieldInput>;
  tier?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  works?: InputMaybe<CategoryTagWorksFieldInput>;
};

export type CategoryTagDeleteInput = {
  certificationRequests?: InputMaybe<Array<CategoryTagCertificationRequestsDeleteFieldInput>>;
  channels?: InputMaybe<Array<CategoryTagChannelsDeleteFieldInput>>;
  editions?: InputMaybe<Array<CategoryTagEditionsDeleteFieldInput>>;
  parentTag?: InputMaybe<Array<CategoryTagParentTagDeleteFieldInput>>;
  tags?: InputMaybe<Array<CategoryTagTagsDeleteFieldInput>>;
  works?: InputMaybe<Array<CategoryTagWorksDeleteFieldInput>>;
};

export type CategoryTagDisconnectInput = {
  certificationRequests?: InputMaybe<Array<CategoryTagCertificationRequestsDisconnectFieldInput>>;
  channels?: InputMaybe<Array<CategoryTagChannelsDisconnectFieldInput>>;
  editions?: InputMaybe<Array<CategoryTagEditionsDisconnectFieldInput>>;
  parentTag?: InputMaybe<Array<CategoryTagParentTagDisconnectFieldInput>>;
  tags?: InputMaybe<Array<CategoryTagTagsDisconnectFieldInput>>;
  works?: InputMaybe<Array<CategoryTagWorksDisconnectFieldInput>>;
};

export type CategoryTagEdge = {
  __typename?: 'CategoryTagEdge';
  cursor: Scalars['String']['output'];
  node: CategoryTag;
};

export type CategoryTagEditionEditionsAggregationSelection = {
  __typename?: 'CategoryTagEditionEditionsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CategoryTagEditionEditionsNodeAggregateSelection>;
};

export type CategoryTagEditionEditionsNodeAggregateSelection = {
  __typename?: 'CategoryTagEditionEditionsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type CategoryTagEditionsAggregateInput = {
  AND?: InputMaybe<Array<CategoryTagEditionsAggregateInput>>;
  NOT?: InputMaybe<CategoryTagEditionsAggregateInput>;
  OR?: InputMaybe<Array<CategoryTagEditionsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CategoryTagEditionsNodeAggregationWhereInput>;
};

export type CategoryTagEditionsConnectFieldInput = {
  connect?: InputMaybe<Array<EditionConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<EditionConnectWhere>;
};

export type CategoryTagEditionsConnectOrCreateFieldInput = {
  onCreate: CategoryTagEditionsConnectOrCreateFieldInputOnCreate;
  where: EditionConnectOrCreateWhere;
};

export type CategoryTagEditionsConnectOrCreateFieldInputOnCreate = {
  node: EditionOnCreateInput;
};

export type CategoryTagEditionsConnection = {
  __typename?: 'CategoryTagEditionsConnection';
  edges: Array<CategoryTagEditionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryTagEditionsConnectionSort = {
  node?: InputMaybe<EditionSort>;
};

export type CategoryTagEditionsConnectionWhere = {
  AND?: InputMaybe<Array<CategoryTagEditionsConnectionWhere>>;
  NOT?: InputMaybe<CategoryTagEditionsConnectionWhere>;
  OR?: InputMaybe<Array<CategoryTagEditionsConnectionWhere>>;
  node?: InputMaybe<EditionWhere>;
};

export type CategoryTagEditionsCreateFieldInput = {
  node: EditionCreateInput;
};

export type CategoryTagEditionsDeleteFieldInput = {
  delete?: InputMaybe<EditionDeleteInput>;
  where?: InputMaybe<CategoryTagEditionsConnectionWhere>;
};

export type CategoryTagEditionsDisconnectFieldInput = {
  disconnect?: InputMaybe<EditionDisconnectInput>;
  where?: InputMaybe<CategoryTagEditionsConnectionWhere>;
};

export type CategoryTagEditionsFieldInput = {
  connect?: InputMaybe<Array<CategoryTagEditionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryTagEditionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CategoryTagEditionsCreateFieldInput>>;
};

export type CategoryTagEditionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CategoryTagEditionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CategoryTagEditionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CategoryTagEditionsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryTagEditionsRelationship = {
  __typename?: 'CategoryTagEditionsRelationship';
  cursor: Scalars['String']['output'];
  node: Edition;
};

export type CategoryTagEditionsUpdateConnectionInput = {
  node?: InputMaybe<EditionUpdateInput>;
};

export type CategoryTagEditionsUpdateFieldInput = {
  connect?: InputMaybe<Array<CategoryTagEditionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryTagEditionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CategoryTagEditionsCreateFieldInput>>;
  delete?: InputMaybe<Array<CategoryTagEditionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CategoryTagEditionsDisconnectFieldInput>>;
  update?: InputMaybe<CategoryTagEditionsUpdateConnectionInput>;
  where?: InputMaybe<CategoryTagEditionsConnectionWhere>;
};

export type CategoryTagOnCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  tier?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryTagOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more CategoryTagSort objects to sort CategoryTags by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CategoryTagSort>>;
};

export type CategoryTagParentTagAggregateInput = {
  AND?: InputMaybe<Array<CategoryTagParentTagAggregateInput>>;
  NOT?: InputMaybe<CategoryTagParentTagAggregateInput>;
  OR?: InputMaybe<Array<CategoryTagParentTagAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CategoryTagParentTagNodeAggregationWhereInput>;
};

export type CategoryTagParentTagConnectFieldInput = {
  connect?: InputMaybe<Array<CategoryTagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CategoryTagConnectWhere>;
};

export type CategoryTagParentTagConnectOrCreateFieldInput = {
  onCreate: CategoryTagParentTagConnectOrCreateFieldInputOnCreate;
  where: CategoryTagConnectOrCreateWhere;
};

export type CategoryTagParentTagConnectOrCreateFieldInputOnCreate = {
  node: CategoryTagOnCreateInput;
};

export type CategoryTagParentTagConnection = {
  __typename?: 'CategoryTagParentTagConnection';
  edges: Array<CategoryTagParentTagRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryTagParentTagConnectionSort = {
  node?: InputMaybe<CategoryTagSort>;
};

export type CategoryTagParentTagConnectionWhere = {
  AND?: InputMaybe<Array<CategoryTagParentTagConnectionWhere>>;
  NOT?: InputMaybe<CategoryTagParentTagConnectionWhere>;
  OR?: InputMaybe<Array<CategoryTagParentTagConnectionWhere>>;
  node?: InputMaybe<CategoryTagWhere>;
};

export type CategoryTagParentTagCreateFieldInput = {
  node: CategoryTagCreateInput;
};

export type CategoryTagParentTagDeleteFieldInput = {
  delete?: InputMaybe<CategoryTagDeleteInput>;
  where?: InputMaybe<CategoryTagParentTagConnectionWhere>;
};

export type CategoryTagParentTagDisconnectFieldInput = {
  disconnect?: InputMaybe<CategoryTagDisconnectInput>;
  where?: InputMaybe<CategoryTagParentTagConnectionWhere>;
};

export type CategoryTagParentTagFieldInput = {
  connect?: InputMaybe<Array<CategoryTagParentTagConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryTagParentTagConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CategoryTagParentTagCreateFieldInput>>;
};

export type CategoryTagParentTagNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CategoryTagParentTagNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CategoryTagParentTagNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CategoryTagParentTagNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  icon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  icon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  tier_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryTagParentTagRelationship = {
  __typename?: 'CategoryTagParentTagRelationship';
  cursor: Scalars['String']['output'];
  node: CategoryTag;
};

export type CategoryTagParentTagUpdateConnectionInput = {
  node?: InputMaybe<CategoryTagUpdateInput>;
};

export type CategoryTagParentTagUpdateFieldInput = {
  connect?: InputMaybe<Array<CategoryTagParentTagConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryTagParentTagConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CategoryTagParentTagCreateFieldInput>>;
  delete?: InputMaybe<Array<CategoryTagParentTagDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CategoryTagParentTagDisconnectFieldInput>>;
  update?: InputMaybe<CategoryTagParentTagUpdateConnectionInput>;
  where?: InputMaybe<CategoryTagParentTagConnectionWhere>;
};

export type CategoryTagRelationInput = {
  certificationRequests?: InputMaybe<Array<CategoryTagCertificationRequestsCreateFieldInput>>;
  channels?: InputMaybe<Array<CategoryTagChannelsCreateFieldInput>>;
  editions?: InputMaybe<Array<CategoryTagEditionsCreateFieldInput>>;
  parentTag?: InputMaybe<Array<CategoryTagParentTagCreateFieldInput>>;
  tags?: InputMaybe<Array<CategoryTagTagsCreateFieldInput>>;
  works?: InputMaybe<Array<CategoryTagWorksCreateFieldInput>>;
};

/** Category Requests */
export type CategoryTagRequest = {
  __typename?: 'CategoryTagRequest';
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Category Description */
  description?: Maybe<Scalars['String']['output']>;
  /** Request has been fulfilled */
  fulfilled?: Maybe<Scalars['Boolean']['output']>;
  /** System generated GUID */
  id: Scalars['ID']['output'];
  /** Requested by which user */
  requestedBy: User;
  requestedByAggregate?: Maybe<CategoryTagRequestUserRequestedByAggregationSelection>;
  requestedByConnection: CategoryTagRequestRequestedByConnection;
  /** Category title */
  title?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


/** Category Requests */
export type CategoryTagRequestRequestedByArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};


/** Category Requests */
export type CategoryTagRequestRequestedByAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserWhere>;
};


/** Category Requests */
export type CategoryTagRequestRequestedByConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CategoryTagRequestRequestedByConnectionSort>>;
  where?: InputMaybe<CategoryTagRequestRequestedByConnectionWhere>;
};

export type CategoryTagRequestAggregateSelection = {
  __typename?: 'CategoryTagRequestAggregateSelection';
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CategoryTagRequestConnectInput = {
  requestedBy?: InputMaybe<CategoryTagRequestRequestedByConnectFieldInput>;
};

export type CategoryTagRequestConnectOrCreateInput = {
  requestedBy?: InputMaybe<CategoryTagRequestRequestedByConnectOrCreateFieldInput>;
};

export type CategoryTagRequestCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fulfilled?: InputMaybe<Scalars['Boolean']['input']>;
  requestedBy?: InputMaybe<CategoryTagRequestRequestedByFieldInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryTagRequestDeleteInput = {
  requestedBy?: InputMaybe<CategoryTagRequestRequestedByDeleteFieldInput>;
};

export type CategoryTagRequestDisconnectInput = {
  requestedBy?: InputMaybe<CategoryTagRequestRequestedByDisconnectFieldInput>;
};

export type CategoryTagRequestEdge = {
  __typename?: 'CategoryTagRequestEdge';
  cursor: Scalars['String']['output'];
  node: CategoryTagRequest;
};

export type CategoryTagRequestOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more CategoryTagRequestSort objects to sort CategoryTagRequests by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CategoryTagRequestSort>>;
};

export type CategoryTagRequestRelationInput = {
  requestedBy?: InputMaybe<CategoryTagRequestRequestedByCreateFieldInput>;
};

export type CategoryTagRequestRequestedByAggregateInput = {
  AND?: InputMaybe<Array<CategoryTagRequestRequestedByAggregateInput>>;
  NOT?: InputMaybe<CategoryTagRequestRequestedByAggregateInput>;
  OR?: InputMaybe<Array<CategoryTagRequestRequestedByAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CategoryTagRequestRequestedByNodeAggregationWhereInput>;
};

export type CategoryTagRequestRequestedByConnectFieldInput = {
  connect?: InputMaybe<UserConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserConnectWhere>;
};

export type CategoryTagRequestRequestedByConnectOrCreateFieldInput = {
  onCreate: CategoryTagRequestRequestedByConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type CategoryTagRequestRequestedByConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type CategoryTagRequestRequestedByConnection = {
  __typename?: 'CategoryTagRequestRequestedByConnection';
  edges: Array<CategoryTagRequestRequestedByRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryTagRequestRequestedByConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type CategoryTagRequestRequestedByConnectionWhere = {
  AND?: InputMaybe<Array<CategoryTagRequestRequestedByConnectionWhere>>;
  NOT?: InputMaybe<CategoryTagRequestRequestedByConnectionWhere>;
  OR?: InputMaybe<Array<CategoryTagRequestRequestedByConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type CategoryTagRequestRequestedByCreateFieldInput = {
  node: UserCreateInput;
};

export type CategoryTagRequestRequestedByDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<CategoryTagRequestRequestedByConnectionWhere>;
};

export type CategoryTagRequestRequestedByDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<CategoryTagRequestRequestedByConnectionWhere>;
};

export type CategoryTagRequestRequestedByFieldInput = {
  connect?: InputMaybe<CategoryTagRequestRequestedByConnectFieldInput>;
  connectOrCreate?: InputMaybe<CategoryTagRequestRequestedByConnectOrCreateFieldInput>;
  create?: InputMaybe<CategoryTagRequestRequestedByCreateFieldInput>;
};

export type CategoryTagRequestRequestedByNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CategoryTagRequestRequestedByNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CategoryTagRequestRequestedByNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CategoryTagRequestRequestedByNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nickname_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  picture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  picture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryTagRequestRequestedByRelationship = {
  __typename?: 'CategoryTagRequestRequestedByRelationship';
  cursor: Scalars['String']['output'];
  node: User;
};

export type CategoryTagRequestRequestedByUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type CategoryTagRequestRequestedByUpdateFieldInput = {
  connect?: InputMaybe<CategoryTagRequestRequestedByConnectFieldInput>;
  connectOrCreate?: InputMaybe<CategoryTagRequestRequestedByConnectOrCreateFieldInput>;
  create?: InputMaybe<CategoryTagRequestRequestedByCreateFieldInput>;
  delete?: InputMaybe<CategoryTagRequestRequestedByDeleteFieldInput>;
  disconnect?: InputMaybe<CategoryTagRequestRequestedByDisconnectFieldInput>;
  update?: InputMaybe<CategoryTagRequestRequestedByUpdateConnectionInput>;
  where?: InputMaybe<CategoryTagRequestRequestedByConnectionWhere>;
};

/** Fields to sort CategoryTagRequests by. The order in which sorts are applied is not guaranteed when specifying many fields in one CategoryTagRequestSort object. */
export type CategoryTagRequestSort = {
  createdAt?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  fulfilled?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CategoryTagRequestUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fulfilled?: InputMaybe<Scalars['Boolean']['input']>;
  requestedBy?: InputMaybe<CategoryTagRequestRequestedByUpdateFieldInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryTagRequestUserRequestedByAggregationSelection = {
  __typename?: 'CategoryTagRequestUserRequestedByAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CategoryTagRequestUserRequestedByNodeAggregateSelection>;
};

export type CategoryTagRequestUserRequestedByNodeAggregateSelection = {
  __typename?: 'CategoryTagRequestUserRequestedByNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  email: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  nickname: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  picture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  userId: IdAggregateSelectionNonNullable;
};

export type CategoryTagRequestWhere = {
  AND?: InputMaybe<Array<CategoryTagRequestWhere>>;
  NOT?: InputMaybe<CategoryTagRequestWhere>;
  OR?: InputMaybe<Array<CategoryTagRequestWhere>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  description_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  fulfilled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  requestedBy?: InputMaybe<UserWhere>;
  requestedByAggregate?: InputMaybe<CategoryTagRequestRequestedByAggregateInput>;
  requestedByConnection?: InputMaybe<CategoryTagRequestRequestedByConnectionWhere>;
  requestedByConnection_NOT?: InputMaybe<CategoryTagRequestRequestedByConnectionWhere>;
  requestedBy_NOT?: InputMaybe<UserWhere>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  title_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  title_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryTagRequestsConnection = {
  __typename?: 'CategoryTagRequestsConnection';
  edges: Array<CategoryTagRequestEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Fields to sort CategoryTags by. The order in which sorts are applied is not guaranteed when specifying many fields in one CategoryTagSort object. */
export type CategoryTagSort = {
  createdAt?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  icon?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  tier?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CategoryTagTagsAggregateInput = {
  AND?: InputMaybe<Array<CategoryTagTagsAggregateInput>>;
  NOT?: InputMaybe<CategoryTagTagsAggregateInput>;
  OR?: InputMaybe<Array<CategoryTagTagsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CategoryTagTagsNodeAggregationWhereInput>;
};

export type CategoryTagTagsConnectFieldInput = {
  connect?: InputMaybe<Array<CategoryTagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CategoryTagConnectWhere>;
};

export type CategoryTagTagsConnectOrCreateFieldInput = {
  onCreate: CategoryTagTagsConnectOrCreateFieldInputOnCreate;
  where: CategoryTagConnectOrCreateWhere;
};

export type CategoryTagTagsConnectOrCreateFieldInputOnCreate = {
  node: CategoryTagOnCreateInput;
};

export type CategoryTagTagsConnection = {
  __typename?: 'CategoryTagTagsConnection';
  edges: Array<CategoryTagTagsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryTagTagsConnectionSort = {
  node?: InputMaybe<CategoryTagSort>;
};

export type CategoryTagTagsConnectionWhere = {
  AND?: InputMaybe<Array<CategoryTagTagsConnectionWhere>>;
  NOT?: InputMaybe<CategoryTagTagsConnectionWhere>;
  OR?: InputMaybe<Array<CategoryTagTagsConnectionWhere>>;
  node?: InputMaybe<CategoryTagWhere>;
};

export type CategoryTagTagsCreateFieldInput = {
  node: CategoryTagCreateInput;
};

export type CategoryTagTagsDeleteFieldInput = {
  delete?: InputMaybe<CategoryTagDeleteInput>;
  where?: InputMaybe<CategoryTagTagsConnectionWhere>;
};

export type CategoryTagTagsDisconnectFieldInput = {
  disconnect?: InputMaybe<CategoryTagDisconnectInput>;
  where?: InputMaybe<CategoryTagTagsConnectionWhere>;
};

export type CategoryTagTagsFieldInput = {
  connect?: InputMaybe<Array<CategoryTagTagsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryTagTagsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CategoryTagTagsCreateFieldInput>>;
};

export type CategoryTagTagsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CategoryTagTagsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CategoryTagTagsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CategoryTagTagsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  icon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  icon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  tier_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryTagTagsRelationship = {
  __typename?: 'CategoryTagTagsRelationship';
  cursor: Scalars['String']['output'];
  node: CategoryTag;
};

export type CategoryTagTagsUpdateConnectionInput = {
  node?: InputMaybe<CategoryTagUpdateInput>;
};

export type CategoryTagTagsUpdateFieldInput = {
  connect?: InputMaybe<Array<CategoryTagTagsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryTagTagsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CategoryTagTagsCreateFieldInput>>;
  delete?: InputMaybe<Array<CategoryTagTagsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CategoryTagTagsDisconnectFieldInput>>;
  update?: InputMaybe<CategoryTagTagsUpdateConnectionInput>;
  where?: InputMaybe<CategoryTagTagsConnectionWhere>;
};

export type CategoryTagUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CategoryTagUpdateInput = {
  certificationRequests?: InputMaybe<Array<CategoryTagCertificationRequestsUpdateFieldInput>>;
  channels?: InputMaybe<Array<CategoryTagChannelsUpdateFieldInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  editions?: InputMaybe<Array<CategoryTagEditionsUpdateFieldInput>>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  parentTag?: InputMaybe<Array<CategoryTagParentTagUpdateFieldInput>>;
  tags?: InputMaybe<Array<CategoryTagTagsUpdateFieldInput>>;
  tier?: InputMaybe<Scalars['Int']['input']>;
  tier_DECREMENT?: InputMaybe<Scalars['Int']['input']>;
  tier_INCREMENT?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  works?: InputMaybe<Array<CategoryTagWorksUpdateFieldInput>>;
};

export type CategoryTagWhere = {
  AND?: InputMaybe<Array<CategoryTagWhere>>;
  NOT?: InputMaybe<CategoryTagWhere>;
  OR?: InputMaybe<Array<CategoryTagWhere>>;
  certificationRequestsAggregate?: InputMaybe<CategoryTagCertificationRequestsAggregateInput>;
  /** Return CategoryTags where all of the related CategoryTagCertificationRequestsConnections match this filter */
  certificationRequestsConnection_ALL?: InputMaybe<CategoryTagCertificationRequestsConnectionWhere>;
  /** Return CategoryTags where none of the related CategoryTagCertificationRequestsConnections match this filter */
  certificationRequestsConnection_NONE?: InputMaybe<CategoryTagCertificationRequestsConnectionWhere>;
  /** Return CategoryTags where one of the related CategoryTagCertificationRequestsConnections match this filter */
  certificationRequestsConnection_SINGLE?: InputMaybe<CategoryTagCertificationRequestsConnectionWhere>;
  /** Return CategoryTags where some of the related CategoryTagCertificationRequestsConnections match this filter */
  certificationRequestsConnection_SOME?: InputMaybe<CategoryTagCertificationRequestsConnectionWhere>;
  /** Return CategoryTags where all of the related CertificationRequests match this filter */
  certificationRequests_ALL?: InputMaybe<CertificationRequestWhere>;
  /** Return CategoryTags where none of the related CertificationRequests match this filter */
  certificationRequests_NONE?: InputMaybe<CertificationRequestWhere>;
  /** Return CategoryTags where one of the related CertificationRequests match this filter */
  certificationRequests_SINGLE?: InputMaybe<CertificationRequestWhere>;
  /** Return CategoryTags where some of the related CertificationRequests match this filter */
  certificationRequests_SOME?: InputMaybe<CertificationRequestWhere>;
  channelsAggregate?: InputMaybe<CategoryTagChannelsAggregateInput>;
  /** Return CategoryTags where all of the related CategoryTagChannelsConnections match this filter */
  channelsConnection_ALL?: InputMaybe<CategoryTagChannelsConnectionWhere>;
  /** Return CategoryTags where none of the related CategoryTagChannelsConnections match this filter */
  channelsConnection_NONE?: InputMaybe<CategoryTagChannelsConnectionWhere>;
  /** Return CategoryTags where one of the related CategoryTagChannelsConnections match this filter */
  channelsConnection_SINGLE?: InputMaybe<CategoryTagChannelsConnectionWhere>;
  /** Return CategoryTags where some of the related CategoryTagChannelsConnections match this filter */
  channelsConnection_SOME?: InputMaybe<CategoryTagChannelsConnectionWhere>;
  /** Return CategoryTags where all of the related Channels match this filter */
  channels_ALL?: InputMaybe<ChannelWhere>;
  /** Return CategoryTags where none of the related Channels match this filter */
  channels_NONE?: InputMaybe<ChannelWhere>;
  /** Return CategoryTags where one of the related Channels match this filter */
  channels_SINGLE?: InputMaybe<ChannelWhere>;
  /** Return CategoryTags where some of the related Channels match this filter */
  channels_SOME?: InputMaybe<ChannelWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  description_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  editionsAggregate?: InputMaybe<CategoryTagEditionsAggregateInput>;
  /** Return CategoryTags where all of the related CategoryTagEditionsConnections match this filter */
  editionsConnection_ALL?: InputMaybe<CategoryTagEditionsConnectionWhere>;
  /** Return CategoryTags where none of the related CategoryTagEditionsConnections match this filter */
  editionsConnection_NONE?: InputMaybe<CategoryTagEditionsConnectionWhere>;
  /** Return CategoryTags where one of the related CategoryTagEditionsConnections match this filter */
  editionsConnection_SINGLE?: InputMaybe<CategoryTagEditionsConnectionWhere>;
  /** Return CategoryTags where some of the related CategoryTagEditionsConnections match this filter */
  editionsConnection_SOME?: InputMaybe<CategoryTagEditionsConnectionWhere>;
  /** Return CategoryTags where all of the related Editions match this filter */
  editions_ALL?: InputMaybe<EditionWhere>;
  /** Return CategoryTags where none of the related Editions match this filter */
  editions_NONE?: InputMaybe<EditionWhere>;
  /** Return CategoryTags where one of the related Editions match this filter */
  editions_SINGLE?: InputMaybe<EditionWhere>;
  /** Return CategoryTags where some of the related Editions match this filter */
  editions_SOME?: InputMaybe<EditionWhere>;
  icon?: InputMaybe<Scalars['String']['input']>;
  icon_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  icon_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  icon_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  icon_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  parentTagAggregate?: InputMaybe<CategoryTagParentTagAggregateInput>;
  /** Return CategoryTags where all of the related CategoryTagParentTagConnections match this filter */
  parentTagConnection_ALL?: InputMaybe<CategoryTagParentTagConnectionWhere>;
  /** Return CategoryTags where none of the related CategoryTagParentTagConnections match this filter */
  parentTagConnection_NONE?: InputMaybe<CategoryTagParentTagConnectionWhere>;
  /** Return CategoryTags where one of the related CategoryTagParentTagConnections match this filter */
  parentTagConnection_SINGLE?: InputMaybe<CategoryTagParentTagConnectionWhere>;
  /** Return CategoryTags where some of the related CategoryTagParentTagConnections match this filter */
  parentTagConnection_SOME?: InputMaybe<CategoryTagParentTagConnectionWhere>;
  /** Return CategoryTags where all of the related CategoryTags match this filter */
  parentTag_ALL?: InputMaybe<CategoryTagWhere>;
  /** Return CategoryTags where none of the related CategoryTags match this filter */
  parentTag_NONE?: InputMaybe<CategoryTagWhere>;
  /** Return CategoryTags where one of the related CategoryTags match this filter */
  parentTag_SINGLE?: InputMaybe<CategoryTagWhere>;
  /** Return CategoryTags where some of the related CategoryTags match this filter */
  parentTag_SOME?: InputMaybe<CategoryTagWhere>;
  tagsAggregate?: InputMaybe<CategoryTagTagsAggregateInput>;
  /** Return CategoryTags where all of the related CategoryTagTagsConnections match this filter */
  tagsConnection_ALL?: InputMaybe<CategoryTagTagsConnectionWhere>;
  /** Return CategoryTags where none of the related CategoryTagTagsConnections match this filter */
  tagsConnection_NONE?: InputMaybe<CategoryTagTagsConnectionWhere>;
  /** Return CategoryTags where one of the related CategoryTagTagsConnections match this filter */
  tagsConnection_SINGLE?: InputMaybe<CategoryTagTagsConnectionWhere>;
  /** Return CategoryTags where some of the related CategoryTagTagsConnections match this filter */
  tagsConnection_SOME?: InputMaybe<CategoryTagTagsConnectionWhere>;
  /** Return CategoryTags where all of the related CategoryTags match this filter */
  tags_ALL?: InputMaybe<CategoryTagWhere>;
  /** Return CategoryTags where none of the related CategoryTags match this filter */
  tags_NONE?: InputMaybe<CategoryTagWhere>;
  /** Return CategoryTags where one of the related CategoryTags match this filter */
  tags_SINGLE?: InputMaybe<CategoryTagWhere>;
  /** Return CategoryTags where some of the related CategoryTags match this filter */
  tags_SOME?: InputMaybe<CategoryTagWhere>;
  tier?: InputMaybe<Scalars['Int']['input']>;
  tier_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_IN?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tier_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_LTE?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  title_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  title_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  worksAggregate?: InputMaybe<CategoryTagWorksAggregateInput>;
  /** Return CategoryTags where all of the related CategoryTagWorksConnections match this filter */
  worksConnection_ALL?: InputMaybe<CategoryTagWorksConnectionWhere>;
  /** Return CategoryTags where none of the related CategoryTagWorksConnections match this filter */
  worksConnection_NONE?: InputMaybe<CategoryTagWorksConnectionWhere>;
  /** Return CategoryTags where one of the related CategoryTagWorksConnections match this filter */
  worksConnection_SINGLE?: InputMaybe<CategoryTagWorksConnectionWhere>;
  /** Return CategoryTags where some of the related CategoryTagWorksConnections match this filter */
  worksConnection_SOME?: InputMaybe<CategoryTagWorksConnectionWhere>;
  /** Return CategoryTags where all of the related Works match this filter */
  works_ALL?: InputMaybe<WorkWhere>;
  /** Return CategoryTags where none of the related Works match this filter */
  works_NONE?: InputMaybe<WorkWhere>;
  /** Return CategoryTags where one of the related Works match this filter */
  works_SINGLE?: InputMaybe<WorkWhere>;
  /** Return CategoryTags where some of the related Works match this filter */
  works_SOME?: InputMaybe<WorkWhere>;
};

export type CategoryTagWorkWorksAggregationSelection = {
  __typename?: 'CategoryTagWorkWorksAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CategoryTagWorkWorksNodeAggregateSelection>;
};

export type CategoryTagWorkWorksNodeAggregateSelection = {
  __typename?: 'CategoryTagWorkWorksNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  workUrl: StringAggregateSelectionNullable;
};

export type CategoryTagWorksAggregateInput = {
  AND?: InputMaybe<Array<CategoryTagWorksAggregateInput>>;
  NOT?: InputMaybe<CategoryTagWorksAggregateInput>;
  OR?: InputMaybe<Array<CategoryTagWorksAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CategoryTagWorksNodeAggregationWhereInput>;
};

export type CategoryTagWorksConnectFieldInput = {
  connect?: InputMaybe<Array<WorkConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<WorkConnectWhere>;
};

export type CategoryTagWorksConnectOrCreateFieldInput = {
  onCreate: CategoryTagWorksConnectOrCreateFieldInputOnCreate;
  where: WorkConnectOrCreateWhere;
};

export type CategoryTagWorksConnectOrCreateFieldInputOnCreate = {
  node: WorkOnCreateInput;
};

export type CategoryTagWorksConnection = {
  __typename?: 'CategoryTagWorksConnection';
  edges: Array<CategoryTagWorksRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryTagWorksConnectionSort = {
  node?: InputMaybe<WorkSort>;
};

export type CategoryTagWorksConnectionWhere = {
  AND?: InputMaybe<Array<CategoryTagWorksConnectionWhere>>;
  NOT?: InputMaybe<CategoryTagWorksConnectionWhere>;
  OR?: InputMaybe<Array<CategoryTagWorksConnectionWhere>>;
  node?: InputMaybe<WorkWhere>;
};

export type CategoryTagWorksCreateFieldInput = {
  node: WorkCreateInput;
};

export type CategoryTagWorksDeleteFieldInput = {
  delete?: InputMaybe<WorkDeleteInput>;
  where?: InputMaybe<CategoryTagWorksConnectionWhere>;
};

export type CategoryTagWorksDisconnectFieldInput = {
  disconnect?: InputMaybe<WorkDisconnectInput>;
  where?: InputMaybe<CategoryTagWorksConnectionWhere>;
};

export type CategoryTagWorksFieldInput = {
  connect?: InputMaybe<Array<CategoryTagWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryTagWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CategoryTagWorksCreateFieldInput>>;
};

export type CategoryTagWorksNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CategoryTagWorksNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CategoryTagWorksNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CategoryTagWorksNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  workUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryTagWorksRelationship = {
  __typename?: 'CategoryTagWorksRelationship';
  cursor: Scalars['String']['output'];
  node: Work;
};

export type CategoryTagWorksUpdateConnectionInput = {
  node?: InputMaybe<WorkUpdateInput>;
};

export type CategoryTagWorksUpdateFieldInput = {
  connect?: InputMaybe<Array<CategoryTagWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryTagWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CategoryTagWorksCreateFieldInput>>;
  delete?: InputMaybe<Array<CategoryTagWorksDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CategoryTagWorksDisconnectFieldInput>>;
  update?: InputMaybe<CategoryTagWorksUpdateConnectionInput>;
  where?: InputMaybe<CategoryTagWorksConnectionWhere>;
};

export type CategoryTagsConnection = {
  __typename?: 'CategoryTagsConnection';
  edges: Array<CategoryTagEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Content Origin Certification Request */
export type CertificationRequest = {
  __typename?: 'CertificationRequest';
  /** Auto Correct Tools Usage */
  autoCorrectTools: Array<AutoCorrectToolsTypes>;
  /** Auto Correct Tools Explanation */
  autoCorrectToolsExplanation?: Maybe<Scalars['String']['output']>;
  /** Certification Responses */
  certificationResponses: Array<CertificationResponse>;
  certificationResponsesAggregate?: Maybe<CertificationRequestCertificationResponseCertificationResponsesAggregationSelection>;
  certificationResponsesConnection: CertificationRequestCertificationResponsesConnection;
  /** Channel Description */
  channelDescription?: Maybe<Scalars['String']['output']>;
  /** Channel Name */
  channelName?: Maybe<Scalars['String']['output']>;
  /** Channel URL */
  channelUrl?: Maybe<Scalars['String']['output']>;
  /** Content categories */
  contentCategories: Array<CategoryTag>;
  contentCategoriesAggregate?: Maybe<CertificationRequestCategoryTagContentCategoriesAggregationSelection>;
  contentCategoriesConnection: CertificationRequestContentCategoriesConnection;
  /** Content types */
  contentTypes: Array<TypeTag>;
  contentTypesAggregate?: Maybe<CertificationRequestTypeTagContentTypesAggregationSelection>;
  contentTypesConnection: CertificationRequestContentTypesConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** User Who Created This Certification Request */
  createdBy?: Maybe<User>;
  createdByAggregate?: Maybe<CertificationRequestUserCreatedByAggregationSelection>;
  createdByConnection: CertificationRequestCreatedByConnection;
  /** Author Email */
  email?: Maybe<Scalars['String']['output']>;
  /** Generative AI Usage */
  generativeAi: Array<GenerativeAiTypes>;
  /** Generative AI Explanation */
  generativeAiExplanation?: Maybe<Scalars['String']['output']>;
  /** System generated GUID */
  id: Scalars['ID']['output'];
  /** Machine Learning Tools Usage */
  machineLearningTools: Array<MachineLearningToolsTypes>;
  /** Machine Learning Tools Explanation */
  machineLearningToolsExplanation?: Maybe<Scalars['String']['output']>;
  /** Content Media */
  media: Array<UserMedia>;
  mediaAggregate?: Maybe<CertificationRequestUserMediaMediaAggregationSelection>;
  mediaConnection: CertificationRequestMediaConnection;
  /** Author Name */
  name?: Maybe<Scalars['String']['output']>;
  /** Other AI Tools Usage */
  otherAiTools: Array<OtherAiToolsTypes>;
  /** Other AI Tools Explanation */
  otherAiToolsExplanation?: Maybe<Scalars['String']['output']>;
  /** Request Date */
  requestDate?: Maybe<Scalars['Date']['output']>;
  /** Request Signature */
  requestSignature?: Maybe<Scalars['String']['output']>;
  /** Certification Result (TODO: we might need more fields or different data type to store the result or conclusion) */
  result?: Maybe<Scalars['String']['output']>;
  /** Request Status */
  status?: Maybe<RequestStatuses>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


/** Content Origin Certification Request */
export type CertificationRequestCertificationResponsesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CertificationResponseOptions>;
  where?: InputMaybe<CertificationResponseWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestCertificationResponsesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CertificationResponseWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestCertificationResponsesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CertificationRequestCertificationResponsesConnectionSort>>;
  where?: InputMaybe<CertificationRequestCertificationResponsesConnectionWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestContentCategoriesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CategoryTagOptions>;
  where?: InputMaybe<CategoryTagWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestContentCategoriesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CategoryTagWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestContentCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CertificationRequestContentCategoriesConnectionSort>>;
  where?: InputMaybe<CertificationRequestContentCategoriesConnectionWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestContentTypesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TypeTagOptions>;
  where?: InputMaybe<TypeTagWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestContentTypesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TypeTagWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestContentTypesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CertificationRequestContentTypesConnectionSort>>;
  where?: InputMaybe<CertificationRequestContentTypesConnectionWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestCreatedByArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestCreatedByAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestCreatedByConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CertificationRequestCreatedByConnectionSort>>;
  where?: InputMaybe<CertificationRequestCreatedByConnectionWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestMediaArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserMediaOptions>;
  where?: InputMaybe<UserMediaWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestMediaAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserMediaWhere>;
};


/** Content Origin Certification Request */
export type CertificationRequestMediaConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CertificationRequestMediaConnectionSort>>;
  where?: InputMaybe<CertificationRequestMediaConnectionWhere>;
};

export type CertificationRequestAggregateSelection = {
  __typename?: 'CertificationRequestAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  channelDescription: StringAggregateSelectionNullable;
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  email: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  name: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  requestSignature: StringAggregateSelectionNullable;
  result: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CertificationRequestCategoryTagContentCategoriesAggregationSelection = {
  __typename?: 'CertificationRequestCategoryTagContentCategoriesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CertificationRequestCategoryTagContentCategoriesNodeAggregateSelection>;
};

export type CertificationRequestCategoryTagContentCategoriesNodeAggregateSelection = {
  __typename?: 'CertificationRequestCategoryTagContentCategoriesNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  icon: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CertificationRequestCertificationResponseCertificationResponsesAggregationSelection = {
  __typename?: 'CertificationRequestCertificationResponseCertificationResponsesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CertificationRequestCertificationResponseCertificationResponsesNodeAggregateSelection>;
};

export type CertificationRequestCertificationResponseCertificationResponsesNodeAggregateSelection = {
  __typename?: 'CertificationRequestCertificationResponseCertificationResponsesNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  result: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CertificationRequestCertificationResponsesAggregateInput = {
  AND?: InputMaybe<Array<CertificationRequestCertificationResponsesAggregateInput>>;
  NOT?: InputMaybe<CertificationRequestCertificationResponsesAggregateInput>;
  OR?: InputMaybe<Array<CertificationRequestCertificationResponsesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CertificationRequestCertificationResponsesNodeAggregationWhereInput>;
};

export type CertificationRequestCertificationResponsesConnectFieldInput = {
  connect?: InputMaybe<Array<CertificationResponseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CertificationResponseConnectWhere>;
};

export type CertificationRequestCertificationResponsesConnectOrCreateFieldInput = {
  onCreate: CertificationRequestCertificationResponsesConnectOrCreateFieldInputOnCreate;
  where: CertificationResponseConnectOrCreateWhere;
};

export type CertificationRequestCertificationResponsesConnectOrCreateFieldInputOnCreate = {
  node: CertificationResponseOnCreateInput;
};

export type CertificationRequestCertificationResponsesConnection = {
  __typename?: 'CertificationRequestCertificationResponsesConnection';
  edges: Array<CertificationRequestCertificationResponsesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CertificationRequestCertificationResponsesConnectionSort = {
  node?: InputMaybe<CertificationResponseSort>;
};

export type CertificationRequestCertificationResponsesConnectionWhere = {
  AND?: InputMaybe<Array<CertificationRequestCertificationResponsesConnectionWhere>>;
  NOT?: InputMaybe<CertificationRequestCertificationResponsesConnectionWhere>;
  OR?: InputMaybe<Array<CertificationRequestCertificationResponsesConnectionWhere>>;
  node?: InputMaybe<CertificationResponseWhere>;
};

export type CertificationRequestCertificationResponsesCreateFieldInput = {
  node: CertificationResponseCreateInput;
};

export type CertificationRequestCertificationResponsesDeleteFieldInput = {
  delete?: InputMaybe<CertificationResponseDeleteInput>;
  where?: InputMaybe<CertificationRequestCertificationResponsesConnectionWhere>;
};

export type CertificationRequestCertificationResponsesDisconnectFieldInput = {
  disconnect?: InputMaybe<CertificationResponseDisconnectInput>;
  where?: InputMaybe<CertificationRequestCertificationResponsesConnectionWhere>;
};

export type CertificationRequestCertificationResponsesFieldInput = {
  connect?: InputMaybe<Array<CertificationRequestCertificationResponsesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CertificationRequestCertificationResponsesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CertificationRequestCertificationResponsesCreateFieldInput>>;
};

export type CertificationRequestCertificationResponsesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CertificationRequestCertificationResponsesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CertificationRequestCertificationResponsesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CertificationRequestCertificationResponsesNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  result_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  result_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CertificationRequestCertificationResponsesRelationship = {
  __typename?: 'CertificationRequestCertificationResponsesRelationship';
  cursor: Scalars['String']['output'];
  node: CertificationResponse;
};

export type CertificationRequestCertificationResponsesUpdateConnectionInput = {
  node?: InputMaybe<CertificationResponseUpdateInput>;
};

export type CertificationRequestCertificationResponsesUpdateFieldInput = {
  connect?: InputMaybe<Array<CertificationRequestCertificationResponsesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CertificationRequestCertificationResponsesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CertificationRequestCertificationResponsesCreateFieldInput>>;
  delete?: InputMaybe<Array<CertificationRequestCertificationResponsesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CertificationRequestCertificationResponsesDisconnectFieldInput>>;
  update?: InputMaybe<CertificationRequestCertificationResponsesUpdateConnectionInput>;
  where?: InputMaybe<CertificationRequestCertificationResponsesConnectionWhere>;
};

export type CertificationRequestConnectInput = {
  certificationResponses?: InputMaybe<Array<CertificationRequestCertificationResponsesConnectFieldInput>>;
  contentCategories?: InputMaybe<Array<CertificationRequestContentCategoriesConnectFieldInput>>;
  contentTypes?: InputMaybe<Array<CertificationRequestContentTypesConnectFieldInput>>;
  createdBy?: InputMaybe<CertificationRequestCreatedByConnectFieldInput>;
  media?: InputMaybe<Array<CertificationRequestMediaConnectFieldInput>>;
};

export type CertificationRequestConnectOrCreateInput = {
  certificationResponses?: InputMaybe<Array<CertificationRequestCertificationResponsesConnectOrCreateFieldInput>>;
  contentCategories?: InputMaybe<Array<CertificationRequestContentCategoriesConnectOrCreateFieldInput>>;
  contentTypes?: InputMaybe<Array<CertificationRequestContentTypesConnectOrCreateFieldInput>>;
  createdBy?: InputMaybe<CertificationRequestCreatedByConnectOrCreateFieldInput>;
  media?: InputMaybe<Array<CertificationRequestMediaConnectOrCreateFieldInput>>;
};

export type CertificationRequestConnectOrCreateWhere = {
  node: CertificationRequestUniqueWhere;
};

export type CertificationRequestConnectWhere = {
  node: CertificationRequestWhere;
};

export type CertificationRequestContentCategoriesAggregateInput = {
  AND?: InputMaybe<Array<CertificationRequestContentCategoriesAggregateInput>>;
  NOT?: InputMaybe<CertificationRequestContentCategoriesAggregateInput>;
  OR?: InputMaybe<Array<CertificationRequestContentCategoriesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CertificationRequestContentCategoriesNodeAggregationWhereInput>;
};

export type CertificationRequestContentCategoriesConnectFieldInput = {
  connect?: InputMaybe<Array<CategoryTagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CategoryTagConnectWhere>;
};

export type CertificationRequestContentCategoriesConnectOrCreateFieldInput = {
  onCreate: CertificationRequestContentCategoriesConnectOrCreateFieldInputOnCreate;
  where: CategoryTagConnectOrCreateWhere;
};

export type CertificationRequestContentCategoriesConnectOrCreateFieldInputOnCreate = {
  node: CategoryTagOnCreateInput;
};

export type CertificationRequestContentCategoriesConnection = {
  __typename?: 'CertificationRequestContentCategoriesConnection';
  edges: Array<CertificationRequestContentCategoriesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CertificationRequestContentCategoriesConnectionSort = {
  node?: InputMaybe<CategoryTagSort>;
};

export type CertificationRequestContentCategoriesConnectionWhere = {
  AND?: InputMaybe<Array<CertificationRequestContentCategoriesConnectionWhere>>;
  NOT?: InputMaybe<CertificationRequestContentCategoriesConnectionWhere>;
  OR?: InputMaybe<Array<CertificationRequestContentCategoriesConnectionWhere>>;
  node?: InputMaybe<CategoryTagWhere>;
};

export type CertificationRequestContentCategoriesCreateFieldInput = {
  node: CategoryTagCreateInput;
};

export type CertificationRequestContentCategoriesDeleteFieldInput = {
  delete?: InputMaybe<CategoryTagDeleteInput>;
  where?: InputMaybe<CertificationRequestContentCategoriesConnectionWhere>;
};

export type CertificationRequestContentCategoriesDisconnectFieldInput = {
  disconnect?: InputMaybe<CategoryTagDisconnectInput>;
  where?: InputMaybe<CertificationRequestContentCategoriesConnectionWhere>;
};

export type CertificationRequestContentCategoriesFieldInput = {
  connect?: InputMaybe<Array<CertificationRequestContentCategoriesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CertificationRequestContentCategoriesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CertificationRequestContentCategoriesCreateFieldInput>>;
};

export type CertificationRequestContentCategoriesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CertificationRequestContentCategoriesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CertificationRequestContentCategoriesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CertificationRequestContentCategoriesNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  icon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  icon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  tier_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CertificationRequestContentCategoriesRelationship = {
  __typename?: 'CertificationRequestContentCategoriesRelationship';
  cursor: Scalars['String']['output'];
  node: CategoryTag;
};

export type CertificationRequestContentCategoriesUpdateConnectionInput = {
  node?: InputMaybe<CategoryTagUpdateInput>;
};

export type CertificationRequestContentCategoriesUpdateFieldInput = {
  connect?: InputMaybe<Array<CertificationRequestContentCategoriesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CertificationRequestContentCategoriesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CertificationRequestContentCategoriesCreateFieldInput>>;
  delete?: InputMaybe<Array<CertificationRequestContentCategoriesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CertificationRequestContentCategoriesDisconnectFieldInput>>;
  update?: InputMaybe<CertificationRequestContentCategoriesUpdateConnectionInput>;
  where?: InputMaybe<CertificationRequestContentCategoriesConnectionWhere>;
};

export type CertificationRequestContentTypesAggregateInput = {
  AND?: InputMaybe<Array<CertificationRequestContentTypesAggregateInput>>;
  NOT?: InputMaybe<CertificationRequestContentTypesAggregateInput>;
  OR?: InputMaybe<Array<CertificationRequestContentTypesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CertificationRequestContentTypesNodeAggregationWhereInput>;
};

export type CertificationRequestContentTypesConnectFieldInput = {
  connect?: InputMaybe<Array<TypeTagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<TypeTagConnectWhere>;
};

export type CertificationRequestContentTypesConnectOrCreateFieldInput = {
  onCreate: CertificationRequestContentTypesConnectOrCreateFieldInputOnCreate;
  where: TypeTagConnectOrCreateWhere;
};

export type CertificationRequestContentTypesConnectOrCreateFieldInputOnCreate = {
  node: TypeTagOnCreateInput;
};

export type CertificationRequestContentTypesConnection = {
  __typename?: 'CertificationRequestContentTypesConnection';
  edges: Array<CertificationRequestContentTypesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CertificationRequestContentTypesConnectionSort = {
  node?: InputMaybe<TypeTagSort>;
};

export type CertificationRequestContentTypesConnectionWhere = {
  AND?: InputMaybe<Array<CertificationRequestContentTypesConnectionWhere>>;
  NOT?: InputMaybe<CertificationRequestContentTypesConnectionWhere>;
  OR?: InputMaybe<Array<CertificationRequestContentTypesConnectionWhere>>;
  node?: InputMaybe<TypeTagWhere>;
};

export type CertificationRequestContentTypesCreateFieldInput = {
  node: TypeTagCreateInput;
};

export type CertificationRequestContentTypesDeleteFieldInput = {
  delete?: InputMaybe<TypeTagDeleteInput>;
  where?: InputMaybe<CertificationRequestContentTypesConnectionWhere>;
};

export type CertificationRequestContentTypesDisconnectFieldInput = {
  disconnect?: InputMaybe<TypeTagDisconnectInput>;
  where?: InputMaybe<CertificationRequestContentTypesConnectionWhere>;
};

export type CertificationRequestContentTypesFieldInput = {
  connect?: InputMaybe<Array<CertificationRequestContentTypesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CertificationRequestContentTypesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CertificationRequestContentTypesCreateFieldInput>>;
};

export type CertificationRequestContentTypesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CertificationRequestContentTypesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CertificationRequestContentTypesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CertificationRequestContentTypesNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  tier_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CertificationRequestContentTypesRelationship = {
  __typename?: 'CertificationRequestContentTypesRelationship';
  cursor: Scalars['String']['output'];
  node: TypeTag;
};

export type CertificationRequestContentTypesUpdateConnectionInput = {
  node?: InputMaybe<TypeTagUpdateInput>;
};

export type CertificationRequestContentTypesUpdateFieldInput = {
  connect?: InputMaybe<Array<CertificationRequestContentTypesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CertificationRequestContentTypesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CertificationRequestContentTypesCreateFieldInput>>;
  delete?: InputMaybe<Array<CertificationRequestContentTypesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CertificationRequestContentTypesDisconnectFieldInput>>;
  update?: InputMaybe<CertificationRequestContentTypesUpdateConnectionInput>;
  where?: InputMaybe<CertificationRequestContentTypesConnectionWhere>;
};

export type CertificationRequestCreateInput = {
  autoCorrectTools: Array<AutoCorrectToolsTypes>;
  autoCorrectToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  certificationResponses?: InputMaybe<CertificationRequestCertificationResponsesFieldInput>;
  channelDescription?: InputMaybe<Scalars['String']['input']>;
  channelName?: InputMaybe<Scalars['String']['input']>;
  channelUrl?: InputMaybe<Scalars['String']['input']>;
  contentCategories?: InputMaybe<CertificationRequestContentCategoriesFieldInput>;
  contentTypes?: InputMaybe<CertificationRequestContentTypesFieldInput>;
  createdBy?: InputMaybe<CertificationRequestCreatedByFieldInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  generativeAi: Array<GenerativeAiTypes>;
  generativeAiExplanation?: InputMaybe<Scalars['String']['input']>;
  machineLearningTools: Array<MachineLearningToolsTypes>;
  machineLearningToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<CertificationRequestMediaFieldInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  otherAiTools: Array<OtherAiToolsTypes>;
  otherAiToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  requestDate?: InputMaybe<Scalars['Date']['input']>;
  requestSignature?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RequestStatuses>;
};

export type CertificationRequestCreatedByAggregateInput = {
  AND?: InputMaybe<Array<CertificationRequestCreatedByAggregateInput>>;
  NOT?: InputMaybe<CertificationRequestCreatedByAggregateInput>;
  OR?: InputMaybe<Array<CertificationRequestCreatedByAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CertificationRequestCreatedByNodeAggregationWhereInput>;
};

export type CertificationRequestCreatedByConnectFieldInput = {
  connect?: InputMaybe<UserConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserConnectWhere>;
};

export type CertificationRequestCreatedByConnectOrCreateFieldInput = {
  onCreate: CertificationRequestCreatedByConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type CertificationRequestCreatedByConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type CertificationRequestCreatedByConnection = {
  __typename?: 'CertificationRequestCreatedByConnection';
  edges: Array<CertificationRequestCreatedByRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CertificationRequestCreatedByConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type CertificationRequestCreatedByConnectionWhere = {
  AND?: InputMaybe<Array<CertificationRequestCreatedByConnectionWhere>>;
  NOT?: InputMaybe<CertificationRequestCreatedByConnectionWhere>;
  OR?: InputMaybe<Array<CertificationRequestCreatedByConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type CertificationRequestCreatedByCreateFieldInput = {
  node: UserCreateInput;
};

export type CertificationRequestCreatedByDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<CertificationRequestCreatedByConnectionWhere>;
};

export type CertificationRequestCreatedByDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<CertificationRequestCreatedByConnectionWhere>;
};

export type CertificationRequestCreatedByFieldInput = {
  connect?: InputMaybe<CertificationRequestCreatedByConnectFieldInput>;
  connectOrCreate?: InputMaybe<CertificationRequestCreatedByConnectOrCreateFieldInput>;
  create?: InputMaybe<CertificationRequestCreatedByCreateFieldInput>;
};

export type CertificationRequestCreatedByNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CertificationRequestCreatedByNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CertificationRequestCreatedByNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CertificationRequestCreatedByNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nickname_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  picture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  picture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CertificationRequestCreatedByRelationship = {
  __typename?: 'CertificationRequestCreatedByRelationship';
  cursor: Scalars['String']['output'];
  node: User;
};

export type CertificationRequestCreatedByUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type CertificationRequestCreatedByUpdateFieldInput = {
  connect?: InputMaybe<CertificationRequestCreatedByConnectFieldInput>;
  connectOrCreate?: InputMaybe<CertificationRequestCreatedByConnectOrCreateFieldInput>;
  create?: InputMaybe<CertificationRequestCreatedByCreateFieldInput>;
  delete?: InputMaybe<CertificationRequestCreatedByDeleteFieldInput>;
  disconnect?: InputMaybe<CertificationRequestCreatedByDisconnectFieldInput>;
  update?: InputMaybe<CertificationRequestCreatedByUpdateConnectionInput>;
  where?: InputMaybe<CertificationRequestCreatedByConnectionWhere>;
};

export type CertificationRequestDeleteInput = {
  certificationResponses?: InputMaybe<Array<CertificationRequestCertificationResponsesDeleteFieldInput>>;
  contentCategories?: InputMaybe<Array<CertificationRequestContentCategoriesDeleteFieldInput>>;
  contentTypes?: InputMaybe<Array<CertificationRequestContentTypesDeleteFieldInput>>;
  createdBy?: InputMaybe<CertificationRequestCreatedByDeleteFieldInput>;
  media?: InputMaybe<Array<CertificationRequestMediaDeleteFieldInput>>;
};

export type CertificationRequestDisconnectInput = {
  certificationResponses?: InputMaybe<Array<CertificationRequestCertificationResponsesDisconnectFieldInput>>;
  contentCategories?: InputMaybe<Array<CertificationRequestContentCategoriesDisconnectFieldInput>>;
  contentTypes?: InputMaybe<Array<CertificationRequestContentTypesDisconnectFieldInput>>;
  createdBy?: InputMaybe<CertificationRequestCreatedByDisconnectFieldInput>;
  media?: InputMaybe<Array<CertificationRequestMediaDisconnectFieldInput>>;
};

export type CertificationRequestEdge = {
  __typename?: 'CertificationRequestEdge';
  cursor: Scalars['String']['output'];
  node: CertificationRequest;
};

export type CertificationRequestMediaAggregateInput = {
  AND?: InputMaybe<Array<CertificationRequestMediaAggregateInput>>;
  NOT?: InputMaybe<CertificationRequestMediaAggregateInput>;
  OR?: InputMaybe<Array<CertificationRequestMediaAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CertificationRequestMediaNodeAggregationWhereInput>;
};

export type CertificationRequestMediaConnectFieldInput = {
  connect?: InputMaybe<Array<UserMediaConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserMediaConnectWhere>;
};

export type CertificationRequestMediaConnectOrCreateFieldInput = {
  onCreate: CertificationRequestMediaConnectOrCreateFieldInputOnCreate;
  where: UserMediaConnectOrCreateWhere;
};

export type CertificationRequestMediaConnectOrCreateFieldInputOnCreate = {
  node: UserMediaOnCreateInput;
};

export type CertificationRequestMediaConnection = {
  __typename?: 'CertificationRequestMediaConnection';
  edges: Array<CertificationRequestMediaRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CertificationRequestMediaConnectionSort = {
  node?: InputMaybe<UserMediaSort>;
};

export type CertificationRequestMediaConnectionWhere = {
  AND?: InputMaybe<Array<CertificationRequestMediaConnectionWhere>>;
  NOT?: InputMaybe<CertificationRequestMediaConnectionWhere>;
  OR?: InputMaybe<Array<CertificationRequestMediaConnectionWhere>>;
  node?: InputMaybe<UserMediaWhere>;
};

export type CertificationRequestMediaCreateFieldInput = {
  node: UserMediaCreateInput;
};

export type CertificationRequestMediaDeleteFieldInput = {
  delete?: InputMaybe<UserMediaDeleteInput>;
  where?: InputMaybe<CertificationRequestMediaConnectionWhere>;
};

export type CertificationRequestMediaDisconnectFieldInput = {
  disconnect?: InputMaybe<UserMediaDisconnectInput>;
  where?: InputMaybe<CertificationRequestMediaConnectionWhere>;
};

export type CertificationRequestMediaFieldInput = {
  connect?: InputMaybe<Array<CertificationRequestMediaConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CertificationRequestMediaConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CertificationRequestMediaCreateFieldInput>>;
};

export type CertificationRequestMediaNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CertificationRequestMediaNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CertificationRequestMediaNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CertificationRequestMediaNodeAggregationWhereInput>>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  caption_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  encoding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  extension_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  height_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  source_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  width_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  width_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type CertificationRequestMediaRelationship = {
  __typename?: 'CertificationRequestMediaRelationship';
  cursor: Scalars['String']['output'];
  node: UserMedia;
};

export type CertificationRequestMediaUpdateConnectionInput = {
  node?: InputMaybe<UserMediaUpdateInput>;
};

export type CertificationRequestMediaUpdateFieldInput = {
  connect?: InputMaybe<Array<CertificationRequestMediaConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CertificationRequestMediaConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CertificationRequestMediaCreateFieldInput>>;
  delete?: InputMaybe<Array<CertificationRequestMediaDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CertificationRequestMediaDisconnectFieldInput>>;
  update?: InputMaybe<CertificationRequestMediaUpdateConnectionInput>;
  where?: InputMaybe<CertificationRequestMediaConnectionWhere>;
};

export type CertificationRequestOnCreateInput = {
  autoCorrectTools: Array<AutoCorrectToolsTypes>;
  autoCorrectToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  channelDescription?: InputMaybe<Scalars['String']['input']>;
  channelName?: InputMaybe<Scalars['String']['input']>;
  channelUrl?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  generativeAi: Array<GenerativeAiTypes>;
  generativeAiExplanation?: InputMaybe<Scalars['String']['input']>;
  machineLearningTools: Array<MachineLearningToolsTypes>;
  machineLearningToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otherAiTools: Array<OtherAiToolsTypes>;
  otherAiToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  requestDate?: InputMaybe<Scalars['Date']['input']>;
  requestSignature?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RequestStatuses>;
};

export type CertificationRequestOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more CertificationRequestSort objects to sort CertificationRequests by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CertificationRequestSort>>;
};

export type CertificationRequestRelationInput = {
  certificationResponses?: InputMaybe<Array<CertificationRequestCertificationResponsesCreateFieldInput>>;
  contentCategories?: InputMaybe<Array<CertificationRequestContentCategoriesCreateFieldInput>>;
  contentTypes?: InputMaybe<Array<CertificationRequestContentTypesCreateFieldInput>>;
  createdBy?: InputMaybe<CertificationRequestCreatedByCreateFieldInput>;
  media?: InputMaybe<Array<CertificationRequestMediaCreateFieldInput>>;
};

/** Fields to sort CertificationRequests by. The order in which sorts are applied is not guaranteed when specifying many fields in one CertificationRequestSort object. */
export type CertificationRequestSort = {
  autoCorrectToolsExplanation?: InputMaybe<SortDirection>;
  channelDescription?: InputMaybe<SortDirection>;
  channelName?: InputMaybe<SortDirection>;
  channelUrl?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  generativeAiExplanation?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  machineLearningToolsExplanation?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  otherAiToolsExplanation?: InputMaybe<SortDirection>;
  requestDate?: InputMaybe<SortDirection>;
  requestSignature?: InputMaybe<SortDirection>;
  result?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CertificationRequestTypeTagContentTypesAggregationSelection = {
  __typename?: 'CertificationRequestTypeTagContentTypesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CertificationRequestTypeTagContentTypesNodeAggregateSelection>;
};

export type CertificationRequestTypeTagContentTypesNodeAggregateSelection = {
  __typename?: 'CertificationRequestTypeTagContentTypesNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CertificationRequestUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CertificationRequestUpdateInput = {
  autoCorrectTools?: InputMaybe<Array<AutoCorrectToolsTypes>>;
  autoCorrectToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  certificationResponses?: InputMaybe<Array<CertificationRequestCertificationResponsesUpdateFieldInput>>;
  channelDescription?: InputMaybe<Scalars['String']['input']>;
  channelName?: InputMaybe<Scalars['String']['input']>;
  channelUrl?: InputMaybe<Scalars['String']['input']>;
  contentCategories?: InputMaybe<Array<CertificationRequestContentCategoriesUpdateFieldInput>>;
  contentTypes?: InputMaybe<Array<CertificationRequestContentTypesUpdateFieldInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<CertificationRequestCreatedByUpdateFieldInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  generativeAi?: InputMaybe<Array<GenerativeAiTypes>>;
  generativeAiExplanation?: InputMaybe<Scalars['String']['input']>;
  machineLearningTools?: InputMaybe<Array<MachineLearningToolsTypes>>;
  machineLearningToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<Array<CertificationRequestMediaUpdateFieldInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  otherAiTools?: InputMaybe<Array<OtherAiToolsTypes>>;
  otherAiToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  requestDate?: InputMaybe<Scalars['Date']['input']>;
  requestSignature?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RequestStatuses>;
};

export type CertificationRequestUserCreatedByAggregationSelection = {
  __typename?: 'CertificationRequestUserCreatedByAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CertificationRequestUserCreatedByNodeAggregateSelection>;
};

export type CertificationRequestUserCreatedByNodeAggregateSelection = {
  __typename?: 'CertificationRequestUserCreatedByNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  email: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  nickname: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  picture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  userId: IdAggregateSelectionNonNullable;
};

export type CertificationRequestUserMediaMediaAggregationSelection = {
  __typename?: 'CertificationRequestUserMediaMediaAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CertificationRequestUserMediaMediaNodeAggregateSelection>;
};

export type CertificationRequestUserMediaMediaNodeAggregateSelection = {
  __typename?: 'CertificationRequestUserMediaMediaNodeAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  caption: StringAggregateSelectionNullable;
  contentLength: IntAggregateSelectionNullable;
  contentType: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  encoding: StringAggregateSelectionNullable;
  extension: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  height: IntAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  source: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  width: IntAggregateSelectionNullable;
};

export type CertificationRequestWhere = {
  AND?: InputMaybe<Array<CertificationRequestWhere>>;
  NOT?: InputMaybe<CertificationRequestWhere>;
  OR?: InputMaybe<Array<CertificationRequestWhere>>;
  autoCorrectTools?: InputMaybe<Array<AutoCorrectToolsTypes>>;
  autoCorrectToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  autoCorrectToolsExplanation_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  autoCorrectToolsExplanation_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  autoCorrectToolsExplanation_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  autoCorrectToolsExplanation_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  autoCorrectTools_INCLUDES?: InputMaybe<AutoCorrectToolsTypes>;
  certificationResponsesAggregate?: InputMaybe<CertificationRequestCertificationResponsesAggregateInput>;
  /** Return CertificationRequests where all of the related CertificationRequestCertificationResponsesConnections match this filter */
  certificationResponsesConnection_ALL?: InputMaybe<CertificationRequestCertificationResponsesConnectionWhere>;
  /** Return CertificationRequests where none of the related CertificationRequestCertificationResponsesConnections match this filter */
  certificationResponsesConnection_NONE?: InputMaybe<CertificationRequestCertificationResponsesConnectionWhere>;
  /** Return CertificationRequests where one of the related CertificationRequestCertificationResponsesConnections match this filter */
  certificationResponsesConnection_SINGLE?: InputMaybe<CertificationRequestCertificationResponsesConnectionWhere>;
  /** Return CertificationRequests where some of the related CertificationRequestCertificationResponsesConnections match this filter */
  certificationResponsesConnection_SOME?: InputMaybe<CertificationRequestCertificationResponsesConnectionWhere>;
  /** Return CertificationRequests where all of the related CertificationResponses match this filter */
  certificationResponses_ALL?: InputMaybe<CertificationResponseWhere>;
  /** Return CertificationRequests where none of the related CertificationResponses match this filter */
  certificationResponses_NONE?: InputMaybe<CertificationResponseWhere>;
  /** Return CertificationRequests where one of the related CertificationResponses match this filter */
  certificationResponses_SINGLE?: InputMaybe<CertificationResponseWhere>;
  /** Return CertificationRequests where some of the related CertificationResponses match this filter */
  certificationResponses_SOME?: InputMaybe<CertificationResponseWhere>;
  channelDescription?: InputMaybe<Scalars['String']['input']>;
  channelDescription_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  channelDescription_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  channelDescription_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channelDescription_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  channelName?: InputMaybe<Scalars['String']['input']>;
  channelName_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  channelName_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  channelName_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channelName_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  channelUrl?: InputMaybe<Scalars['String']['input']>;
  channelUrl_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  channelUrl_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  channelUrl_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channelUrl_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  contentCategoriesAggregate?: InputMaybe<CertificationRequestContentCategoriesAggregateInput>;
  /** Return CertificationRequests where all of the related CertificationRequestContentCategoriesConnections match this filter */
  contentCategoriesConnection_ALL?: InputMaybe<CertificationRequestContentCategoriesConnectionWhere>;
  /** Return CertificationRequests where none of the related CertificationRequestContentCategoriesConnections match this filter */
  contentCategoriesConnection_NONE?: InputMaybe<CertificationRequestContentCategoriesConnectionWhere>;
  /** Return CertificationRequests where one of the related CertificationRequestContentCategoriesConnections match this filter */
  contentCategoriesConnection_SINGLE?: InputMaybe<CertificationRequestContentCategoriesConnectionWhere>;
  /** Return CertificationRequests where some of the related CertificationRequestContentCategoriesConnections match this filter */
  contentCategoriesConnection_SOME?: InputMaybe<CertificationRequestContentCategoriesConnectionWhere>;
  /** Return CertificationRequests where all of the related CategoryTags match this filter */
  contentCategories_ALL?: InputMaybe<CategoryTagWhere>;
  /** Return CertificationRequests where none of the related CategoryTags match this filter */
  contentCategories_NONE?: InputMaybe<CategoryTagWhere>;
  /** Return CertificationRequests where one of the related CategoryTags match this filter */
  contentCategories_SINGLE?: InputMaybe<CategoryTagWhere>;
  /** Return CertificationRequests where some of the related CategoryTags match this filter */
  contentCategories_SOME?: InputMaybe<CategoryTagWhere>;
  contentTypesAggregate?: InputMaybe<CertificationRequestContentTypesAggregateInput>;
  /** Return CertificationRequests where all of the related CertificationRequestContentTypesConnections match this filter */
  contentTypesConnection_ALL?: InputMaybe<CertificationRequestContentTypesConnectionWhere>;
  /** Return CertificationRequests where none of the related CertificationRequestContentTypesConnections match this filter */
  contentTypesConnection_NONE?: InputMaybe<CertificationRequestContentTypesConnectionWhere>;
  /** Return CertificationRequests where one of the related CertificationRequestContentTypesConnections match this filter */
  contentTypesConnection_SINGLE?: InputMaybe<CertificationRequestContentTypesConnectionWhere>;
  /** Return CertificationRequests where some of the related CertificationRequestContentTypesConnections match this filter */
  contentTypesConnection_SOME?: InputMaybe<CertificationRequestContentTypesConnectionWhere>;
  /** Return CertificationRequests where all of the related TypeTags match this filter */
  contentTypes_ALL?: InputMaybe<TypeTagWhere>;
  /** Return CertificationRequests where none of the related TypeTags match this filter */
  contentTypes_NONE?: InputMaybe<TypeTagWhere>;
  /** Return CertificationRequests where one of the related TypeTags match this filter */
  contentTypes_SINGLE?: InputMaybe<TypeTagWhere>;
  /** Return CertificationRequests where some of the related TypeTags match this filter */
  contentTypes_SOME?: InputMaybe<TypeTagWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserWhere>;
  createdByAggregate?: InputMaybe<CertificationRequestCreatedByAggregateInput>;
  createdByConnection?: InputMaybe<CertificationRequestCreatedByConnectionWhere>;
  createdByConnection_NOT?: InputMaybe<CertificationRequestCreatedByConnectionWhere>;
  createdBy_NOT?: InputMaybe<UserWhere>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  email_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  email_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  generativeAi?: InputMaybe<Array<GenerativeAiTypes>>;
  generativeAiExplanation?: InputMaybe<Scalars['String']['input']>;
  generativeAiExplanation_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  generativeAiExplanation_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  generativeAiExplanation_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  generativeAiExplanation_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  generativeAi_INCLUDES?: InputMaybe<GenerativeAiTypes>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  machineLearningTools?: InputMaybe<Array<MachineLearningToolsTypes>>;
  machineLearningToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  machineLearningToolsExplanation_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  machineLearningToolsExplanation_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  machineLearningToolsExplanation_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  machineLearningToolsExplanation_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  machineLearningTools_INCLUDES?: InputMaybe<MachineLearningToolsTypes>;
  mediaAggregate?: InputMaybe<CertificationRequestMediaAggregateInput>;
  /** Return CertificationRequests where all of the related CertificationRequestMediaConnections match this filter */
  mediaConnection_ALL?: InputMaybe<CertificationRequestMediaConnectionWhere>;
  /** Return CertificationRequests where none of the related CertificationRequestMediaConnections match this filter */
  mediaConnection_NONE?: InputMaybe<CertificationRequestMediaConnectionWhere>;
  /** Return CertificationRequests where one of the related CertificationRequestMediaConnections match this filter */
  mediaConnection_SINGLE?: InputMaybe<CertificationRequestMediaConnectionWhere>;
  /** Return CertificationRequests where some of the related CertificationRequestMediaConnections match this filter */
  mediaConnection_SOME?: InputMaybe<CertificationRequestMediaConnectionWhere>;
  /** Return CertificationRequests where all of the related UserMedias match this filter */
  media_ALL?: InputMaybe<UserMediaWhere>;
  /** Return CertificationRequests where none of the related UserMedias match this filter */
  media_NONE?: InputMaybe<UserMediaWhere>;
  /** Return CertificationRequests where one of the related UserMedias match this filter */
  media_SINGLE?: InputMaybe<UserMediaWhere>;
  /** Return CertificationRequests where some of the related UserMedias match this filter */
  media_SOME?: InputMaybe<UserMediaWhere>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  name_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  otherAiTools?: InputMaybe<Array<OtherAiToolsTypes>>;
  otherAiToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  otherAiToolsExplanation_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  otherAiToolsExplanation_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  otherAiToolsExplanation_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  otherAiToolsExplanation_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  otherAiTools_INCLUDES?: InputMaybe<OtherAiToolsTypes>;
  requestDate?: InputMaybe<Scalars['Date']['input']>;
  requestDate_GT?: InputMaybe<Scalars['Date']['input']>;
  requestDate_GTE?: InputMaybe<Scalars['Date']['input']>;
  requestDate_IN?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  requestDate_LT?: InputMaybe<Scalars['Date']['input']>;
  requestDate_LTE?: InputMaybe<Scalars['Date']['input']>;
  requestSignature?: InputMaybe<Scalars['String']['input']>;
  requestSignature_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  requestSignature_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  requestSignature_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  requestSignature_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<Scalars['String']['input']>;
  result_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  result_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  result_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  result_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RequestStatuses>;
  status_IN?: InputMaybe<Array<InputMaybe<RequestStatuses>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CertificationRequestsConnection = {
  __typename?: 'CertificationRequestsConnection';
  edges: Array<CertificationRequestEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Content Origin Certification Request */
export type CertificationResponse = {
  __typename?: 'CertificationResponse';
  /** Certification Request */
  certificationRequest: CertificationRequest;
  certificationRequestAggregate?: Maybe<CertificationResponseCertificationRequestCertificationRequestAggregationSelection>;
  certificationRequestConnection: CertificationResponseCertificationRequestConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** User Who This Certification Response belongs to */
  createdBy?: Maybe<User>;
  createdByAggregate?: Maybe<CertificationResponseUserCreatedByAggregationSelection>;
  createdByConnection: CertificationResponseCreatedByConnection;
  /** System generated GUID */
  id: Scalars['ID']['output'];
  /** Content Media */
  media: UserMedia;
  mediaAggregate?: Maybe<CertificationResponseUserMediaMediaAggregationSelection>;
  mediaConnection: CertificationResponseMediaConnection;
  /** Certification Result (TODO: we might need more fields or different data type to store the result or conclusion) */
  result?: Maybe<Scalars['String']['output']>;
  /** Service Provider */
  serviceProvider: ServiceProviders;
  /** Response Status */
  status: ResponseStatuses;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


/** Content Origin Certification Request */
export type CertificationResponseCertificationRequestArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CertificationRequestOptions>;
  where?: InputMaybe<CertificationRequestWhere>;
};


/** Content Origin Certification Request */
export type CertificationResponseCertificationRequestAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CertificationRequestWhere>;
};


/** Content Origin Certification Request */
export type CertificationResponseCertificationRequestConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CertificationResponseCertificationRequestConnectionSort>>;
  where?: InputMaybe<CertificationResponseCertificationRequestConnectionWhere>;
};


/** Content Origin Certification Request */
export type CertificationResponseCreatedByArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};


/** Content Origin Certification Request */
export type CertificationResponseCreatedByAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserWhere>;
};


/** Content Origin Certification Request */
export type CertificationResponseCreatedByConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CertificationResponseCreatedByConnectionSort>>;
  where?: InputMaybe<CertificationResponseCreatedByConnectionWhere>;
};


/** Content Origin Certification Request */
export type CertificationResponseMediaArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserMediaOptions>;
  where?: InputMaybe<UserMediaWhere>;
};


/** Content Origin Certification Request */
export type CertificationResponseMediaAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserMediaWhere>;
};


/** Content Origin Certification Request */
export type CertificationResponseMediaConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CertificationResponseMediaConnectionSort>>;
  where?: InputMaybe<CertificationResponseMediaConnectionWhere>;
};

export type CertificationResponseAggregateSelection = {
  __typename?: 'CertificationResponseAggregateSelection';
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  result: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CertificationResponseCertificationRequestAggregateInput = {
  AND?: InputMaybe<Array<CertificationResponseCertificationRequestAggregateInput>>;
  NOT?: InputMaybe<CertificationResponseCertificationRequestAggregateInput>;
  OR?: InputMaybe<Array<CertificationResponseCertificationRequestAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CertificationResponseCertificationRequestNodeAggregationWhereInput>;
};

export type CertificationResponseCertificationRequestCertificationRequestAggregationSelection = {
  __typename?: 'CertificationResponseCertificationRequestCertificationRequestAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CertificationResponseCertificationRequestCertificationRequestNodeAggregateSelection>;
};

export type CertificationResponseCertificationRequestCertificationRequestNodeAggregateSelection = {
  __typename?: 'CertificationResponseCertificationRequestCertificationRequestNodeAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  channelDescription: StringAggregateSelectionNullable;
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  email: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  name: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  requestSignature: StringAggregateSelectionNullable;
  result: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CertificationResponseCertificationRequestConnectFieldInput = {
  connect?: InputMaybe<CertificationRequestConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CertificationRequestConnectWhere>;
};

export type CertificationResponseCertificationRequestConnectOrCreateFieldInput = {
  onCreate: CertificationResponseCertificationRequestConnectOrCreateFieldInputOnCreate;
  where: CertificationRequestConnectOrCreateWhere;
};

export type CertificationResponseCertificationRequestConnectOrCreateFieldInputOnCreate = {
  node: CertificationRequestOnCreateInput;
};

export type CertificationResponseCertificationRequestConnection = {
  __typename?: 'CertificationResponseCertificationRequestConnection';
  edges: Array<CertificationResponseCertificationRequestRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CertificationResponseCertificationRequestConnectionSort = {
  node?: InputMaybe<CertificationRequestSort>;
};

export type CertificationResponseCertificationRequestConnectionWhere = {
  AND?: InputMaybe<Array<CertificationResponseCertificationRequestConnectionWhere>>;
  NOT?: InputMaybe<CertificationResponseCertificationRequestConnectionWhere>;
  OR?: InputMaybe<Array<CertificationResponseCertificationRequestConnectionWhere>>;
  node?: InputMaybe<CertificationRequestWhere>;
};

export type CertificationResponseCertificationRequestCreateFieldInput = {
  node: CertificationRequestCreateInput;
};

export type CertificationResponseCertificationRequestDeleteFieldInput = {
  delete?: InputMaybe<CertificationRequestDeleteInput>;
  where?: InputMaybe<CertificationResponseCertificationRequestConnectionWhere>;
};

export type CertificationResponseCertificationRequestDisconnectFieldInput = {
  disconnect?: InputMaybe<CertificationRequestDisconnectInput>;
  where?: InputMaybe<CertificationResponseCertificationRequestConnectionWhere>;
};

export type CertificationResponseCertificationRequestFieldInput = {
  connect?: InputMaybe<CertificationResponseCertificationRequestConnectFieldInput>;
  connectOrCreate?: InputMaybe<CertificationResponseCertificationRequestConnectOrCreateFieldInput>;
  create?: InputMaybe<CertificationResponseCertificationRequestCreateFieldInput>;
};

export type CertificationResponseCertificationRequestNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CertificationResponseCertificationRequestNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CertificationResponseCertificationRequestNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CertificationResponseCertificationRequestNodeAggregationWhereInput>>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  result_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CertificationResponseCertificationRequestRelationship = {
  __typename?: 'CertificationResponseCertificationRequestRelationship';
  cursor: Scalars['String']['output'];
  node: CertificationRequest;
};

export type CertificationResponseCertificationRequestUpdateConnectionInput = {
  node?: InputMaybe<CertificationRequestUpdateInput>;
};

export type CertificationResponseCertificationRequestUpdateFieldInput = {
  connect?: InputMaybe<CertificationResponseCertificationRequestConnectFieldInput>;
  connectOrCreate?: InputMaybe<CertificationResponseCertificationRequestConnectOrCreateFieldInput>;
  create?: InputMaybe<CertificationResponseCertificationRequestCreateFieldInput>;
  delete?: InputMaybe<CertificationResponseCertificationRequestDeleteFieldInput>;
  disconnect?: InputMaybe<CertificationResponseCertificationRequestDisconnectFieldInput>;
  update?: InputMaybe<CertificationResponseCertificationRequestUpdateConnectionInput>;
  where?: InputMaybe<CertificationResponseCertificationRequestConnectionWhere>;
};

export type CertificationResponseConnectInput = {
  certificationRequest?: InputMaybe<CertificationResponseCertificationRequestConnectFieldInput>;
  createdBy?: InputMaybe<CertificationResponseCreatedByConnectFieldInput>;
  media?: InputMaybe<CertificationResponseMediaConnectFieldInput>;
};

export type CertificationResponseConnectOrCreateInput = {
  certificationRequest?: InputMaybe<CertificationResponseCertificationRequestConnectOrCreateFieldInput>;
  createdBy?: InputMaybe<CertificationResponseCreatedByConnectOrCreateFieldInput>;
  media?: InputMaybe<CertificationResponseMediaConnectOrCreateFieldInput>;
};

export type CertificationResponseConnectOrCreateWhere = {
  node: CertificationResponseUniqueWhere;
};

export type CertificationResponseConnectWhere = {
  node: CertificationResponseWhere;
};

export type CertificationResponseCreateInput = {
  certificationRequest?: InputMaybe<CertificationResponseCertificationRequestFieldInput>;
  createdBy?: InputMaybe<CertificationResponseCreatedByFieldInput>;
  media?: InputMaybe<CertificationResponseMediaFieldInput>;
  result?: InputMaybe<Scalars['String']['input']>;
  serviceProvider: ServiceProviders;
  status: ResponseStatuses;
};

export type CertificationResponseCreatedByAggregateInput = {
  AND?: InputMaybe<Array<CertificationResponseCreatedByAggregateInput>>;
  NOT?: InputMaybe<CertificationResponseCreatedByAggregateInput>;
  OR?: InputMaybe<Array<CertificationResponseCreatedByAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CertificationResponseCreatedByNodeAggregationWhereInput>;
};

export type CertificationResponseCreatedByConnectFieldInput = {
  connect?: InputMaybe<UserConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserConnectWhere>;
};

export type CertificationResponseCreatedByConnectOrCreateFieldInput = {
  onCreate: CertificationResponseCreatedByConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type CertificationResponseCreatedByConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type CertificationResponseCreatedByConnection = {
  __typename?: 'CertificationResponseCreatedByConnection';
  edges: Array<CertificationResponseCreatedByRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CertificationResponseCreatedByConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type CertificationResponseCreatedByConnectionWhere = {
  AND?: InputMaybe<Array<CertificationResponseCreatedByConnectionWhere>>;
  NOT?: InputMaybe<CertificationResponseCreatedByConnectionWhere>;
  OR?: InputMaybe<Array<CertificationResponseCreatedByConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type CertificationResponseCreatedByCreateFieldInput = {
  node: UserCreateInput;
};

export type CertificationResponseCreatedByDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<CertificationResponseCreatedByConnectionWhere>;
};

export type CertificationResponseCreatedByDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<CertificationResponseCreatedByConnectionWhere>;
};

export type CertificationResponseCreatedByFieldInput = {
  connect?: InputMaybe<CertificationResponseCreatedByConnectFieldInput>;
  connectOrCreate?: InputMaybe<CertificationResponseCreatedByConnectOrCreateFieldInput>;
  create?: InputMaybe<CertificationResponseCreatedByCreateFieldInput>;
};

export type CertificationResponseCreatedByNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CertificationResponseCreatedByNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CertificationResponseCreatedByNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CertificationResponseCreatedByNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nickname_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  picture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  picture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CertificationResponseCreatedByRelationship = {
  __typename?: 'CertificationResponseCreatedByRelationship';
  cursor: Scalars['String']['output'];
  node: User;
};

export type CertificationResponseCreatedByUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type CertificationResponseCreatedByUpdateFieldInput = {
  connect?: InputMaybe<CertificationResponseCreatedByConnectFieldInput>;
  connectOrCreate?: InputMaybe<CertificationResponseCreatedByConnectOrCreateFieldInput>;
  create?: InputMaybe<CertificationResponseCreatedByCreateFieldInput>;
  delete?: InputMaybe<CertificationResponseCreatedByDeleteFieldInput>;
  disconnect?: InputMaybe<CertificationResponseCreatedByDisconnectFieldInput>;
  update?: InputMaybe<CertificationResponseCreatedByUpdateConnectionInput>;
  where?: InputMaybe<CertificationResponseCreatedByConnectionWhere>;
};

export type CertificationResponseDeleteInput = {
  certificationRequest?: InputMaybe<CertificationResponseCertificationRequestDeleteFieldInput>;
  createdBy?: InputMaybe<CertificationResponseCreatedByDeleteFieldInput>;
  media?: InputMaybe<CertificationResponseMediaDeleteFieldInput>;
};

export type CertificationResponseDisconnectInput = {
  certificationRequest?: InputMaybe<CertificationResponseCertificationRequestDisconnectFieldInput>;
  createdBy?: InputMaybe<CertificationResponseCreatedByDisconnectFieldInput>;
  media?: InputMaybe<CertificationResponseMediaDisconnectFieldInput>;
};

export type CertificationResponseEdge = {
  __typename?: 'CertificationResponseEdge';
  cursor: Scalars['String']['output'];
  node: CertificationResponse;
};

export type CertificationResponseMediaAggregateInput = {
  AND?: InputMaybe<Array<CertificationResponseMediaAggregateInput>>;
  NOT?: InputMaybe<CertificationResponseMediaAggregateInput>;
  OR?: InputMaybe<Array<CertificationResponseMediaAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CertificationResponseMediaNodeAggregationWhereInput>;
};

export type CertificationResponseMediaConnectFieldInput = {
  connect?: InputMaybe<UserMediaConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserMediaConnectWhere>;
};

export type CertificationResponseMediaConnectOrCreateFieldInput = {
  onCreate: CertificationResponseMediaConnectOrCreateFieldInputOnCreate;
  where: UserMediaConnectOrCreateWhere;
};

export type CertificationResponseMediaConnectOrCreateFieldInputOnCreate = {
  node: UserMediaOnCreateInput;
};

export type CertificationResponseMediaConnection = {
  __typename?: 'CertificationResponseMediaConnection';
  edges: Array<CertificationResponseMediaRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CertificationResponseMediaConnectionSort = {
  node?: InputMaybe<UserMediaSort>;
};

export type CertificationResponseMediaConnectionWhere = {
  AND?: InputMaybe<Array<CertificationResponseMediaConnectionWhere>>;
  NOT?: InputMaybe<CertificationResponseMediaConnectionWhere>;
  OR?: InputMaybe<Array<CertificationResponseMediaConnectionWhere>>;
  node?: InputMaybe<UserMediaWhere>;
};

export type CertificationResponseMediaCreateFieldInput = {
  node: UserMediaCreateInput;
};

export type CertificationResponseMediaDeleteFieldInput = {
  delete?: InputMaybe<UserMediaDeleteInput>;
  where?: InputMaybe<CertificationResponseMediaConnectionWhere>;
};

export type CertificationResponseMediaDisconnectFieldInput = {
  disconnect?: InputMaybe<UserMediaDisconnectInput>;
  where?: InputMaybe<CertificationResponseMediaConnectionWhere>;
};

export type CertificationResponseMediaFieldInput = {
  connect?: InputMaybe<CertificationResponseMediaConnectFieldInput>;
  connectOrCreate?: InputMaybe<CertificationResponseMediaConnectOrCreateFieldInput>;
  create?: InputMaybe<CertificationResponseMediaCreateFieldInput>;
};

export type CertificationResponseMediaNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CertificationResponseMediaNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CertificationResponseMediaNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CertificationResponseMediaNodeAggregationWhereInput>>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  caption_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  encoding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  extension_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  height_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  source_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  width_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  width_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type CertificationResponseMediaRelationship = {
  __typename?: 'CertificationResponseMediaRelationship';
  cursor: Scalars['String']['output'];
  node: UserMedia;
};

export type CertificationResponseMediaUpdateConnectionInput = {
  node?: InputMaybe<UserMediaUpdateInput>;
};

export type CertificationResponseMediaUpdateFieldInput = {
  connect?: InputMaybe<CertificationResponseMediaConnectFieldInput>;
  connectOrCreate?: InputMaybe<CertificationResponseMediaConnectOrCreateFieldInput>;
  create?: InputMaybe<CertificationResponseMediaCreateFieldInput>;
  delete?: InputMaybe<CertificationResponseMediaDeleteFieldInput>;
  disconnect?: InputMaybe<CertificationResponseMediaDisconnectFieldInput>;
  update?: InputMaybe<CertificationResponseMediaUpdateConnectionInput>;
  where?: InputMaybe<CertificationResponseMediaConnectionWhere>;
};

export type CertificationResponseOnCreateInput = {
  result?: InputMaybe<Scalars['String']['input']>;
  serviceProvider: ServiceProviders;
  status: ResponseStatuses;
};

export type CertificationResponseOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more CertificationResponseSort objects to sort CertificationResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CertificationResponseSort>>;
};

export type CertificationResponseRelationInput = {
  certificationRequest?: InputMaybe<CertificationResponseCertificationRequestCreateFieldInput>;
  createdBy?: InputMaybe<CertificationResponseCreatedByCreateFieldInput>;
  media?: InputMaybe<CertificationResponseMediaCreateFieldInput>;
};

/** Fields to sort CertificationResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CertificationResponseSort object. */
export type CertificationResponseSort = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  result?: InputMaybe<SortDirection>;
  serviceProvider?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CertificationResponseUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CertificationResponseUpdateInput = {
  certificationRequest?: InputMaybe<CertificationResponseCertificationRequestUpdateFieldInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<CertificationResponseCreatedByUpdateFieldInput>;
  media?: InputMaybe<CertificationResponseMediaUpdateFieldInput>;
  result?: InputMaybe<Scalars['String']['input']>;
  serviceProvider?: InputMaybe<ServiceProviders>;
  status?: InputMaybe<ResponseStatuses>;
};

export type CertificationResponseUserCreatedByAggregationSelection = {
  __typename?: 'CertificationResponseUserCreatedByAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CertificationResponseUserCreatedByNodeAggregateSelection>;
};

export type CertificationResponseUserCreatedByNodeAggregateSelection = {
  __typename?: 'CertificationResponseUserCreatedByNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  email: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  nickname: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  picture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  userId: IdAggregateSelectionNonNullable;
};

export type CertificationResponseUserMediaMediaAggregationSelection = {
  __typename?: 'CertificationResponseUserMediaMediaAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CertificationResponseUserMediaMediaNodeAggregateSelection>;
};

export type CertificationResponseUserMediaMediaNodeAggregateSelection = {
  __typename?: 'CertificationResponseUserMediaMediaNodeAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  caption: StringAggregateSelectionNullable;
  contentLength: IntAggregateSelectionNullable;
  contentType: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  encoding: StringAggregateSelectionNullable;
  extension: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  height: IntAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  source: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  width: IntAggregateSelectionNullable;
};

export type CertificationResponseWhere = {
  AND?: InputMaybe<Array<CertificationResponseWhere>>;
  NOT?: InputMaybe<CertificationResponseWhere>;
  OR?: InputMaybe<Array<CertificationResponseWhere>>;
  certificationRequest?: InputMaybe<CertificationRequestWhere>;
  certificationRequestAggregate?: InputMaybe<CertificationResponseCertificationRequestAggregateInput>;
  certificationRequestConnection?: InputMaybe<CertificationResponseCertificationRequestConnectionWhere>;
  certificationRequestConnection_NOT?: InputMaybe<CertificationResponseCertificationRequestConnectionWhere>;
  certificationRequest_NOT?: InputMaybe<CertificationRequestWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserWhere>;
  createdByAggregate?: InputMaybe<CertificationResponseCreatedByAggregateInput>;
  createdByConnection?: InputMaybe<CertificationResponseCreatedByConnectionWhere>;
  createdByConnection_NOT?: InputMaybe<CertificationResponseCreatedByConnectionWhere>;
  createdBy_NOT?: InputMaybe<UserWhere>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  media?: InputMaybe<UserMediaWhere>;
  mediaAggregate?: InputMaybe<CertificationResponseMediaAggregateInput>;
  mediaConnection?: InputMaybe<CertificationResponseMediaConnectionWhere>;
  mediaConnection_NOT?: InputMaybe<CertificationResponseMediaConnectionWhere>;
  media_NOT?: InputMaybe<UserMediaWhere>;
  result?: InputMaybe<Scalars['String']['input']>;
  result_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  result_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  result_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  result_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  serviceProvider?: InputMaybe<ServiceProviders>;
  serviceProvider_IN?: InputMaybe<Array<ServiceProviders>>;
  status?: InputMaybe<ResponseStatuses>;
  status_IN?: InputMaybe<Array<ResponseStatuses>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CertificationResponsesConnection = {
  __typename?: 'CertificationResponsesConnection';
  edges: Array<CertificationResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum CertificationType {
  Certified = 'CERTIFIED',
  Uncertified = 'UNCERTIFIED'
}

/** Channel */
export type Channel = {
  __typename?: 'Channel';
  /** Channel's Connected Accounts */
  accounts: Array<Account>;
  accountsAggregate?: Maybe<ChannelAccountAccountsAggregationSelection>;
  accountsConnection: ChannelAccountsConnection;
  /** Channel Content Categories (e.g., gaming, lifestyle, tech). Multi-select options, including an Other choices. */
  categories: Array<CategoryTag>;
  categoriesAggregate?: Maybe<ChannelCategoryTagCategoriesAggregationSelection>;
  categoriesConnection: ChannelCategoriesConnection;
  /** Channel Name */
  channelName?: Maybe<Scalars['String']['output']>;
  /** Channel's content type tags */
  channelTypes: Array<TypeTag>;
  channelTypesAggregate?: Maybe<ChannelTypeTagChannelTypesAggregationSelection>;
  channelTypesConnection: ChannelChannelTypesConnection;
  /** Channels URL */
  channelUrl?: Maybe<Scalars['String']['output']>;
  /** Channel's content badges */
  contentBadges: Array<ContentBadge>;
  contentBadgesAggregate?: Maybe<ChannelContentBadgeContentBadgesAggregationSelection>;
  contentBadgesConnection: ChannelContentBadgesConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Deprecated, should use 'creators' instead, keep it until data migration is done
   * creator: Creator @relationship(type: "CREATOR_HAS_CHANNEL", direction: IN)
   *
   * Channel's Creators
   */
  creators: Array<Creator>;
  creatorsAggregate?: Maybe<ChannelCreatorCreatorsAggregationSelection>;
  creatorsConnection: ChannelCreatorsConnection;
  /**
   * Deprecated, should use 'channelName' instead, keep it until data migration is done
   * name: String
   *
   * Short description of the type of content they produce
   */
  description?: Maybe<Scalars['String']['output']>;
  /** System generated GUID */
  id: Scalars['ID']['output'];
  /** User Media */
  media: Array<UserMedia>;
  mediaAggregate?: Maybe<ChannelUserMediaMediaAggregationSelection>;
  mediaConnection: ChannelMediaConnection;
  /** Rights Owners of the Channel */
  owners: Array<Owner>;
  ownersAggregate?: Maybe<ChannelOwnerOwnersAggregationSelection>;
  ownersConnection: ChannelOwnersConnection;
  /**
   * "
   * Creators Freeform Tags
   */
  tags: Array<Tag>;
  tagsAggregate?: Maybe<ChannelTagTagsAggregationSelection>;
  tagsConnection: ChannelTagsConnection;
  /** User Media as thumbnail */
  thumbnail?: Maybe<UserMedia>;
  thumbnailAggregate?: Maybe<ChannelUserMediaThumbnailAggregationSelection>;
  thumbnailConnection: ChannelThumbnailConnection;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


/** Channel */
export type ChannelAccountsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<AccountOptions>;
  where?: InputMaybe<AccountWhere>;
};


/** Channel */
export type ChannelAccountsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccountWhere>;
};


/** Channel */
export type ChannelAccountsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ChannelAccountsConnectionSort>>;
  where?: InputMaybe<ChannelAccountsConnectionWhere>;
};


/** Channel */
export type ChannelCategoriesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CategoryTagOptions>;
  where?: InputMaybe<CategoryTagWhere>;
};


/** Channel */
export type ChannelCategoriesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CategoryTagWhere>;
};


/** Channel */
export type ChannelCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ChannelCategoriesConnectionSort>>;
  where?: InputMaybe<ChannelCategoriesConnectionWhere>;
};


/** Channel */
export type ChannelChannelTypesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TypeTagOptions>;
  where?: InputMaybe<TypeTagWhere>;
};


/** Channel */
export type ChannelChannelTypesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TypeTagWhere>;
};


/** Channel */
export type ChannelChannelTypesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ChannelChannelTypesConnectionSort>>;
  where?: InputMaybe<ChannelChannelTypesConnectionWhere>;
};


/** Channel */
export type ChannelContentBadgesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<ContentBadgeOptions>;
  where?: InputMaybe<ContentBadgeWhere>;
};


/** Channel */
export type ChannelContentBadgesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ContentBadgeWhere>;
};


/** Channel */
export type ChannelContentBadgesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ChannelContentBadgesConnectionSort>>;
  where?: InputMaybe<ChannelContentBadgesConnectionWhere>;
};


/** Channel */
export type ChannelCreatorsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CreatorOptions>;
  where?: InputMaybe<CreatorWhere>;
};


/** Channel */
export type ChannelCreatorsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CreatorWhere>;
};


/** Channel */
export type ChannelCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ChannelCreatorsConnectionSort>>;
  where?: InputMaybe<ChannelCreatorsConnectionWhere>;
};


/** Channel */
export type ChannelMediaArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserMediaOptions>;
  where?: InputMaybe<UserMediaWhere>;
};


/** Channel */
export type ChannelMediaAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserMediaWhere>;
};


/** Channel */
export type ChannelMediaConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ChannelMediaConnectionSort>>;
  where?: InputMaybe<ChannelMediaConnectionWhere>;
};


/** Channel */
export type ChannelOwnersArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<OwnerOptions>;
  where?: InputMaybe<OwnerWhere>;
};


/** Channel */
export type ChannelOwnersAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<OwnerWhere>;
};


/** Channel */
export type ChannelOwnersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ChannelOwnersConnectionSort>>;
  where?: InputMaybe<ChannelOwnersConnectionWhere>;
};


/** Channel */
export type ChannelTagsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TagOptions>;
  where?: InputMaybe<TagWhere>;
};


/** Channel */
export type ChannelTagsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TagWhere>;
};


/** Channel */
export type ChannelTagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ChannelTagsConnectionSort>>;
  where?: InputMaybe<ChannelTagsConnectionWhere>;
};


/** Channel */
export type ChannelThumbnailArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserMediaOptions>;
  where?: InputMaybe<UserMediaWhere>;
};


/** Channel */
export type ChannelThumbnailAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserMediaWhere>;
};


/** Channel */
export type ChannelThumbnailConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ChannelThumbnailConnectionSort>>;
  where?: InputMaybe<ChannelThumbnailConnectionWhere>;
};

export type ChannelAccountAccountsAggregationSelection = {
  __typename?: 'ChannelAccountAccountsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<ChannelAccountAccountsNodeAggregateSelection>;
};

export type ChannelAccountAccountsNodeAggregateSelection = {
  __typename?: 'ChannelAccountAccountsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  joinedDate: StringAggregateSelectionNullable;
  lastActive: StringAggregateSelectionNullable;
  profileURL: StringAggregateSelectionNullable;
  taxId: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  username: StringAggregateSelectionNullable;
};

export type ChannelAccountsAggregateInput = {
  AND?: InputMaybe<Array<ChannelAccountsAggregateInput>>;
  NOT?: InputMaybe<ChannelAccountsAggregateInput>;
  OR?: InputMaybe<Array<ChannelAccountsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<ChannelAccountsNodeAggregationWhereInput>;
};

export type ChannelAccountsConnectFieldInput = {
  connect?: InputMaybe<Array<AccountConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<AccountConnectWhere>;
};

export type ChannelAccountsConnectOrCreateFieldInput = {
  onCreate: ChannelAccountsConnectOrCreateFieldInputOnCreate;
  where: AccountConnectOrCreateWhere;
};

export type ChannelAccountsConnectOrCreateFieldInputOnCreate = {
  node: AccountOnCreateInput;
};

export type ChannelAccountsConnection = {
  __typename?: 'ChannelAccountsConnection';
  edges: Array<ChannelAccountsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChannelAccountsConnectionSort = {
  node?: InputMaybe<AccountSort>;
};

export type ChannelAccountsConnectionWhere = {
  AND?: InputMaybe<Array<ChannelAccountsConnectionWhere>>;
  NOT?: InputMaybe<ChannelAccountsConnectionWhere>;
  OR?: InputMaybe<Array<ChannelAccountsConnectionWhere>>;
  node?: InputMaybe<AccountWhere>;
};

export type ChannelAccountsCreateFieldInput = {
  node: AccountCreateInput;
};

export type ChannelAccountsDeleteFieldInput = {
  delete?: InputMaybe<AccountDeleteInput>;
  where?: InputMaybe<ChannelAccountsConnectionWhere>;
};

export type ChannelAccountsDisconnectFieldInput = {
  disconnect?: InputMaybe<AccountDisconnectInput>;
  where?: InputMaybe<ChannelAccountsConnectionWhere>;
};

export type ChannelAccountsFieldInput = {
  connect?: InputMaybe<Array<ChannelAccountsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelAccountsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelAccountsCreateFieldInput>>;
};

export type ChannelAccountsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChannelAccountsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChannelAccountsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChannelAccountsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  joinedDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type ChannelAccountsRelationship = {
  __typename?: 'ChannelAccountsRelationship';
  cursor: Scalars['String']['output'];
  node: Account;
};

export type ChannelAccountsUpdateConnectionInput = {
  node?: InputMaybe<AccountUpdateInput>;
};

export type ChannelAccountsUpdateFieldInput = {
  connect?: InputMaybe<Array<ChannelAccountsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelAccountsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelAccountsCreateFieldInput>>;
  delete?: InputMaybe<Array<ChannelAccountsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChannelAccountsDisconnectFieldInput>>;
  update?: InputMaybe<ChannelAccountsUpdateConnectionInput>;
  where?: InputMaybe<ChannelAccountsConnectionWhere>;
};

export type ChannelAggregateSelection = {
  __typename?: 'ChannelAggregateSelection';
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type ChannelCategoriesAggregateInput = {
  AND?: InputMaybe<Array<ChannelCategoriesAggregateInput>>;
  NOT?: InputMaybe<ChannelCategoriesAggregateInput>;
  OR?: InputMaybe<Array<ChannelCategoriesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<ChannelCategoriesNodeAggregationWhereInput>;
};

export type ChannelCategoriesConnectFieldInput = {
  connect?: InputMaybe<Array<CategoryTagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CategoryTagConnectWhere>;
};

export type ChannelCategoriesConnectOrCreateFieldInput = {
  onCreate: ChannelCategoriesConnectOrCreateFieldInputOnCreate;
  where: CategoryTagConnectOrCreateWhere;
};

export type ChannelCategoriesConnectOrCreateFieldInputOnCreate = {
  node: CategoryTagOnCreateInput;
};

export type ChannelCategoriesConnection = {
  __typename?: 'ChannelCategoriesConnection';
  edges: Array<ChannelCategoriesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChannelCategoriesConnectionSort = {
  node?: InputMaybe<CategoryTagSort>;
};

export type ChannelCategoriesConnectionWhere = {
  AND?: InputMaybe<Array<ChannelCategoriesConnectionWhere>>;
  NOT?: InputMaybe<ChannelCategoriesConnectionWhere>;
  OR?: InputMaybe<Array<ChannelCategoriesConnectionWhere>>;
  node?: InputMaybe<CategoryTagWhere>;
};

export type ChannelCategoriesCreateFieldInput = {
  node: CategoryTagCreateInput;
};

export type ChannelCategoriesDeleteFieldInput = {
  delete?: InputMaybe<CategoryTagDeleteInput>;
  where?: InputMaybe<ChannelCategoriesConnectionWhere>;
};

export type ChannelCategoriesDisconnectFieldInput = {
  disconnect?: InputMaybe<CategoryTagDisconnectInput>;
  where?: InputMaybe<ChannelCategoriesConnectionWhere>;
};

export type ChannelCategoriesFieldInput = {
  connect?: InputMaybe<Array<ChannelCategoriesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelCategoriesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelCategoriesCreateFieldInput>>;
};

export type ChannelCategoriesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChannelCategoriesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChannelCategoriesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChannelCategoriesNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  icon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  icon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  tier_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ChannelCategoriesRelationship = {
  __typename?: 'ChannelCategoriesRelationship';
  cursor: Scalars['String']['output'];
  node: CategoryTag;
};

export type ChannelCategoriesUpdateConnectionInput = {
  node?: InputMaybe<CategoryTagUpdateInput>;
};

export type ChannelCategoriesUpdateFieldInput = {
  connect?: InputMaybe<Array<ChannelCategoriesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelCategoriesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelCategoriesCreateFieldInput>>;
  delete?: InputMaybe<Array<ChannelCategoriesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChannelCategoriesDisconnectFieldInput>>;
  update?: InputMaybe<ChannelCategoriesUpdateConnectionInput>;
  where?: InputMaybe<ChannelCategoriesConnectionWhere>;
};

export type ChannelCategoryTagCategoriesAggregationSelection = {
  __typename?: 'ChannelCategoryTagCategoriesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<ChannelCategoryTagCategoriesNodeAggregateSelection>;
};

export type ChannelCategoryTagCategoriesNodeAggregateSelection = {
  __typename?: 'ChannelCategoryTagCategoriesNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  icon: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type ChannelChannelTypesAggregateInput = {
  AND?: InputMaybe<Array<ChannelChannelTypesAggregateInput>>;
  NOT?: InputMaybe<ChannelChannelTypesAggregateInput>;
  OR?: InputMaybe<Array<ChannelChannelTypesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<ChannelChannelTypesNodeAggregationWhereInput>;
};

export type ChannelChannelTypesConnectFieldInput = {
  connect?: InputMaybe<Array<TypeTagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<TypeTagConnectWhere>;
};

export type ChannelChannelTypesConnectOrCreateFieldInput = {
  onCreate: ChannelChannelTypesConnectOrCreateFieldInputOnCreate;
  where: TypeTagConnectOrCreateWhere;
};

export type ChannelChannelTypesConnectOrCreateFieldInputOnCreate = {
  node: TypeTagOnCreateInput;
};

export type ChannelChannelTypesConnection = {
  __typename?: 'ChannelChannelTypesConnection';
  edges: Array<ChannelChannelTypesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChannelChannelTypesConnectionSort = {
  node?: InputMaybe<TypeTagSort>;
};

export type ChannelChannelTypesConnectionWhere = {
  AND?: InputMaybe<Array<ChannelChannelTypesConnectionWhere>>;
  NOT?: InputMaybe<ChannelChannelTypesConnectionWhere>;
  OR?: InputMaybe<Array<ChannelChannelTypesConnectionWhere>>;
  node?: InputMaybe<TypeTagWhere>;
};

export type ChannelChannelTypesCreateFieldInput = {
  node: TypeTagCreateInput;
};

export type ChannelChannelTypesDeleteFieldInput = {
  delete?: InputMaybe<TypeTagDeleteInput>;
  where?: InputMaybe<ChannelChannelTypesConnectionWhere>;
};

export type ChannelChannelTypesDisconnectFieldInput = {
  disconnect?: InputMaybe<TypeTagDisconnectInput>;
  where?: InputMaybe<ChannelChannelTypesConnectionWhere>;
};

export type ChannelChannelTypesFieldInput = {
  connect?: InputMaybe<Array<ChannelChannelTypesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelChannelTypesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelChannelTypesCreateFieldInput>>;
};

export type ChannelChannelTypesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChannelChannelTypesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChannelChannelTypesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChannelChannelTypesNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  tier_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ChannelChannelTypesRelationship = {
  __typename?: 'ChannelChannelTypesRelationship';
  cursor: Scalars['String']['output'];
  node: TypeTag;
};

export type ChannelChannelTypesUpdateConnectionInput = {
  node?: InputMaybe<TypeTagUpdateInput>;
};

export type ChannelChannelTypesUpdateFieldInput = {
  connect?: InputMaybe<Array<ChannelChannelTypesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelChannelTypesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelChannelTypesCreateFieldInput>>;
  delete?: InputMaybe<Array<ChannelChannelTypesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChannelChannelTypesDisconnectFieldInput>>;
  update?: InputMaybe<ChannelChannelTypesUpdateConnectionInput>;
  where?: InputMaybe<ChannelChannelTypesConnectionWhere>;
};

export type ChannelConnectInput = {
  accounts?: InputMaybe<Array<ChannelAccountsConnectFieldInput>>;
  categories?: InputMaybe<Array<ChannelCategoriesConnectFieldInput>>;
  channelTypes?: InputMaybe<Array<ChannelChannelTypesConnectFieldInput>>;
  contentBadges?: InputMaybe<Array<ChannelContentBadgesConnectFieldInput>>;
  creators?: InputMaybe<Array<ChannelCreatorsConnectFieldInput>>;
  media?: InputMaybe<Array<ChannelMediaConnectFieldInput>>;
  owners?: InputMaybe<Array<ChannelOwnersConnectFieldInput>>;
  tags?: InputMaybe<Array<ChannelTagsConnectFieldInput>>;
  thumbnail?: InputMaybe<ChannelThumbnailConnectFieldInput>;
};

export type ChannelConnectOrCreateInput = {
  accounts?: InputMaybe<Array<ChannelAccountsConnectOrCreateFieldInput>>;
  categories?: InputMaybe<Array<ChannelCategoriesConnectOrCreateFieldInput>>;
  channelTypes?: InputMaybe<Array<ChannelChannelTypesConnectOrCreateFieldInput>>;
  contentBadges?: InputMaybe<Array<ChannelContentBadgesConnectOrCreateFieldInput>>;
  creators?: InputMaybe<Array<ChannelCreatorsConnectOrCreateFieldInput>>;
  media?: InputMaybe<Array<ChannelMediaConnectOrCreateFieldInput>>;
  owners?: InputMaybe<Array<ChannelOwnersConnectOrCreateFieldInput>>;
  tags?: InputMaybe<Array<ChannelTagsConnectOrCreateFieldInput>>;
  thumbnail?: InputMaybe<ChannelThumbnailConnectOrCreateFieldInput>;
};

export type ChannelConnectOrCreateWhere = {
  node: ChannelUniqueWhere;
};

export type ChannelConnectWhere = {
  node: ChannelWhere;
};

export type ChannelContentBadgeContentBadgesAggregationSelection = {
  __typename?: 'ChannelContentBadgeContentBadgesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<ChannelContentBadgeContentBadgesNodeAggregateSelection>;
};

export type ChannelContentBadgeContentBadgesNodeAggregateSelection = {
  __typename?: 'ChannelContentBadgeContentBadgesNodeAggregateSelection';
  acronym: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  label: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type ChannelContentBadgesAggregateInput = {
  AND?: InputMaybe<Array<ChannelContentBadgesAggregateInput>>;
  NOT?: InputMaybe<ChannelContentBadgesAggregateInput>;
  OR?: InputMaybe<Array<ChannelContentBadgesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<ChannelContentBadgesNodeAggregationWhereInput>;
};

export type ChannelContentBadgesConnectFieldInput = {
  connect?: InputMaybe<Array<ContentBadgeConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<ContentBadgeConnectWhere>;
};

export type ChannelContentBadgesConnectOrCreateFieldInput = {
  onCreate: ChannelContentBadgesConnectOrCreateFieldInputOnCreate;
  where: ContentBadgeConnectOrCreateWhere;
};

export type ChannelContentBadgesConnectOrCreateFieldInputOnCreate = {
  node: ContentBadgeOnCreateInput;
};

export type ChannelContentBadgesConnection = {
  __typename?: 'ChannelContentBadgesConnection';
  edges: Array<ChannelContentBadgesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChannelContentBadgesConnectionSort = {
  node?: InputMaybe<ContentBadgeSort>;
};

export type ChannelContentBadgesConnectionWhere = {
  AND?: InputMaybe<Array<ChannelContentBadgesConnectionWhere>>;
  NOT?: InputMaybe<ChannelContentBadgesConnectionWhere>;
  OR?: InputMaybe<Array<ChannelContentBadgesConnectionWhere>>;
  node?: InputMaybe<ContentBadgeWhere>;
};

export type ChannelContentBadgesCreateFieldInput = {
  node: ContentBadgeCreateInput;
};

export type ChannelContentBadgesDeleteFieldInput = {
  delete?: InputMaybe<ContentBadgeDeleteInput>;
  where?: InputMaybe<ChannelContentBadgesConnectionWhere>;
};

export type ChannelContentBadgesDisconnectFieldInput = {
  disconnect?: InputMaybe<ContentBadgeDisconnectInput>;
  where?: InputMaybe<ChannelContentBadgesConnectionWhere>;
};

export type ChannelContentBadgesFieldInput = {
  connect?: InputMaybe<Array<ChannelContentBadgesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelContentBadgesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelContentBadgesCreateFieldInput>>;
};

export type ChannelContentBadgesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChannelContentBadgesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChannelContentBadgesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChannelContentBadgesNodeAggregationWhereInput>>;
  acronym_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  acronym_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  acronym_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  acronym_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  acronym_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  acronym_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  acronym_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  acronym_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  acronym_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  acronym_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  acronym_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  acronym_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  acronym_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  acronym_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  acronym_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ChannelContentBadgesRelationship = {
  __typename?: 'ChannelContentBadgesRelationship';
  cursor: Scalars['String']['output'];
  node: ContentBadge;
};

export type ChannelContentBadgesUpdateConnectionInput = {
  node?: InputMaybe<ContentBadgeUpdateInput>;
};

export type ChannelContentBadgesUpdateFieldInput = {
  connect?: InputMaybe<Array<ChannelContentBadgesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelContentBadgesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelContentBadgesCreateFieldInput>>;
  delete?: InputMaybe<Array<ChannelContentBadgesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChannelContentBadgesDisconnectFieldInput>>;
  update?: InputMaybe<ChannelContentBadgesUpdateConnectionInput>;
  where?: InputMaybe<ChannelContentBadgesConnectionWhere>;
};

export type ChannelCreateInput = {
  accounts?: InputMaybe<ChannelAccountsFieldInput>;
  categories?: InputMaybe<ChannelCategoriesFieldInput>;
  channelName?: InputMaybe<Scalars['String']['input']>;
  channelTypes?: InputMaybe<ChannelChannelTypesFieldInput>;
  channelUrl?: InputMaybe<Scalars['String']['input']>;
  contentBadges?: InputMaybe<ChannelContentBadgesFieldInput>;
  creators?: InputMaybe<ChannelCreatorsFieldInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<ChannelMediaFieldInput>;
  owners?: InputMaybe<ChannelOwnersFieldInput>;
  tags?: InputMaybe<ChannelTagsFieldInput>;
  thumbnail?: InputMaybe<ChannelThumbnailFieldInput>;
};

export type ChannelCreatorCreatorsAggregationSelection = {
  __typename?: 'ChannelCreatorCreatorsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<ChannelCreatorCreatorsNodeAggregateSelection>;
};

export type ChannelCreatorCreatorsNodeAggregateSelection = {
  __typename?: 'ChannelCreatorCreatorsNodeAggregateSelection';
  bannerImage: StringAggregateSelectionNullable;
  bio: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  creatorID: StringAggregateSelectionNullable;
  dateOfBirth: StringAggregateSelectionNullable;
  firstName: StringAggregateSelectionNullable;
  firstNameLC: StringAggregateSelectionNullable;
  genderOther: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  lastName: StringAggregateSelectionNullable;
  lastNameLC: StringAggregateSelectionNullable;
  middleName: StringAggregateSelectionNullable;
  nationality: StringAggregateSelectionNullable;
  nicknames: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  pronouns: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type ChannelCreatorsAggregateInput = {
  AND?: InputMaybe<Array<ChannelCreatorsAggregateInput>>;
  NOT?: InputMaybe<ChannelCreatorsAggregateInput>;
  OR?: InputMaybe<Array<ChannelCreatorsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<ChannelCreatorsNodeAggregationWhereInput>;
};

export type ChannelCreatorsConnectFieldInput = {
  connect?: InputMaybe<Array<CreatorConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CreatorConnectWhere>;
};

export type ChannelCreatorsConnectOrCreateFieldInput = {
  onCreate: ChannelCreatorsConnectOrCreateFieldInputOnCreate;
  where: CreatorConnectOrCreateWhere;
};

export type ChannelCreatorsConnectOrCreateFieldInputOnCreate = {
  node: CreatorOnCreateInput;
};

export type ChannelCreatorsConnection = {
  __typename?: 'ChannelCreatorsConnection';
  edges: Array<ChannelCreatorsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChannelCreatorsConnectionSort = {
  node?: InputMaybe<CreatorSort>;
};

export type ChannelCreatorsConnectionWhere = {
  AND?: InputMaybe<Array<ChannelCreatorsConnectionWhere>>;
  NOT?: InputMaybe<ChannelCreatorsConnectionWhere>;
  OR?: InputMaybe<Array<ChannelCreatorsConnectionWhere>>;
  node?: InputMaybe<CreatorWhere>;
};

export type ChannelCreatorsCreateFieldInput = {
  node: CreatorCreateInput;
};

export type ChannelCreatorsDeleteFieldInput = {
  delete?: InputMaybe<CreatorDeleteInput>;
  where?: InputMaybe<ChannelCreatorsConnectionWhere>;
};

export type ChannelCreatorsDisconnectFieldInput = {
  disconnect?: InputMaybe<CreatorDisconnectInput>;
  where?: InputMaybe<ChannelCreatorsConnectionWhere>;
};

export type ChannelCreatorsFieldInput = {
  connect?: InputMaybe<Array<ChannelCreatorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelCreatorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelCreatorsCreateFieldInput>>;
};

export type ChannelCreatorsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChannelCreatorsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChannelCreatorsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChannelCreatorsNodeAggregationWhereInput>>;
  bannerImage_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bio_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  bio_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creatorID_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  creatorID_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  firstName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  genderOther_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  middleName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nationality_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nicknames_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  pronouns_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ChannelCreatorsRelationship = {
  __typename?: 'ChannelCreatorsRelationship';
  cursor: Scalars['String']['output'];
  node: Creator;
};

export type ChannelCreatorsUpdateConnectionInput = {
  node?: InputMaybe<CreatorUpdateInput>;
};

export type ChannelCreatorsUpdateFieldInput = {
  connect?: InputMaybe<Array<ChannelCreatorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelCreatorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelCreatorsCreateFieldInput>>;
  delete?: InputMaybe<Array<ChannelCreatorsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChannelCreatorsDisconnectFieldInput>>;
  update?: InputMaybe<ChannelCreatorsUpdateConnectionInput>;
  where?: InputMaybe<ChannelCreatorsConnectionWhere>;
};

export type ChannelDeleteInput = {
  accounts?: InputMaybe<Array<ChannelAccountsDeleteFieldInput>>;
  categories?: InputMaybe<Array<ChannelCategoriesDeleteFieldInput>>;
  channelTypes?: InputMaybe<Array<ChannelChannelTypesDeleteFieldInput>>;
  contentBadges?: InputMaybe<Array<ChannelContentBadgesDeleteFieldInput>>;
  creators?: InputMaybe<Array<ChannelCreatorsDeleteFieldInput>>;
  media?: InputMaybe<Array<ChannelMediaDeleteFieldInput>>;
  owners?: InputMaybe<Array<ChannelOwnersDeleteFieldInput>>;
  tags?: InputMaybe<Array<ChannelTagsDeleteFieldInput>>;
  thumbnail?: InputMaybe<ChannelThumbnailDeleteFieldInput>;
};

export type ChannelDisconnectInput = {
  accounts?: InputMaybe<Array<ChannelAccountsDisconnectFieldInput>>;
  categories?: InputMaybe<Array<ChannelCategoriesDisconnectFieldInput>>;
  channelTypes?: InputMaybe<Array<ChannelChannelTypesDisconnectFieldInput>>;
  contentBadges?: InputMaybe<Array<ChannelContentBadgesDisconnectFieldInput>>;
  creators?: InputMaybe<Array<ChannelCreatorsDisconnectFieldInput>>;
  media?: InputMaybe<Array<ChannelMediaDisconnectFieldInput>>;
  owners?: InputMaybe<Array<ChannelOwnersDisconnectFieldInput>>;
  tags?: InputMaybe<Array<ChannelTagsDisconnectFieldInput>>;
  thumbnail?: InputMaybe<ChannelThumbnailDisconnectFieldInput>;
};

export type ChannelEdge = {
  __typename?: 'ChannelEdge';
  cursor: Scalars['String']['output'];
  node: Channel;
};

export type ChannelMediaAggregateInput = {
  AND?: InputMaybe<Array<ChannelMediaAggregateInput>>;
  NOT?: InputMaybe<ChannelMediaAggregateInput>;
  OR?: InputMaybe<Array<ChannelMediaAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<ChannelMediaNodeAggregationWhereInput>;
};

export type ChannelMediaConnectFieldInput = {
  connect?: InputMaybe<Array<UserMediaConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserMediaConnectWhere>;
};

export type ChannelMediaConnectOrCreateFieldInput = {
  onCreate: ChannelMediaConnectOrCreateFieldInputOnCreate;
  where: UserMediaConnectOrCreateWhere;
};

export type ChannelMediaConnectOrCreateFieldInputOnCreate = {
  node: UserMediaOnCreateInput;
};

export type ChannelMediaConnection = {
  __typename?: 'ChannelMediaConnection';
  edges: Array<ChannelMediaRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChannelMediaConnectionSort = {
  node?: InputMaybe<UserMediaSort>;
};

export type ChannelMediaConnectionWhere = {
  AND?: InputMaybe<Array<ChannelMediaConnectionWhere>>;
  NOT?: InputMaybe<ChannelMediaConnectionWhere>;
  OR?: InputMaybe<Array<ChannelMediaConnectionWhere>>;
  node?: InputMaybe<UserMediaWhere>;
};

export type ChannelMediaCreateFieldInput = {
  node: UserMediaCreateInput;
};

export type ChannelMediaDeleteFieldInput = {
  delete?: InputMaybe<UserMediaDeleteInput>;
  where?: InputMaybe<ChannelMediaConnectionWhere>;
};

export type ChannelMediaDisconnectFieldInput = {
  disconnect?: InputMaybe<UserMediaDisconnectInput>;
  where?: InputMaybe<ChannelMediaConnectionWhere>;
};

export type ChannelMediaFieldInput = {
  connect?: InputMaybe<Array<ChannelMediaConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelMediaConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelMediaCreateFieldInput>>;
};

export type ChannelMediaNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChannelMediaNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChannelMediaNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChannelMediaNodeAggregationWhereInput>>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  caption_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  encoding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  extension_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  height_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  source_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  width_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  width_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type ChannelMediaRelationship = {
  __typename?: 'ChannelMediaRelationship';
  cursor: Scalars['String']['output'];
  node: UserMedia;
};

export type ChannelMediaUpdateConnectionInput = {
  node?: InputMaybe<UserMediaUpdateInput>;
};

export type ChannelMediaUpdateFieldInput = {
  connect?: InputMaybe<Array<ChannelMediaConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelMediaConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelMediaCreateFieldInput>>;
  delete?: InputMaybe<Array<ChannelMediaDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChannelMediaDisconnectFieldInput>>;
  update?: InputMaybe<ChannelMediaUpdateConnectionInput>;
  where?: InputMaybe<ChannelMediaConnectionWhere>;
};

export type ChannelOnCreateInput = {
  channelName?: InputMaybe<Scalars['String']['input']>;
  channelUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

export type ChannelOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more ChannelSort objects to sort Channels by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ChannelSort>>;
};

export type ChannelOwnerOwnersAggregationSelection = {
  __typename?: 'ChannelOwnerOwnersAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<ChannelOwnerOwnersNodeAggregateSelection>;
};

export type ChannelOwnerOwnersNodeAggregateSelection = {
  __typename?: 'ChannelOwnerOwnersNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  nameLC: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type ChannelOwnersAggregateInput = {
  AND?: InputMaybe<Array<ChannelOwnersAggregateInput>>;
  NOT?: InputMaybe<ChannelOwnersAggregateInput>;
  OR?: InputMaybe<Array<ChannelOwnersAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<ChannelOwnersNodeAggregationWhereInput>;
};

export type ChannelOwnersConnectFieldInput = {
  connect?: InputMaybe<Array<OwnerConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<OwnerConnectWhere>;
};

export type ChannelOwnersConnectOrCreateFieldInput = {
  onCreate: ChannelOwnersConnectOrCreateFieldInputOnCreate;
  where: OwnerConnectOrCreateWhere;
};

export type ChannelOwnersConnectOrCreateFieldInputOnCreate = {
  node: OwnerOnCreateInput;
};

export type ChannelOwnersConnection = {
  __typename?: 'ChannelOwnersConnection';
  edges: Array<ChannelOwnersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChannelOwnersConnectionSort = {
  node?: InputMaybe<OwnerSort>;
};

export type ChannelOwnersConnectionWhere = {
  AND?: InputMaybe<Array<ChannelOwnersConnectionWhere>>;
  NOT?: InputMaybe<ChannelOwnersConnectionWhere>;
  OR?: InputMaybe<Array<ChannelOwnersConnectionWhere>>;
  node?: InputMaybe<OwnerWhere>;
};

export type ChannelOwnersCreateFieldInput = {
  node: OwnerCreateInput;
};

export type ChannelOwnersDeleteFieldInput = {
  delete?: InputMaybe<OwnerDeleteInput>;
  where?: InputMaybe<ChannelOwnersConnectionWhere>;
};

export type ChannelOwnersDisconnectFieldInput = {
  disconnect?: InputMaybe<OwnerDisconnectInput>;
  where?: InputMaybe<ChannelOwnersConnectionWhere>;
};

export type ChannelOwnersFieldInput = {
  connect?: InputMaybe<Array<ChannelOwnersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelOwnersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelOwnersCreateFieldInput>>;
};

export type ChannelOwnersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChannelOwnersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChannelOwnersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChannelOwnersNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  nameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type ChannelOwnersRelationship = {
  __typename?: 'ChannelOwnersRelationship';
  cursor: Scalars['String']['output'];
  node: Owner;
};

export type ChannelOwnersUpdateConnectionInput = {
  node?: InputMaybe<OwnerUpdateInput>;
};

export type ChannelOwnersUpdateFieldInput = {
  connect?: InputMaybe<Array<ChannelOwnersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelOwnersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelOwnersCreateFieldInput>>;
  delete?: InputMaybe<Array<ChannelOwnersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChannelOwnersDisconnectFieldInput>>;
  update?: InputMaybe<ChannelOwnersUpdateConnectionInput>;
  where?: InputMaybe<ChannelOwnersConnectionWhere>;
};

export type ChannelRelationInput = {
  accounts?: InputMaybe<Array<ChannelAccountsCreateFieldInput>>;
  categories?: InputMaybe<Array<ChannelCategoriesCreateFieldInput>>;
  channelTypes?: InputMaybe<Array<ChannelChannelTypesCreateFieldInput>>;
  contentBadges?: InputMaybe<Array<ChannelContentBadgesCreateFieldInput>>;
  creators?: InputMaybe<Array<ChannelCreatorsCreateFieldInput>>;
  media?: InputMaybe<Array<ChannelMediaCreateFieldInput>>;
  owners?: InputMaybe<Array<ChannelOwnersCreateFieldInput>>;
  tags?: InputMaybe<Array<ChannelTagsCreateFieldInput>>;
  thumbnail?: InputMaybe<ChannelThumbnailCreateFieldInput>;
};

/** Fields to sort Channels by. The order in which sorts are applied is not guaranteed when specifying many fields in one ChannelSort object. */
export type ChannelSort = {
  channelName?: InputMaybe<SortDirection>;
  channelUrl?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type ChannelTagTagsAggregationSelection = {
  __typename?: 'ChannelTagTagsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<ChannelTagTagsNodeAggregateSelection>;
};

export type ChannelTagTagsNodeAggregateSelection = {
  __typename?: 'ChannelTagTagsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type ChannelTagsAggregateInput = {
  AND?: InputMaybe<Array<ChannelTagsAggregateInput>>;
  NOT?: InputMaybe<ChannelTagsAggregateInput>;
  OR?: InputMaybe<Array<ChannelTagsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<ChannelTagsNodeAggregationWhereInput>;
};

export type ChannelTagsConnectFieldInput = {
  connect?: InputMaybe<Array<TagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<TagConnectWhere>;
};

export type ChannelTagsConnectOrCreateFieldInput = {
  onCreate: ChannelTagsConnectOrCreateFieldInputOnCreate;
  where: TagConnectOrCreateWhere;
};

export type ChannelTagsConnectOrCreateFieldInputOnCreate = {
  node: TagOnCreateInput;
};

export type ChannelTagsConnection = {
  __typename?: 'ChannelTagsConnection';
  edges: Array<ChannelTagsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChannelTagsConnectionSort = {
  node?: InputMaybe<TagSort>;
};

export type ChannelTagsConnectionWhere = {
  AND?: InputMaybe<Array<ChannelTagsConnectionWhere>>;
  NOT?: InputMaybe<ChannelTagsConnectionWhere>;
  OR?: InputMaybe<Array<ChannelTagsConnectionWhere>>;
  node?: InputMaybe<TagWhere>;
};

export type ChannelTagsCreateFieldInput = {
  node: TagCreateInput;
};

export type ChannelTagsDeleteFieldInput = {
  delete?: InputMaybe<TagDeleteInput>;
  where?: InputMaybe<ChannelTagsConnectionWhere>;
};

export type ChannelTagsDisconnectFieldInput = {
  disconnect?: InputMaybe<TagDisconnectInput>;
  where?: InputMaybe<ChannelTagsConnectionWhere>;
};

export type ChannelTagsFieldInput = {
  connect?: InputMaybe<Array<ChannelTagsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelTagsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelTagsCreateFieldInput>>;
};

export type ChannelTagsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChannelTagsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChannelTagsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChannelTagsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ChannelTagsRelationship = {
  __typename?: 'ChannelTagsRelationship';
  cursor: Scalars['String']['output'];
  node: Tag;
};

export type ChannelTagsUpdateConnectionInput = {
  node?: InputMaybe<TagUpdateInput>;
};

export type ChannelTagsUpdateFieldInput = {
  connect?: InputMaybe<Array<ChannelTagsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChannelTagsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChannelTagsCreateFieldInput>>;
  delete?: InputMaybe<Array<ChannelTagsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChannelTagsDisconnectFieldInput>>;
  update?: InputMaybe<ChannelTagsUpdateConnectionInput>;
  where?: InputMaybe<ChannelTagsConnectionWhere>;
};

export type ChannelThumbnailAggregateInput = {
  AND?: InputMaybe<Array<ChannelThumbnailAggregateInput>>;
  NOT?: InputMaybe<ChannelThumbnailAggregateInput>;
  OR?: InputMaybe<Array<ChannelThumbnailAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<ChannelThumbnailNodeAggregationWhereInput>;
};

export type ChannelThumbnailConnectFieldInput = {
  connect?: InputMaybe<UserMediaConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserMediaConnectWhere>;
};

export type ChannelThumbnailConnectOrCreateFieldInput = {
  onCreate: ChannelThumbnailConnectOrCreateFieldInputOnCreate;
  where: UserMediaConnectOrCreateWhere;
};

export type ChannelThumbnailConnectOrCreateFieldInputOnCreate = {
  node: UserMediaOnCreateInput;
};

export type ChannelThumbnailConnection = {
  __typename?: 'ChannelThumbnailConnection';
  edges: Array<ChannelThumbnailRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChannelThumbnailConnectionSort = {
  node?: InputMaybe<UserMediaSort>;
};

export type ChannelThumbnailConnectionWhere = {
  AND?: InputMaybe<Array<ChannelThumbnailConnectionWhere>>;
  NOT?: InputMaybe<ChannelThumbnailConnectionWhere>;
  OR?: InputMaybe<Array<ChannelThumbnailConnectionWhere>>;
  node?: InputMaybe<UserMediaWhere>;
};

export type ChannelThumbnailCreateFieldInput = {
  node: UserMediaCreateInput;
};

export type ChannelThumbnailDeleteFieldInput = {
  delete?: InputMaybe<UserMediaDeleteInput>;
  where?: InputMaybe<ChannelThumbnailConnectionWhere>;
};

export type ChannelThumbnailDisconnectFieldInput = {
  disconnect?: InputMaybe<UserMediaDisconnectInput>;
  where?: InputMaybe<ChannelThumbnailConnectionWhere>;
};

export type ChannelThumbnailFieldInput = {
  connect?: InputMaybe<ChannelThumbnailConnectFieldInput>;
  connectOrCreate?: InputMaybe<ChannelThumbnailConnectOrCreateFieldInput>;
  create?: InputMaybe<ChannelThumbnailCreateFieldInput>;
};

export type ChannelThumbnailNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChannelThumbnailNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChannelThumbnailNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChannelThumbnailNodeAggregationWhereInput>>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  caption_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  encoding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  extension_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  height_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  source_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  width_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  width_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type ChannelThumbnailRelationship = {
  __typename?: 'ChannelThumbnailRelationship';
  cursor: Scalars['String']['output'];
  node: UserMedia;
};

export type ChannelThumbnailUpdateConnectionInput = {
  node?: InputMaybe<UserMediaUpdateInput>;
};

export type ChannelThumbnailUpdateFieldInput = {
  connect?: InputMaybe<ChannelThumbnailConnectFieldInput>;
  connectOrCreate?: InputMaybe<ChannelThumbnailConnectOrCreateFieldInput>;
  create?: InputMaybe<ChannelThumbnailCreateFieldInput>;
  delete?: InputMaybe<ChannelThumbnailDeleteFieldInput>;
  disconnect?: InputMaybe<ChannelThumbnailDisconnectFieldInput>;
  update?: InputMaybe<ChannelThumbnailUpdateConnectionInput>;
  where?: InputMaybe<ChannelThumbnailConnectionWhere>;
};

export type ChannelTypeTagChannelTypesAggregationSelection = {
  __typename?: 'ChannelTypeTagChannelTypesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<ChannelTypeTagChannelTypesNodeAggregateSelection>;
};

export type ChannelTypeTagChannelTypesNodeAggregateSelection = {
  __typename?: 'ChannelTypeTagChannelTypesNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type ChannelUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ChannelUpdateInput = {
  accounts?: InputMaybe<Array<ChannelAccountsUpdateFieldInput>>;
  categories?: InputMaybe<Array<ChannelCategoriesUpdateFieldInput>>;
  channelName?: InputMaybe<Scalars['String']['input']>;
  channelTypes?: InputMaybe<Array<ChannelChannelTypesUpdateFieldInput>>;
  channelUrl?: InputMaybe<Scalars['String']['input']>;
  contentBadges?: InputMaybe<Array<ChannelContentBadgesUpdateFieldInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creators?: InputMaybe<Array<ChannelCreatorsUpdateFieldInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<Array<ChannelMediaUpdateFieldInput>>;
  owners?: InputMaybe<Array<ChannelOwnersUpdateFieldInput>>;
  tags?: InputMaybe<Array<ChannelTagsUpdateFieldInput>>;
  thumbnail?: InputMaybe<ChannelThumbnailUpdateFieldInput>;
};

export type ChannelUserMediaMediaAggregationSelection = {
  __typename?: 'ChannelUserMediaMediaAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<ChannelUserMediaMediaNodeAggregateSelection>;
};

export type ChannelUserMediaMediaNodeAggregateSelection = {
  __typename?: 'ChannelUserMediaMediaNodeAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  caption: StringAggregateSelectionNullable;
  contentLength: IntAggregateSelectionNullable;
  contentType: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  encoding: StringAggregateSelectionNullable;
  extension: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  height: IntAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  source: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  width: IntAggregateSelectionNullable;
};

export type ChannelUserMediaThumbnailAggregationSelection = {
  __typename?: 'ChannelUserMediaThumbnailAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<ChannelUserMediaThumbnailNodeAggregateSelection>;
};

export type ChannelUserMediaThumbnailNodeAggregateSelection = {
  __typename?: 'ChannelUserMediaThumbnailNodeAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  caption: StringAggregateSelectionNullable;
  contentLength: IntAggregateSelectionNullable;
  contentType: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  encoding: StringAggregateSelectionNullable;
  extension: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  height: IntAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  source: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  width: IntAggregateSelectionNullable;
};

export type ChannelWhere = {
  AND?: InputMaybe<Array<ChannelWhere>>;
  NOT?: InputMaybe<ChannelWhere>;
  OR?: InputMaybe<Array<ChannelWhere>>;
  accountsAggregate?: InputMaybe<ChannelAccountsAggregateInput>;
  /** Return Channels where all of the related ChannelAccountsConnections match this filter */
  accountsConnection_ALL?: InputMaybe<ChannelAccountsConnectionWhere>;
  /** Return Channels where none of the related ChannelAccountsConnections match this filter */
  accountsConnection_NONE?: InputMaybe<ChannelAccountsConnectionWhere>;
  /** Return Channels where one of the related ChannelAccountsConnections match this filter */
  accountsConnection_SINGLE?: InputMaybe<ChannelAccountsConnectionWhere>;
  /** Return Channels where some of the related ChannelAccountsConnections match this filter */
  accountsConnection_SOME?: InputMaybe<ChannelAccountsConnectionWhere>;
  /** Return Channels where all of the related Accounts match this filter */
  accounts_ALL?: InputMaybe<AccountWhere>;
  /** Return Channels where none of the related Accounts match this filter */
  accounts_NONE?: InputMaybe<AccountWhere>;
  /** Return Channels where one of the related Accounts match this filter */
  accounts_SINGLE?: InputMaybe<AccountWhere>;
  /** Return Channels where some of the related Accounts match this filter */
  accounts_SOME?: InputMaybe<AccountWhere>;
  categoriesAggregate?: InputMaybe<ChannelCategoriesAggregateInput>;
  /** Return Channels where all of the related ChannelCategoriesConnections match this filter */
  categoriesConnection_ALL?: InputMaybe<ChannelCategoriesConnectionWhere>;
  /** Return Channels where none of the related ChannelCategoriesConnections match this filter */
  categoriesConnection_NONE?: InputMaybe<ChannelCategoriesConnectionWhere>;
  /** Return Channels where one of the related ChannelCategoriesConnections match this filter */
  categoriesConnection_SINGLE?: InputMaybe<ChannelCategoriesConnectionWhere>;
  /** Return Channels where some of the related ChannelCategoriesConnections match this filter */
  categoriesConnection_SOME?: InputMaybe<ChannelCategoriesConnectionWhere>;
  /** Return Channels where all of the related CategoryTags match this filter */
  categories_ALL?: InputMaybe<CategoryTagWhere>;
  /** Return Channels where none of the related CategoryTags match this filter */
  categories_NONE?: InputMaybe<CategoryTagWhere>;
  /** Return Channels where one of the related CategoryTags match this filter */
  categories_SINGLE?: InputMaybe<CategoryTagWhere>;
  /** Return Channels where some of the related CategoryTags match this filter */
  categories_SOME?: InputMaybe<CategoryTagWhere>;
  channelName?: InputMaybe<Scalars['String']['input']>;
  channelName_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  channelName_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  channelName_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channelName_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  channelTypesAggregate?: InputMaybe<ChannelChannelTypesAggregateInput>;
  /** Return Channels where all of the related ChannelChannelTypesConnections match this filter */
  channelTypesConnection_ALL?: InputMaybe<ChannelChannelTypesConnectionWhere>;
  /** Return Channels where none of the related ChannelChannelTypesConnections match this filter */
  channelTypesConnection_NONE?: InputMaybe<ChannelChannelTypesConnectionWhere>;
  /** Return Channels where one of the related ChannelChannelTypesConnections match this filter */
  channelTypesConnection_SINGLE?: InputMaybe<ChannelChannelTypesConnectionWhere>;
  /** Return Channels where some of the related ChannelChannelTypesConnections match this filter */
  channelTypesConnection_SOME?: InputMaybe<ChannelChannelTypesConnectionWhere>;
  /** Return Channels where all of the related TypeTags match this filter */
  channelTypes_ALL?: InputMaybe<TypeTagWhere>;
  /** Return Channels where none of the related TypeTags match this filter */
  channelTypes_NONE?: InputMaybe<TypeTagWhere>;
  /** Return Channels where one of the related TypeTags match this filter */
  channelTypes_SINGLE?: InputMaybe<TypeTagWhere>;
  /** Return Channels where some of the related TypeTags match this filter */
  channelTypes_SOME?: InputMaybe<TypeTagWhere>;
  channelUrl?: InputMaybe<Scalars['String']['input']>;
  channelUrl_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  channelUrl_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  channelUrl_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channelUrl_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  contentBadgesAggregate?: InputMaybe<ChannelContentBadgesAggregateInput>;
  /** Return Channels where all of the related ChannelContentBadgesConnections match this filter */
  contentBadgesConnection_ALL?: InputMaybe<ChannelContentBadgesConnectionWhere>;
  /** Return Channels where none of the related ChannelContentBadgesConnections match this filter */
  contentBadgesConnection_NONE?: InputMaybe<ChannelContentBadgesConnectionWhere>;
  /** Return Channels where one of the related ChannelContentBadgesConnections match this filter */
  contentBadgesConnection_SINGLE?: InputMaybe<ChannelContentBadgesConnectionWhere>;
  /** Return Channels where some of the related ChannelContentBadgesConnections match this filter */
  contentBadgesConnection_SOME?: InputMaybe<ChannelContentBadgesConnectionWhere>;
  /** Return Channels where all of the related ContentBadges match this filter */
  contentBadges_ALL?: InputMaybe<ContentBadgeWhere>;
  /** Return Channels where none of the related ContentBadges match this filter */
  contentBadges_NONE?: InputMaybe<ContentBadgeWhere>;
  /** Return Channels where one of the related ContentBadges match this filter */
  contentBadges_SINGLE?: InputMaybe<ContentBadgeWhere>;
  /** Return Channels where some of the related ContentBadges match this filter */
  contentBadges_SOME?: InputMaybe<ContentBadgeWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creatorsAggregate?: InputMaybe<ChannelCreatorsAggregateInput>;
  /** Return Channels where all of the related ChannelCreatorsConnections match this filter */
  creatorsConnection_ALL?: InputMaybe<ChannelCreatorsConnectionWhere>;
  /** Return Channels where none of the related ChannelCreatorsConnections match this filter */
  creatorsConnection_NONE?: InputMaybe<ChannelCreatorsConnectionWhere>;
  /** Return Channels where one of the related ChannelCreatorsConnections match this filter */
  creatorsConnection_SINGLE?: InputMaybe<ChannelCreatorsConnectionWhere>;
  /** Return Channels where some of the related ChannelCreatorsConnections match this filter */
  creatorsConnection_SOME?: InputMaybe<ChannelCreatorsConnectionWhere>;
  /** Return Channels where all of the related Creators match this filter */
  creators_ALL?: InputMaybe<CreatorWhere>;
  /** Return Channels where none of the related Creators match this filter */
  creators_NONE?: InputMaybe<CreatorWhere>;
  /** Return Channels where one of the related Creators match this filter */
  creators_SINGLE?: InputMaybe<CreatorWhere>;
  /** Return Channels where some of the related Creators match this filter */
  creators_SOME?: InputMaybe<CreatorWhere>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  description_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  mediaAggregate?: InputMaybe<ChannelMediaAggregateInput>;
  /** Return Channels where all of the related ChannelMediaConnections match this filter */
  mediaConnection_ALL?: InputMaybe<ChannelMediaConnectionWhere>;
  /** Return Channels where none of the related ChannelMediaConnections match this filter */
  mediaConnection_NONE?: InputMaybe<ChannelMediaConnectionWhere>;
  /** Return Channels where one of the related ChannelMediaConnections match this filter */
  mediaConnection_SINGLE?: InputMaybe<ChannelMediaConnectionWhere>;
  /** Return Channels where some of the related ChannelMediaConnections match this filter */
  mediaConnection_SOME?: InputMaybe<ChannelMediaConnectionWhere>;
  /** Return Channels where all of the related UserMedias match this filter */
  media_ALL?: InputMaybe<UserMediaWhere>;
  /** Return Channels where none of the related UserMedias match this filter */
  media_NONE?: InputMaybe<UserMediaWhere>;
  /** Return Channels where one of the related UserMedias match this filter */
  media_SINGLE?: InputMaybe<UserMediaWhere>;
  /** Return Channels where some of the related UserMedias match this filter */
  media_SOME?: InputMaybe<UserMediaWhere>;
  ownersAggregate?: InputMaybe<ChannelOwnersAggregateInput>;
  /** Return Channels where all of the related ChannelOwnersConnections match this filter */
  ownersConnection_ALL?: InputMaybe<ChannelOwnersConnectionWhere>;
  /** Return Channels where none of the related ChannelOwnersConnections match this filter */
  ownersConnection_NONE?: InputMaybe<ChannelOwnersConnectionWhere>;
  /** Return Channels where one of the related ChannelOwnersConnections match this filter */
  ownersConnection_SINGLE?: InputMaybe<ChannelOwnersConnectionWhere>;
  /** Return Channels where some of the related ChannelOwnersConnections match this filter */
  ownersConnection_SOME?: InputMaybe<ChannelOwnersConnectionWhere>;
  /** Return Channels where all of the related Owners match this filter */
  owners_ALL?: InputMaybe<OwnerWhere>;
  /** Return Channels where none of the related Owners match this filter */
  owners_NONE?: InputMaybe<OwnerWhere>;
  /** Return Channels where one of the related Owners match this filter */
  owners_SINGLE?: InputMaybe<OwnerWhere>;
  /** Return Channels where some of the related Owners match this filter */
  owners_SOME?: InputMaybe<OwnerWhere>;
  tagsAggregate?: InputMaybe<ChannelTagsAggregateInput>;
  /** Return Channels where all of the related ChannelTagsConnections match this filter */
  tagsConnection_ALL?: InputMaybe<ChannelTagsConnectionWhere>;
  /** Return Channels where none of the related ChannelTagsConnections match this filter */
  tagsConnection_NONE?: InputMaybe<ChannelTagsConnectionWhere>;
  /** Return Channels where one of the related ChannelTagsConnections match this filter */
  tagsConnection_SINGLE?: InputMaybe<ChannelTagsConnectionWhere>;
  /** Return Channels where some of the related ChannelTagsConnections match this filter */
  tagsConnection_SOME?: InputMaybe<ChannelTagsConnectionWhere>;
  /** Return Channels where all of the related Tags match this filter */
  tags_ALL?: InputMaybe<TagWhere>;
  /** Return Channels where none of the related Tags match this filter */
  tags_NONE?: InputMaybe<TagWhere>;
  /** Return Channels where one of the related Tags match this filter */
  tags_SINGLE?: InputMaybe<TagWhere>;
  /** Return Channels where some of the related Tags match this filter */
  tags_SOME?: InputMaybe<TagWhere>;
  thumbnail?: InputMaybe<UserMediaWhere>;
  thumbnailAggregate?: InputMaybe<ChannelThumbnailAggregateInput>;
  thumbnailConnection?: InputMaybe<ChannelThumbnailConnectionWhere>;
  thumbnailConnection_NOT?: InputMaybe<ChannelThumbnailConnectionWhere>;
  thumbnail_NOT?: InputMaybe<UserMediaWhere>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ChannelsConnection = {
  __typename?: 'ChannelsConnection';
  edges: Array<ChannelEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Content badges are used to label content. */
export type ContentBadge = {
  __typename?: 'ContentBadge';
  /** Acronym of badge label */
  acronym?: Maybe<Scalars['String']['output']>;
  /** Certification type */
  certification?: Maybe<CertificationType>;
  /** Channels with this badge */
  channels: Array<Channel>;
  channelsAggregate?: Maybe<ContentBadgeChannelChannelsAggregationSelection>;
  channelsConnection: ContentBadgeChannelsConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Badge description */
  description?: Maybe<Scalars['String']['output']>;
  /** Code generated ID */
  id: Scalars['ID']['output'];
  /** Badge label */
  label?: Maybe<Scalars['String']['output']>;
  /** Badge type */
  type?: Maybe<ContentBadgeType>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Works with this badge */
  works: Array<Work>;
  worksAggregate?: Maybe<ContentBadgeWorkWorksAggregationSelection>;
  worksConnection: ContentBadgeWorksConnection;
};


/** Content badges are used to label content. */
export type ContentBadgeChannelsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<ChannelOptions>;
  where?: InputMaybe<ChannelWhere>;
};


/** Content badges are used to label content. */
export type ContentBadgeChannelsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ChannelWhere>;
};


/** Content badges are used to label content. */
export type ContentBadgeChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ContentBadgeChannelsConnectionSort>>;
  where?: InputMaybe<ContentBadgeChannelsConnectionWhere>;
};


/** Content badges are used to label content. */
export type ContentBadgeWorksArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<WorkOptions>;
  where?: InputMaybe<WorkWhere>;
};


/** Content badges are used to label content. */
export type ContentBadgeWorksAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<WorkWhere>;
};


/** Content badges are used to label content. */
export type ContentBadgeWorksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ContentBadgeWorksConnectionSort>>;
  where?: InputMaybe<ContentBadgeWorksConnectionWhere>;
};

export type ContentBadgeAggregateSelection = {
  __typename?: 'ContentBadgeAggregateSelection';
  acronym: StringAggregateSelectionNullable;
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  label: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type ContentBadgeChannelChannelsAggregationSelection = {
  __typename?: 'ContentBadgeChannelChannelsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<ContentBadgeChannelChannelsNodeAggregateSelection>;
};

export type ContentBadgeChannelChannelsNodeAggregateSelection = {
  __typename?: 'ContentBadgeChannelChannelsNodeAggregateSelection';
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type ContentBadgeChannelsAggregateInput = {
  AND?: InputMaybe<Array<ContentBadgeChannelsAggregateInput>>;
  NOT?: InputMaybe<ContentBadgeChannelsAggregateInput>;
  OR?: InputMaybe<Array<ContentBadgeChannelsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<ContentBadgeChannelsNodeAggregationWhereInput>;
};

export type ContentBadgeChannelsConnectFieldInput = {
  connect?: InputMaybe<Array<ChannelConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<ChannelConnectWhere>;
};

export type ContentBadgeChannelsConnectOrCreateFieldInput = {
  onCreate: ContentBadgeChannelsConnectOrCreateFieldInputOnCreate;
  where: ChannelConnectOrCreateWhere;
};

export type ContentBadgeChannelsConnectOrCreateFieldInputOnCreate = {
  node: ChannelOnCreateInput;
};

export type ContentBadgeChannelsConnection = {
  __typename?: 'ContentBadgeChannelsConnection';
  edges: Array<ContentBadgeChannelsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ContentBadgeChannelsConnectionSort = {
  node?: InputMaybe<ChannelSort>;
};

export type ContentBadgeChannelsConnectionWhere = {
  AND?: InputMaybe<Array<ContentBadgeChannelsConnectionWhere>>;
  NOT?: InputMaybe<ContentBadgeChannelsConnectionWhere>;
  OR?: InputMaybe<Array<ContentBadgeChannelsConnectionWhere>>;
  node?: InputMaybe<ChannelWhere>;
};

export type ContentBadgeChannelsCreateFieldInput = {
  node: ChannelCreateInput;
};

export type ContentBadgeChannelsDeleteFieldInput = {
  delete?: InputMaybe<ChannelDeleteInput>;
  where?: InputMaybe<ContentBadgeChannelsConnectionWhere>;
};

export type ContentBadgeChannelsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChannelDisconnectInput>;
  where?: InputMaybe<ContentBadgeChannelsConnectionWhere>;
};

export type ContentBadgeChannelsFieldInput = {
  connect?: InputMaybe<Array<ContentBadgeChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ContentBadgeChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ContentBadgeChannelsCreateFieldInput>>;
};

export type ContentBadgeChannelsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ContentBadgeChannelsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ContentBadgeChannelsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ContentBadgeChannelsNodeAggregationWhereInput>>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentBadgeChannelsRelationship = {
  __typename?: 'ContentBadgeChannelsRelationship';
  cursor: Scalars['String']['output'];
  node: Channel;
};

export type ContentBadgeChannelsUpdateConnectionInput = {
  node?: InputMaybe<ChannelUpdateInput>;
};

export type ContentBadgeChannelsUpdateFieldInput = {
  connect?: InputMaybe<Array<ContentBadgeChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ContentBadgeChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ContentBadgeChannelsCreateFieldInput>>;
  delete?: InputMaybe<Array<ContentBadgeChannelsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ContentBadgeChannelsDisconnectFieldInput>>;
  update?: InputMaybe<ContentBadgeChannelsUpdateConnectionInput>;
  where?: InputMaybe<ContentBadgeChannelsConnectionWhere>;
};

export type ContentBadgeConnectInput = {
  channels?: InputMaybe<Array<ContentBadgeChannelsConnectFieldInput>>;
  works?: InputMaybe<Array<ContentBadgeWorksConnectFieldInput>>;
};

export type ContentBadgeConnectOrCreateInput = {
  channels?: InputMaybe<Array<ContentBadgeChannelsConnectOrCreateFieldInput>>;
  works?: InputMaybe<Array<ContentBadgeWorksConnectOrCreateFieldInput>>;
};

export type ContentBadgeConnectOrCreateWhere = {
  node: ContentBadgeUniqueWhere;
};

export type ContentBadgeConnectWhere = {
  node: ContentBadgeWhere;
};

export type ContentBadgeCreateInput = {
  acronym?: InputMaybe<Scalars['String']['input']>;
  certification?: InputMaybe<CertificationType>;
  channels?: InputMaybe<ContentBadgeChannelsFieldInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ContentBadgeType>;
  works?: InputMaybe<ContentBadgeWorksFieldInput>;
};

export type ContentBadgeDeleteInput = {
  channels?: InputMaybe<Array<ContentBadgeChannelsDeleteFieldInput>>;
  works?: InputMaybe<Array<ContentBadgeWorksDeleteFieldInput>>;
};

export type ContentBadgeDisconnectInput = {
  channels?: InputMaybe<Array<ContentBadgeChannelsDisconnectFieldInput>>;
  works?: InputMaybe<Array<ContentBadgeWorksDisconnectFieldInput>>;
};

export type ContentBadgeEdge = {
  __typename?: 'ContentBadgeEdge';
  cursor: Scalars['String']['output'];
  node: ContentBadge;
};

export type ContentBadgeOnCreateInput = {
  acronym?: InputMaybe<Scalars['String']['input']>;
  certification?: InputMaybe<CertificationType>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ContentBadgeType>;
};

export type ContentBadgeOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more ContentBadgeSort objects to sort ContentBadges by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ContentBadgeSort>>;
};

export type ContentBadgeRelationInput = {
  channels?: InputMaybe<Array<ContentBadgeChannelsCreateFieldInput>>;
  works?: InputMaybe<Array<ContentBadgeWorksCreateFieldInput>>;
};

/** Fields to sort ContentBadges by. The order in which sorts are applied is not guaranteed when specifying many fields in one ContentBadgeSort object. */
export type ContentBadgeSort = {
  acronym?: InputMaybe<SortDirection>;
  certification?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum ContentBadgeType {
  Origin = 'ORIGIN',
  Sourcing = 'SOURCING'
}

export type ContentBadgeUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ContentBadgeUpdateInput = {
  acronym?: InputMaybe<Scalars['String']['input']>;
  certification?: InputMaybe<CertificationType>;
  channels?: InputMaybe<Array<ContentBadgeChannelsUpdateFieldInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ContentBadgeType>;
  works?: InputMaybe<Array<ContentBadgeWorksUpdateFieldInput>>;
};

export type ContentBadgeWhere = {
  AND?: InputMaybe<Array<ContentBadgeWhere>>;
  NOT?: InputMaybe<ContentBadgeWhere>;
  OR?: InputMaybe<Array<ContentBadgeWhere>>;
  acronym?: InputMaybe<Scalars['String']['input']>;
  acronym_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  acronym_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  acronym_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  acronym_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  certification?: InputMaybe<CertificationType>;
  certification_IN?: InputMaybe<Array<InputMaybe<CertificationType>>>;
  channelsAggregate?: InputMaybe<ContentBadgeChannelsAggregateInput>;
  /** Return ContentBadges where all of the related ContentBadgeChannelsConnections match this filter */
  channelsConnection_ALL?: InputMaybe<ContentBadgeChannelsConnectionWhere>;
  /** Return ContentBadges where none of the related ContentBadgeChannelsConnections match this filter */
  channelsConnection_NONE?: InputMaybe<ContentBadgeChannelsConnectionWhere>;
  /** Return ContentBadges where one of the related ContentBadgeChannelsConnections match this filter */
  channelsConnection_SINGLE?: InputMaybe<ContentBadgeChannelsConnectionWhere>;
  /** Return ContentBadges where some of the related ContentBadgeChannelsConnections match this filter */
  channelsConnection_SOME?: InputMaybe<ContentBadgeChannelsConnectionWhere>;
  /** Return ContentBadges where all of the related Channels match this filter */
  channels_ALL?: InputMaybe<ChannelWhere>;
  /** Return ContentBadges where none of the related Channels match this filter */
  channels_NONE?: InputMaybe<ChannelWhere>;
  /** Return ContentBadges where one of the related Channels match this filter */
  channels_SINGLE?: InputMaybe<ChannelWhere>;
  /** Return ContentBadges where some of the related Channels match this filter */
  channels_SOME?: InputMaybe<ChannelWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  description_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  label_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  label_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ContentBadgeType>;
  type_IN?: InputMaybe<Array<InputMaybe<ContentBadgeType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  worksAggregate?: InputMaybe<ContentBadgeWorksAggregateInput>;
  /** Return ContentBadges where all of the related ContentBadgeWorksConnections match this filter */
  worksConnection_ALL?: InputMaybe<ContentBadgeWorksConnectionWhere>;
  /** Return ContentBadges where none of the related ContentBadgeWorksConnections match this filter */
  worksConnection_NONE?: InputMaybe<ContentBadgeWorksConnectionWhere>;
  /** Return ContentBadges where one of the related ContentBadgeWorksConnections match this filter */
  worksConnection_SINGLE?: InputMaybe<ContentBadgeWorksConnectionWhere>;
  /** Return ContentBadges where some of the related ContentBadgeWorksConnections match this filter */
  worksConnection_SOME?: InputMaybe<ContentBadgeWorksConnectionWhere>;
  /** Return ContentBadges where all of the related Works match this filter */
  works_ALL?: InputMaybe<WorkWhere>;
  /** Return ContentBadges where none of the related Works match this filter */
  works_NONE?: InputMaybe<WorkWhere>;
  /** Return ContentBadges where one of the related Works match this filter */
  works_SINGLE?: InputMaybe<WorkWhere>;
  /** Return ContentBadges where some of the related Works match this filter */
  works_SOME?: InputMaybe<WorkWhere>;
};

export type ContentBadgeWorkWorksAggregationSelection = {
  __typename?: 'ContentBadgeWorkWorksAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<ContentBadgeWorkWorksNodeAggregateSelection>;
};

export type ContentBadgeWorkWorksNodeAggregateSelection = {
  __typename?: 'ContentBadgeWorkWorksNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  workUrl: StringAggregateSelectionNullable;
};

export type ContentBadgeWorksAggregateInput = {
  AND?: InputMaybe<Array<ContentBadgeWorksAggregateInput>>;
  NOT?: InputMaybe<ContentBadgeWorksAggregateInput>;
  OR?: InputMaybe<Array<ContentBadgeWorksAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<ContentBadgeWorksNodeAggregationWhereInput>;
};

export type ContentBadgeWorksConnectFieldInput = {
  connect?: InputMaybe<Array<WorkConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<WorkConnectWhere>;
};

export type ContentBadgeWorksConnectOrCreateFieldInput = {
  onCreate: ContentBadgeWorksConnectOrCreateFieldInputOnCreate;
  where: WorkConnectOrCreateWhere;
};

export type ContentBadgeWorksConnectOrCreateFieldInputOnCreate = {
  node: WorkOnCreateInput;
};

export type ContentBadgeWorksConnection = {
  __typename?: 'ContentBadgeWorksConnection';
  edges: Array<ContentBadgeWorksRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ContentBadgeWorksConnectionSort = {
  node?: InputMaybe<WorkSort>;
};

export type ContentBadgeWorksConnectionWhere = {
  AND?: InputMaybe<Array<ContentBadgeWorksConnectionWhere>>;
  NOT?: InputMaybe<ContentBadgeWorksConnectionWhere>;
  OR?: InputMaybe<Array<ContentBadgeWorksConnectionWhere>>;
  node?: InputMaybe<WorkWhere>;
};

export type ContentBadgeWorksCreateFieldInput = {
  node: WorkCreateInput;
};

export type ContentBadgeWorksDeleteFieldInput = {
  delete?: InputMaybe<WorkDeleteInput>;
  where?: InputMaybe<ContentBadgeWorksConnectionWhere>;
};

export type ContentBadgeWorksDisconnectFieldInput = {
  disconnect?: InputMaybe<WorkDisconnectInput>;
  where?: InputMaybe<ContentBadgeWorksConnectionWhere>;
};

export type ContentBadgeWorksFieldInput = {
  connect?: InputMaybe<Array<ContentBadgeWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ContentBadgeWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ContentBadgeWorksCreateFieldInput>>;
};

export type ContentBadgeWorksNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ContentBadgeWorksNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ContentBadgeWorksNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ContentBadgeWorksNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  workUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentBadgeWorksRelationship = {
  __typename?: 'ContentBadgeWorksRelationship';
  cursor: Scalars['String']['output'];
  node: Work;
};

export type ContentBadgeWorksUpdateConnectionInput = {
  node?: InputMaybe<WorkUpdateInput>;
};

export type ContentBadgeWorksUpdateFieldInput = {
  connect?: InputMaybe<Array<ContentBadgeWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ContentBadgeWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ContentBadgeWorksCreateFieldInput>>;
  delete?: InputMaybe<Array<ContentBadgeWorksDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ContentBadgeWorksDisconnectFieldInput>>;
  update?: InputMaybe<ContentBadgeWorksUpdateConnectionInput>;
  where?: InputMaybe<ContentBadgeWorksConnectionWhere>;
};

export type ContentBadgesConnection = {
  __typename?: 'ContentBadgesConnection';
  edges: Array<ContentBadgeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CreateAccountSubscriptionsMutationResponse = {
  __typename?: 'CreateAccountSubscriptionsMutationResponse';
  accountSubscriptions: Array<AccountSubscription>;
  info: CreateInfo;
};

export type CreateAccountsMutationResponse = {
  __typename?: 'CreateAccountsMutationResponse';
  accounts: Array<Account>;
  info: CreateInfo;
};

export type CreateCategoryTagRequestsMutationResponse = {
  __typename?: 'CreateCategoryTagRequestsMutationResponse';
  categoryTagRequests: Array<CategoryTagRequest>;
  info: CreateInfo;
};

export type CreateCategoryTagsMutationResponse = {
  __typename?: 'CreateCategoryTagsMutationResponse';
  categoryTags: Array<CategoryTag>;
  info: CreateInfo;
};

export type CreateCertificationRequestsMutationResponse = {
  __typename?: 'CreateCertificationRequestsMutationResponse';
  certificationRequests: Array<CertificationRequest>;
  info: CreateInfo;
};

export type CreateCertificationResponsesMutationResponse = {
  __typename?: 'CreateCertificationResponsesMutationResponse';
  certificationResponses: Array<CertificationResponse>;
  info: CreateInfo;
};

export type CreateChannelsMutationResponse = {
  __typename?: 'CreateChannelsMutationResponse';
  channels: Array<Channel>;
  info: CreateInfo;
};

export type CreateContentBadgesMutationResponse = {
  __typename?: 'CreateContentBadgesMutationResponse';
  contentBadges: Array<ContentBadge>;
  info: CreateInfo;
};

export type CreateCreatorContactsMutationResponse = {
  __typename?: 'CreateCreatorContactsMutationResponse';
  creatorContacts: Array<CreatorContact>;
  info: CreateInfo;
};

export type CreateCreatorsMutationResponse = {
  __typename?: 'CreateCreatorsMutationResponse';
  creators: Array<Creator>;
  info: CreateInfo;
};

export type CreateEditionsMutationResponse = {
  __typename?: 'CreateEditionsMutationResponse';
  editions: Array<Edition>;
  info: CreateInfo;
};

export type CreateFeedbacksMutationResponse = {
  __typename?: 'CreateFeedbacksMutationResponse';
  feedbacks: Array<Feedback>;
  info: CreateInfo;
};

/** Information about the number of nodes and relationships created during a create mutation */
export type CreateInfo = {
  __typename?: 'CreateInfo';
  /** @deprecated This field has been deprecated because bookmarks are now handled by the driver. */
  bookmark?: Maybe<Scalars['String']['output']>;
  nodesCreated: Scalars['Int']['output'];
  relationshipsCreated: Scalars['Int']['output'];
};

export type CreateNutshellLeadResponse = {
  __typename?: 'CreateNutshellLeadResponse';
  error?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type CreateOwnerContactsMutationResponse = {
  __typename?: 'CreateOwnerContactsMutationResponse';
  info: CreateInfo;
  ownerContacts: Array<OwnerContact>;
};

export type CreateOwnersMutationResponse = {
  __typename?: 'CreateOwnersMutationResponse';
  info: CreateInfo;
  owners: Array<Owner>;
};

export type CreateTagsMutationResponse = {
  __typename?: 'CreateTagsMutationResponse';
  info: CreateInfo;
  tags: Array<Tag>;
};

export type CreateTypeTagsMutationResponse = {
  __typename?: 'CreateTypeTagsMutationResponse';
  info: CreateInfo;
  typeTags: Array<TypeTag>;
};

export type CreateUserMediasMutationResponse = {
  __typename?: 'CreateUserMediasMutationResponse';
  info: CreateInfo;
  userMedias: Array<UserMedia>;
};

export type CreateUsersMutationResponse = {
  __typename?: 'CreateUsersMutationResponse';
  info: CreateInfo;
  users: Array<User>;
};

export type CreateWorksMutationResponse = {
  __typename?: 'CreateWorksMutationResponse';
  info: CreateInfo;
  works: Array<Work>;
};

export type Creator = {
  __typename?: 'Creator';
  /** Creator's Account */
  account?: Maybe<Account>;
  accountAggregate?: Maybe<CreatorAccountAccountAggregationSelection>;
  accountConnection: CreatorAccountConnection;
  /** URL or path to their profile banner or cover photo. */
  bannerImage?: Maybe<Scalars['String']['output']>;
  /** Short biography or description about the creator. */
  bio?: Maybe<Scalars['String']['output']>;
  /** Channels belong to this Creator */
  channels: Array<Channel>;
  channelsAggregate?: Maybe<CreatorChannelChannelsAggregationSelection>;
  channelsConnection: CreatorChannelsConnection;
  /** Contacts */
  contacts: Array<CreatorContact>;
  contactsAggregate?: Maybe<CreatorCreatorContactContactsAggregationSelection>;
  contactsConnection: CreatorContactsConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Unique identifier for the content creator. This will be your platform user name. Length should be 128 characters. */
  creatorID?: Maybe<Scalars['String']['output']>;
  /** Creators date of birth. */
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  /** Creator's Editions */
  editions: Array<Edition>;
  editionsAggregate?: Maybe<CreatorEditionEditionsAggregationSelection>;
  editionsConnection: CreatorEditionsConnection;
  /** Creator's first name. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Creator's first name, lowercase */
  firstNameLC?: Maybe<Scalars['String']['output']>;
  /** Creators gender identity. Male, Female, Non-Binary, Other */
  gender?: Maybe<GenderTypes>;
  /** Creators gender  Other (blank to fill in) */
  genderOther?: Maybe<Scalars['String']['output']>;
  /** System generated GUID */
  id: Scalars['ID']['output'];
  /** Creator's last name. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Creator's last name, lowercase */
  lastNameLC?: Maybe<Scalars['String']['output']>;
  /** Creator's middle name (optional). */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Country of origin or nationality. */
  nationality?: Maybe<Scalars['String']['output']>;
  /** Creator's Nicknames. */
  nicknames?: Maybe<Scalars['String']['output']>;
  /**
   * Nutshell Contact/Person/People ID
   * Required to fetch Contact/Person/People through Nutshell API
   */
  nutshellContactId?: Maybe<Scalars['String']['output']>;
  /**
   * Nutshell Lead ID
   * Required to fetch Lead through Nutshell API
   */
  nutshellLeadId?: Maybe<Scalars['String']['output']>;
  /**
   * Nutshell Lead Number
   * Nutshell Console does not use the above ID, it shows Lead Number only, weird.
   */
  nutshellLeadNumber?: Maybe<Scalars['String']['output']>;
  /** URL or path to their profile picture. */
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** Creators preferred pronouns. */
  pronouns?: Maybe<Scalars['String']['output']>;
  /** Creators Freeform Tags */
  tags: Array<Tag>;
  tagsAggregate?: Maybe<CreatorTagTagsAggregationSelection>;
  tagsConnection: CreatorTagsConnection;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * "
   * Creator's Works
   */
  works: Array<Work>;
  worksAggregate?: Maybe<CreatorWorkWorksAggregationSelection>;
  worksConnection: CreatorWorksConnection;
};


export type CreatorAccountArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<AccountOptions>;
  where?: InputMaybe<AccountWhere>;
};


export type CreatorAccountAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccountWhere>;
};


export type CreatorAccountConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CreatorAccountConnectionSort>>;
  where?: InputMaybe<CreatorAccountConnectionWhere>;
};


export type CreatorChannelsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<ChannelOptions>;
  where?: InputMaybe<ChannelWhere>;
};


export type CreatorChannelsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ChannelWhere>;
};


export type CreatorChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CreatorChannelsConnectionSort>>;
  where?: InputMaybe<CreatorChannelsConnectionWhere>;
};


export type CreatorContactsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CreatorContactOptions>;
  where?: InputMaybe<CreatorContactWhere>;
};


export type CreatorContactsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CreatorContactWhere>;
};


export type CreatorContactsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CreatorContactsConnectionSort>>;
  where?: InputMaybe<CreatorContactsConnectionWhere>;
};


export type CreatorEditionsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<EditionOptions>;
  where?: InputMaybe<EditionWhere>;
};


export type CreatorEditionsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<EditionWhere>;
};


export type CreatorEditionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CreatorEditionsConnectionSort>>;
  where?: InputMaybe<CreatorEditionsConnectionWhere>;
};


export type CreatorTagsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TagOptions>;
  where?: InputMaybe<TagWhere>;
};


export type CreatorTagsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TagWhere>;
};


export type CreatorTagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CreatorTagsConnectionSort>>;
  where?: InputMaybe<CreatorTagsConnectionWhere>;
};


export type CreatorWorksArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<WorkOptions>;
  where?: InputMaybe<WorkWhere>;
};


export type CreatorWorksAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<WorkWhere>;
};


export type CreatorWorksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CreatorWorksConnectionSort>>;
  where?: InputMaybe<CreatorWorksConnectionWhere>;
};

export type CreatorAccountAccountAggregationSelection = {
  __typename?: 'CreatorAccountAccountAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CreatorAccountAccountNodeAggregateSelection>;
};

export type CreatorAccountAccountNodeAggregateSelection = {
  __typename?: 'CreatorAccountAccountNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  joinedDate: StringAggregateSelectionNullable;
  lastActive: StringAggregateSelectionNullable;
  profileURL: StringAggregateSelectionNullable;
  taxId: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  username: StringAggregateSelectionNullable;
};

export type CreatorAccountAggregateInput = {
  AND?: InputMaybe<Array<CreatorAccountAggregateInput>>;
  NOT?: InputMaybe<CreatorAccountAggregateInput>;
  OR?: InputMaybe<Array<CreatorAccountAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CreatorAccountNodeAggregationWhereInput>;
};

export type CreatorAccountConnectFieldInput = {
  connect?: InputMaybe<AccountConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<AccountConnectWhere>;
};

export type CreatorAccountConnectOrCreateFieldInput = {
  onCreate: CreatorAccountConnectOrCreateFieldInputOnCreate;
  where: AccountConnectOrCreateWhere;
};

export type CreatorAccountConnectOrCreateFieldInputOnCreate = {
  node: AccountOnCreateInput;
};

export type CreatorAccountConnection = {
  __typename?: 'CreatorAccountConnection';
  edges: Array<CreatorAccountRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CreatorAccountConnectionSort = {
  node?: InputMaybe<AccountSort>;
};

export type CreatorAccountConnectionWhere = {
  AND?: InputMaybe<Array<CreatorAccountConnectionWhere>>;
  NOT?: InputMaybe<CreatorAccountConnectionWhere>;
  OR?: InputMaybe<Array<CreatorAccountConnectionWhere>>;
  node?: InputMaybe<AccountWhere>;
};

export type CreatorAccountCreateFieldInput = {
  node: AccountCreateInput;
};

export type CreatorAccountDeleteFieldInput = {
  delete?: InputMaybe<AccountDeleteInput>;
  where?: InputMaybe<CreatorAccountConnectionWhere>;
};

export type CreatorAccountDisconnectFieldInput = {
  disconnect?: InputMaybe<AccountDisconnectInput>;
  where?: InputMaybe<CreatorAccountConnectionWhere>;
};

export type CreatorAccountFieldInput = {
  connect?: InputMaybe<CreatorAccountConnectFieldInput>;
  connectOrCreate?: InputMaybe<CreatorAccountConnectOrCreateFieldInput>;
  create?: InputMaybe<CreatorAccountCreateFieldInput>;
};

export type CreatorAccountNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CreatorAccountNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CreatorAccountNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CreatorAccountNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  joinedDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatorAccountRelationship = {
  __typename?: 'CreatorAccountRelationship';
  cursor: Scalars['String']['output'];
  node: Account;
};

export type CreatorAccountUpdateConnectionInput = {
  node?: InputMaybe<AccountUpdateInput>;
};

export type CreatorAccountUpdateFieldInput = {
  connect?: InputMaybe<CreatorAccountConnectFieldInput>;
  connectOrCreate?: InputMaybe<CreatorAccountConnectOrCreateFieldInput>;
  create?: InputMaybe<CreatorAccountCreateFieldInput>;
  delete?: InputMaybe<CreatorAccountDeleteFieldInput>;
  disconnect?: InputMaybe<CreatorAccountDisconnectFieldInput>;
  update?: InputMaybe<CreatorAccountUpdateConnectionInput>;
  where?: InputMaybe<CreatorAccountConnectionWhere>;
};

export type CreatorAggregateSelection = {
  __typename?: 'CreatorAggregateSelection';
  bannerImage: StringAggregateSelectionNullable;
  bio: StringAggregateSelectionNullable;
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  creatorID: StringAggregateSelectionNullable;
  dateOfBirth: StringAggregateSelectionNullable;
  firstName: StringAggregateSelectionNullable;
  firstNameLC: StringAggregateSelectionNullable;
  genderOther: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  lastName: StringAggregateSelectionNullable;
  lastNameLC: StringAggregateSelectionNullable;
  middleName: StringAggregateSelectionNullable;
  nationality: StringAggregateSelectionNullable;
  nicknames: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  pronouns: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CreatorChannelChannelsAggregationSelection = {
  __typename?: 'CreatorChannelChannelsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CreatorChannelChannelsNodeAggregateSelection>;
};

export type CreatorChannelChannelsNodeAggregateSelection = {
  __typename?: 'CreatorChannelChannelsNodeAggregateSelection';
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CreatorChannelsAggregateInput = {
  AND?: InputMaybe<Array<CreatorChannelsAggregateInput>>;
  NOT?: InputMaybe<CreatorChannelsAggregateInput>;
  OR?: InputMaybe<Array<CreatorChannelsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CreatorChannelsNodeAggregationWhereInput>;
};

export type CreatorChannelsConnectFieldInput = {
  connect?: InputMaybe<Array<ChannelConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<ChannelConnectWhere>;
};

export type CreatorChannelsConnectOrCreateFieldInput = {
  onCreate: CreatorChannelsConnectOrCreateFieldInputOnCreate;
  where: ChannelConnectOrCreateWhere;
};

export type CreatorChannelsConnectOrCreateFieldInputOnCreate = {
  node: ChannelOnCreateInput;
};

export type CreatorChannelsConnection = {
  __typename?: 'CreatorChannelsConnection';
  edges: Array<CreatorChannelsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CreatorChannelsConnectionSort = {
  node?: InputMaybe<ChannelSort>;
};

export type CreatorChannelsConnectionWhere = {
  AND?: InputMaybe<Array<CreatorChannelsConnectionWhere>>;
  NOT?: InputMaybe<CreatorChannelsConnectionWhere>;
  OR?: InputMaybe<Array<CreatorChannelsConnectionWhere>>;
  node?: InputMaybe<ChannelWhere>;
};

export type CreatorChannelsCreateFieldInput = {
  node: ChannelCreateInput;
};

export type CreatorChannelsDeleteFieldInput = {
  delete?: InputMaybe<ChannelDeleteInput>;
  where?: InputMaybe<CreatorChannelsConnectionWhere>;
};

export type CreatorChannelsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChannelDisconnectInput>;
  where?: InputMaybe<CreatorChannelsConnectionWhere>;
};

export type CreatorChannelsFieldInput = {
  connect?: InputMaybe<Array<CreatorChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CreatorChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CreatorChannelsCreateFieldInput>>;
};

export type CreatorChannelsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CreatorChannelsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CreatorChannelsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CreatorChannelsNodeAggregationWhereInput>>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreatorChannelsRelationship = {
  __typename?: 'CreatorChannelsRelationship';
  cursor: Scalars['String']['output'];
  node: Channel;
};

export type CreatorChannelsUpdateConnectionInput = {
  node?: InputMaybe<ChannelUpdateInput>;
};

export type CreatorChannelsUpdateFieldInput = {
  connect?: InputMaybe<Array<CreatorChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CreatorChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CreatorChannelsCreateFieldInput>>;
  delete?: InputMaybe<Array<CreatorChannelsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CreatorChannelsDisconnectFieldInput>>;
  update?: InputMaybe<CreatorChannelsUpdateConnectionInput>;
  where?: InputMaybe<CreatorChannelsConnectionWhere>;
};

export type CreatorConnectInput = {
  account?: InputMaybe<CreatorAccountConnectFieldInput>;
  channels?: InputMaybe<Array<CreatorChannelsConnectFieldInput>>;
  contacts?: InputMaybe<Array<CreatorContactsConnectFieldInput>>;
  editions?: InputMaybe<Array<CreatorEditionsConnectFieldInput>>;
  tags?: InputMaybe<Array<CreatorTagsConnectFieldInput>>;
  works?: InputMaybe<Array<CreatorWorksConnectFieldInput>>;
};

export type CreatorConnectOrCreateInput = {
  account?: InputMaybe<CreatorAccountConnectOrCreateFieldInput>;
  channels?: InputMaybe<Array<CreatorChannelsConnectOrCreateFieldInput>>;
  editions?: InputMaybe<Array<CreatorEditionsConnectOrCreateFieldInput>>;
  tags?: InputMaybe<Array<CreatorTagsConnectOrCreateFieldInput>>;
  works?: InputMaybe<Array<CreatorWorksConnectOrCreateFieldInput>>;
};

export type CreatorConnectOrCreateWhere = {
  node: CreatorUniqueWhere;
};

export type CreatorConnectWhere = {
  node: CreatorWhere;
};

export type CreatorContact = {
  __typename?: 'CreatorContact';
  /** Street address. */
  addressLine1?: Maybe<Scalars['String']['output']>;
  /** Additional address details (optional). */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** City of residence. */
  city?: Maybe<Scalars['String']['output']>;
  /** Country of residence. */
  country?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Creator>;
  creatorAggregate?: Maybe<CreatorContactCreatorCreatorAggregationSelection>;
  creatorConnection: CreatorContactCreatorConnection;
  /** Creator's email address. */
  emailAddress?: Maybe<Scalars['String']['output']>;
  /** Creator's contact number. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Postal or ZIP code. */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** State or province of residence. */
  stateProvince?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type CreatorContactCreatorArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CreatorOptions>;
  where?: InputMaybe<CreatorWhere>;
};


export type CreatorContactCreatorAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CreatorWhere>;
};


export type CreatorContactCreatorConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CreatorContactCreatorConnectionSort>>;
  where?: InputMaybe<CreatorContactCreatorConnectionWhere>;
};

export type CreatorContactAggregateSelection = {
  __typename?: 'CreatorContactAggregateSelection';
  addressLine1: StringAggregateSelectionNullable;
  addressLine2: StringAggregateSelectionNullable;
  city: StringAggregateSelectionNullable;
  count: Scalars['Int']['output'];
  country: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  emailAddress: StringAggregateSelectionNullable;
  phoneNumber: StringAggregateSelectionNullable;
  postalCode: StringAggregateSelectionNullable;
  stateProvince: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CreatorContactConnectInput = {
  creator?: InputMaybe<CreatorContactCreatorConnectFieldInput>;
};

export type CreatorContactConnectOrCreateInput = {
  creator?: InputMaybe<CreatorContactCreatorConnectOrCreateFieldInput>;
};

export type CreatorContactConnectWhere = {
  node: CreatorContactWhere;
};

export type CreatorContactCreateInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  creator?: InputMaybe<CreatorContactCreatorFieldInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateProvince?: InputMaybe<Scalars['String']['input']>;
};

export type CreatorContactCreatorAggregateInput = {
  AND?: InputMaybe<Array<CreatorContactCreatorAggregateInput>>;
  NOT?: InputMaybe<CreatorContactCreatorAggregateInput>;
  OR?: InputMaybe<Array<CreatorContactCreatorAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CreatorContactCreatorNodeAggregationWhereInput>;
};

export type CreatorContactCreatorConnectFieldInput = {
  connect?: InputMaybe<CreatorConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CreatorConnectWhere>;
};

export type CreatorContactCreatorConnectOrCreateFieldInput = {
  onCreate: CreatorContactCreatorConnectOrCreateFieldInputOnCreate;
  where: CreatorConnectOrCreateWhere;
};

export type CreatorContactCreatorConnectOrCreateFieldInputOnCreate = {
  node: CreatorOnCreateInput;
};

export type CreatorContactCreatorConnection = {
  __typename?: 'CreatorContactCreatorConnection';
  edges: Array<CreatorContactCreatorRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CreatorContactCreatorConnectionSort = {
  node?: InputMaybe<CreatorSort>;
};

export type CreatorContactCreatorConnectionWhere = {
  AND?: InputMaybe<Array<CreatorContactCreatorConnectionWhere>>;
  NOT?: InputMaybe<CreatorContactCreatorConnectionWhere>;
  OR?: InputMaybe<Array<CreatorContactCreatorConnectionWhere>>;
  node?: InputMaybe<CreatorWhere>;
};

export type CreatorContactCreatorCreateFieldInput = {
  node: CreatorCreateInput;
};

export type CreatorContactCreatorCreatorAggregationSelection = {
  __typename?: 'CreatorContactCreatorCreatorAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CreatorContactCreatorCreatorNodeAggregateSelection>;
};

export type CreatorContactCreatorCreatorNodeAggregateSelection = {
  __typename?: 'CreatorContactCreatorCreatorNodeAggregateSelection';
  bannerImage: StringAggregateSelectionNullable;
  bio: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  creatorID: StringAggregateSelectionNullable;
  dateOfBirth: StringAggregateSelectionNullable;
  firstName: StringAggregateSelectionNullable;
  firstNameLC: StringAggregateSelectionNullable;
  genderOther: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  lastName: StringAggregateSelectionNullable;
  lastNameLC: StringAggregateSelectionNullable;
  middleName: StringAggregateSelectionNullable;
  nationality: StringAggregateSelectionNullable;
  nicknames: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  pronouns: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CreatorContactCreatorDeleteFieldInput = {
  delete?: InputMaybe<CreatorDeleteInput>;
  where?: InputMaybe<CreatorContactCreatorConnectionWhere>;
};

export type CreatorContactCreatorDisconnectFieldInput = {
  disconnect?: InputMaybe<CreatorDisconnectInput>;
  where?: InputMaybe<CreatorContactCreatorConnectionWhere>;
};

export type CreatorContactCreatorFieldInput = {
  connect?: InputMaybe<CreatorContactCreatorConnectFieldInput>;
  connectOrCreate?: InputMaybe<CreatorContactCreatorConnectOrCreateFieldInput>;
  create?: InputMaybe<CreatorContactCreatorCreateFieldInput>;
};

export type CreatorContactCreatorNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CreatorContactCreatorNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CreatorContactCreatorNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CreatorContactCreatorNodeAggregationWhereInput>>;
  bannerImage_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bio_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  bio_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creatorID_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  creatorID_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  firstName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  genderOther_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  middleName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nationality_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nicknames_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  pronouns_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreatorContactCreatorRelationship = {
  __typename?: 'CreatorContactCreatorRelationship';
  cursor: Scalars['String']['output'];
  node: Creator;
};

export type CreatorContactCreatorUpdateConnectionInput = {
  node?: InputMaybe<CreatorUpdateInput>;
};

export type CreatorContactCreatorUpdateFieldInput = {
  connect?: InputMaybe<CreatorContactCreatorConnectFieldInput>;
  connectOrCreate?: InputMaybe<CreatorContactCreatorConnectOrCreateFieldInput>;
  create?: InputMaybe<CreatorContactCreatorCreateFieldInput>;
  delete?: InputMaybe<CreatorContactCreatorDeleteFieldInput>;
  disconnect?: InputMaybe<CreatorContactCreatorDisconnectFieldInput>;
  update?: InputMaybe<CreatorContactCreatorUpdateConnectionInput>;
  where?: InputMaybe<CreatorContactCreatorConnectionWhere>;
};

export type CreatorContactDeleteInput = {
  creator?: InputMaybe<CreatorContactCreatorDeleteFieldInput>;
};

export type CreatorContactDisconnectInput = {
  creator?: InputMaybe<CreatorContactCreatorDisconnectFieldInput>;
};

export type CreatorContactEdge = {
  __typename?: 'CreatorContactEdge';
  cursor: Scalars['String']['output'];
  node: CreatorContact;
};

export type CreatorContactOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more CreatorContactSort objects to sort CreatorContacts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CreatorContactSort>>;
};

export type CreatorContactRelationInput = {
  creator?: InputMaybe<CreatorContactCreatorCreateFieldInput>;
};

/** Fields to sort CreatorContacts by. The order in which sorts are applied is not guaranteed when specifying many fields in one CreatorContactSort object. */
export type CreatorContactSort = {
  addressLine1?: InputMaybe<SortDirection>;
  addressLine2?: InputMaybe<SortDirection>;
  city?: InputMaybe<SortDirection>;
  country?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  emailAddress?: InputMaybe<SortDirection>;
  phoneNumber?: InputMaybe<SortDirection>;
  postalCode?: InputMaybe<SortDirection>;
  stateProvince?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CreatorContactUpdateInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator?: InputMaybe<CreatorContactCreatorUpdateFieldInput>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateProvince?: InputMaybe<Scalars['String']['input']>;
};

export type CreatorContactWhere = {
  AND?: InputMaybe<Array<CreatorContactWhere>>;
  NOT?: InputMaybe<CreatorContactWhere>;
  OR?: InputMaybe<Array<CreatorContactWhere>>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine1_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  addressLine1_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  addressLine1_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  addressLine1_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine2_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  addressLine2_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  addressLine2_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  addressLine2_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  city_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  city_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  city_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  country_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  country_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creator?: InputMaybe<CreatorWhere>;
  creatorAggregate?: InputMaybe<CreatorContactCreatorAggregateInput>;
  creatorConnection?: InputMaybe<CreatorContactCreatorConnectionWhere>;
  creatorConnection_NOT?: InputMaybe<CreatorContactCreatorConnectionWhere>;
  creator_NOT?: InputMaybe<CreatorWhere>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailAddress_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  emailAddress_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  emailAddress_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  emailAddress_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  postalCode_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  postalCode_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  postalCode_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  stateProvince?: InputMaybe<Scalars['String']['input']>;
  stateProvince_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  stateProvince_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  stateProvince_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stateProvince_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreatorContactsAggregateInput = {
  AND?: InputMaybe<Array<CreatorContactsAggregateInput>>;
  NOT?: InputMaybe<CreatorContactsAggregateInput>;
  OR?: InputMaybe<Array<CreatorContactsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CreatorContactsNodeAggregationWhereInput>;
};

export type CreatorContactsConnectFieldInput = {
  connect?: InputMaybe<Array<CreatorContactConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CreatorContactConnectWhere>;
};

export type CreatorContactsConnection = {
  __typename?: 'CreatorContactsConnection';
  edges: Array<CreatorContactsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CreatorContactsConnectionSort = {
  node?: InputMaybe<CreatorContactSort>;
};

export type CreatorContactsConnectionWhere = {
  AND?: InputMaybe<Array<CreatorContactsConnectionWhere>>;
  NOT?: InputMaybe<CreatorContactsConnectionWhere>;
  OR?: InputMaybe<Array<CreatorContactsConnectionWhere>>;
  node?: InputMaybe<CreatorContactWhere>;
};

export type CreatorContactsCreateFieldInput = {
  node: CreatorContactCreateInput;
};

export type CreatorContactsDeleteFieldInput = {
  delete?: InputMaybe<CreatorContactDeleteInput>;
  where?: InputMaybe<CreatorContactsConnectionWhere>;
};

export type CreatorContactsDisconnectFieldInput = {
  disconnect?: InputMaybe<CreatorContactDisconnectInput>;
  where?: InputMaybe<CreatorContactsConnectionWhere>;
};

export type CreatorContactsFieldInput = {
  connect?: InputMaybe<Array<CreatorContactsConnectFieldInput>>;
  create?: InputMaybe<Array<CreatorContactsCreateFieldInput>>;
};

export type CreatorContactsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CreatorContactsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CreatorContactsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CreatorContactsNodeAggregationWhereInput>>;
  addressLine1_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  addressLine1_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  addressLine1_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  addressLine1_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  addressLine1_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  addressLine1_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  addressLine2_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  addressLine2_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  addressLine2_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  addressLine2_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  addressLine2_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  city_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  city_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  city_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  city_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  city_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  city_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  city_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  city_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  city_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  city_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  city_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  city_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  city_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  city_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  city_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  country_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  country_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  country_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  country_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  country_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  country_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  country_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  country_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  country_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  country_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  country_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  country_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  country_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  country_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  country_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  emailAddress_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  postalCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  postalCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  postalCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  postalCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  postalCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  postalCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  postalCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  postalCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  postalCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  postalCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  postalCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  postalCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  postalCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  postalCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  postalCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  stateProvince_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  stateProvince_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  stateProvince_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  stateProvince_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  stateProvince_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreatorContactsRelationship = {
  __typename?: 'CreatorContactsRelationship';
  cursor: Scalars['String']['output'];
  node: CreatorContact;
};

export type CreatorContactsUpdateConnectionInput = {
  node?: InputMaybe<CreatorContactUpdateInput>;
};

export type CreatorContactsUpdateFieldInput = {
  connect?: InputMaybe<Array<CreatorContactsConnectFieldInput>>;
  create?: InputMaybe<Array<CreatorContactsCreateFieldInput>>;
  delete?: InputMaybe<Array<CreatorContactsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CreatorContactsDisconnectFieldInput>>;
  update?: InputMaybe<CreatorContactsUpdateConnectionInput>;
  where?: InputMaybe<CreatorContactsConnectionWhere>;
};

export type CreatorCreateInput = {
  account?: InputMaybe<CreatorAccountFieldInput>;
  bannerImage?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  channels?: InputMaybe<CreatorChannelsFieldInput>;
  contacts?: InputMaybe<CreatorContactsFieldInput>;
  creatorID?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  editions?: InputMaybe<CreatorEditionsFieldInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameLC?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GenderTypes>;
  genderOther?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameLC?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nicknames?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<CreatorTagsFieldInput>;
  works?: InputMaybe<CreatorWorksFieldInput>;
};

export type CreatorCreatorContactContactsAggregationSelection = {
  __typename?: 'CreatorCreatorContactContactsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CreatorCreatorContactContactsNodeAggregateSelection>;
};

export type CreatorCreatorContactContactsNodeAggregateSelection = {
  __typename?: 'CreatorCreatorContactContactsNodeAggregateSelection';
  addressLine1: StringAggregateSelectionNullable;
  addressLine2: StringAggregateSelectionNullable;
  city: StringAggregateSelectionNullable;
  country: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  emailAddress: StringAggregateSelectionNullable;
  phoneNumber: StringAggregateSelectionNullable;
  postalCode: StringAggregateSelectionNullable;
  stateProvince: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CreatorDeleteInput = {
  account?: InputMaybe<CreatorAccountDeleteFieldInput>;
  channels?: InputMaybe<Array<CreatorChannelsDeleteFieldInput>>;
  contacts?: InputMaybe<Array<CreatorContactsDeleteFieldInput>>;
  editions?: InputMaybe<Array<CreatorEditionsDeleteFieldInput>>;
  tags?: InputMaybe<Array<CreatorTagsDeleteFieldInput>>;
  works?: InputMaybe<Array<CreatorWorksDeleteFieldInput>>;
};

export type CreatorDisconnectInput = {
  account?: InputMaybe<CreatorAccountDisconnectFieldInput>;
  channels?: InputMaybe<Array<CreatorChannelsDisconnectFieldInput>>;
  contacts?: InputMaybe<Array<CreatorContactsDisconnectFieldInput>>;
  editions?: InputMaybe<Array<CreatorEditionsDisconnectFieldInput>>;
  tags?: InputMaybe<Array<CreatorTagsDisconnectFieldInput>>;
  works?: InputMaybe<Array<CreatorWorksDisconnectFieldInput>>;
};

export type CreatorEdge = {
  __typename?: 'CreatorEdge';
  cursor: Scalars['String']['output'];
  node: Creator;
};

export type CreatorEditionEditionsAggregationSelection = {
  __typename?: 'CreatorEditionEditionsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CreatorEditionEditionsNodeAggregateSelection>;
};

export type CreatorEditionEditionsNodeAggregateSelection = {
  __typename?: 'CreatorEditionEditionsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type CreatorEditionsAggregateInput = {
  AND?: InputMaybe<Array<CreatorEditionsAggregateInput>>;
  NOT?: InputMaybe<CreatorEditionsAggregateInput>;
  OR?: InputMaybe<Array<CreatorEditionsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CreatorEditionsNodeAggregationWhereInput>;
};

export type CreatorEditionsConnectFieldInput = {
  connect?: InputMaybe<Array<EditionConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<EditionConnectWhere>;
};

export type CreatorEditionsConnectOrCreateFieldInput = {
  onCreate: CreatorEditionsConnectOrCreateFieldInputOnCreate;
  where: EditionConnectOrCreateWhere;
};

export type CreatorEditionsConnectOrCreateFieldInputOnCreate = {
  node: EditionOnCreateInput;
};

export type CreatorEditionsConnection = {
  __typename?: 'CreatorEditionsConnection';
  edges: Array<CreatorEditionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CreatorEditionsConnectionSort = {
  node?: InputMaybe<EditionSort>;
};

export type CreatorEditionsConnectionWhere = {
  AND?: InputMaybe<Array<CreatorEditionsConnectionWhere>>;
  NOT?: InputMaybe<CreatorEditionsConnectionWhere>;
  OR?: InputMaybe<Array<CreatorEditionsConnectionWhere>>;
  node?: InputMaybe<EditionWhere>;
};

export type CreatorEditionsCreateFieldInput = {
  node: EditionCreateInput;
};

export type CreatorEditionsDeleteFieldInput = {
  delete?: InputMaybe<EditionDeleteInput>;
  where?: InputMaybe<CreatorEditionsConnectionWhere>;
};

export type CreatorEditionsDisconnectFieldInput = {
  disconnect?: InputMaybe<EditionDisconnectInput>;
  where?: InputMaybe<CreatorEditionsConnectionWhere>;
};

export type CreatorEditionsFieldInput = {
  connect?: InputMaybe<Array<CreatorEditionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CreatorEditionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CreatorEditionsCreateFieldInput>>;
};

export type CreatorEditionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CreatorEditionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CreatorEditionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CreatorEditionsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatorEditionsRelationship = {
  __typename?: 'CreatorEditionsRelationship';
  cursor: Scalars['String']['output'];
  node: Edition;
};

export type CreatorEditionsUpdateConnectionInput = {
  node?: InputMaybe<EditionUpdateInput>;
};

export type CreatorEditionsUpdateFieldInput = {
  connect?: InputMaybe<Array<CreatorEditionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CreatorEditionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CreatorEditionsCreateFieldInput>>;
  delete?: InputMaybe<Array<CreatorEditionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CreatorEditionsDisconnectFieldInput>>;
  update?: InputMaybe<CreatorEditionsUpdateConnectionInput>;
  where?: InputMaybe<CreatorEditionsConnectionWhere>;
};

export type CreatorOnCreateInput = {
  bannerImage?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  creatorID?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameLC?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GenderTypes>;
  genderOther?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameLC?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nicknames?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
};

export type CreatorOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more CreatorSort objects to sort Creators by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CreatorSort>>;
};

export type CreatorRelationInput = {
  account?: InputMaybe<CreatorAccountCreateFieldInput>;
  channels?: InputMaybe<Array<CreatorChannelsCreateFieldInput>>;
  contacts?: InputMaybe<Array<CreatorContactsCreateFieldInput>>;
  editions?: InputMaybe<Array<CreatorEditionsCreateFieldInput>>;
  tags?: InputMaybe<Array<CreatorTagsCreateFieldInput>>;
  works?: InputMaybe<Array<CreatorWorksCreateFieldInput>>;
};

/** Fields to sort Creators by. The order in which sorts are applied is not guaranteed when specifying many fields in one CreatorSort object. */
export type CreatorSort = {
  bannerImage?: InputMaybe<SortDirection>;
  bio?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  creatorID?: InputMaybe<SortDirection>;
  dateOfBirth?: InputMaybe<SortDirection>;
  firstName?: InputMaybe<SortDirection>;
  firstNameLC?: InputMaybe<SortDirection>;
  gender?: InputMaybe<SortDirection>;
  genderOther?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastName?: InputMaybe<SortDirection>;
  lastNameLC?: InputMaybe<SortDirection>;
  middleName?: InputMaybe<SortDirection>;
  nationality?: InputMaybe<SortDirection>;
  nicknames?: InputMaybe<SortDirection>;
  nutshellContactId?: InputMaybe<SortDirection>;
  nutshellLeadId?: InputMaybe<SortDirection>;
  nutshellLeadNumber?: InputMaybe<SortDirection>;
  profilePicture?: InputMaybe<SortDirection>;
  pronouns?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CreatorTagTagsAggregationSelection = {
  __typename?: 'CreatorTagTagsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CreatorTagTagsNodeAggregateSelection>;
};

export type CreatorTagTagsNodeAggregateSelection = {
  __typename?: 'CreatorTagTagsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type CreatorTagsAggregateInput = {
  AND?: InputMaybe<Array<CreatorTagsAggregateInput>>;
  NOT?: InputMaybe<CreatorTagsAggregateInput>;
  OR?: InputMaybe<Array<CreatorTagsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CreatorTagsNodeAggregationWhereInput>;
};

export type CreatorTagsConnectFieldInput = {
  connect?: InputMaybe<Array<TagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<TagConnectWhere>;
};

export type CreatorTagsConnectOrCreateFieldInput = {
  onCreate: CreatorTagsConnectOrCreateFieldInputOnCreate;
  where: TagConnectOrCreateWhere;
};

export type CreatorTagsConnectOrCreateFieldInputOnCreate = {
  node: TagOnCreateInput;
};

export type CreatorTagsConnection = {
  __typename?: 'CreatorTagsConnection';
  edges: Array<CreatorTagsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CreatorTagsConnectionSort = {
  node?: InputMaybe<TagSort>;
};

export type CreatorTagsConnectionWhere = {
  AND?: InputMaybe<Array<CreatorTagsConnectionWhere>>;
  NOT?: InputMaybe<CreatorTagsConnectionWhere>;
  OR?: InputMaybe<Array<CreatorTagsConnectionWhere>>;
  node?: InputMaybe<TagWhere>;
};

export type CreatorTagsCreateFieldInput = {
  node: TagCreateInput;
};

export type CreatorTagsDeleteFieldInput = {
  delete?: InputMaybe<TagDeleteInput>;
  where?: InputMaybe<CreatorTagsConnectionWhere>;
};

export type CreatorTagsDisconnectFieldInput = {
  disconnect?: InputMaybe<TagDisconnectInput>;
  where?: InputMaybe<CreatorTagsConnectionWhere>;
};

export type CreatorTagsFieldInput = {
  connect?: InputMaybe<Array<CreatorTagsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CreatorTagsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CreatorTagsCreateFieldInput>>;
};

export type CreatorTagsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CreatorTagsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CreatorTagsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CreatorTagsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreatorTagsRelationship = {
  __typename?: 'CreatorTagsRelationship';
  cursor: Scalars['String']['output'];
  node: Tag;
};

export type CreatorTagsUpdateConnectionInput = {
  node?: InputMaybe<TagUpdateInput>;
};

export type CreatorTagsUpdateFieldInput = {
  connect?: InputMaybe<Array<CreatorTagsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CreatorTagsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CreatorTagsCreateFieldInput>>;
  delete?: InputMaybe<Array<CreatorTagsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CreatorTagsDisconnectFieldInput>>;
  update?: InputMaybe<CreatorTagsUpdateConnectionInput>;
  where?: InputMaybe<CreatorTagsConnectionWhere>;
};

export type CreatorUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreatorUpdateInput = {
  account?: InputMaybe<CreatorAccountUpdateFieldInput>;
  bannerImage?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  channels?: InputMaybe<Array<CreatorChannelsUpdateFieldInput>>;
  contacts?: InputMaybe<Array<CreatorContactsUpdateFieldInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creatorID?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  editions?: InputMaybe<Array<CreatorEditionsUpdateFieldInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameLC?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GenderTypes>;
  genderOther?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameLC?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nicknames?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<CreatorTagsUpdateFieldInput>>;
  works?: InputMaybe<Array<CreatorWorksUpdateFieldInput>>;
};

export type CreatorWhere = {
  AND?: InputMaybe<Array<CreatorWhere>>;
  NOT?: InputMaybe<CreatorWhere>;
  OR?: InputMaybe<Array<CreatorWhere>>;
  account?: InputMaybe<AccountWhere>;
  accountAggregate?: InputMaybe<CreatorAccountAggregateInput>;
  accountConnection?: InputMaybe<CreatorAccountConnectionWhere>;
  accountConnection_NOT?: InputMaybe<CreatorAccountConnectionWhere>;
  account_NOT?: InputMaybe<AccountWhere>;
  bannerImage?: InputMaybe<Scalars['String']['input']>;
  bannerImage_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  bannerImage_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  bannerImage_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bannerImage_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bio_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  bio_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  bio_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bio_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  channelsAggregate?: InputMaybe<CreatorChannelsAggregateInput>;
  /** Return Creators where all of the related CreatorChannelsConnections match this filter */
  channelsConnection_ALL?: InputMaybe<CreatorChannelsConnectionWhere>;
  /** Return Creators where none of the related CreatorChannelsConnections match this filter */
  channelsConnection_NONE?: InputMaybe<CreatorChannelsConnectionWhere>;
  /** Return Creators where one of the related CreatorChannelsConnections match this filter */
  channelsConnection_SINGLE?: InputMaybe<CreatorChannelsConnectionWhere>;
  /** Return Creators where some of the related CreatorChannelsConnections match this filter */
  channelsConnection_SOME?: InputMaybe<CreatorChannelsConnectionWhere>;
  /** Return Creators where all of the related Channels match this filter */
  channels_ALL?: InputMaybe<ChannelWhere>;
  /** Return Creators where none of the related Channels match this filter */
  channels_NONE?: InputMaybe<ChannelWhere>;
  /** Return Creators where one of the related Channels match this filter */
  channels_SINGLE?: InputMaybe<ChannelWhere>;
  /** Return Creators where some of the related Channels match this filter */
  channels_SOME?: InputMaybe<ChannelWhere>;
  contactsAggregate?: InputMaybe<CreatorContactsAggregateInput>;
  /** Return Creators where all of the related CreatorContactsConnections match this filter */
  contactsConnection_ALL?: InputMaybe<CreatorContactsConnectionWhere>;
  /** Return Creators where none of the related CreatorContactsConnections match this filter */
  contactsConnection_NONE?: InputMaybe<CreatorContactsConnectionWhere>;
  /** Return Creators where one of the related CreatorContactsConnections match this filter */
  contactsConnection_SINGLE?: InputMaybe<CreatorContactsConnectionWhere>;
  /** Return Creators where some of the related CreatorContactsConnections match this filter */
  contactsConnection_SOME?: InputMaybe<CreatorContactsConnectionWhere>;
  /** Return Creators where all of the related CreatorContacts match this filter */
  contacts_ALL?: InputMaybe<CreatorContactWhere>;
  /** Return Creators where none of the related CreatorContacts match this filter */
  contacts_NONE?: InputMaybe<CreatorContactWhere>;
  /** Return Creators where one of the related CreatorContacts match this filter */
  contacts_SINGLE?: InputMaybe<CreatorContactWhere>;
  /** Return Creators where some of the related CreatorContacts match this filter */
  contacts_SOME?: InputMaybe<CreatorContactWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creatorID?: InputMaybe<Scalars['String']['input']>;
  creatorID_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  creatorID_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  creatorID_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  creatorID_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateOfBirth_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  editionsAggregate?: InputMaybe<CreatorEditionsAggregateInput>;
  /** Return Creators where all of the related CreatorEditionsConnections match this filter */
  editionsConnection_ALL?: InputMaybe<CreatorEditionsConnectionWhere>;
  /** Return Creators where none of the related CreatorEditionsConnections match this filter */
  editionsConnection_NONE?: InputMaybe<CreatorEditionsConnectionWhere>;
  /** Return Creators where one of the related CreatorEditionsConnections match this filter */
  editionsConnection_SINGLE?: InputMaybe<CreatorEditionsConnectionWhere>;
  /** Return Creators where some of the related CreatorEditionsConnections match this filter */
  editionsConnection_SOME?: InputMaybe<CreatorEditionsConnectionWhere>;
  /** Return Creators where all of the related Editions match this filter */
  editions_ALL?: InputMaybe<EditionWhere>;
  /** Return Creators where none of the related Editions match this filter */
  editions_NONE?: InputMaybe<EditionWhere>;
  /** Return Creators where one of the related Editions match this filter */
  editions_SINGLE?: InputMaybe<EditionWhere>;
  /** Return Creators where some of the related Editions match this filter */
  editions_SOME?: InputMaybe<EditionWhere>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameLC?: InputMaybe<Scalars['String']['input']>;
  firstNameLC_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  firstNameLC_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  firstNameLC_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstNameLC_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  firstName_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  firstName_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  firstName_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GenderTypes>;
  genderOther?: InputMaybe<Scalars['String']['input']>;
  genderOther_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  genderOther_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  genderOther_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  genderOther_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  gender_IN?: InputMaybe<Array<InputMaybe<GenderTypes>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameLC?: InputMaybe<Scalars['String']['input']>;
  lastNameLC_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  lastNameLC_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  lastNameLC_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastNameLC_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  lastName_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  lastName_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  lastName_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastName_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  middleName_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  middleName_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  middleName_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  middleName_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nationality_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nationality_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nationality_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nationality_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  nicknames?: InputMaybe<Scalars['String']['input']>;
  nicknames_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nicknames_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nicknames_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nicknames_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nutshellContactId_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nutshellLeadId_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nutshellLeadNumber_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  profilePicture_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  profilePicture_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  profilePicture_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  profilePicture_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  pronouns_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  pronouns_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  pronouns_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pronouns_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  tagsAggregate?: InputMaybe<CreatorTagsAggregateInput>;
  /** Return Creators where all of the related CreatorTagsConnections match this filter */
  tagsConnection_ALL?: InputMaybe<CreatorTagsConnectionWhere>;
  /** Return Creators where none of the related CreatorTagsConnections match this filter */
  tagsConnection_NONE?: InputMaybe<CreatorTagsConnectionWhere>;
  /** Return Creators where one of the related CreatorTagsConnections match this filter */
  tagsConnection_SINGLE?: InputMaybe<CreatorTagsConnectionWhere>;
  /** Return Creators where some of the related CreatorTagsConnections match this filter */
  tagsConnection_SOME?: InputMaybe<CreatorTagsConnectionWhere>;
  /** Return Creators where all of the related Tags match this filter */
  tags_ALL?: InputMaybe<TagWhere>;
  /** Return Creators where none of the related Tags match this filter */
  tags_NONE?: InputMaybe<TagWhere>;
  /** Return Creators where one of the related Tags match this filter */
  tags_SINGLE?: InputMaybe<TagWhere>;
  /** Return Creators where some of the related Tags match this filter */
  tags_SOME?: InputMaybe<TagWhere>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  worksAggregate?: InputMaybe<CreatorWorksAggregateInput>;
  /** Return Creators where all of the related CreatorWorksConnections match this filter */
  worksConnection_ALL?: InputMaybe<CreatorWorksConnectionWhere>;
  /** Return Creators where none of the related CreatorWorksConnections match this filter */
  worksConnection_NONE?: InputMaybe<CreatorWorksConnectionWhere>;
  /** Return Creators where one of the related CreatorWorksConnections match this filter */
  worksConnection_SINGLE?: InputMaybe<CreatorWorksConnectionWhere>;
  /** Return Creators where some of the related CreatorWorksConnections match this filter */
  worksConnection_SOME?: InputMaybe<CreatorWorksConnectionWhere>;
  /** Return Creators where all of the related Works match this filter */
  works_ALL?: InputMaybe<WorkWhere>;
  /** Return Creators where none of the related Works match this filter */
  works_NONE?: InputMaybe<WorkWhere>;
  /** Return Creators where one of the related Works match this filter */
  works_SINGLE?: InputMaybe<WorkWhere>;
  /** Return Creators where some of the related Works match this filter */
  works_SOME?: InputMaybe<WorkWhere>;
};

export type CreatorWorkWorksAggregationSelection = {
  __typename?: 'CreatorWorkWorksAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<CreatorWorkWorksNodeAggregateSelection>;
};

export type CreatorWorkWorksNodeAggregateSelection = {
  __typename?: 'CreatorWorkWorksNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  workUrl: StringAggregateSelectionNullable;
};

export type CreatorWorksAggregateInput = {
  AND?: InputMaybe<Array<CreatorWorksAggregateInput>>;
  NOT?: InputMaybe<CreatorWorksAggregateInput>;
  OR?: InputMaybe<Array<CreatorWorksAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<CreatorWorksNodeAggregationWhereInput>;
};

export type CreatorWorksConnectFieldInput = {
  connect?: InputMaybe<Array<WorkConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<WorkConnectWhere>;
};

export type CreatorWorksConnectOrCreateFieldInput = {
  onCreate: CreatorWorksConnectOrCreateFieldInputOnCreate;
  where: WorkConnectOrCreateWhere;
};

export type CreatorWorksConnectOrCreateFieldInputOnCreate = {
  node: WorkOnCreateInput;
};

export type CreatorWorksConnection = {
  __typename?: 'CreatorWorksConnection';
  edges: Array<CreatorWorksRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CreatorWorksConnectionSort = {
  node?: InputMaybe<WorkSort>;
};

export type CreatorWorksConnectionWhere = {
  AND?: InputMaybe<Array<CreatorWorksConnectionWhere>>;
  NOT?: InputMaybe<CreatorWorksConnectionWhere>;
  OR?: InputMaybe<Array<CreatorWorksConnectionWhere>>;
  node?: InputMaybe<WorkWhere>;
};

export type CreatorWorksCreateFieldInput = {
  node: WorkCreateInput;
};

export type CreatorWorksDeleteFieldInput = {
  delete?: InputMaybe<WorkDeleteInput>;
  where?: InputMaybe<CreatorWorksConnectionWhere>;
};

export type CreatorWorksDisconnectFieldInput = {
  disconnect?: InputMaybe<WorkDisconnectInput>;
  where?: InputMaybe<CreatorWorksConnectionWhere>;
};

export type CreatorWorksFieldInput = {
  connect?: InputMaybe<Array<CreatorWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CreatorWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CreatorWorksCreateFieldInput>>;
};

export type CreatorWorksNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CreatorWorksNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CreatorWorksNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CreatorWorksNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  workUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatorWorksRelationship = {
  __typename?: 'CreatorWorksRelationship';
  cursor: Scalars['String']['output'];
  node: Work;
};

export type CreatorWorksUpdateConnectionInput = {
  node?: InputMaybe<WorkUpdateInput>;
};

export type CreatorWorksUpdateFieldInput = {
  connect?: InputMaybe<Array<CreatorWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CreatorWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CreatorWorksCreateFieldInput>>;
  delete?: InputMaybe<Array<CreatorWorksDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CreatorWorksDisconnectFieldInput>>;
  update?: InputMaybe<CreatorWorksUpdateConnectionInput>;
  where?: InputMaybe<CreatorWorksConnectionWhere>;
};

export type CreatorsConnection = {
  __typename?: 'CreatorsConnection';
  edges: Array<CreatorEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DateTimeAggregateSelectionNullable = {
  __typename?: 'DateTimeAggregateSelectionNullable';
  max?: Maybe<Scalars['DateTime']['output']>;
  min?: Maybe<Scalars['DateTime']['output']>;
};

/** Information about the number of nodes and relationships deleted during a delete mutation */
export type DeleteInfo = {
  __typename?: 'DeleteInfo';
  /** @deprecated This field has been deprecated because bookmarks are now handled by the driver. */
  bookmark?: Maybe<Scalars['String']['output']>;
  nodesDeleted: Scalars['Int']['output'];
  relationshipsDeleted: Scalars['Int']['output'];
};

/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type Edition = {
  __typename?: 'Edition';
  /** Edition's Connected Accounts */
  accounts: Array<Account>;
  accountsAggregate?: Maybe<EditionAccountAccountsAggregationSelection>;
  accountsConnection: EditionAccountsConnection;
  /** Works Content Categories (e.g., gaming, lifestyle, tech). Multi-select options, including an Other choices. */
  categories: Array<CategoryTag>;
  categoriesAggregate?: Maybe<EditionCategoryTagCategoriesAggregationSelection>;
  categoriesConnection: EditionCategoriesConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Creators of the Edition (NOT-NULL) */
  creators: Array<Creator>;
  creatorsAggregate?: Maybe<EditionCreatorCreatorsAggregationSelection>;
  creatorsConnection: EditionCreatorsConnection;
  /** Edition Content Types */
  editionTypes: Array<TypeTag>;
  editionTypesAggregate?: Maybe<EditionTypeTagEditionTypesAggregationSelection>;
  editionTypesConnection: EditionEditionTypesConnection;
  /** System generated GUID */
  id: Scalars['ID']['output'];
  /** Edition Name */
  name?: Maybe<Scalars['String']['output']>;
  /** Rights Owner of the edition (NOT-NULL) */
  owners: Array<Owner>;
  ownersAggregate?: Maybe<EditionOwnerOwnersAggregationSelection>;
  ownersConnection: EditionOwnersConnection;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Edition URL */
  url?: Maybe<Scalars['String']['output']>;
  /** The Work this Edition belongs to */
  work: Work;
  workAggregate?: Maybe<EditionWorkWorkAggregationSelection>;
  workConnection: EditionWorkConnection;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionAccountsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<AccountOptions>;
  where?: InputMaybe<AccountWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionAccountsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccountWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionAccountsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EditionAccountsConnectionSort>>;
  where?: InputMaybe<EditionAccountsConnectionWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionCategoriesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CategoryTagOptions>;
  where?: InputMaybe<CategoryTagWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionCategoriesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CategoryTagWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EditionCategoriesConnectionSort>>;
  where?: InputMaybe<EditionCategoriesConnectionWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionCreatorsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CreatorOptions>;
  where?: InputMaybe<CreatorWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionCreatorsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CreatorWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EditionCreatorsConnectionSort>>;
  where?: InputMaybe<EditionCreatorsConnectionWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionEditionTypesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TypeTagOptions>;
  where?: InputMaybe<TypeTagWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionEditionTypesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TypeTagWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionEditionTypesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EditionEditionTypesConnectionSort>>;
  where?: InputMaybe<EditionEditionTypesConnectionWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionOwnersArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<OwnerOptions>;
  where?: InputMaybe<OwnerWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionOwnersAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<OwnerWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionOwnersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EditionOwnersConnectionSort>>;
  where?: InputMaybe<EditionOwnersConnectionWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionWorkArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<WorkOptions>;
  where?: InputMaybe<WorkWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionWorkAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<WorkWhere>;
};


/** Editions: Each Edition of a Work can have a Rights Owner. Rights owner is assumed Creator if not specified. */
export type EditionWorkConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EditionWorkConnectionSort>>;
  where?: InputMaybe<EditionWorkConnectionWhere>;
};

export type EditionAccountAccountsAggregationSelection = {
  __typename?: 'EditionAccountAccountsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<EditionAccountAccountsNodeAggregateSelection>;
};

export type EditionAccountAccountsNodeAggregateSelection = {
  __typename?: 'EditionAccountAccountsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  joinedDate: StringAggregateSelectionNullable;
  lastActive: StringAggregateSelectionNullable;
  profileURL: StringAggregateSelectionNullable;
  taxId: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  username: StringAggregateSelectionNullable;
};

export type EditionAccountsAggregateInput = {
  AND?: InputMaybe<Array<EditionAccountsAggregateInput>>;
  NOT?: InputMaybe<EditionAccountsAggregateInput>;
  OR?: InputMaybe<Array<EditionAccountsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<EditionAccountsNodeAggregationWhereInput>;
};

export type EditionAccountsConnectFieldInput = {
  connect?: InputMaybe<Array<AccountConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<AccountConnectWhere>;
};

export type EditionAccountsConnectOrCreateFieldInput = {
  onCreate: EditionAccountsConnectOrCreateFieldInputOnCreate;
  where: AccountConnectOrCreateWhere;
};

export type EditionAccountsConnectOrCreateFieldInputOnCreate = {
  node: AccountOnCreateInput;
};

export type EditionAccountsConnection = {
  __typename?: 'EditionAccountsConnection';
  edges: Array<EditionAccountsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type EditionAccountsConnectionSort = {
  node?: InputMaybe<AccountSort>;
};

export type EditionAccountsConnectionWhere = {
  AND?: InputMaybe<Array<EditionAccountsConnectionWhere>>;
  NOT?: InputMaybe<EditionAccountsConnectionWhere>;
  OR?: InputMaybe<Array<EditionAccountsConnectionWhere>>;
  node?: InputMaybe<AccountWhere>;
};

export type EditionAccountsCreateFieldInput = {
  node: AccountCreateInput;
};

export type EditionAccountsDeleteFieldInput = {
  delete?: InputMaybe<AccountDeleteInput>;
  where?: InputMaybe<EditionAccountsConnectionWhere>;
};

export type EditionAccountsDisconnectFieldInput = {
  disconnect?: InputMaybe<AccountDisconnectInput>;
  where?: InputMaybe<EditionAccountsConnectionWhere>;
};

export type EditionAccountsFieldInput = {
  connect?: InputMaybe<Array<EditionAccountsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<EditionAccountsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<EditionAccountsCreateFieldInput>>;
};

export type EditionAccountsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<EditionAccountsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<EditionAccountsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<EditionAccountsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  joinedDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type EditionAccountsRelationship = {
  __typename?: 'EditionAccountsRelationship';
  cursor: Scalars['String']['output'];
  node: Account;
};

export type EditionAccountsUpdateConnectionInput = {
  node?: InputMaybe<AccountUpdateInput>;
};

export type EditionAccountsUpdateFieldInput = {
  connect?: InputMaybe<Array<EditionAccountsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<EditionAccountsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<EditionAccountsCreateFieldInput>>;
  delete?: InputMaybe<Array<EditionAccountsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<EditionAccountsDisconnectFieldInput>>;
  update?: InputMaybe<EditionAccountsUpdateConnectionInput>;
  where?: InputMaybe<EditionAccountsConnectionWhere>;
};

export type EditionAggregateSelection = {
  __typename?: 'EditionAggregateSelection';
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type EditionCategoriesAggregateInput = {
  AND?: InputMaybe<Array<EditionCategoriesAggregateInput>>;
  NOT?: InputMaybe<EditionCategoriesAggregateInput>;
  OR?: InputMaybe<Array<EditionCategoriesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<EditionCategoriesNodeAggregationWhereInput>;
};

export type EditionCategoriesConnectFieldInput = {
  connect?: InputMaybe<Array<CategoryTagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CategoryTagConnectWhere>;
};

export type EditionCategoriesConnectOrCreateFieldInput = {
  onCreate: EditionCategoriesConnectOrCreateFieldInputOnCreate;
  where: CategoryTagConnectOrCreateWhere;
};

export type EditionCategoriesConnectOrCreateFieldInputOnCreate = {
  node: CategoryTagOnCreateInput;
};

export type EditionCategoriesConnection = {
  __typename?: 'EditionCategoriesConnection';
  edges: Array<EditionCategoriesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type EditionCategoriesConnectionSort = {
  node?: InputMaybe<CategoryTagSort>;
};

export type EditionCategoriesConnectionWhere = {
  AND?: InputMaybe<Array<EditionCategoriesConnectionWhere>>;
  NOT?: InputMaybe<EditionCategoriesConnectionWhere>;
  OR?: InputMaybe<Array<EditionCategoriesConnectionWhere>>;
  node?: InputMaybe<CategoryTagWhere>;
};

export type EditionCategoriesCreateFieldInput = {
  node: CategoryTagCreateInput;
};

export type EditionCategoriesDeleteFieldInput = {
  delete?: InputMaybe<CategoryTagDeleteInput>;
  where?: InputMaybe<EditionCategoriesConnectionWhere>;
};

export type EditionCategoriesDisconnectFieldInput = {
  disconnect?: InputMaybe<CategoryTagDisconnectInput>;
  where?: InputMaybe<EditionCategoriesConnectionWhere>;
};

export type EditionCategoriesFieldInput = {
  connect?: InputMaybe<Array<EditionCategoriesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<EditionCategoriesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<EditionCategoriesCreateFieldInput>>;
};

export type EditionCategoriesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<EditionCategoriesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<EditionCategoriesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<EditionCategoriesNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  icon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  icon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  tier_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EditionCategoriesRelationship = {
  __typename?: 'EditionCategoriesRelationship';
  cursor: Scalars['String']['output'];
  node: CategoryTag;
};

export type EditionCategoriesUpdateConnectionInput = {
  node?: InputMaybe<CategoryTagUpdateInput>;
};

export type EditionCategoriesUpdateFieldInput = {
  connect?: InputMaybe<Array<EditionCategoriesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<EditionCategoriesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<EditionCategoriesCreateFieldInput>>;
  delete?: InputMaybe<Array<EditionCategoriesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<EditionCategoriesDisconnectFieldInput>>;
  update?: InputMaybe<EditionCategoriesUpdateConnectionInput>;
  where?: InputMaybe<EditionCategoriesConnectionWhere>;
};

export type EditionCategoryTagCategoriesAggregationSelection = {
  __typename?: 'EditionCategoryTagCategoriesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<EditionCategoryTagCategoriesNodeAggregateSelection>;
};

export type EditionCategoryTagCategoriesNodeAggregateSelection = {
  __typename?: 'EditionCategoryTagCategoriesNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  icon: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type EditionConnectInput = {
  accounts?: InputMaybe<Array<EditionAccountsConnectFieldInput>>;
  categories?: InputMaybe<Array<EditionCategoriesConnectFieldInput>>;
  creators?: InputMaybe<Array<EditionCreatorsConnectFieldInput>>;
  editionTypes?: InputMaybe<Array<EditionEditionTypesConnectFieldInput>>;
  owners?: InputMaybe<Array<EditionOwnersConnectFieldInput>>;
  work?: InputMaybe<EditionWorkConnectFieldInput>;
};

export type EditionConnectOrCreateInput = {
  accounts?: InputMaybe<Array<EditionAccountsConnectOrCreateFieldInput>>;
  categories?: InputMaybe<Array<EditionCategoriesConnectOrCreateFieldInput>>;
  creators?: InputMaybe<Array<EditionCreatorsConnectOrCreateFieldInput>>;
  editionTypes?: InputMaybe<Array<EditionEditionTypesConnectOrCreateFieldInput>>;
  owners?: InputMaybe<Array<EditionOwnersConnectOrCreateFieldInput>>;
  work?: InputMaybe<EditionWorkConnectOrCreateFieldInput>;
};

export type EditionConnectOrCreateWhere = {
  node: EditionUniqueWhere;
};

export type EditionConnectWhere = {
  node: EditionWhere;
};

export type EditionCreateInput = {
  accounts?: InputMaybe<EditionAccountsFieldInput>;
  categories?: InputMaybe<EditionCategoriesFieldInput>;
  creators?: InputMaybe<EditionCreatorsFieldInput>;
  editionTypes?: InputMaybe<EditionEditionTypesFieldInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  owners?: InputMaybe<EditionOwnersFieldInput>;
  url?: InputMaybe<Scalars['String']['input']>;
  work?: InputMaybe<EditionWorkFieldInput>;
};

export type EditionCreatorCreatorsAggregationSelection = {
  __typename?: 'EditionCreatorCreatorsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<EditionCreatorCreatorsNodeAggregateSelection>;
};

export type EditionCreatorCreatorsNodeAggregateSelection = {
  __typename?: 'EditionCreatorCreatorsNodeAggregateSelection';
  bannerImage: StringAggregateSelectionNullable;
  bio: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  creatorID: StringAggregateSelectionNullable;
  dateOfBirth: StringAggregateSelectionNullable;
  firstName: StringAggregateSelectionNullable;
  firstNameLC: StringAggregateSelectionNullable;
  genderOther: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  lastName: StringAggregateSelectionNullable;
  lastNameLC: StringAggregateSelectionNullable;
  middleName: StringAggregateSelectionNullable;
  nationality: StringAggregateSelectionNullable;
  nicknames: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  pronouns: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type EditionCreatorsAggregateInput = {
  AND?: InputMaybe<Array<EditionCreatorsAggregateInput>>;
  NOT?: InputMaybe<EditionCreatorsAggregateInput>;
  OR?: InputMaybe<Array<EditionCreatorsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<EditionCreatorsNodeAggregationWhereInput>;
};

export type EditionCreatorsConnectFieldInput = {
  connect?: InputMaybe<Array<CreatorConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CreatorConnectWhere>;
};

export type EditionCreatorsConnectOrCreateFieldInput = {
  onCreate: EditionCreatorsConnectOrCreateFieldInputOnCreate;
  where: CreatorConnectOrCreateWhere;
};

export type EditionCreatorsConnectOrCreateFieldInputOnCreate = {
  node: CreatorOnCreateInput;
};

export type EditionCreatorsConnection = {
  __typename?: 'EditionCreatorsConnection';
  edges: Array<EditionCreatorsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type EditionCreatorsConnectionSort = {
  node?: InputMaybe<CreatorSort>;
};

export type EditionCreatorsConnectionWhere = {
  AND?: InputMaybe<Array<EditionCreatorsConnectionWhere>>;
  NOT?: InputMaybe<EditionCreatorsConnectionWhere>;
  OR?: InputMaybe<Array<EditionCreatorsConnectionWhere>>;
  node?: InputMaybe<CreatorWhere>;
};

export type EditionCreatorsCreateFieldInput = {
  node: CreatorCreateInput;
};

export type EditionCreatorsDeleteFieldInput = {
  delete?: InputMaybe<CreatorDeleteInput>;
  where?: InputMaybe<EditionCreatorsConnectionWhere>;
};

export type EditionCreatorsDisconnectFieldInput = {
  disconnect?: InputMaybe<CreatorDisconnectInput>;
  where?: InputMaybe<EditionCreatorsConnectionWhere>;
};

export type EditionCreatorsFieldInput = {
  connect?: InputMaybe<Array<EditionCreatorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<EditionCreatorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<EditionCreatorsCreateFieldInput>>;
};

export type EditionCreatorsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<EditionCreatorsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<EditionCreatorsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<EditionCreatorsNodeAggregationWhereInput>>;
  bannerImage_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bio_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  bio_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creatorID_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  creatorID_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  firstName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  genderOther_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  middleName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nationality_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nicknames_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  pronouns_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EditionCreatorsRelationship = {
  __typename?: 'EditionCreatorsRelationship';
  cursor: Scalars['String']['output'];
  node: Creator;
};

export type EditionCreatorsUpdateConnectionInput = {
  node?: InputMaybe<CreatorUpdateInput>;
};

export type EditionCreatorsUpdateFieldInput = {
  connect?: InputMaybe<Array<EditionCreatorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<EditionCreatorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<EditionCreatorsCreateFieldInput>>;
  delete?: InputMaybe<Array<EditionCreatorsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<EditionCreatorsDisconnectFieldInput>>;
  update?: InputMaybe<EditionCreatorsUpdateConnectionInput>;
  where?: InputMaybe<EditionCreatorsConnectionWhere>;
};

export type EditionDeleteInput = {
  accounts?: InputMaybe<Array<EditionAccountsDeleteFieldInput>>;
  categories?: InputMaybe<Array<EditionCategoriesDeleteFieldInput>>;
  creators?: InputMaybe<Array<EditionCreatorsDeleteFieldInput>>;
  editionTypes?: InputMaybe<Array<EditionEditionTypesDeleteFieldInput>>;
  owners?: InputMaybe<Array<EditionOwnersDeleteFieldInput>>;
  work?: InputMaybe<EditionWorkDeleteFieldInput>;
};

export type EditionDisconnectInput = {
  accounts?: InputMaybe<Array<EditionAccountsDisconnectFieldInput>>;
  categories?: InputMaybe<Array<EditionCategoriesDisconnectFieldInput>>;
  creators?: InputMaybe<Array<EditionCreatorsDisconnectFieldInput>>;
  editionTypes?: InputMaybe<Array<EditionEditionTypesDisconnectFieldInput>>;
  owners?: InputMaybe<Array<EditionOwnersDisconnectFieldInput>>;
  work?: InputMaybe<EditionWorkDisconnectFieldInput>;
};

export type EditionEdge = {
  __typename?: 'EditionEdge';
  cursor: Scalars['String']['output'];
  node: Edition;
};

export type EditionEditionTypesAggregateInput = {
  AND?: InputMaybe<Array<EditionEditionTypesAggregateInput>>;
  NOT?: InputMaybe<EditionEditionTypesAggregateInput>;
  OR?: InputMaybe<Array<EditionEditionTypesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<EditionEditionTypesNodeAggregationWhereInput>;
};

export type EditionEditionTypesConnectFieldInput = {
  connect?: InputMaybe<Array<TypeTagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<TypeTagConnectWhere>;
};

export type EditionEditionTypesConnectOrCreateFieldInput = {
  onCreate: EditionEditionTypesConnectOrCreateFieldInputOnCreate;
  where: TypeTagConnectOrCreateWhere;
};

export type EditionEditionTypesConnectOrCreateFieldInputOnCreate = {
  node: TypeTagOnCreateInput;
};

export type EditionEditionTypesConnection = {
  __typename?: 'EditionEditionTypesConnection';
  edges: Array<EditionEditionTypesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type EditionEditionTypesConnectionSort = {
  node?: InputMaybe<TypeTagSort>;
};

export type EditionEditionTypesConnectionWhere = {
  AND?: InputMaybe<Array<EditionEditionTypesConnectionWhere>>;
  NOT?: InputMaybe<EditionEditionTypesConnectionWhere>;
  OR?: InputMaybe<Array<EditionEditionTypesConnectionWhere>>;
  node?: InputMaybe<TypeTagWhere>;
};

export type EditionEditionTypesCreateFieldInput = {
  node: TypeTagCreateInput;
};

export type EditionEditionTypesDeleteFieldInput = {
  delete?: InputMaybe<TypeTagDeleteInput>;
  where?: InputMaybe<EditionEditionTypesConnectionWhere>;
};

export type EditionEditionTypesDisconnectFieldInput = {
  disconnect?: InputMaybe<TypeTagDisconnectInput>;
  where?: InputMaybe<EditionEditionTypesConnectionWhere>;
};

export type EditionEditionTypesFieldInput = {
  connect?: InputMaybe<Array<EditionEditionTypesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<EditionEditionTypesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<EditionEditionTypesCreateFieldInput>>;
};

export type EditionEditionTypesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<EditionEditionTypesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<EditionEditionTypesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<EditionEditionTypesNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  tier_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EditionEditionTypesRelationship = {
  __typename?: 'EditionEditionTypesRelationship';
  cursor: Scalars['String']['output'];
  node: TypeTag;
};

export type EditionEditionTypesUpdateConnectionInput = {
  node?: InputMaybe<TypeTagUpdateInput>;
};

export type EditionEditionTypesUpdateFieldInput = {
  connect?: InputMaybe<Array<EditionEditionTypesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<EditionEditionTypesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<EditionEditionTypesCreateFieldInput>>;
  delete?: InputMaybe<Array<EditionEditionTypesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<EditionEditionTypesDisconnectFieldInput>>;
  update?: InputMaybe<EditionEditionTypesUpdateConnectionInput>;
  where?: InputMaybe<EditionEditionTypesConnectionWhere>;
};

export type EditionOnCreateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type EditionOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more EditionSort objects to sort Editions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<EditionSort>>;
};

export type EditionOwnerOwnersAggregationSelection = {
  __typename?: 'EditionOwnerOwnersAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<EditionOwnerOwnersNodeAggregateSelection>;
};

export type EditionOwnerOwnersNodeAggregateSelection = {
  __typename?: 'EditionOwnerOwnersNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  nameLC: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type EditionOwnersAggregateInput = {
  AND?: InputMaybe<Array<EditionOwnersAggregateInput>>;
  NOT?: InputMaybe<EditionOwnersAggregateInput>;
  OR?: InputMaybe<Array<EditionOwnersAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<EditionOwnersNodeAggregationWhereInput>;
};

export type EditionOwnersConnectFieldInput = {
  connect?: InputMaybe<Array<OwnerConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<OwnerConnectWhere>;
};

export type EditionOwnersConnectOrCreateFieldInput = {
  onCreate: EditionOwnersConnectOrCreateFieldInputOnCreate;
  where: OwnerConnectOrCreateWhere;
};

export type EditionOwnersConnectOrCreateFieldInputOnCreate = {
  node: OwnerOnCreateInput;
};

export type EditionOwnersConnection = {
  __typename?: 'EditionOwnersConnection';
  edges: Array<EditionOwnersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type EditionOwnersConnectionSort = {
  node?: InputMaybe<OwnerSort>;
};

export type EditionOwnersConnectionWhere = {
  AND?: InputMaybe<Array<EditionOwnersConnectionWhere>>;
  NOT?: InputMaybe<EditionOwnersConnectionWhere>;
  OR?: InputMaybe<Array<EditionOwnersConnectionWhere>>;
  node?: InputMaybe<OwnerWhere>;
};

export type EditionOwnersCreateFieldInput = {
  node: OwnerCreateInput;
};

export type EditionOwnersDeleteFieldInput = {
  delete?: InputMaybe<OwnerDeleteInput>;
  where?: InputMaybe<EditionOwnersConnectionWhere>;
};

export type EditionOwnersDisconnectFieldInput = {
  disconnect?: InputMaybe<OwnerDisconnectInput>;
  where?: InputMaybe<EditionOwnersConnectionWhere>;
};

export type EditionOwnersFieldInput = {
  connect?: InputMaybe<Array<EditionOwnersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<EditionOwnersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<EditionOwnersCreateFieldInput>>;
};

export type EditionOwnersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<EditionOwnersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<EditionOwnersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<EditionOwnersNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  nameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type EditionOwnersRelationship = {
  __typename?: 'EditionOwnersRelationship';
  cursor: Scalars['String']['output'];
  node: Owner;
};

export type EditionOwnersUpdateConnectionInput = {
  node?: InputMaybe<OwnerUpdateInput>;
};

export type EditionOwnersUpdateFieldInput = {
  connect?: InputMaybe<Array<EditionOwnersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<EditionOwnersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<EditionOwnersCreateFieldInput>>;
  delete?: InputMaybe<Array<EditionOwnersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<EditionOwnersDisconnectFieldInput>>;
  update?: InputMaybe<EditionOwnersUpdateConnectionInput>;
  where?: InputMaybe<EditionOwnersConnectionWhere>;
};

export type EditionRelationInput = {
  accounts?: InputMaybe<Array<EditionAccountsCreateFieldInput>>;
  categories?: InputMaybe<Array<EditionCategoriesCreateFieldInput>>;
  creators?: InputMaybe<Array<EditionCreatorsCreateFieldInput>>;
  editionTypes?: InputMaybe<Array<EditionEditionTypesCreateFieldInput>>;
  owners?: InputMaybe<Array<EditionOwnersCreateFieldInput>>;
  work?: InputMaybe<EditionWorkCreateFieldInput>;
};

/** Fields to sort Editions by. The order in which sorts are applied is not guaranteed when specifying many fields in one EditionSort object. */
export type EditionSort = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type EditionTypeTagEditionTypesAggregationSelection = {
  __typename?: 'EditionTypeTagEditionTypesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<EditionTypeTagEditionTypesNodeAggregateSelection>;
};

export type EditionTypeTagEditionTypesNodeAggregateSelection = {
  __typename?: 'EditionTypeTagEditionTypesNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type EditionUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type EditionUpdateInput = {
  accounts?: InputMaybe<Array<EditionAccountsUpdateFieldInput>>;
  categories?: InputMaybe<Array<EditionCategoriesUpdateFieldInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creators?: InputMaybe<Array<EditionCreatorsUpdateFieldInput>>;
  editionTypes?: InputMaybe<Array<EditionEditionTypesUpdateFieldInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  owners?: InputMaybe<Array<EditionOwnersUpdateFieldInput>>;
  url?: InputMaybe<Scalars['String']['input']>;
  work?: InputMaybe<EditionWorkUpdateFieldInput>;
};

export type EditionWhere = {
  AND?: InputMaybe<Array<EditionWhere>>;
  NOT?: InputMaybe<EditionWhere>;
  OR?: InputMaybe<Array<EditionWhere>>;
  accountsAggregate?: InputMaybe<EditionAccountsAggregateInput>;
  /** Return Editions where all of the related EditionAccountsConnections match this filter */
  accountsConnection_ALL?: InputMaybe<EditionAccountsConnectionWhere>;
  /** Return Editions where none of the related EditionAccountsConnections match this filter */
  accountsConnection_NONE?: InputMaybe<EditionAccountsConnectionWhere>;
  /** Return Editions where one of the related EditionAccountsConnections match this filter */
  accountsConnection_SINGLE?: InputMaybe<EditionAccountsConnectionWhere>;
  /** Return Editions where some of the related EditionAccountsConnections match this filter */
  accountsConnection_SOME?: InputMaybe<EditionAccountsConnectionWhere>;
  /** Return Editions where all of the related Accounts match this filter */
  accounts_ALL?: InputMaybe<AccountWhere>;
  /** Return Editions where none of the related Accounts match this filter */
  accounts_NONE?: InputMaybe<AccountWhere>;
  /** Return Editions where one of the related Accounts match this filter */
  accounts_SINGLE?: InputMaybe<AccountWhere>;
  /** Return Editions where some of the related Accounts match this filter */
  accounts_SOME?: InputMaybe<AccountWhere>;
  categoriesAggregate?: InputMaybe<EditionCategoriesAggregateInput>;
  /** Return Editions where all of the related EditionCategoriesConnections match this filter */
  categoriesConnection_ALL?: InputMaybe<EditionCategoriesConnectionWhere>;
  /** Return Editions where none of the related EditionCategoriesConnections match this filter */
  categoriesConnection_NONE?: InputMaybe<EditionCategoriesConnectionWhere>;
  /** Return Editions where one of the related EditionCategoriesConnections match this filter */
  categoriesConnection_SINGLE?: InputMaybe<EditionCategoriesConnectionWhere>;
  /** Return Editions where some of the related EditionCategoriesConnections match this filter */
  categoriesConnection_SOME?: InputMaybe<EditionCategoriesConnectionWhere>;
  /** Return Editions where all of the related CategoryTags match this filter */
  categories_ALL?: InputMaybe<CategoryTagWhere>;
  /** Return Editions where none of the related CategoryTags match this filter */
  categories_NONE?: InputMaybe<CategoryTagWhere>;
  /** Return Editions where one of the related CategoryTags match this filter */
  categories_SINGLE?: InputMaybe<CategoryTagWhere>;
  /** Return Editions where some of the related CategoryTags match this filter */
  categories_SOME?: InputMaybe<CategoryTagWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creatorsAggregate?: InputMaybe<EditionCreatorsAggregateInput>;
  /** Return Editions where all of the related EditionCreatorsConnections match this filter */
  creatorsConnection_ALL?: InputMaybe<EditionCreatorsConnectionWhere>;
  /** Return Editions where none of the related EditionCreatorsConnections match this filter */
  creatorsConnection_NONE?: InputMaybe<EditionCreatorsConnectionWhere>;
  /** Return Editions where one of the related EditionCreatorsConnections match this filter */
  creatorsConnection_SINGLE?: InputMaybe<EditionCreatorsConnectionWhere>;
  /** Return Editions where some of the related EditionCreatorsConnections match this filter */
  creatorsConnection_SOME?: InputMaybe<EditionCreatorsConnectionWhere>;
  /** Return Editions where all of the related Creators match this filter */
  creators_ALL?: InputMaybe<CreatorWhere>;
  /** Return Editions where none of the related Creators match this filter */
  creators_NONE?: InputMaybe<CreatorWhere>;
  /** Return Editions where one of the related Creators match this filter */
  creators_SINGLE?: InputMaybe<CreatorWhere>;
  /** Return Editions where some of the related Creators match this filter */
  creators_SOME?: InputMaybe<CreatorWhere>;
  editionTypesAggregate?: InputMaybe<EditionEditionTypesAggregateInput>;
  /** Return Editions where all of the related EditionEditionTypesConnections match this filter */
  editionTypesConnection_ALL?: InputMaybe<EditionEditionTypesConnectionWhere>;
  /** Return Editions where none of the related EditionEditionTypesConnections match this filter */
  editionTypesConnection_NONE?: InputMaybe<EditionEditionTypesConnectionWhere>;
  /** Return Editions where one of the related EditionEditionTypesConnections match this filter */
  editionTypesConnection_SINGLE?: InputMaybe<EditionEditionTypesConnectionWhere>;
  /** Return Editions where some of the related EditionEditionTypesConnections match this filter */
  editionTypesConnection_SOME?: InputMaybe<EditionEditionTypesConnectionWhere>;
  /** Return Editions where all of the related TypeTags match this filter */
  editionTypes_ALL?: InputMaybe<TypeTagWhere>;
  /** Return Editions where none of the related TypeTags match this filter */
  editionTypes_NONE?: InputMaybe<TypeTagWhere>;
  /** Return Editions where one of the related TypeTags match this filter */
  editionTypes_SINGLE?: InputMaybe<TypeTagWhere>;
  /** Return Editions where some of the related TypeTags match this filter */
  editionTypes_SOME?: InputMaybe<TypeTagWhere>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  name_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  ownersAggregate?: InputMaybe<EditionOwnersAggregateInput>;
  /** Return Editions where all of the related EditionOwnersConnections match this filter */
  ownersConnection_ALL?: InputMaybe<EditionOwnersConnectionWhere>;
  /** Return Editions where none of the related EditionOwnersConnections match this filter */
  ownersConnection_NONE?: InputMaybe<EditionOwnersConnectionWhere>;
  /** Return Editions where one of the related EditionOwnersConnections match this filter */
  ownersConnection_SINGLE?: InputMaybe<EditionOwnersConnectionWhere>;
  /** Return Editions where some of the related EditionOwnersConnections match this filter */
  ownersConnection_SOME?: InputMaybe<EditionOwnersConnectionWhere>;
  /** Return Editions where all of the related Owners match this filter */
  owners_ALL?: InputMaybe<OwnerWhere>;
  /** Return Editions where none of the related Owners match this filter */
  owners_NONE?: InputMaybe<OwnerWhere>;
  /** Return Editions where one of the related Owners match this filter */
  owners_SINGLE?: InputMaybe<OwnerWhere>;
  /** Return Editions where some of the related Owners match this filter */
  owners_SOME?: InputMaybe<OwnerWhere>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  url_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  work?: InputMaybe<WorkWhere>;
  workAggregate?: InputMaybe<EditionWorkAggregateInput>;
  workConnection?: InputMaybe<EditionWorkConnectionWhere>;
  workConnection_NOT?: InputMaybe<EditionWorkConnectionWhere>;
  work_NOT?: InputMaybe<WorkWhere>;
};

export type EditionWorkAggregateInput = {
  AND?: InputMaybe<Array<EditionWorkAggregateInput>>;
  NOT?: InputMaybe<EditionWorkAggregateInput>;
  OR?: InputMaybe<Array<EditionWorkAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<EditionWorkNodeAggregationWhereInput>;
};

export type EditionWorkConnectFieldInput = {
  connect?: InputMaybe<WorkConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<WorkConnectWhere>;
};

export type EditionWorkConnectOrCreateFieldInput = {
  onCreate: EditionWorkConnectOrCreateFieldInputOnCreate;
  where: WorkConnectOrCreateWhere;
};

export type EditionWorkConnectOrCreateFieldInputOnCreate = {
  node: WorkOnCreateInput;
};

export type EditionWorkConnection = {
  __typename?: 'EditionWorkConnection';
  edges: Array<EditionWorkRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type EditionWorkConnectionSort = {
  node?: InputMaybe<WorkSort>;
};

export type EditionWorkConnectionWhere = {
  AND?: InputMaybe<Array<EditionWorkConnectionWhere>>;
  NOT?: InputMaybe<EditionWorkConnectionWhere>;
  OR?: InputMaybe<Array<EditionWorkConnectionWhere>>;
  node?: InputMaybe<WorkWhere>;
};

export type EditionWorkCreateFieldInput = {
  node: WorkCreateInput;
};

export type EditionWorkDeleteFieldInput = {
  delete?: InputMaybe<WorkDeleteInput>;
  where?: InputMaybe<EditionWorkConnectionWhere>;
};

export type EditionWorkDisconnectFieldInput = {
  disconnect?: InputMaybe<WorkDisconnectInput>;
  where?: InputMaybe<EditionWorkConnectionWhere>;
};

export type EditionWorkFieldInput = {
  connect?: InputMaybe<EditionWorkConnectFieldInput>;
  connectOrCreate?: InputMaybe<EditionWorkConnectOrCreateFieldInput>;
  create?: InputMaybe<EditionWorkCreateFieldInput>;
};

export type EditionWorkNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<EditionWorkNodeAggregationWhereInput>>;
  NOT?: InputMaybe<EditionWorkNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<EditionWorkNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  workUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type EditionWorkRelationship = {
  __typename?: 'EditionWorkRelationship';
  cursor: Scalars['String']['output'];
  node: Work;
};

export type EditionWorkUpdateConnectionInput = {
  node?: InputMaybe<WorkUpdateInput>;
};

export type EditionWorkUpdateFieldInput = {
  connect?: InputMaybe<EditionWorkConnectFieldInput>;
  connectOrCreate?: InputMaybe<EditionWorkConnectOrCreateFieldInput>;
  create?: InputMaybe<EditionWorkCreateFieldInput>;
  delete?: InputMaybe<EditionWorkDeleteFieldInput>;
  disconnect?: InputMaybe<EditionWorkDisconnectFieldInput>;
  update?: InputMaybe<EditionWorkUpdateConnectionInput>;
  where?: InputMaybe<EditionWorkConnectionWhere>;
};

export type EditionWorkWorkAggregationSelection = {
  __typename?: 'EditionWorkWorkAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<EditionWorkWorkNodeAggregateSelection>;
};

export type EditionWorkWorkNodeAggregateSelection = {
  __typename?: 'EditionWorkWorkNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  workUrl: StringAggregateSelectionNullable;
};

export type EditionsConnection = {
  __typename?: 'EditionsConnection';
  edges: Array<EditionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** User Feedbacks */
export type Feedback = {
  __typename?: 'Feedback';
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Created by */
  createdBy: User;
  createdByAggregate?: Maybe<FeedbackUserCreatedByAggregationSelection>;
  createdByConnection: FeedbackCreatedByConnection;
  /** Freefrom feedback text */
  feedback?: Maybe<Scalars['String']['output']>;
  /** System generated GUID */
  id: Scalars['ID']['output'];
  /** Reaction */
  reaction?: Maybe<ReactionType>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


/** User Feedbacks */
export type FeedbackCreatedByArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};


/** User Feedbacks */
export type FeedbackCreatedByAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserWhere>;
};


/** User Feedbacks */
export type FeedbackCreatedByConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<FeedbackCreatedByConnectionSort>>;
  where?: InputMaybe<FeedbackCreatedByConnectionWhere>;
};

export type FeedbackAggregateSelection = {
  __typename?: 'FeedbackAggregateSelection';
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  feedback: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type FeedbackConnectInput = {
  createdBy?: InputMaybe<FeedbackCreatedByConnectFieldInput>;
};

export type FeedbackConnectOrCreateInput = {
  createdBy?: InputMaybe<FeedbackCreatedByConnectOrCreateFieldInput>;
};

export type FeedbackCreateInput = {
  createdBy?: InputMaybe<FeedbackCreatedByFieldInput>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  reaction?: InputMaybe<ReactionType>;
};

export type FeedbackCreatedByAggregateInput = {
  AND?: InputMaybe<Array<FeedbackCreatedByAggregateInput>>;
  NOT?: InputMaybe<FeedbackCreatedByAggregateInput>;
  OR?: InputMaybe<Array<FeedbackCreatedByAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<FeedbackCreatedByNodeAggregationWhereInput>;
};

export type FeedbackCreatedByConnectFieldInput = {
  connect?: InputMaybe<UserConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserConnectWhere>;
};

export type FeedbackCreatedByConnectOrCreateFieldInput = {
  onCreate: FeedbackCreatedByConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type FeedbackCreatedByConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type FeedbackCreatedByConnection = {
  __typename?: 'FeedbackCreatedByConnection';
  edges: Array<FeedbackCreatedByRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FeedbackCreatedByConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type FeedbackCreatedByConnectionWhere = {
  AND?: InputMaybe<Array<FeedbackCreatedByConnectionWhere>>;
  NOT?: InputMaybe<FeedbackCreatedByConnectionWhere>;
  OR?: InputMaybe<Array<FeedbackCreatedByConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type FeedbackCreatedByCreateFieldInput = {
  node: UserCreateInput;
};

export type FeedbackCreatedByDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<FeedbackCreatedByConnectionWhere>;
};

export type FeedbackCreatedByDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<FeedbackCreatedByConnectionWhere>;
};

export type FeedbackCreatedByFieldInput = {
  connect?: InputMaybe<FeedbackCreatedByConnectFieldInput>;
  connectOrCreate?: InputMaybe<FeedbackCreatedByConnectOrCreateFieldInput>;
  create?: InputMaybe<FeedbackCreatedByCreateFieldInput>;
};

export type FeedbackCreatedByNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<FeedbackCreatedByNodeAggregationWhereInput>>;
  NOT?: InputMaybe<FeedbackCreatedByNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<FeedbackCreatedByNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nickname_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  picture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  picture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FeedbackCreatedByRelationship = {
  __typename?: 'FeedbackCreatedByRelationship';
  cursor: Scalars['String']['output'];
  node: User;
};

export type FeedbackCreatedByUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type FeedbackCreatedByUpdateFieldInput = {
  connect?: InputMaybe<FeedbackCreatedByConnectFieldInput>;
  connectOrCreate?: InputMaybe<FeedbackCreatedByConnectOrCreateFieldInput>;
  create?: InputMaybe<FeedbackCreatedByCreateFieldInput>;
  delete?: InputMaybe<FeedbackCreatedByDeleteFieldInput>;
  disconnect?: InputMaybe<FeedbackCreatedByDisconnectFieldInput>;
  update?: InputMaybe<FeedbackCreatedByUpdateConnectionInput>;
  where?: InputMaybe<FeedbackCreatedByConnectionWhere>;
};

export type FeedbackDeleteInput = {
  createdBy?: InputMaybe<FeedbackCreatedByDeleteFieldInput>;
};

export type FeedbackDisconnectInput = {
  createdBy?: InputMaybe<FeedbackCreatedByDisconnectFieldInput>;
};

export type FeedbackEdge = {
  __typename?: 'FeedbackEdge';
  cursor: Scalars['String']['output'];
  node: Feedback;
};

export type FeedbackOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more FeedbackSort objects to sort Feedbacks by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FeedbackSort>>;
};

export type FeedbackRelationInput = {
  createdBy?: InputMaybe<FeedbackCreatedByCreateFieldInput>;
};

/** Fields to sort Feedbacks by. The order in which sorts are applied is not guaranteed when specifying many fields in one FeedbackSort object. */
export type FeedbackSort = {
  createdAt?: InputMaybe<SortDirection>;
  feedback?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  reaction?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type FeedbackUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<FeedbackCreatedByUpdateFieldInput>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  reaction?: InputMaybe<ReactionType>;
};

export type FeedbackUserCreatedByAggregationSelection = {
  __typename?: 'FeedbackUserCreatedByAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<FeedbackUserCreatedByNodeAggregateSelection>;
};

export type FeedbackUserCreatedByNodeAggregateSelection = {
  __typename?: 'FeedbackUserCreatedByNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  email: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  nickname: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  picture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  userId: IdAggregateSelectionNonNullable;
};

export type FeedbackWhere = {
  AND?: InputMaybe<Array<FeedbackWhere>>;
  NOT?: InputMaybe<FeedbackWhere>;
  OR?: InputMaybe<Array<FeedbackWhere>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserWhere>;
  createdByAggregate?: InputMaybe<FeedbackCreatedByAggregateInput>;
  createdByConnection?: InputMaybe<FeedbackCreatedByConnectionWhere>;
  createdByConnection_NOT?: InputMaybe<FeedbackCreatedByConnectionWhere>;
  createdBy_NOT?: InputMaybe<UserWhere>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  feedback_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  feedback_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  feedback_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feedback_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  reaction?: InputMaybe<ReactionType>;
  reaction_IN?: InputMaybe<Array<InputMaybe<ReactionType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FeedbacksConnection = {
  __typename?: 'FeedbacksConnection';
  edges: Array<FeedbackEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FloatAggregateSelectionNullable = {
  __typename?: 'FloatAggregateSelectionNullable';
  average?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  sum?: Maybe<Scalars['Float']['output']>;
};

export enum GenderTypes {
  Female = 'FEMALE',
  Male = 'MALE',
  Nonbinary = 'NONBINARY',
  Other = 'OTHER'
}

export enum GenerativeAiTypes {
  NotUsed = 'NOT_USED',
  UsedInContentExecution = 'USED_IN_CONTENT_EXECUTION',
  UsedInContentPlanning = 'USED_IN_CONTENT_PLANNING',
  UsedInEditing = 'USED_IN_EDITING',
  UsedInPromotion = 'USED_IN_PROMOTION',
  UsedInResearch = 'USED_IN_RESEARCH'
}

export type IdAggregateSelectionNonNullable = {
  __typename?: 'IDAggregateSelectionNonNullable';
  longest: Scalars['ID']['output'];
  shortest: Scalars['ID']['output'];
};

export type IntAggregateSelectionNullable = {
  __typename?: 'IntAggregateSelectionNullable';
  average?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  sum?: Maybe<Scalars['Int']['output']>;
};

export enum MachineLearningToolsTypes {
  NotUsed = 'NOT_USED',
  UsedInContentOptimization = 'USED_IN_CONTENT_OPTIMIZATION',
  UsedInDataAnalysis = 'USED_IN_DATA_ANALYSIS'
}

export enum MediaSourceType {
  OpenGraph = 'OPEN_GRAPH',
  Screenshot = 'SCREENSHOT',
  UserUpload = 'USER_UPLOAD'
}

export type Mutation = {
  __typename?: 'Mutation';
  createAccountSubscriptions: CreateAccountSubscriptionsMutationResponse;
  createAccounts: CreateAccountsMutationResponse;
  createCategoryTagRequests: CreateCategoryTagRequestsMutationResponse;
  createCategoryTags: CreateCategoryTagsMutationResponse;
  createCertificationRequests: CreateCertificationRequestsMutationResponse;
  createCertificationResponses: CreateCertificationResponsesMutationResponse;
  createChannels: CreateChannelsMutationResponse;
  createContentBadges: CreateContentBadgesMutationResponse;
  createCreatorContacts: CreateCreatorContactsMutationResponse;
  createCreators: CreateCreatorsMutationResponse;
  createEditions: CreateEditionsMutationResponse;
  createFeedbacks: CreateFeedbacksMutationResponse;
  createNutshellLead: CreateNutshellLeadResponse;
  createOwnerContacts: CreateOwnerContactsMutationResponse;
  createOwners: CreateOwnersMutationResponse;
  createTags: CreateTagsMutationResponse;
  createTypeTags: CreateTypeTagsMutationResponse;
  createUserMedias: CreateUserMediasMutationResponse;
  createUsers: CreateUsersMutationResponse;
  createWorks: CreateWorksMutationResponse;
  deleteAccountSubscriptions: DeleteInfo;
  deleteAccounts: DeleteInfo;
  deleteCategoryTagRequests: DeleteInfo;
  deleteCategoryTags: DeleteInfo;
  deleteCertificationRequests: DeleteInfo;
  deleteCertificationResponses: DeleteInfo;
  deleteChannels: DeleteInfo;
  deleteContentBadges: DeleteInfo;
  deleteCreatorContacts: DeleteInfo;
  deleteCreators: DeleteInfo;
  deleteEditions: DeleteInfo;
  deleteFeedbacks: DeleteInfo;
  deleteOwnerContacts: DeleteInfo;
  deleteOwners: DeleteInfo;
  deleteTags: DeleteInfo;
  deleteTypeTags: DeleteInfo;
  deleteUserMedias: DeleteInfo;
  deleteUsers: DeleteInfo;
  deleteWorks: DeleteInfo;
  parsePageFromUrl?: Maybe<ParsedPage>;
  subscribeAccount: SubscribeAccountResponse;
  updateAccountSubscriptions: UpdateAccountSubscriptionsMutationResponse;
  updateAccounts: UpdateAccountsMutationResponse;
  updateCategoryTagRequests: UpdateCategoryTagRequestsMutationResponse;
  updateCategoryTags: UpdateCategoryTagsMutationResponse;
  updateCertificationRequests: UpdateCertificationRequestsMutationResponse;
  updateCertificationResponses: UpdateCertificationResponsesMutationResponse;
  updateChannels: UpdateChannelsMutationResponse;
  updateContentBadges: UpdateContentBadgesMutationResponse;
  updateCreatorContacts: UpdateCreatorContactsMutationResponse;
  updateCreators: UpdateCreatorsMutationResponse;
  updateEditions: UpdateEditionsMutationResponse;
  updateFeedbacks: UpdateFeedbacksMutationResponse;
  updateOwnerContacts: UpdateOwnerContactsMutationResponse;
  updateOwners: UpdateOwnersMutationResponse;
  updateTags: UpdateTagsMutationResponse;
  updateTypeTags: UpdateTypeTagsMutationResponse;
  updateUserMedias: UpdateUserMediasMutationResponse;
  updateUsers: UpdateUsersMutationResponse;
  updateWorks: UpdateWorksMutationResponse;
  uploadMedia: UploadMediaResponse;
  userAcceptTerms: UserAcceptTermsResponse;
  verifyUserIdentity: VerifyUserIdentityResponse;
};


export type MutationCreateAccountSubscriptionsArgs = {
  input: Array<AccountSubscriptionCreateInput>;
};


export type MutationCreateAccountsArgs = {
  input: Array<AccountCreateInput>;
};


export type MutationCreateCategoryTagRequestsArgs = {
  input: Array<CategoryTagRequestCreateInput>;
};


export type MutationCreateCategoryTagsArgs = {
  input: Array<CategoryTagCreateInput>;
};


export type MutationCreateCertificationRequestsArgs = {
  input: Array<CertificationRequestCreateInput>;
};


export type MutationCreateCertificationResponsesArgs = {
  input: Array<CertificationResponseCreateInput>;
};


export type MutationCreateChannelsArgs = {
  input: Array<ChannelCreateInput>;
};


export type MutationCreateContentBadgesArgs = {
  input: Array<ContentBadgeCreateInput>;
};


export type MutationCreateCreatorContactsArgs = {
  input: Array<CreatorContactCreateInput>;
};


export type MutationCreateCreatorsArgs = {
  input: Array<CreatorCreateInput>;
};


export type MutationCreateEditionsArgs = {
  input: Array<EditionCreateInput>;
};


export type MutationCreateFeedbacksArgs = {
  input: Array<FeedbackCreateInput>;
};


export type MutationCreateNutshellLeadArgs = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationCreateOwnerContactsArgs = {
  input: Array<OwnerContactCreateInput>;
};


export type MutationCreateOwnersArgs = {
  input: Array<OwnerCreateInput>;
};


export type MutationCreateTagsArgs = {
  input: Array<TagCreateInput>;
};


export type MutationCreateTypeTagsArgs = {
  input: Array<TypeTagCreateInput>;
};


export type MutationCreateUserMediasArgs = {
  input: Array<UserMediaCreateInput>;
};


export type MutationCreateUsersArgs = {
  input: Array<UserCreateInput>;
};


export type MutationCreateWorksArgs = {
  input: Array<WorkCreateInput>;
};


export type MutationDeleteAccountSubscriptionsArgs = {
  delete?: InputMaybe<AccountSubscriptionDeleteInput>;
  where?: InputMaybe<AccountSubscriptionWhere>;
};


export type MutationDeleteAccountsArgs = {
  delete?: InputMaybe<AccountDeleteInput>;
  where?: InputMaybe<AccountWhere>;
};


export type MutationDeleteCategoryTagRequestsArgs = {
  delete?: InputMaybe<CategoryTagRequestDeleteInput>;
  where?: InputMaybe<CategoryTagRequestWhere>;
};


export type MutationDeleteCategoryTagsArgs = {
  delete?: InputMaybe<CategoryTagDeleteInput>;
  where?: InputMaybe<CategoryTagWhere>;
};


export type MutationDeleteCertificationRequestsArgs = {
  delete?: InputMaybe<CertificationRequestDeleteInput>;
  where?: InputMaybe<CertificationRequestWhere>;
};


export type MutationDeleteCertificationResponsesArgs = {
  delete?: InputMaybe<CertificationResponseDeleteInput>;
  where?: InputMaybe<CertificationResponseWhere>;
};


export type MutationDeleteChannelsArgs = {
  delete?: InputMaybe<ChannelDeleteInput>;
  where?: InputMaybe<ChannelWhere>;
};


export type MutationDeleteContentBadgesArgs = {
  delete?: InputMaybe<ContentBadgeDeleteInput>;
  where?: InputMaybe<ContentBadgeWhere>;
};


export type MutationDeleteCreatorContactsArgs = {
  delete?: InputMaybe<CreatorContactDeleteInput>;
  where?: InputMaybe<CreatorContactWhere>;
};


export type MutationDeleteCreatorsArgs = {
  delete?: InputMaybe<CreatorDeleteInput>;
  where?: InputMaybe<CreatorWhere>;
};


export type MutationDeleteEditionsArgs = {
  delete?: InputMaybe<EditionDeleteInput>;
  where?: InputMaybe<EditionWhere>;
};


export type MutationDeleteFeedbacksArgs = {
  delete?: InputMaybe<FeedbackDeleteInput>;
  where?: InputMaybe<FeedbackWhere>;
};


export type MutationDeleteOwnerContactsArgs = {
  delete?: InputMaybe<OwnerContactDeleteInput>;
  where?: InputMaybe<OwnerContactWhere>;
};


export type MutationDeleteOwnersArgs = {
  delete?: InputMaybe<OwnerDeleteInput>;
  where?: InputMaybe<OwnerWhere>;
};


export type MutationDeleteTagsArgs = {
  delete?: InputMaybe<TagDeleteInput>;
  where?: InputMaybe<TagWhere>;
};


export type MutationDeleteTypeTagsArgs = {
  delete?: InputMaybe<TypeTagDeleteInput>;
  where?: InputMaybe<TypeTagWhere>;
};


export type MutationDeleteUserMediasArgs = {
  delete?: InputMaybe<UserMediaDeleteInput>;
  where?: InputMaybe<UserMediaWhere>;
};


export type MutationDeleteUsersArgs = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<UserWhere>;
};


export type MutationDeleteWorksArgs = {
  delete?: InputMaybe<WorkDeleteInput>;
  where?: InputMaybe<WorkWhere>;
};


export type MutationParsePageFromUrlArgs = {
  url: Scalars['String']['input'];
};


export type MutationSubscribeAccountArgs = {
  displayPrice: Scalars['Float']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  paymentMethod: Scalars['String']['input'];
  plan: PlanTypes;
  priceId: Scalars['String']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAccountSubscriptionsArgs = {
  connect?: InputMaybe<AccountSubscriptionConnectInput>;
  connectOrCreate?: InputMaybe<AccountSubscriptionConnectOrCreateInput>;
  create?: InputMaybe<AccountSubscriptionRelationInput>;
  delete?: InputMaybe<AccountSubscriptionDeleteInput>;
  disconnect?: InputMaybe<AccountSubscriptionDisconnectInput>;
  update?: InputMaybe<AccountSubscriptionUpdateInput>;
  where?: InputMaybe<AccountSubscriptionWhere>;
};


export type MutationUpdateAccountsArgs = {
  connect?: InputMaybe<AccountConnectInput>;
  connectOrCreate?: InputMaybe<AccountConnectOrCreateInput>;
  create?: InputMaybe<AccountRelationInput>;
  delete?: InputMaybe<AccountDeleteInput>;
  disconnect?: InputMaybe<AccountDisconnectInput>;
  update?: InputMaybe<AccountUpdateInput>;
  where?: InputMaybe<AccountWhere>;
};


export type MutationUpdateCategoryTagRequestsArgs = {
  connect?: InputMaybe<CategoryTagRequestConnectInput>;
  connectOrCreate?: InputMaybe<CategoryTagRequestConnectOrCreateInput>;
  create?: InputMaybe<CategoryTagRequestRelationInput>;
  delete?: InputMaybe<CategoryTagRequestDeleteInput>;
  disconnect?: InputMaybe<CategoryTagRequestDisconnectInput>;
  update?: InputMaybe<CategoryTagRequestUpdateInput>;
  where?: InputMaybe<CategoryTagRequestWhere>;
};


export type MutationUpdateCategoryTagsArgs = {
  connect?: InputMaybe<CategoryTagConnectInput>;
  connectOrCreate?: InputMaybe<CategoryTagConnectOrCreateInput>;
  create?: InputMaybe<CategoryTagRelationInput>;
  delete?: InputMaybe<CategoryTagDeleteInput>;
  disconnect?: InputMaybe<CategoryTagDisconnectInput>;
  update?: InputMaybe<CategoryTagUpdateInput>;
  where?: InputMaybe<CategoryTagWhere>;
};


export type MutationUpdateCertificationRequestsArgs = {
  connect?: InputMaybe<CertificationRequestConnectInput>;
  connectOrCreate?: InputMaybe<CertificationRequestConnectOrCreateInput>;
  create?: InputMaybe<CertificationRequestRelationInput>;
  delete?: InputMaybe<CertificationRequestDeleteInput>;
  disconnect?: InputMaybe<CertificationRequestDisconnectInput>;
  update?: InputMaybe<CertificationRequestUpdateInput>;
  where?: InputMaybe<CertificationRequestWhere>;
};


export type MutationUpdateCertificationResponsesArgs = {
  connect?: InputMaybe<CertificationResponseConnectInput>;
  connectOrCreate?: InputMaybe<CertificationResponseConnectOrCreateInput>;
  create?: InputMaybe<CertificationResponseRelationInput>;
  delete?: InputMaybe<CertificationResponseDeleteInput>;
  disconnect?: InputMaybe<CertificationResponseDisconnectInput>;
  update?: InputMaybe<CertificationResponseUpdateInput>;
  where?: InputMaybe<CertificationResponseWhere>;
};


export type MutationUpdateChannelsArgs = {
  connect?: InputMaybe<ChannelConnectInput>;
  connectOrCreate?: InputMaybe<ChannelConnectOrCreateInput>;
  create?: InputMaybe<ChannelRelationInput>;
  delete?: InputMaybe<ChannelDeleteInput>;
  disconnect?: InputMaybe<ChannelDisconnectInput>;
  update?: InputMaybe<ChannelUpdateInput>;
  where?: InputMaybe<ChannelWhere>;
};


export type MutationUpdateContentBadgesArgs = {
  connect?: InputMaybe<ContentBadgeConnectInput>;
  connectOrCreate?: InputMaybe<ContentBadgeConnectOrCreateInput>;
  create?: InputMaybe<ContentBadgeRelationInput>;
  delete?: InputMaybe<ContentBadgeDeleteInput>;
  disconnect?: InputMaybe<ContentBadgeDisconnectInput>;
  update?: InputMaybe<ContentBadgeUpdateInput>;
  where?: InputMaybe<ContentBadgeWhere>;
};


export type MutationUpdateCreatorContactsArgs = {
  connect?: InputMaybe<CreatorContactConnectInput>;
  connectOrCreate?: InputMaybe<CreatorContactConnectOrCreateInput>;
  create?: InputMaybe<CreatorContactRelationInput>;
  delete?: InputMaybe<CreatorContactDeleteInput>;
  disconnect?: InputMaybe<CreatorContactDisconnectInput>;
  update?: InputMaybe<CreatorContactUpdateInput>;
  where?: InputMaybe<CreatorContactWhere>;
};


export type MutationUpdateCreatorsArgs = {
  connect?: InputMaybe<CreatorConnectInput>;
  connectOrCreate?: InputMaybe<CreatorConnectOrCreateInput>;
  create?: InputMaybe<CreatorRelationInput>;
  delete?: InputMaybe<CreatorDeleteInput>;
  disconnect?: InputMaybe<CreatorDisconnectInput>;
  update?: InputMaybe<CreatorUpdateInput>;
  where?: InputMaybe<CreatorWhere>;
};


export type MutationUpdateEditionsArgs = {
  connect?: InputMaybe<EditionConnectInput>;
  connectOrCreate?: InputMaybe<EditionConnectOrCreateInput>;
  create?: InputMaybe<EditionRelationInput>;
  delete?: InputMaybe<EditionDeleteInput>;
  disconnect?: InputMaybe<EditionDisconnectInput>;
  update?: InputMaybe<EditionUpdateInput>;
  where?: InputMaybe<EditionWhere>;
};


export type MutationUpdateFeedbacksArgs = {
  connect?: InputMaybe<FeedbackConnectInput>;
  connectOrCreate?: InputMaybe<FeedbackConnectOrCreateInput>;
  create?: InputMaybe<FeedbackRelationInput>;
  delete?: InputMaybe<FeedbackDeleteInput>;
  disconnect?: InputMaybe<FeedbackDisconnectInput>;
  update?: InputMaybe<FeedbackUpdateInput>;
  where?: InputMaybe<FeedbackWhere>;
};


export type MutationUpdateOwnerContactsArgs = {
  connect?: InputMaybe<OwnerContactConnectInput>;
  connectOrCreate?: InputMaybe<OwnerContactConnectOrCreateInput>;
  create?: InputMaybe<OwnerContactRelationInput>;
  delete?: InputMaybe<OwnerContactDeleteInput>;
  disconnect?: InputMaybe<OwnerContactDisconnectInput>;
  update?: InputMaybe<OwnerContactUpdateInput>;
  where?: InputMaybe<OwnerContactWhere>;
};


export type MutationUpdateOwnersArgs = {
  connect?: InputMaybe<OwnerConnectInput>;
  connectOrCreate?: InputMaybe<OwnerConnectOrCreateInput>;
  create?: InputMaybe<OwnerRelationInput>;
  delete?: InputMaybe<OwnerDeleteInput>;
  disconnect?: InputMaybe<OwnerDisconnectInput>;
  update?: InputMaybe<OwnerUpdateInput>;
  where?: InputMaybe<OwnerWhere>;
};


export type MutationUpdateTagsArgs = {
  connect?: InputMaybe<TagConnectInput>;
  connectOrCreate?: InputMaybe<TagConnectOrCreateInput>;
  create?: InputMaybe<TagRelationInput>;
  delete?: InputMaybe<TagDeleteInput>;
  disconnect?: InputMaybe<TagDisconnectInput>;
  update?: InputMaybe<TagUpdateInput>;
  where?: InputMaybe<TagWhere>;
};


export type MutationUpdateTypeTagsArgs = {
  connect?: InputMaybe<TypeTagConnectInput>;
  connectOrCreate?: InputMaybe<TypeTagConnectOrCreateInput>;
  create?: InputMaybe<TypeTagRelationInput>;
  delete?: InputMaybe<TypeTagDeleteInput>;
  disconnect?: InputMaybe<TypeTagDisconnectInput>;
  update?: InputMaybe<TypeTagUpdateInput>;
  where?: InputMaybe<TypeTagWhere>;
};


export type MutationUpdateUserMediasArgs = {
  connect?: InputMaybe<UserMediaConnectInput>;
  connectOrCreate?: InputMaybe<UserMediaConnectOrCreateInput>;
  create?: InputMaybe<UserMediaRelationInput>;
  delete?: InputMaybe<UserMediaDeleteInput>;
  disconnect?: InputMaybe<UserMediaDisconnectInput>;
  update?: InputMaybe<UserMediaUpdateInput>;
  where?: InputMaybe<UserMediaWhere>;
};


export type MutationUpdateUsersArgs = {
  connect?: InputMaybe<UserConnectInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateInput>;
  create?: InputMaybe<UserRelationInput>;
  delete?: InputMaybe<UserDeleteInput>;
  disconnect?: InputMaybe<UserDisconnectInput>;
  update?: InputMaybe<UserUpdateInput>;
  where?: InputMaybe<UserWhere>;
};


export type MutationUpdateWorksArgs = {
  connect?: InputMaybe<WorkConnectInput>;
  connectOrCreate?: InputMaybe<WorkConnectOrCreateInput>;
  create?: InputMaybe<WorkRelationInput>;
  delete?: InputMaybe<WorkDeleteInput>;
  disconnect?: InputMaybe<WorkDisconnectInput>;
  update?: InputMaybe<WorkUpdateInput>;
  where?: InputMaybe<WorkWhere>;
};


export type MutationUploadMediaArgs = {
  contentLength?: InputMaybe<Scalars['Int']['input']>;
  upload: Scalars['Upload']['input'];
};


export type MutationVerifyUserIdentityArgs = {
  inquiryId: Scalars['String']['input'];
};

export enum OnboardingStatus {
  IdVerified = 'ID_VERIFIED',
  SubscriptionPaid = 'SUBSCRIPTION_PAID',
  TermsAccepted = 'TERMS_ACCEPTED',
  UserCreated = 'USER_CREATED'
}

export enum OtherAiToolsTypes {
  NotUsed = 'NOT_USED',
  Used = 'USED'
}

/** Rights Owner: Both Channels and Works can have Rights Owners */
export type Owner = {
  __typename?: 'Owner';
  /** The Account this Rights Owner belongs to */
  account?: Maybe<Account>;
  accountAggregate?: Maybe<OwnerAccountAccountAggregationSelection>;
  accountConnection: OwnerAccountConnection;
  /** Channels belong to this Rights Owner */
  channels: Array<Channel>;
  channelsAggregate?: Maybe<OwnerChannelChannelsAggregationSelection>;
  channelsConnection: OwnerChannelsConnection;
  /** Contacts */
  contacts: Array<OwnerContact>;
  contactsAggregate?: Maybe<OwnerOwnerContactContactsAggregationSelection>;
  contactsConnection: OwnerContactsConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Editions belong to this Rights Owner */
  editions: Array<Edition>;
  editionsAggregate?: Maybe<OwnerEditionEditionsAggregationSelection>;
  editionsConnection: OwnerEditionsConnection;
  /** System generated GUID */
  id: Scalars['ID']['output'];
  /** Rights Owner Name */
  name?: Maybe<Scalars['String']['output']>;
  /** Rights Owner's name in lower case, for search */
  nameLC?: Maybe<Scalars['String']['output']>;
  /**
   * Nutshell Contact/Person/People ID
   * Required to fetch Contact/Person/People through Nutshell API
   */
  nutshellContactId?: Maybe<Scalars['String']['output']>;
  /**
   * Nutshell Lead ID
   * Required to fetch Lead through Nutshell API
   */
  nutshellLeadId?: Maybe<Scalars['String']['output']>;
  /**
   * Nutshell Lead Number
   * Nutshell Console does not use the above ID, it shows Lead Number only, weird.
   */
  nutshellLeadNumber?: Maybe<Scalars['String']['output']>;
  /** URL or path to their profile picture. */
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** Rights Owner Tags (Optional) */
  tags: Array<Tag>;
  tagsAggregate?: Maybe<OwnerTagTagsAggregationSelection>;
  tagsConnection: OwnerTagsConnection;
  /** Rights owner type */
  type?: Maybe<OwnerTypes>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Rights Owner URL */
  url?: Maybe<Scalars['String']['output']>;
  /** Works of this Rights Owner */
  works: Array<Work>;
  worksAggregate?: Maybe<OwnerWorkWorksAggregationSelection>;
  worksConnection: OwnerWorksConnection;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerAccountArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<AccountOptions>;
  where?: InputMaybe<AccountWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerAccountAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccountWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerAccountConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OwnerAccountConnectionSort>>;
  where?: InputMaybe<OwnerAccountConnectionWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerChannelsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<ChannelOptions>;
  where?: InputMaybe<ChannelWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerChannelsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ChannelWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OwnerChannelsConnectionSort>>;
  where?: InputMaybe<OwnerChannelsConnectionWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerContactsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<OwnerContactOptions>;
  where?: InputMaybe<OwnerContactWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerContactsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<OwnerContactWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerContactsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OwnerContactsConnectionSort>>;
  where?: InputMaybe<OwnerContactsConnectionWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerEditionsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<EditionOptions>;
  where?: InputMaybe<EditionWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerEditionsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<EditionWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerEditionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OwnerEditionsConnectionSort>>;
  where?: InputMaybe<OwnerEditionsConnectionWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerTagsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TagOptions>;
  where?: InputMaybe<TagWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerTagsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TagWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerTagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OwnerTagsConnectionSort>>;
  where?: InputMaybe<OwnerTagsConnectionWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerWorksArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<WorkOptions>;
  where?: InputMaybe<WorkWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerWorksAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<WorkWhere>;
};


/** Rights Owner: Both Channels and Works can have Rights Owners */
export type OwnerWorksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OwnerWorksConnectionSort>>;
  where?: InputMaybe<OwnerWorksConnectionWhere>;
};

export type OwnerAccountAccountAggregationSelection = {
  __typename?: 'OwnerAccountAccountAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<OwnerAccountAccountNodeAggregateSelection>;
};

export type OwnerAccountAccountNodeAggregateSelection = {
  __typename?: 'OwnerAccountAccountNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  joinedDate: StringAggregateSelectionNullable;
  lastActive: StringAggregateSelectionNullable;
  profileURL: StringAggregateSelectionNullable;
  taxId: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  username: StringAggregateSelectionNullable;
};

export type OwnerAccountAggregateInput = {
  AND?: InputMaybe<Array<OwnerAccountAggregateInput>>;
  NOT?: InputMaybe<OwnerAccountAggregateInput>;
  OR?: InputMaybe<Array<OwnerAccountAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<OwnerAccountNodeAggregationWhereInput>;
};

export type OwnerAccountConnectFieldInput = {
  connect?: InputMaybe<AccountConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<AccountConnectWhere>;
};

export type OwnerAccountConnectOrCreateFieldInput = {
  onCreate: OwnerAccountConnectOrCreateFieldInputOnCreate;
  where: AccountConnectOrCreateWhere;
};

export type OwnerAccountConnectOrCreateFieldInputOnCreate = {
  node: AccountOnCreateInput;
};

export type OwnerAccountConnection = {
  __typename?: 'OwnerAccountConnection';
  edges: Array<OwnerAccountRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OwnerAccountConnectionSort = {
  node?: InputMaybe<AccountSort>;
};

export type OwnerAccountConnectionWhere = {
  AND?: InputMaybe<Array<OwnerAccountConnectionWhere>>;
  NOT?: InputMaybe<OwnerAccountConnectionWhere>;
  OR?: InputMaybe<Array<OwnerAccountConnectionWhere>>;
  node?: InputMaybe<AccountWhere>;
};

export type OwnerAccountCreateFieldInput = {
  node: AccountCreateInput;
};

export type OwnerAccountDeleteFieldInput = {
  delete?: InputMaybe<AccountDeleteInput>;
  where?: InputMaybe<OwnerAccountConnectionWhere>;
};

export type OwnerAccountDisconnectFieldInput = {
  disconnect?: InputMaybe<AccountDisconnectInput>;
  where?: InputMaybe<OwnerAccountConnectionWhere>;
};

export type OwnerAccountFieldInput = {
  connect?: InputMaybe<OwnerAccountConnectFieldInput>;
  connectOrCreate?: InputMaybe<OwnerAccountConnectOrCreateFieldInput>;
  create?: InputMaybe<OwnerAccountCreateFieldInput>;
};

export type OwnerAccountNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<OwnerAccountNodeAggregationWhereInput>>;
  NOT?: InputMaybe<OwnerAccountNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<OwnerAccountNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  joinedDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type OwnerAccountRelationship = {
  __typename?: 'OwnerAccountRelationship';
  cursor: Scalars['String']['output'];
  node: Account;
};

export type OwnerAccountUpdateConnectionInput = {
  node?: InputMaybe<AccountUpdateInput>;
};

export type OwnerAccountUpdateFieldInput = {
  connect?: InputMaybe<OwnerAccountConnectFieldInput>;
  connectOrCreate?: InputMaybe<OwnerAccountConnectOrCreateFieldInput>;
  create?: InputMaybe<OwnerAccountCreateFieldInput>;
  delete?: InputMaybe<OwnerAccountDeleteFieldInput>;
  disconnect?: InputMaybe<OwnerAccountDisconnectFieldInput>;
  update?: InputMaybe<OwnerAccountUpdateConnectionInput>;
  where?: InputMaybe<OwnerAccountConnectionWhere>;
};

export type OwnerAggregateSelection = {
  __typename?: 'OwnerAggregateSelection';
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  nameLC: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type OwnerChannelChannelsAggregationSelection = {
  __typename?: 'OwnerChannelChannelsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<OwnerChannelChannelsNodeAggregateSelection>;
};

export type OwnerChannelChannelsNodeAggregateSelection = {
  __typename?: 'OwnerChannelChannelsNodeAggregateSelection';
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type OwnerChannelsAggregateInput = {
  AND?: InputMaybe<Array<OwnerChannelsAggregateInput>>;
  NOT?: InputMaybe<OwnerChannelsAggregateInput>;
  OR?: InputMaybe<Array<OwnerChannelsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<OwnerChannelsNodeAggregationWhereInput>;
};

export type OwnerChannelsConnectFieldInput = {
  connect?: InputMaybe<Array<ChannelConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<ChannelConnectWhere>;
};

export type OwnerChannelsConnectOrCreateFieldInput = {
  onCreate: OwnerChannelsConnectOrCreateFieldInputOnCreate;
  where: ChannelConnectOrCreateWhere;
};

export type OwnerChannelsConnectOrCreateFieldInputOnCreate = {
  node: ChannelOnCreateInput;
};

export type OwnerChannelsConnection = {
  __typename?: 'OwnerChannelsConnection';
  edges: Array<OwnerChannelsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OwnerChannelsConnectionSort = {
  node?: InputMaybe<ChannelSort>;
};

export type OwnerChannelsConnectionWhere = {
  AND?: InputMaybe<Array<OwnerChannelsConnectionWhere>>;
  NOT?: InputMaybe<OwnerChannelsConnectionWhere>;
  OR?: InputMaybe<Array<OwnerChannelsConnectionWhere>>;
  node?: InputMaybe<ChannelWhere>;
};

export type OwnerChannelsCreateFieldInput = {
  node: ChannelCreateInput;
};

export type OwnerChannelsDeleteFieldInput = {
  delete?: InputMaybe<ChannelDeleteInput>;
  where?: InputMaybe<OwnerChannelsConnectionWhere>;
};

export type OwnerChannelsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChannelDisconnectInput>;
  where?: InputMaybe<OwnerChannelsConnectionWhere>;
};

export type OwnerChannelsFieldInput = {
  connect?: InputMaybe<Array<OwnerChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<OwnerChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<OwnerChannelsCreateFieldInput>>;
};

export type OwnerChannelsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<OwnerChannelsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<OwnerChannelsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<OwnerChannelsNodeAggregationWhereInput>>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OwnerChannelsRelationship = {
  __typename?: 'OwnerChannelsRelationship';
  cursor: Scalars['String']['output'];
  node: Channel;
};

export type OwnerChannelsUpdateConnectionInput = {
  node?: InputMaybe<ChannelUpdateInput>;
};

export type OwnerChannelsUpdateFieldInput = {
  connect?: InputMaybe<Array<OwnerChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<OwnerChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<OwnerChannelsCreateFieldInput>>;
  delete?: InputMaybe<Array<OwnerChannelsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<OwnerChannelsDisconnectFieldInput>>;
  update?: InputMaybe<OwnerChannelsUpdateConnectionInput>;
  where?: InputMaybe<OwnerChannelsConnectionWhere>;
};

export type OwnerConnectInput = {
  account?: InputMaybe<OwnerAccountConnectFieldInput>;
  channels?: InputMaybe<Array<OwnerChannelsConnectFieldInput>>;
  contacts?: InputMaybe<Array<OwnerContactsConnectFieldInput>>;
  editions?: InputMaybe<Array<OwnerEditionsConnectFieldInput>>;
  tags?: InputMaybe<Array<OwnerTagsConnectFieldInput>>;
  works?: InputMaybe<Array<OwnerWorksConnectFieldInput>>;
};

export type OwnerConnectOrCreateInput = {
  account?: InputMaybe<OwnerAccountConnectOrCreateFieldInput>;
  channels?: InputMaybe<Array<OwnerChannelsConnectOrCreateFieldInput>>;
  editions?: InputMaybe<Array<OwnerEditionsConnectOrCreateFieldInput>>;
  tags?: InputMaybe<Array<OwnerTagsConnectOrCreateFieldInput>>;
  works?: InputMaybe<Array<OwnerWorksConnectOrCreateFieldInput>>;
};

export type OwnerConnectOrCreateWhere = {
  node: OwnerUniqueWhere;
};

export type OwnerConnectWhere = {
  node: OwnerWhere;
};

/** Rights owner's contact information */
export type OwnerContact = {
  __typename?: 'OwnerContact';
  /** Street address. */
  addressLine1?: Maybe<Scalars['String']['output']>;
  /** Additional address details (optional). */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** City of business. */
  city?: Maybe<Scalars['String']['output']>;
  /** Country of business. */
  country?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Rights owner's email address. */
  emailAddress?: Maybe<Scalars['String']['output']>;
  /** Rights owner */
  owner: Owner;
  ownerAggregate?: Maybe<OwnerContactOwnerOwnerAggregationSelection>;
  ownerConnection: OwnerContactOwnerConnection;
  /** Rights owner's contact number. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Postal or ZIP code. */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** State or province of business. */
  stateProvince?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


/** Rights owner's contact information */
export type OwnerContactOwnerArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<OwnerOptions>;
  where?: InputMaybe<OwnerWhere>;
};


/** Rights owner's contact information */
export type OwnerContactOwnerAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<OwnerWhere>;
};


/** Rights owner's contact information */
export type OwnerContactOwnerConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OwnerContactOwnerConnectionSort>>;
  where?: InputMaybe<OwnerContactOwnerConnectionWhere>;
};

export type OwnerContactAggregateSelection = {
  __typename?: 'OwnerContactAggregateSelection';
  addressLine1: StringAggregateSelectionNullable;
  addressLine2: StringAggregateSelectionNullable;
  city: StringAggregateSelectionNullable;
  count: Scalars['Int']['output'];
  country: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  emailAddress: StringAggregateSelectionNullable;
  phoneNumber: StringAggregateSelectionNullable;
  postalCode: StringAggregateSelectionNullable;
  stateProvince: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type OwnerContactConnectInput = {
  owner?: InputMaybe<OwnerContactOwnerConnectFieldInput>;
};

export type OwnerContactConnectOrCreateInput = {
  owner?: InputMaybe<OwnerContactOwnerConnectOrCreateFieldInput>;
};

export type OwnerContactConnectWhere = {
  node: OwnerContactWhere;
};

export type OwnerContactCreateInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<OwnerContactOwnerFieldInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateProvince?: InputMaybe<Scalars['String']['input']>;
};

export type OwnerContactDeleteInput = {
  owner?: InputMaybe<OwnerContactOwnerDeleteFieldInput>;
};

export type OwnerContactDisconnectInput = {
  owner?: InputMaybe<OwnerContactOwnerDisconnectFieldInput>;
};

export type OwnerContactEdge = {
  __typename?: 'OwnerContactEdge';
  cursor: Scalars['String']['output'];
  node: OwnerContact;
};

export type OwnerContactOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more OwnerContactSort objects to sort OwnerContacts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<OwnerContactSort>>;
};

export type OwnerContactOwnerAggregateInput = {
  AND?: InputMaybe<Array<OwnerContactOwnerAggregateInput>>;
  NOT?: InputMaybe<OwnerContactOwnerAggregateInput>;
  OR?: InputMaybe<Array<OwnerContactOwnerAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<OwnerContactOwnerNodeAggregationWhereInput>;
};

export type OwnerContactOwnerConnectFieldInput = {
  connect?: InputMaybe<OwnerConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<OwnerConnectWhere>;
};

export type OwnerContactOwnerConnectOrCreateFieldInput = {
  onCreate: OwnerContactOwnerConnectOrCreateFieldInputOnCreate;
  where: OwnerConnectOrCreateWhere;
};

export type OwnerContactOwnerConnectOrCreateFieldInputOnCreate = {
  node: OwnerOnCreateInput;
};

export type OwnerContactOwnerConnection = {
  __typename?: 'OwnerContactOwnerConnection';
  edges: Array<OwnerContactOwnerRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OwnerContactOwnerConnectionSort = {
  node?: InputMaybe<OwnerSort>;
};

export type OwnerContactOwnerConnectionWhere = {
  AND?: InputMaybe<Array<OwnerContactOwnerConnectionWhere>>;
  NOT?: InputMaybe<OwnerContactOwnerConnectionWhere>;
  OR?: InputMaybe<Array<OwnerContactOwnerConnectionWhere>>;
  node?: InputMaybe<OwnerWhere>;
};

export type OwnerContactOwnerCreateFieldInput = {
  node: OwnerCreateInput;
};

export type OwnerContactOwnerDeleteFieldInput = {
  delete?: InputMaybe<OwnerDeleteInput>;
  where?: InputMaybe<OwnerContactOwnerConnectionWhere>;
};

export type OwnerContactOwnerDisconnectFieldInput = {
  disconnect?: InputMaybe<OwnerDisconnectInput>;
  where?: InputMaybe<OwnerContactOwnerConnectionWhere>;
};

export type OwnerContactOwnerFieldInput = {
  connect?: InputMaybe<OwnerContactOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<OwnerContactOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<OwnerContactOwnerCreateFieldInput>;
};

export type OwnerContactOwnerNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<OwnerContactOwnerNodeAggregationWhereInput>>;
  NOT?: InputMaybe<OwnerContactOwnerNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<OwnerContactOwnerNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  nameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type OwnerContactOwnerOwnerAggregationSelection = {
  __typename?: 'OwnerContactOwnerOwnerAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<OwnerContactOwnerOwnerNodeAggregateSelection>;
};

export type OwnerContactOwnerOwnerNodeAggregateSelection = {
  __typename?: 'OwnerContactOwnerOwnerNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  nameLC: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type OwnerContactOwnerRelationship = {
  __typename?: 'OwnerContactOwnerRelationship';
  cursor: Scalars['String']['output'];
  node: Owner;
};

export type OwnerContactOwnerUpdateConnectionInput = {
  node?: InputMaybe<OwnerUpdateInput>;
};

export type OwnerContactOwnerUpdateFieldInput = {
  connect?: InputMaybe<OwnerContactOwnerConnectFieldInput>;
  connectOrCreate?: InputMaybe<OwnerContactOwnerConnectOrCreateFieldInput>;
  create?: InputMaybe<OwnerContactOwnerCreateFieldInput>;
  delete?: InputMaybe<OwnerContactOwnerDeleteFieldInput>;
  disconnect?: InputMaybe<OwnerContactOwnerDisconnectFieldInput>;
  update?: InputMaybe<OwnerContactOwnerUpdateConnectionInput>;
  where?: InputMaybe<OwnerContactOwnerConnectionWhere>;
};

export type OwnerContactRelationInput = {
  owner?: InputMaybe<OwnerContactOwnerCreateFieldInput>;
};

/** Fields to sort OwnerContacts by. The order in which sorts are applied is not guaranteed when specifying many fields in one OwnerContactSort object. */
export type OwnerContactSort = {
  addressLine1?: InputMaybe<SortDirection>;
  addressLine2?: InputMaybe<SortDirection>;
  city?: InputMaybe<SortDirection>;
  country?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  emailAddress?: InputMaybe<SortDirection>;
  phoneNumber?: InputMaybe<SortDirection>;
  postalCode?: InputMaybe<SortDirection>;
  stateProvince?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type OwnerContactUpdateInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<OwnerContactOwnerUpdateFieldInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateProvince?: InputMaybe<Scalars['String']['input']>;
};

export type OwnerContactWhere = {
  AND?: InputMaybe<Array<OwnerContactWhere>>;
  NOT?: InputMaybe<OwnerContactWhere>;
  OR?: InputMaybe<Array<OwnerContactWhere>>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine1_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  addressLine1_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  addressLine1_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  addressLine1_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine2_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  addressLine2_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  addressLine2_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  addressLine2_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  city_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  city_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  city_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  country_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  country_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailAddress_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  emailAddress_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  emailAddress_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  emailAddress_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<OwnerWhere>;
  ownerAggregate?: InputMaybe<OwnerContactOwnerAggregateInput>;
  ownerConnection?: InputMaybe<OwnerContactOwnerConnectionWhere>;
  ownerConnection_NOT?: InputMaybe<OwnerContactOwnerConnectionWhere>;
  owner_NOT?: InputMaybe<OwnerWhere>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  phoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  postalCode_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  postalCode_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  postalCode_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  postalCode_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  stateProvince?: InputMaybe<Scalars['String']['input']>;
  stateProvince_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  stateProvince_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  stateProvince_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stateProvince_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OwnerContactsAggregateInput = {
  AND?: InputMaybe<Array<OwnerContactsAggregateInput>>;
  NOT?: InputMaybe<OwnerContactsAggregateInput>;
  OR?: InputMaybe<Array<OwnerContactsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<OwnerContactsNodeAggregationWhereInput>;
};

export type OwnerContactsConnectFieldInput = {
  connect?: InputMaybe<Array<OwnerContactConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<OwnerContactConnectWhere>;
};

export type OwnerContactsConnection = {
  __typename?: 'OwnerContactsConnection';
  edges: Array<OwnerContactsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OwnerContactsConnectionSort = {
  node?: InputMaybe<OwnerContactSort>;
};

export type OwnerContactsConnectionWhere = {
  AND?: InputMaybe<Array<OwnerContactsConnectionWhere>>;
  NOT?: InputMaybe<OwnerContactsConnectionWhere>;
  OR?: InputMaybe<Array<OwnerContactsConnectionWhere>>;
  node?: InputMaybe<OwnerContactWhere>;
};

export type OwnerContactsCreateFieldInput = {
  node: OwnerContactCreateInput;
};

export type OwnerContactsDeleteFieldInput = {
  delete?: InputMaybe<OwnerContactDeleteInput>;
  where?: InputMaybe<OwnerContactsConnectionWhere>;
};

export type OwnerContactsDisconnectFieldInput = {
  disconnect?: InputMaybe<OwnerContactDisconnectInput>;
  where?: InputMaybe<OwnerContactsConnectionWhere>;
};

export type OwnerContactsFieldInput = {
  connect?: InputMaybe<Array<OwnerContactsConnectFieldInput>>;
  create?: InputMaybe<Array<OwnerContactsCreateFieldInput>>;
};

export type OwnerContactsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<OwnerContactsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<OwnerContactsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<OwnerContactsNodeAggregationWhereInput>>;
  addressLine1_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  addressLine1_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  addressLine1_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  addressLine1_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  addressLine1_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  addressLine1_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  addressLine1_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  addressLine2_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  addressLine2_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  addressLine2_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  addressLine2_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  addressLine2_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  addressLine2_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  city_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  city_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  city_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  city_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  city_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  city_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  city_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  city_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  city_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  city_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  city_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  city_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  city_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  city_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  city_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  country_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  country_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  country_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  country_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  country_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  country_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  country_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  country_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  country_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  country_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  country_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  country_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  country_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  country_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  country_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  emailAddress_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  emailAddress_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  emailAddress_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  postalCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  postalCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  postalCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  postalCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  postalCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  postalCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  postalCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  postalCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  postalCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  postalCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  postalCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  postalCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  postalCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  postalCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  postalCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  stateProvince_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  stateProvince_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  stateProvince_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  stateProvince_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  stateProvince_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  stateProvince_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OwnerContactsRelationship = {
  __typename?: 'OwnerContactsRelationship';
  cursor: Scalars['String']['output'];
  node: OwnerContact;
};

export type OwnerContactsUpdateConnectionInput = {
  node?: InputMaybe<OwnerContactUpdateInput>;
};

export type OwnerContactsUpdateFieldInput = {
  connect?: InputMaybe<Array<OwnerContactsConnectFieldInput>>;
  create?: InputMaybe<Array<OwnerContactsCreateFieldInput>>;
  delete?: InputMaybe<Array<OwnerContactsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<OwnerContactsDisconnectFieldInput>>;
  update?: InputMaybe<OwnerContactsUpdateConnectionInput>;
  where?: InputMaybe<OwnerContactsConnectionWhere>;
};

export type OwnerCreateInput = {
  account?: InputMaybe<OwnerAccountFieldInput>;
  channels?: InputMaybe<OwnerChannelsFieldInput>;
  contacts?: InputMaybe<OwnerContactsFieldInput>;
  editions?: InputMaybe<OwnerEditionsFieldInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameLC?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<OwnerTagsFieldInput>;
  type?: InputMaybe<OwnerTypes>;
  url?: InputMaybe<Scalars['String']['input']>;
  works?: InputMaybe<OwnerWorksFieldInput>;
};

export type OwnerDeleteInput = {
  account?: InputMaybe<OwnerAccountDeleteFieldInput>;
  channels?: InputMaybe<Array<OwnerChannelsDeleteFieldInput>>;
  contacts?: InputMaybe<Array<OwnerContactsDeleteFieldInput>>;
  editions?: InputMaybe<Array<OwnerEditionsDeleteFieldInput>>;
  tags?: InputMaybe<Array<OwnerTagsDeleteFieldInput>>;
  works?: InputMaybe<Array<OwnerWorksDeleteFieldInput>>;
};

export type OwnerDisconnectInput = {
  account?: InputMaybe<OwnerAccountDisconnectFieldInput>;
  channels?: InputMaybe<Array<OwnerChannelsDisconnectFieldInput>>;
  contacts?: InputMaybe<Array<OwnerContactsDisconnectFieldInput>>;
  editions?: InputMaybe<Array<OwnerEditionsDisconnectFieldInput>>;
  tags?: InputMaybe<Array<OwnerTagsDisconnectFieldInput>>;
  works?: InputMaybe<Array<OwnerWorksDisconnectFieldInput>>;
};

export type OwnerEdge = {
  __typename?: 'OwnerEdge';
  cursor: Scalars['String']['output'];
  node: Owner;
};

export type OwnerEditionEditionsAggregationSelection = {
  __typename?: 'OwnerEditionEditionsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<OwnerEditionEditionsNodeAggregateSelection>;
};

export type OwnerEditionEditionsNodeAggregateSelection = {
  __typename?: 'OwnerEditionEditionsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type OwnerEditionsAggregateInput = {
  AND?: InputMaybe<Array<OwnerEditionsAggregateInput>>;
  NOT?: InputMaybe<OwnerEditionsAggregateInput>;
  OR?: InputMaybe<Array<OwnerEditionsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<OwnerEditionsNodeAggregationWhereInput>;
};

export type OwnerEditionsConnectFieldInput = {
  connect?: InputMaybe<Array<EditionConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<EditionConnectWhere>;
};

export type OwnerEditionsConnectOrCreateFieldInput = {
  onCreate: OwnerEditionsConnectOrCreateFieldInputOnCreate;
  where: EditionConnectOrCreateWhere;
};

export type OwnerEditionsConnectOrCreateFieldInputOnCreate = {
  node: EditionOnCreateInput;
};

export type OwnerEditionsConnection = {
  __typename?: 'OwnerEditionsConnection';
  edges: Array<OwnerEditionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OwnerEditionsConnectionSort = {
  node?: InputMaybe<EditionSort>;
};

export type OwnerEditionsConnectionWhere = {
  AND?: InputMaybe<Array<OwnerEditionsConnectionWhere>>;
  NOT?: InputMaybe<OwnerEditionsConnectionWhere>;
  OR?: InputMaybe<Array<OwnerEditionsConnectionWhere>>;
  node?: InputMaybe<EditionWhere>;
};

export type OwnerEditionsCreateFieldInput = {
  node: EditionCreateInput;
};

export type OwnerEditionsDeleteFieldInput = {
  delete?: InputMaybe<EditionDeleteInput>;
  where?: InputMaybe<OwnerEditionsConnectionWhere>;
};

export type OwnerEditionsDisconnectFieldInput = {
  disconnect?: InputMaybe<EditionDisconnectInput>;
  where?: InputMaybe<OwnerEditionsConnectionWhere>;
};

export type OwnerEditionsFieldInput = {
  connect?: InputMaybe<Array<OwnerEditionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<OwnerEditionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<OwnerEditionsCreateFieldInput>>;
};

export type OwnerEditionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<OwnerEditionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<OwnerEditionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<OwnerEditionsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type OwnerEditionsRelationship = {
  __typename?: 'OwnerEditionsRelationship';
  cursor: Scalars['String']['output'];
  node: Edition;
};

export type OwnerEditionsUpdateConnectionInput = {
  node?: InputMaybe<EditionUpdateInput>;
};

export type OwnerEditionsUpdateFieldInput = {
  connect?: InputMaybe<Array<OwnerEditionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<OwnerEditionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<OwnerEditionsCreateFieldInput>>;
  delete?: InputMaybe<Array<OwnerEditionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<OwnerEditionsDisconnectFieldInput>>;
  update?: InputMaybe<OwnerEditionsUpdateConnectionInput>;
  where?: InputMaybe<OwnerEditionsConnectionWhere>;
};

export type OwnerOnCreateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameLC?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OwnerTypes>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type OwnerOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more OwnerSort objects to sort Owners by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<OwnerSort>>;
};

export type OwnerOwnerContactContactsAggregationSelection = {
  __typename?: 'OwnerOwnerContactContactsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<OwnerOwnerContactContactsNodeAggregateSelection>;
};

export type OwnerOwnerContactContactsNodeAggregateSelection = {
  __typename?: 'OwnerOwnerContactContactsNodeAggregateSelection';
  addressLine1: StringAggregateSelectionNullable;
  addressLine2: StringAggregateSelectionNullable;
  city: StringAggregateSelectionNullable;
  country: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  emailAddress: StringAggregateSelectionNullable;
  phoneNumber: StringAggregateSelectionNullable;
  postalCode: StringAggregateSelectionNullable;
  stateProvince: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type OwnerRelationInput = {
  account?: InputMaybe<OwnerAccountCreateFieldInput>;
  channels?: InputMaybe<Array<OwnerChannelsCreateFieldInput>>;
  contacts?: InputMaybe<Array<OwnerContactsCreateFieldInput>>;
  editions?: InputMaybe<Array<OwnerEditionsCreateFieldInput>>;
  tags?: InputMaybe<Array<OwnerTagsCreateFieldInput>>;
  works?: InputMaybe<Array<OwnerWorksCreateFieldInput>>;
};

/** Fields to sort Owners by. The order in which sorts are applied is not guaranteed when specifying many fields in one OwnerSort object. */
export type OwnerSort = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  nameLC?: InputMaybe<SortDirection>;
  nutshellContactId?: InputMaybe<SortDirection>;
  nutshellLeadId?: InputMaybe<SortDirection>;
  nutshellLeadNumber?: InputMaybe<SortDirection>;
  profilePicture?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type OwnerTagTagsAggregationSelection = {
  __typename?: 'OwnerTagTagsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<OwnerTagTagsNodeAggregateSelection>;
};

export type OwnerTagTagsNodeAggregateSelection = {
  __typename?: 'OwnerTagTagsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type OwnerTagsAggregateInput = {
  AND?: InputMaybe<Array<OwnerTagsAggregateInput>>;
  NOT?: InputMaybe<OwnerTagsAggregateInput>;
  OR?: InputMaybe<Array<OwnerTagsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<OwnerTagsNodeAggregationWhereInput>;
};

export type OwnerTagsConnectFieldInput = {
  connect?: InputMaybe<Array<TagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<TagConnectWhere>;
};

export type OwnerTagsConnectOrCreateFieldInput = {
  onCreate: OwnerTagsConnectOrCreateFieldInputOnCreate;
  where: TagConnectOrCreateWhere;
};

export type OwnerTagsConnectOrCreateFieldInputOnCreate = {
  node: TagOnCreateInput;
};

export type OwnerTagsConnection = {
  __typename?: 'OwnerTagsConnection';
  edges: Array<OwnerTagsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OwnerTagsConnectionSort = {
  node?: InputMaybe<TagSort>;
};

export type OwnerTagsConnectionWhere = {
  AND?: InputMaybe<Array<OwnerTagsConnectionWhere>>;
  NOT?: InputMaybe<OwnerTagsConnectionWhere>;
  OR?: InputMaybe<Array<OwnerTagsConnectionWhere>>;
  node?: InputMaybe<TagWhere>;
};

export type OwnerTagsCreateFieldInput = {
  node: TagCreateInput;
};

export type OwnerTagsDeleteFieldInput = {
  delete?: InputMaybe<TagDeleteInput>;
  where?: InputMaybe<OwnerTagsConnectionWhere>;
};

export type OwnerTagsDisconnectFieldInput = {
  disconnect?: InputMaybe<TagDisconnectInput>;
  where?: InputMaybe<OwnerTagsConnectionWhere>;
};

export type OwnerTagsFieldInput = {
  connect?: InputMaybe<Array<OwnerTagsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<OwnerTagsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<OwnerTagsCreateFieldInput>>;
};

export type OwnerTagsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<OwnerTagsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<OwnerTagsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<OwnerTagsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OwnerTagsRelationship = {
  __typename?: 'OwnerTagsRelationship';
  cursor: Scalars['String']['output'];
  node: Tag;
};

export type OwnerTagsUpdateConnectionInput = {
  node?: InputMaybe<TagUpdateInput>;
};

export type OwnerTagsUpdateFieldInput = {
  connect?: InputMaybe<Array<OwnerTagsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<OwnerTagsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<OwnerTagsCreateFieldInput>>;
  delete?: InputMaybe<Array<OwnerTagsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<OwnerTagsDisconnectFieldInput>>;
  update?: InputMaybe<OwnerTagsUpdateConnectionInput>;
  where?: InputMaybe<OwnerTagsConnectionWhere>;
};

export enum OwnerTypes {
  BooksAndEbooksAuthors = 'BOOKS_AND_EBOOKS_AUTHORS',
  BooksAndEbooksLiteraryAgents = 'BOOKS_AND_EBOOKS_LITERARY_AGENTS',
  BooksAndEbooksPublishers = 'BOOKS_AND_EBOOKS_PUBLISHERS',
  BooksAndEbooksSelfPublishingPlatforms = 'BOOKS_AND_EBOOKS_SELF_PUBLISHING_PLATFORMS',
  FilmActors = 'FILM_ACTORS',
  FilmDirectors = 'FILM_DIRECTORS',
  FilmDistributionCompanies = 'FILM_DISTRIBUTION_COMPANIES',
  FilmProducers = 'FILM_PRODUCERS',
  FilmScreenwriters = 'FILM_SCREENWRITERS',
  FilmStudios = 'FILM_STUDIOS',
  GamesDistributionServices = 'GAMES_DISTRIBUTION_SERVICES',
  GamesGameDevelopers = 'GAMES_GAME_DEVELOPERS',
  GamesIpHolders = 'GAMES_IP_HOLDERS',
  GamesPlatforms = 'GAMES_PLATFORMS',
  GamesPublishers = 'GAMES_PUBLISHERS',
  MusicMechanicalRightsAgencies = 'MUSIC_MECHANICAL_RIGHTS_AGENCIES',
  MusicMusicPublishers = 'MUSIC_MUSIC_PUBLISHERS',
  MusicPerformers = 'MUSIC_PERFORMERS',
  MusicProsPerformanceRightsOrganizations = 'MUSIC_PROS_PERFORMANCE_RIGHTS_ORGANIZATIONS',
  MusicRecordLabels = 'MUSIC_RECORD_LABELS',
  MusicSongwriters = 'MUSIC_SONGWRITERS',
  NewslettersIndependentPublishers = 'NEWSLETTERS_INDEPENDENT_PUBLISHERS',
  NewslettersMediaCompanies = 'NEWSLETTERS_MEDIA_COMPANIES',
  NewslettersPublishingPlatforms = 'NEWSLETTERS_PUBLISHING_PLATFORMS',
  NewslettersWriters = 'NEWSLETTERS_WRITERS',
  OnlineVideoContentCreators = 'ONLINE_VIDEO_CONTENT_CREATORS',
  OnlineVideoMcns = 'ONLINE_VIDEO_MCNS',
  OnlineVideoPlatforms = 'ONLINE_VIDEO_PLATFORMS',
  OnlineVideoProductionCompanies = 'ONLINE_VIDEO_PRODUCTION_COMPANIES',
  OnlineVideoRightsManagementAgencies = 'ONLINE_VIDEO_RIGHTS_MANAGEMENT_AGENCIES',
  PodcastsAdvertisers = 'PODCASTS_ADVERTISERS',
  PodcastsDistributionPlatforms = 'PODCASTS_DISTRIBUTION_PLATFORMS',
  PodcastsNetworks = 'PODCASTS_NETWORKS',
  PodcastsPodcastCreators = 'PODCASTS_PODCAST_CREATORS',
  PodcastsProductionCompanies = 'PODCASTS_PRODUCTION_COMPANIES',
  SocialMediaContentBrands = 'SOCIAL_MEDIA_CONTENT_BRANDS',
  SocialMediaContentContentCreators = 'SOCIAL_MEDIA_CONTENT_CONTENT_CREATORS',
  SocialMediaContentInfluencerAgencies = 'SOCIAL_MEDIA_CONTENT_INFLUENCER_AGENCIES',
  SocialMediaContentPlatforms = 'SOCIAL_MEDIA_CONTENT_PLATFORMS',
  SocialMediaContentRightsManagementServices = 'SOCIAL_MEDIA_CONTENT_RIGHTS_MANAGEMENT_SERVICES'
}

export type OwnerUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type OwnerUpdateInput = {
  account?: InputMaybe<OwnerAccountUpdateFieldInput>;
  channels?: InputMaybe<Array<OwnerChannelsUpdateFieldInput>>;
  contacts?: InputMaybe<Array<OwnerContactsUpdateFieldInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  editions?: InputMaybe<Array<OwnerEditionsUpdateFieldInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameLC?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<OwnerTagsUpdateFieldInput>>;
  type?: InputMaybe<OwnerTypes>;
  url?: InputMaybe<Scalars['String']['input']>;
  works?: InputMaybe<Array<OwnerWorksUpdateFieldInput>>;
};

export type OwnerWhere = {
  AND?: InputMaybe<Array<OwnerWhere>>;
  NOT?: InputMaybe<OwnerWhere>;
  OR?: InputMaybe<Array<OwnerWhere>>;
  account?: InputMaybe<AccountWhere>;
  accountAggregate?: InputMaybe<OwnerAccountAggregateInput>;
  accountConnection?: InputMaybe<OwnerAccountConnectionWhere>;
  accountConnection_NOT?: InputMaybe<OwnerAccountConnectionWhere>;
  account_NOT?: InputMaybe<AccountWhere>;
  channelsAggregate?: InputMaybe<OwnerChannelsAggregateInput>;
  /** Return Owners where all of the related OwnerChannelsConnections match this filter */
  channelsConnection_ALL?: InputMaybe<OwnerChannelsConnectionWhere>;
  /** Return Owners where none of the related OwnerChannelsConnections match this filter */
  channelsConnection_NONE?: InputMaybe<OwnerChannelsConnectionWhere>;
  /** Return Owners where one of the related OwnerChannelsConnections match this filter */
  channelsConnection_SINGLE?: InputMaybe<OwnerChannelsConnectionWhere>;
  /** Return Owners where some of the related OwnerChannelsConnections match this filter */
  channelsConnection_SOME?: InputMaybe<OwnerChannelsConnectionWhere>;
  /** Return Owners where all of the related Channels match this filter */
  channels_ALL?: InputMaybe<ChannelWhere>;
  /** Return Owners where none of the related Channels match this filter */
  channels_NONE?: InputMaybe<ChannelWhere>;
  /** Return Owners where one of the related Channels match this filter */
  channels_SINGLE?: InputMaybe<ChannelWhere>;
  /** Return Owners where some of the related Channels match this filter */
  channels_SOME?: InputMaybe<ChannelWhere>;
  contactsAggregate?: InputMaybe<OwnerContactsAggregateInput>;
  /** Return Owners where all of the related OwnerContactsConnections match this filter */
  contactsConnection_ALL?: InputMaybe<OwnerContactsConnectionWhere>;
  /** Return Owners where none of the related OwnerContactsConnections match this filter */
  contactsConnection_NONE?: InputMaybe<OwnerContactsConnectionWhere>;
  /** Return Owners where one of the related OwnerContactsConnections match this filter */
  contactsConnection_SINGLE?: InputMaybe<OwnerContactsConnectionWhere>;
  /** Return Owners where some of the related OwnerContactsConnections match this filter */
  contactsConnection_SOME?: InputMaybe<OwnerContactsConnectionWhere>;
  /** Return Owners where all of the related OwnerContacts match this filter */
  contacts_ALL?: InputMaybe<OwnerContactWhere>;
  /** Return Owners where none of the related OwnerContacts match this filter */
  contacts_NONE?: InputMaybe<OwnerContactWhere>;
  /** Return Owners where one of the related OwnerContacts match this filter */
  contacts_SINGLE?: InputMaybe<OwnerContactWhere>;
  /** Return Owners where some of the related OwnerContacts match this filter */
  contacts_SOME?: InputMaybe<OwnerContactWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  editionsAggregate?: InputMaybe<OwnerEditionsAggregateInput>;
  /** Return Owners where all of the related OwnerEditionsConnections match this filter */
  editionsConnection_ALL?: InputMaybe<OwnerEditionsConnectionWhere>;
  /** Return Owners where none of the related OwnerEditionsConnections match this filter */
  editionsConnection_NONE?: InputMaybe<OwnerEditionsConnectionWhere>;
  /** Return Owners where one of the related OwnerEditionsConnections match this filter */
  editionsConnection_SINGLE?: InputMaybe<OwnerEditionsConnectionWhere>;
  /** Return Owners where some of the related OwnerEditionsConnections match this filter */
  editionsConnection_SOME?: InputMaybe<OwnerEditionsConnectionWhere>;
  /** Return Owners where all of the related Editions match this filter */
  editions_ALL?: InputMaybe<EditionWhere>;
  /** Return Owners where none of the related Editions match this filter */
  editions_NONE?: InputMaybe<EditionWhere>;
  /** Return Owners where one of the related Editions match this filter */
  editions_SINGLE?: InputMaybe<EditionWhere>;
  /** Return Owners where some of the related Editions match this filter */
  editions_SOME?: InputMaybe<EditionWhere>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameLC?: InputMaybe<Scalars['String']['input']>;
  nameLC_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nameLC_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nameLC_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nameLC_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  name_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  name_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nutshellContactId_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nutshellLeadId_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nutshellLeadNumber_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  profilePicture_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  profilePicture_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  profilePicture_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  profilePicture_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  tagsAggregate?: InputMaybe<OwnerTagsAggregateInput>;
  /** Return Owners where all of the related OwnerTagsConnections match this filter */
  tagsConnection_ALL?: InputMaybe<OwnerTagsConnectionWhere>;
  /** Return Owners where none of the related OwnerTagsConnections match this filter */
  tagsConnection_NONE?: InputMaybe<OwnerTagsConnectionWhere>;
  /** Return Owners where one of the related OwnerTagsConnections match this filter */
  tagsConnection_SINGLE?: InputMaybe<OwnerTagsConnectionWhere>;
  /** Return Owners where some of the related OwnerTagsConnections match this filter */
  tagsConnection_SOME?: InputMaybe<OwnerTagsConnectionWhere>;
  /** Return Owners where all of the related Tags match this filter */
  tags_ALL?: InputMaybe<TagWhere>;
  /** Return Owners where none of the related Tags match this filter */
  tags_NONE?: InputMaybe<TagWhere>;
  /** Return Owners where one of the related Tags match this filter */
  tags_SINGLE?: InputMaybe<TagWhere>;
  /** Return Owners where some of the related Tags match this filter */
  tags_SOME?: InputMaybe<TagWhere>;
  type?: InputMaybe<OwnerTypes>;
  type_IN?: InputMaybe<Array<InputMaybe<OwnerTypes>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  url_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  worksAggregate?: InputMaybe<OwnerWorksAggregateInput>;
  /** Return Owners where all of the related OwnerWorksConnections match this filter */
  worksConnection_ALL?: InputMaybe<OwnerWorksConnectionWhere>;
  /** Return Owners where none of the related OwnerWorksConnections match this filter */
  worksConnection_NONE?: InputMaybe<OwnerWorksConnectionWhere>;
  /** Return Owners where one of the related OwnerWorksConnections match this filter */
  worksConnection_SINGLE?: InputMaybe<OwnerWorksConnectionWhere>;
  /** Return Owners where some of the related OwnerWorksConnections match this filter */
  worksConnection_SOME?: InputMaybe<OwnerWorksConnectionWhere>;
  /** Return Owners where all of the related Works match this filter */
  works_ALL?: InputMaybe<WorkWhere>;
  /** Return Owners where none of the related Works match this filter */
  works_NONE?: InputMaybe<WorkWhere>;
  /** Return Owners where one of the related Works match this filter */
  works_SINGLE?: InputMaybe<WorkWhere>;
  /** Return Owners where some of the related Works match this filter */
  works_SOME?: InputMaybe<WorkWhere>;
};

export type OwnerWorkWorksAggregationSelection = {
  __typename?: 'OwnerWorkWorksAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<OwnerWorkWorksNodeAggregateSelection>;
};

export type OwnerWorkWorksNodeAggregateSelection = {
  __typename?: 'OwnerWorkWorksNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  workUrl: StringAggregateSelectionNullable;
};

export type OwnerWorksAggregateInput = {
  AND?: InputMaybe<Array<OwnerWorksAggregateInput>>;
  NOT?: InputMaybe<OwnerWorksAggregateInput>;
  OR?: InputMaybe<Array<OwnerWorksAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<OwnerWorksNodeAggregationWhereInput>;
};

export type OwnerWorksConnectFieldInput = {
  connect?: InputMaybe<Array<WorkConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<WorkConnectWhere>;
};

export type OwnerWorksConnectOrCreateFieldInput = {
  onCreate: OwnerWorksConnectOrCreateFieldInputOnCreate;
  where: WorkConnectOrCreateWhere;
};

export type OwnerWorksConnectOrCreateFieldInputOnCreate = {
  node: WorkOnCreateInput;
};

export type OwnerWorksConnection = {
  __typename?: 'OwnerWorksConnection';
  edges: Array<OwnerWorksRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OwnerWorksConnectionSort = {
  node?: InputMaybe<WorkSort>;
};

export type OwnerWorksConnectionWhere = {
  AND?: InputMaybe<Array<OwnerWorksConnectionWhere>>;
  NOT?: InputMaybe<OwnerWorksConnectionWhere>;
  OR?: InputMaybe<Array<OwnerWorksConnectionWhere>>;
  node?: InputMaybe<WorkWhere>;
};

export type OwnerWorksCreateFieldInput = {
  node: WorkCreateInput;
};

export type OwnerWorksDeleteFieldInput = {
  delete?: InputMaybe<WorkDeleteInput>;
  where?: InputMaybe<OwnerWorksConnectionWhere>;
};

export type OwnerWorksDisconnectFieldInput = {
  disconnect?: InputMaybe<WorkDisconnectInput>;
  where?: InputMaybe<OwnerWorksConnectionWhere>;
};

export type OwnerWorksFieldInput = {
  connect?: InputMaybe<Array<OwnerWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<OwnerWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<OwnerWorksCreateFieldInput>>;
};

export type OwnerWorksNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<OwnerWorksNodeAggregationWhereInput>>;
  NOT?: InputMaybe<OwnerWorksNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<OwnerWorksNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  workUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type OwnerWorksRelationship = {
  __typename?: 'OwnerWorksRelationship';
  cursor: Scalars['String']['output'];
  node: Work;
};

export type OwnerWorksUpdateConnectionInput = {
  node?: InputMaybe<WorkUpdateInput>;
};

export type OwnerWorksUpdateFieldInput = {
  connect?: InputMaybe<Array<OwnerWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<OwnerWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<OwnerWorksCreateFieldInput>>;
  delete?: InputMaybe<Array<OwnerWorksDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<OwnerWorksDisconnectFieldInput>>;
  update?: InputMaybe<OwnerWorksUpdateConnectionInput>;
  where?: InputMaybe<OwnerWorksConnectionWhere>;
};

export type OwnersConnection = {
  __typename?: 'OwnersConnection';
  edges: Array<OwnerEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Pagination information (Relay) */
export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ParsedPage = {
  __typename?: 'ParsedPage';
  description?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<UserMedia>>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum PlanTypes {
  Dnt = 'DNT',
  Premium = 'PREMIUM',
  Standard = 'STANDARD'
}

export type Query = {
  __typename?: 'Query';
  accountSubscriptions: Array<AccountSubscription>;
  accountSubscriptionsAggregate: AccountSubscriptionAggregateSelection;
  accountSubscriptionsConnection: AccountSubscriptionsConnection;
  accounts: Array<Account>;
  accountsAggregate: AccountAggregateSelection;
  accountsConnection: AccountsConnection;
  categoryTagRequests: Array<CategoryTagRequest>;
  categoryTagRequestsAggregate: CategoryTagRequestAggregateSelection;
  categoryTagRequestsConnection: CategoryTagRequestsConnection;
  categoryTags: Array<CategoryTag>;
  categoryTagsAggregate: CategoryTagAggregateSelection;
  categoryTagsConnection: CategoryTagsConnection;
  certificationRequests: Array<CertificationRequest>;
  certificationRequestsAggregate: CertificationRequestAggregateSelection;
  certificationRequestsConnection: CertificationRequestsConnection;
  certificationResponses: Array<CertificationResponse>;
  certificationResponsesAggregate: CertificationResponseAggregateSelection;
  certificationResponsesConnection: CertificationResponsesConnection;
  channels: Array<Channel>;
  channelsAggregate: ChannelAggregateSelection;
  channelsConnection: ChannelsConnection;
  contentBadges: Array<ContentBadge>;
  contentBadgesAggregate: ContentBadgeAggregateSelection;
  contentBadgesConnection: ContentBadgesConnection;
  creatorContacts: Array<CreatorContact>;
  creatorContactsAggregate: CreatorContactAggregateSelection;
  creatorContactsConnection: CreatorContactsConnection;
  creators: Array<Creator>;
  creatorsAggregate: CreatorAggregateSelection;
  creatorsConnection: CreatorsConnection;
  editions: Array<Edition>;
  editionsAggregate: EditionAggregateSelection;
  editionsConnection: EditionsConnection;
  feedbacks: Array<Feedback>;
  feedbacksAggregate: FeedbackAggregateSelection;
  feedbacksConnection: FeedbacksConnection;
  me?: Maybe<User>;
  ownerContacts: Array<OwnerContact>;
  ownerContactsAggregate: OwnerContactAggregateSelection;
  ownerContactsConnection: OwnerContactsConnection;
  owners: Array<Owner>;
  ownersAggregate: OwnerAggregateSelection;
  ownersConnection: OwnersConnection;
  subscribeAccountResponses: Array<SubscribeAccountResponse>;
  subscribeAccountResponsesConnection: SubscribeAccountResponsesConnection;
  systemStatus: SystemStatus;
  tags: Array<Tag>;
  tagsAggregate: TagAggregateSelection;
  tagsConnection: TagsConnection;
  typeTags: Array<TypeTag>;
  typeTagsAggregate: TypeTagAggregateSelection;
  typeTagsConnection: TypeTagsConnection;
  userMedias: Array<UserMedia>;
  userMediasAggregate: UserMediaAggregateSelection;
  userMediasConnection: UserMediasConnection;
  users: Array<User>;
  usersConnection: UsersConnection;
  works: Array<Work>;
  worksAggregate: WorkAggregateSelection;
  worksConnection: WorksConnection;
};


export type QueryAccountSubscriptionsArgs = {
  options?: InputMaybe<AccountSubscriptionOptions>;
  where?: InputMaybe<AccountSubscriptionWhere>;
};


export type QueryAccountSubscriptionsAggregateArgs = {
  where?: InputMaybe<AccountSubscriptionWhere>;
};


export type QueryAccountSubscriptionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<AccountSubscriptionSort>>>;
  where?: InputMaybe<AccountSubscriptionWhere>;
};


export type QueryAccountsArgs = {
  options?: InputMaybe<AccountOptions>;
  where?: InputMaybe<AccountWhere>;
};


export type QueryAccountsAggregateArgs = {
  where?: InputMaybe<AccountWhere>;
};


export type QueryAccountsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<AccountSort>>>;
  where?: InputMaybe<AccountWhere>;
};


export type QueryCategoryTagRequestsArgs = {
  options?: InputMaybe<CategoryTagRequestOptions>;
  where?: InputMaybe<CategoryTagRequestWhere>;
};


export type QueryCategoryTagRequestsAggregateArgs = {
  where?: InputMaybe<CategoryTagRequestWhere>;
};


export type QueryCategoryTagRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<CategoryTagRequestSort>>>;
  where?: InputMaybe<CategoryTagRequestWhere>;
};


export type QueryCategoryTagsArgs = {
  options?: InputMaybe<CategoryTagOptions>;
  where?: InputMaybe<CategoryTagWhere>;
};


export type QueryCategoryTagsAggregateArgs = {
  where?: InputMaybe<CategoryTagWhere>;
};


export type QueryCategoryTagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<CategoryTagSort>>>;
  where?: InputMaybe<CategoryTagWhere>;
};


export type QueryCertificationRequestsArgs = {
  options?: InputMaybe<CertificationRequestOptions>;
  where?: InputMaybe<CertificationRequestWhere>;
};


export type QueryCertificationRequestsAggregateArgs = {
  where?: InputMaybe<CertificationRequestWhere>;
};


export type QueryCertificationRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<CertificationRequestSort>>>;
  where?: InputMaybe<CertificationRequestWhere>;
};


export type QueryCertificationResponsesArgs = {
  options?: InputMaybe<CertificationResponseOptions>;
  where?: InputMaybe<CertificationResponseWhere>;
};


export type QueryCertificationResponsesAggregateArgs = {
  where?: InputMaybe<CertificationResponseWhere>;
};


export type QueryCertificationResponsesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<CertificationResponseSort>>>;
  where?: InputMaybe<CertificationResponseWhere>;
};


export type QueryChannelsArgs = {
  options?: InputMaybe<ChannelOptions>;
  where?: InputMaybe<ChannelWhere>;
};


export type QueryChannelsAggregateArgs = {
  where?: InputMaybe<ChannelWhere>;
};


export type QueryChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<ChannelSort>>>;
  where?: InputMaybe<ChannelWhere>;
};


export type QueryContentBadgesArgs = {
  options?: InputMaybe<ContentBadgeOptions>;
  where?: InputMaybe<ContentBadgeWhere>;
};


export type QueryContentBadgesAggregateArgs = {
  where?: InputMaybe<ContentBadgeWhere>;
};


export type QueryContentBadgesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<ContentBadgeSort>>>;
  where?: InputMaybe<ContentBadgeWhere>;
};


export type QueryCreatorContactsArgs = {
  options?: InputMaybe<CreatorContactOptions>;
  where?: InputMaybe<CreatorContactWhere>;
};


export type QueryCreatorContactsAggregateArgs = {
  where?: InputMaybe<CreatorContactWhere>;
};


export type QueryCreatorContactsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<CreatorContactSort>>>;
  where?: InputMaybe<CreatorContactWhere>;
};


export type QueryCreatorsArgs = {
  options?: InputMaybe<CreatorOptions>;
  where?: InputMaybe<CreatorWhere>;
};


export type QueryCreatorsAggregateArgs = {
  where?: InputMaybe<CreatorWhere>;
};


export type QueryCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<CreatorSort>>>;
  where?: InputMaybe<CreatorWhere>;
};


export type QueryEditionsArgs = {
  options?: InputMaybe<EditionOptions>;
  where?: InputMaybe<EditionWhere>;
};


export type QueryEditionsAggregateArgs = {
  where?: InputMaybe<EditionWhere>;
};


export type QueryEditionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<EditionSort>>>;
  where?: InputMaybe<EditionWhere>;
};


export type QueryFeedbacksArgs = {
  options?: InputMaybe<FeedbackOptions>;
  where?: InputMaybe<FeedbackWhere>;
};


export type QueryFeedbacksAggregateArgs = {
  where?: InputMaybe<FeedbackWhere>;
};


export type QueryFeedbacksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<FeedbackSort>>>;
  where?: InputMaybe<FeedbackWhere>;
};


export type QueryOwnerContactsArgs = {
  options?: InputMaybe<OwnerContactOptions>;
  where?: InputMaybe<OwnerContactWhere>;
};


export type QueryOwnerContactsAggregateArgs = {
  where?: InputMaybe<OwnerContactWhere>;
};


export type QueryOwnerContactsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<OwnerContactSort>>>;
  where?: InputMaybe<OwnerContactWhere>;
};


export type QueryOwnersArgs = {
  options?: InputMaybe<OwnerOptions>;
  where?: InputMaybe<OwnerWhere>;
};


export type QueryOwnersAggregateArgs = {
  where?: InputMaybe<OwnerWhere>;
};


export type QueryOwnersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<OwnerSort>>>;
  where?: InputMaybe<OwnerWhere>;
};


export type QuerySubscribeAccountResponsesArgs = {
  options?: InputMaybe<SubscribeAccountResponseOptions>;
  where?: InputMaybe<SubscribeAccountResponseWhere>;
};


export type QuerySubscribeAccountResponsesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SubscribeAccountResponseSort>>>;
  where?: InputMaybe<SubscribeAccountResponseWhere>;
};


export type QueryTagsArgs = {
  options?: InputMaybe<TagOptions>;
  where?: InputMaybe<TagWhere>;
};


export type QueryTagsAggregateArgs = {
  where?: InputMaybe<TagWhere>;
};


export type QueryTagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<TagSort>>>;
  where?: InputMaybe<TagWhere>;
};


export type QueryTypeTagsArgs = {
  options?: InputMaybe<TypeTagOptions>;
  where?: InputMaybe<TypeTagWhere>;
};


export type QueryTypeTagsAggregateArgs = {
  where?: InputMaybe<TypeTagWhere>;
};


export type QueryTypeTagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<TypeTagSort>>>;
  where?: InputMaybe<TypeTagWhere>;
};


export type QueryUserMediasArgs = {
  options?: InputMaybe<UserMediaOptions>;
  where?: InputMaybe<UserMediaWhere>;
};


export type QueryUserMediasAggregateArgs = {
  where?: InputMaybe<UserMediaWhere>;
};


export type QueryUserMediasConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<UserMediaSort>>>;
  where?: InputMaybe<UserMediaWhere>;
};


export type QueryUsersArgs = {
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};


export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<UserSort>>>;
  where?: InputMaybe<UserWhere>;
};


export type QueryWorksArgs = {
  options?: InputMaybe<WorkOptions>;
  where?: InputMaybe<WorkWhere>;
};


export type QueryWorksAggregateArgs = {
  where?: InputMaybe<WorkWhere>;
};


export type QueryWorksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<WorkSort>>>;
  where?: InputMaybe<WorkWhere>;
};

export enum ReactionType {
  Dislike = 'DISLIKE',
  Like = 'LIKE',
  Neutral = 'NEUTRAL'
}

export enum RequestStatuses {
  RequestApproved = 'REQUEST_APPROVED',
  RequestCreating = 'REQUEST_CREATING',
  RequestRejected = 'REQUEST_REJECTED',
  RequestRevised = 'REQUEST_REVISED',
  RequestSubmitted = 'REQUEST_SUBMITTED'
}

export enum ResponseStatuses {
  ResponseError = 'RESPONSE_ERROR',
  ResponsePending = 'RESPONSE_PENDING',
  ResponseReceived = 'RESPONSE_RECEIVED'
}

export type ServerStatus = {
  __typename?: 'ServerStatus';
  buildTime?: Maybe<Scalars['String']['output']>;
  commitHash?: Maybe<Scalars['String']['output']>;
};

export enum ServiceProviders {
  Provider_1 = 'PROVIDER_1',
  Provider_2 = 'PROVIDER_2',
  Provider_3 = 'PROVIDER_3',
  Provider_4 = 'PROVIDER_4'
}

/** An enum for sorting in either ascending or descending order. */
export enum SortDirection {
  /** Sort by field values in ascending order. */
  Asc = 'ASC',
  /** Sort by field values in descending order. */
  Desc = 'DESC'
}

export type StringAggregateSelectionNullable = {
  __typename?: 'StringAggregateSelectionNullable';
  longest?: Maybe<Scalars['String']['output']>;
  shortest?: Maybe<Scalars['String']['output']>;
};

export type SubscribeAccountResponse = {
  __typename?: 'SubscribeAccountResponse';
  /** Stripe subscription client secret. https://docs.stripe.com/api/payment_intents/object#payment_intent_object-client_secret */
  clientSecret?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  /** Stripe subscription ID */
  subscriptionId: Scalars['String']['output'];
};

export type SubscribeAccountResponseEdge = {
  __typename?: 'SubscribeAccountResponseEdge';
  cursor: Scalars['String']['output'];
  node: SubscribeAccountResponse;
};

export type SubscribeAccountResponseOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more SubscribeAccountResponseSort objects to sort SubscribeAccountResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SubscribeAccountResponseSort>>;
};

/** Fields to sort SubscribeAccountResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubscribeAccountResponseSort object. */
export type SubscribeAccountResponseSort = {
  clientSecret?: InputMaybe<SortDirection>;
  error?: InputMaybe<SortDirection>;
  subscriptionId?: InputMaybe<SortDirection>;
};

export type SubscribeAccountResponseWhere = {
  AND?: InputMaybe<Array<SubscribeAccountResponseWhere>>;
  NOT?: InputMaybe<SubscribeAccountResponseWhere>;
  OR?: InputMaybe<Array<SubscribeAccountResponseWhere>>;
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  clientSecret_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  clientSecret_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  clientSecret_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientSecret_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  error_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  error_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  error_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  error_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  subscriptionId_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  subscriptionId_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  subscriptionId_IN?: InputMaybe<Array<Scalars['String']['input']>>;
  subscriptionId_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
};

export type SubscribeAccountResponsesConnection = {
  __typename?: 'SubscribeAccountResponsesConnection';
  edges: Array<SubscribeAccountResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SystemStatus = {
  __typename?: 'SystemStatus';
  apolloGraphql: ServerStatus;
};

/** Freeform Tags */
export type Tag = {
  __typename?: 'Tag';
  /** Tag is blocked */
  blocked?: Maybe<Scalars['Boolean']['output']>;
  /** Channels with this tag */
  channels: Array<Channel>;
  channelsAggregate?: Maybe<TagChannelChannelsAggregationSelection>;
  channelsConnection: TagChannelsConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Creators with this tag */
  creators: Array<Creator>;
  creatorsAggregate?: Maybe<TagCreatorCreatorsAggregationSelection>;
  creatorsConnection: TagCreatorsConnection;
  /** Code generated GUID */
  id: Scalars['ID']['output'];
  /** Rights owner with this tag */
  owners: Array<Owner>;
  ownersAggregate?: Maybe<TagOwnerOwnersAggregationSelection>;
  ownersConnection: TagOwnersConnection;
  /** Tag Title */
  title?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Works with this tag */
  works: Array<Work>;
  worksAggregate?: Maybe<TagWorkWorksAggregationSelection>;
  worksConnection: TagWorksConnection;
};


/** Freeform Tags */
export type TagChannelsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<ChannelOptions>;
  where?: InputMaybe<ChannelWhere>;
};


/** Freeform Tags */
export type TagChannelsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ChannelWhere>;
};


/** Freeform Tags */
export type TagChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TagChannelsConnectionSort>>;
  where?: InputMaybe<TagChannelsConnectionWhere>;
};


/** Freeform Tags */
export type TagCreatorsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CreatorOptions>;
  where?: InputMaybe<CreatorWhere>;
};


/** Freeform Tags */
export type TagCreatorsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CreatorWhere>;
};


/** Freeform Tags */
export type TagCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TagCreatorsConnectionSort>>;
  where?: InputMaybe<TagCreatorsConnectionWhere>;
};


/** Freeform Tags */
export type TagOwnersArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<OwnerOptions>;
  where?: InputMaybe<OwnerWhere>;
};


/** Freeform Tags */
export type TagOwnersAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<OwnerWhere>;
};


/** Freeform Tags */
export type TagOwnersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TagOwnersConnectionSort>>;
  where?: InputMaybe<TagOwnersConnectionWhere>;
};


/** Freeform Tags */
export type TagWorksArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<WorkOptions>;
  where?: InputMaybe<WorkWhere>;
};


/** Freeform Tags */
export type TagWorksAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<WorkWhere>;
};


/** Freeform Tags */
export type TagWorksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TagWorksConnectionSort>>;
  where?: InputMaybe<TagWorksConnectionWhere>;
};

export type TagAggregateSelection = {
  __typename?: 'TagAggregateSelection';
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type TagChannelChannelsAggregationSelection = {
  __typename?: 'TagChannelChannelsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<TagChannelChannelsNodeAggregateSelection>;
};

export type TagChannelChannelsNodeAggregateSelection = {
  __typename?: 'TagChannelChannelsNodeAggregateSelection';
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type TagChannelsAggregateInput = {
  AND?: InputMaybe<Array<TagChannelsAggregateInput>>;
  NOT?: InputMaybe<TagChannelsAggregateInput>;
  OR?: InputMaybe<Array<TagChannelsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<TagChannelsNodeAggregationWhereInput>;
};

export type TagChannelsConnectFieldInput = {
  connect?: InputMaybe<Array<ChannelConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<ChannelConnectWhere>;
};

export type TagChannelsConnectOrCreateFieldInput = {
  onCreate: TagChannelsConnectOrCreateFieldInputOnCreate;
  where: ChannelConnectOrCreateWhere;
};

export type TagChannelsConnectOrCreateFieldInputOnCreate = {
  node: ChannelOnCreateInput;
};

export type TagChannelsConnection = {
  __typename?: 'TagChannelsConnection';
  edges: Array<TagChannelsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TagChannelsConnectionSort = {
  node?: InputMaybe<ChannelSort>;
};

export type TagChannelsConnectionWhere = {
  AND?: InputMaybe<Array<TagChannelsConnectionWhere>>;
  NOT?: InputMaybe<TagChannelsConnectionWhere>;
  OR?: InputMaybe<Array<TagChannelsConnectionWhere>>;
  node?: InputMaybe<ChannelWhere>;
};

export type TagChannelsCreateFieldInput = {
  node: ChannelCreateInput;
};

export type TagChannelsDeleteFieldInput = {
  delete?: InputMaybe<ChannelDeleteInput>;
  where?: InputMaybe<TagChannelsConnectionWhere>;
};

export type TagChannelsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChannelDisconnectInput>;
  where?: InputMaybe<TagChannelsConnectionWhere>;
};

export type TagChannelsFieldInput = {
  connect?: InputMaybe<Array<TagChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TagChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TagChannelsCreateFieldInput>>;
};

export type TagChannelsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TagChannelsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TagChannelsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TagChannelsNodeAggregationWhereInput>>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TagChannelsRelationship = {
  __typename?: 'TagChannelsRelationship';
  cursor: Scalars['String']['output'];
  node: Channel;
};

export type TagChannelsUpdateConnectionInput = {
  node?: InputMaybe<ChannelUpdateInput>;
};

export type TagChannelsUpdateFieldInput = {
  connect?: InputMaybe<Array<TagChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TagChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TagChannelsCreateFieldInput>>;
  delete?: InputMaybe<Array<TagChannelsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TagChannelsDisconnectFieldInput>>;
  update?: InputMaybe<TagChannelsUpdateConnectionInput>;
  where?: InputMaybe<TagChannelsConnectionWhere>;
};

export type TagConnectInput = {
  channels?: InputMaybe<Array<TagChannelsConnectFieldInput>>;
  creators?: InputMaybe<Array<TagCreatorsConnectFieldInput>>;
  owners?: InputMaybe<Array<TagOwnersConnectFieldInput>>;
  works?: InputMaybe<Array<TagWorksConnectFieldInput>>;
};

export type TagConnectOrCreateInput = {
  channels?: InputMaybe<Array<TagChannelsConnectOrCreateFieldInput>>;
  creators?: InputMaybe<Array<TagCreatorsConnectOrCreateFieldInput>>;
  owners?: InputMaybe<Array<TagOwnersConnectOrCreateFieldInput>>;
  works?: InputMaybe<Array<TagWorksConnectOrCreateFieldInput>>;
};

export type TagConnectOrCreateWhere = {
  node: TagUniqueWhere;
};

export type TagConnectWhere = {
  node: TagWhere;
};

export type TagCreateInput = {
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  channels?: InputMaybe<TagChannelsFieldInput>;
  creators?: InputMaybe<TagCreatorsFieldInput>;
  id: Scalars['ID']['input'];
  owners?: InputMaybe<TagOwnersFieldInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  works?: InputMaybe<TagWorksFieldInput>;
};

export type TagCreatorCreatorsAggregationSelection = {
  __typename?: 'TagCreatorCreatorsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<TagCreatorCreatorsNodeAggregateSelection>;
};

export type TagCreatorCreatorsNodeAggregateSelection = {
  __typename?: 'TagCreatorCreatorsNodeAggregateSelection';
  bannerImage: StringAggregateSelectionNullable;
  bio: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  creatorID: StringAggregateSelectionNullable;
  dateOfBirth: StringAggregateSelectionNullable;
  firstName: StringAggregateSelectionNullable;
  firstNameLC: StringAggregateSelectionNullable;
  genderOther: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  lastName: StringAggregateSelectionNullable;
  lastNameLC: StringAggregateSelectionNullable;
  middleName: StringAggregateSelectionNullable;
  nationality: StringAggregateSelectionNullable;
  nicknames: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  pronouns: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type TagCreatorsAggregateInput = {
  AND?: InputMaybe<Array<TagCreatorsAggregateInput>>;
  NOT?: InputMaybe<TagCreatorsAggregateInput>;
  OR?: InputMaybe<Array<TagCreatorsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<TagCreatorsNodeAggregationWhereInput>;
};

export type TagCreatorsConnectFieldInput = {
  connect?: InputMaybe<Array<CreatorConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CreatorConnectWhere>;
};

export type TagCreatorsConnectOrCreateFieldInput = {
  onCreate: TagCreatorsConnectOrCreateFieldInputOnCreate;
  where: CreatorConnectOrCreateWhere;
};

export type TagCreatorsConnectOrCreateFieldInputOnCreate = {
  node: CreatorOnCreateInput;
};

export type TagCreatorsConnection = {
  __typename?: 'TagCreatorsConnection';
  edges: Array<TagCreatorsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TagCreatorsConnectionSort = {
  node?: InputMaybe<CreatorSort>;
};

export type TagCreatorsConnectionWhere = {
  AND?: InputMaybe<Array<TagCreatorsConnectionWhere>>;
  NOT?: InputMaybe<TagCreatorsConnectionWhere>;
  OR?: InputMaybe<Array<TagCreatorsConnectionWhere>>;
  node?: InputMaybe<CreatorWhere>;
};

export type TagCreatorsCreateFieldInput = {
  node: CreatorCreateInput;
};

export type TagCreatorsDeleteFieldInput = {
  delete?: InputMaybe<CreatorDeleteInput>;
  where?: InputMaybe<TagCreatorsConnectionWhere>;
};

export type TagCreatorsDisconnectFieldInput = {
  disconnect?: InputMaybe<CreatorDisconnectInput>;
  where?: InputMaybe<TagCreatorsConnectionWhere>;
};

export type TagCreatorsFieldInput = {
  connect?: InputMaybe<Array<TagCreatorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TagCreatorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TagCreatorsCreateFieldInput>>;
};

export type TagCreatorsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TagCreatorsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TagCreatorsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TagCreatorsNodeAggregationWhereInput>>;
  bannerImage_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bio_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  bio_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creatorID_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  creatorID_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  firstName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  genderOther_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  middleName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nationality_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nicknames_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  pronouns_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TagCreatorsRelationship = {
  __typename?: 'TagCreatorsRelationship';
  cursor: Scalars['String']['output'];
  node: Creator;
};

export type TagCreatorsUpdateConnectionInput = {
  node?: InputMaybe<CreatorUpdateInput>;
};

export type TagCreatorsUpdateFieldInput = {
  connect?: InputMaybe<Array<TagCreatorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TagCreatorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TagCreatorsCreateFieldInput>>;
  delete?: InputMaybe<Array<TagCreatorsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TagCreatorsDisconnectFieldInput>>;
  update?: InputMaybe<TagCreatorsUpdateConnectionInput>;
  where?: InputMaybe<TagCreatorsConnectionWhere>;
};

export type TagDeleteInput = {
  channels?: InputMaybe<Array<TagChannelsDeleteFieldInput>>;
  creators?: InputMaybe<Array<TagCreatorsDeleteFieldInput>>;
  owners?: InputMaybe<Array<TagOwnersDeleteFieldInput>>;
  works?: InputMaybe<Array<TagWorksDeleteFieldInput>>;
};

export type TagDisconnectInput = {
  channels?: InputMaybe<Array<TagChannelsDisconnectFieldInput>>;
  creators?: InputMaybe<Array<TagCreatorsDisconnectFieldInput>>;
  owners?: InputMaybe<Array<TagOwnersDisconnectFieldInput>>;
  works?: InputMaybe<Array<TagWorksDisconnectFieldInput>>;
};

export type TagEdge = {
  __typename?: 'TagEdge';
  cursor: Scalars['String']['output'];
  node: Tag;
};

export type TagOnCreateInput = {
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type TagOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more TagSort objects to sort Tags by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TagSort>>;
};

export type TagOwnerOwnersAggregationSelection = {
  __typename?: 'TagOwnerOwnersAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<TagOwnerOwnersNodeAggregateSelection>;
};

export type TagOwnerOwnersNodeAggregateSelection = {
  __typename?: 'TagOwnerOwnersNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  nameLC: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type TagOwnersAggregateInput = {
  AND?: InputMaybe<Array<TagOwnersAggregateInput>>;
  NOT?: InputMaybe<TagOwnersAggregateInput>;
  OR?: InputMaybe<Array<TagOwnersAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<TagOwnersNodeAggregationWhereInput>;
};

export type TagOwnersConnectFieldInput = {
  connect?: InputMaybe<Array<OwnerConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<OwnerConnectWhere>;
};

export type TagOwnersConnectOrCreateFieldInput = {
  onCreate: TagOwnersConnectOrCreateFieldInputOnCreate;
  where: OwnerConnectOrCreateWhere;
};

export type TagOwnersConnectOrCreateFieldInputOnCreate = {
  node: OwnerOnCreateInput;
};

export type TagOwnersConnection = {
  __typename?: 'TagOwnersConnection';
  edges: Array<TagOwnersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TagOwnersConnectionSort = {
  node?: InputMaybe<OwnerSort>;
};

export type TagOwnersConnectionWhere = {
  AND?: InputMaybe<Array<TagOwnersConnectionWhere>>;
  NOT?: InputMaybe<TagOwnersConnectionWhere>;
  OR?: InputMaybe<Array<TagOwnersConnectionWhere>>;
  node?: InputMaybe<OwnerWhere>;
};

export type TagOwnersCreateFieldInput = {
  node: OwnerCreateInput;
};

export type TagOwnersDeleteFieldInput = {
  delete?: InputMaybe<OwnerDeleteInput>;
  where?: InputMaybe<TagOwnersConnectionWhere>;
};

export type TagOwnersDisconnectFieldInput = {
  disconnect?: InputMaybe<OwnerDisconnectInput>;
  where?: InputMaybe<TagOwnersConnectionWhere>;
};

export type TagOwnersFieldInput = {
  connect?: InputMaybe<Array<TagOwnersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TagOwnersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TagOwnersCreateFieldInput>>;
};

export type TagOwnersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TagOwnersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TagOwnersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TagOwnersNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  nameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type TagOwnersRelationship = {
  __typename?: 'TagOwnersRelationship';
  cursor: Scalars['String']['output'];
  node: Owner;
};

export type TagOwnersUpdateConnectionInput = {
  node?: InputMaybe<OwnerUpdateInput>;
};

export type TagOwnersUpdateFieldInput = {
  connect?: InputMaybe<Array<TagOwnersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TagOwnersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TagOwnersCreateFieldInput>>;
  delete?: InputMaybe<Array<TagOwnersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TagOwnersDisconnectFieldInput>>;
  update?: InputMaybe<TagOwnersUpdateConnectionInput>;
  where?: InputMaybe<TagOwnersConnectionWhere>;
};

export type TagRelationInput = {
  channels?: InputMaybe<Array<TagChannelsCreateFieldInput>>;
  creators?: InputMaybe<Array<TagCreatorsCreateFieldInput>>;
  owners?: InputMaybe<Array<TagOwnersCreateFieldInput>>;
  works?: InputMaybe<Array<TagWorksCreateFieldInput>>;
};

/** Fields to sort Tags by. The order in which sorts are applied is not guaranteed when specifying many fields in one TagSort object. */
export type TagSort = {
  blocked?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type TagUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type TagUpdateInput = {
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  channels?: InputMaybe<Array<TagChannelsUpdateFieldInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creators?: InputMaybe<Array<TagCreatorsUpdateFieldInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  owners?: InputMaybe<Array<TagOwnersUpdateFieldInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  works?: InputMaybe<Array<TagWorksUpdateFieldInput>>;
};

export type TagWhere = {
  AND?: InputMaybe<Array<TagWhere>>;
  NOT?: InputMaybe<TagWhere>;
  OR?: InputMaybe<Array<TagWhere>>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  channelsAggregate?: InputMaybe<TagChannelsAggregateInput>;
  /** Return Tags where all of the related TagChannelsConnections match this filter */
  channelsConnection_ALL?: InputMaybe<TagChannelsConnectionWhere>;
  /** Return Tags where none of the related TagChannelsConnections match this filter */
  channelsConnection_NONE?: InputMaybe<TagChannelsConnectionWhere>;
  /** Return Tags where one of the related TagChannelsConnections match this filter */
  channelsConnection_SINGLE?: InputMaybe<TagChannelsConnectionWhere>;
  /** Return Tags where some of the related TagChannelsConnections match this filter */
  channelsConnection_SOME?: InputMaybe<TagChannelsConnectionWhere>;
  /** Return Tags where all of the related Channels match this filter */
  channels_ALL?: InputMaybe<ChannelWhere>;
  /** Return Tags where none of the related Channels match this filter */
  channels_NONE?: InputMaybe<ChannelWhere>;
  /** Return Tags where one of the related Channels match this filter */
  channels_SINGLE?: InputMaybe<ChannelWhere>;
  /** Return Tags where some of the related Channels match this filter */
  channels_SOME?: InputMaybe<ChannelWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creatorsAggregate?: InputMaybe<TagCreatorsAggregateInput>;
  /** Return Tags where all of the related TagCreatorsConnections match this filter */
  creatorsConnection_ALL?: InputMaybe<TagCreatorsConnectionWhere>;
  /** Return Tags where none of the related TagCreatorsConnections match this filter */
  creatorsConnection_NONE?: InputMaybe<TagCreatorsConnectionWhere>;
  /** Return Tags where one of the related TagCreatorsConnections match this filter */
  creatorsConnection_SINGLE?: InputMaybe<TagCreatorsConnectionWhere>;
  /** Return Tags where some of the related TagCreatorsConnections match this filter */
  creatorsConnection_SOME?: InputMaybe<TagCreatorsConnectionWhere>;
  /** Return Tags where all of the related Creators match this filter */
  creators_ALL?: InputMaybe<CreatorWhere>;
  /** Return Tags where none of the related Creators match this filter */
  creators_NONE?: InputMaybe<CreatorWhere>;
  /** Return Tags where one of the related Creators match this filter */
  creators_SINGLE?: InputMaybe<CreatorWhere>;
  /** Return Tags where some of the related Creators match this filter */
  creators_SOME?: InputMaybe<CreatorWhere>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  ownersAggregate?: InputMaybe<TagOwnersAggregateInput>;
  /** Return Tags where all of the related TagOwnersConnections match this filter */
  ownersConnection_ALL?: InputMaybe<TagOwnersConnectionWhere>;
  /** Return Tags where none of the related TagOwnersConnections match this filter */
  ownersConnection_NONE?: InputMaybe<TagOwnersConnectionWhere>;
  /** Return Tags where one of the related TagOwnersConnections match this filter */
  ownersConnection_SINGLE?: InputMaybe<TagOwnersConnectionWhere>;
  /** Return Tags where some of the related TagOwnersConnections match this filter */
  ownersConnection_SOME?: InputMaybe<TagOwnersConnectionWhere>;
  /** Return Tags where all of the related Owners match this filter */
  owners_ALL?: InputMaybe<OwnerWhere>;
  /** Return Tags where none of the related Owners match this filter */
  owners_NONE?: InputMaybe<OwnerWhere>;
  /** Return Tags where one of the related Owners match this filter */
  owners_SINGLE?: InputMaybe<OwnerWhere>;
  /** Return Tags where some of the related Owners match this filter */
  owners_SOME?: InputMaybe<OwnerWhere>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  title_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  title_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  worksAggregate?: InputMaybe<TagWorksAggregateInput>;
  /** Return Tags where all of the related TagWorksConnections match this filter */
  worksConnection_ALL?: InputMaybe<TagWorksConnectionWhere>;
  /** Return Tags where none of the related TagWorksConnections match this filter */
  worksConnection_NONE?: InputMaybe<TagWorksConnectionWhere>;
  /** Return Tags where one of the related TagWorksConnections match this filter */
  worksConnection_SINGLE?: InputMaybe<TagWorksConnectionWhere>;
  /** Return Tags where some of the related TagWorksConnections match this filter */
  worksConnection_SOME?: InputMaybe<TagWorksConnectionWhere>;
  /** Return Tags where all of the related Works match this filter */
  works_ALL?: InputMaybe<WorkWhere>;
  /** Return Tags where none of the related Works match this filter */
  works_NONE?: InputMaybe<WorkWhere>;
  /** Return Tags where one of the related Works match this filter */
  works_SINGLE?: InputMaybe<WorkWhere>;
  /** Return Tags where some of the related Works match this filter */
  works_SOME?: InputMaybe<WorkWhere>;
};

export type TagWorkWorksAggregationSelection = {
  __typename?: 'TagWorkWorksAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<TagWorkWorksNodeAggregateSelection>;
};

export type TagWorkWorksNodeAggregateSelection = {
  __typename?: 'TagWorkWorksNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  workUrl: StringAggregateSelectionNullable;
};

export type TagWorksAggregateInput = {
  AND?: InputMaybe<Array<TagWorksAggregateInput>>;
  NOT?: InputMaybe<TagWorksAggregateInput>;
  OR?: InputMaybe<Array<TagWorksAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<TagWorksNodeAggregationWhereInput>;
};

export type TagWorksConnectFieldInput = {
  connect?: InputMaybe<Array<WorkConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<WorkConnectWhere>;
};

export type TagWorksConnectOrCreateFieldInput = {
  onCreate: TagWorksConnectOrCreateFieldInputOnCreate;
  where: WorkConnectOrCreateWhere;
};

export type TagWorksConnectOrCreateFieldInputOnCreate = {
  node: WorkOnCreateInput;
};

export type TagWorksConnection = {
  __typename?: 'TagWorksConnection';
  edges: Array<TagWorksRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TagWorksConnectionSort = {
  node?: InputMaybe<WorkSort>;
};

export type TagWorksConnectionWhere = {
  AND?: InputMaybe<Array<TagWorksConnectionWhere>>;
  NOT?: InputMaybe<TagWorksConnectionWhere>;
  OR?: InputMaybe<Array<TagWorksConnectionWhere>>;
  node?: InputMaybe<WorkWhere>;
};

export type TagWorksCreateFieldInput = {
  node: WorkCreateInput;
};

export type TagWorksDeleteFieldInput = {
  delete?: InputMaybe<WorkDeleteInput>;
  where?: InputMaybe<TagWorksConnectionWhere>;
};

export type TagWorksDisconnectFieldInput = {
  disconnect?: InputMaybe<WorkDisconnectInput>;
  where?: InputMaybe<TagWorksConnectionWhere>;
};

export type TagWorksFieldInput = {
  connect?: InputMaybe<Array<TagWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TagWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TagWorksCreateFieldInput>>;
};

export type TagWorksNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TagWorksNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TagWorksNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TagWorksNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  workUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type TagWorksRelationship = {
  __typename?: 'TagWorksRelationship';
  cursor: Scalars['String']['output'];
  node: Work;
};

export type TagWorksUpdateConnectionInput = {
  node?: InputMaybe<WorkUpdateInput>;
};

export type TagWorksUpdateFieldInput = {
  connect?: InputMaybe<Array<TagWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TagWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TagWorksCreateFieldInput>>;
  delete?: InputMaybe<Array<TagWorksDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TagWorksDisconnectFieldInput>>;
  update?: InputMaybe<TagWorksUpdateConnectionInput>;
  where?: InputMaybe<TagWorksConnectionWhere>;
};

export type TagsConnection = {
  __typename?: 'TagsConnection';
  edges: Array<TagEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Content Types */
export type TypeTag = {
  __typename?: 'TypeTag';
  /** Certification Requests in this type */
  certificationRequests: Array<CertificationRequest>;
  certificationRequestsAggregate?: Maybe<TypeTagCertificationRequestCertificationRequestsAggregationSelection>;
  certificationRequestsConnection: TypeTagCertificationRequestsConnection;
  /** Channels in this type */
  channels: Array<Channel>;
  channelsAggregate?: Maybe<TypeTagChannelChannelsAggregationSelection>;
  channelsConnection: TypeTagChannelsConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Content Type Description */
  description?: Maybe<Scalars['String']['output']>;
  /** Editions in this type */
  editions: Array<Edition>;
  editionsAggregate?: Maybe<TypeTagEditionEditionsAggregationSelection>;
  editionsConnection: TypeTagEditionsConnection;
  /** Code generated GUID */
  id: Scalars['ID']['output'];
  /** Parent Content Type */
  parentTag: Array<TypeTag>;
  parentTagAggregate?: Maybe<TypeTagTypeTagParentTagAggregationSelection>;
  parentTagConnection: TypeTagParentTagConnection;
  /** Children Content Types */
  tags: Array<TypeTag>;
  tagsAggregate?: Maybe<TypeTagTypeTagTagsAggregationSelection>;
  tagsConnection: TypeTagTagsConnection;
  /** Content Type Tier (Root is 0) */
  tier?: Maybe<Scalars['Int']['output']>;
  /** Content Type Title */
  title?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Works in this type */
  works: Array<Work>;
  worksAggregate?: Maybe<TypeTagWorkWorksAggregationSelection>;
  worksConnection: TypeTagWorksConnection;
};


/** Content Types */
export type TypeTagCertificationRequestsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CertificationRequestOptions>;
  where?: InputMaybe<CertificationRequestWhere>;
};


/** Content Types */
export type TypeTagCertificationRequestsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CertificationRequestWhere>;
};


/** Content Types */
export type TypeTagCertificationRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TypeTagCertificationRequestsConnectionSort>>;
  where?: InputMaybe<TypeTagCertificationRequestsConnectionWhere>;
};


/** Content Types */
export type TypeTagChannelsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<ChannelOptions>;
  where?: InputMaybe<ChannelWhere>;
};


/** Content Types */
export type TypeTagChannelsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ChannelWhere>;
};


/** Content Types */
export type TypeTagChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TypeTagChannelsConnectionSort>>;
  where?: InputMaybe<TypeTagChannelsConnectionWhere>;
};


/** Content Types */
export type TypeTagEditionsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<EditionOptions>;
  where?: InputMaybe<EditionWhere>;
};


/** Content Types */
export type TypeTagEditionsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<EditionWhere>;
};


/** Content Types */
export type TypeTagEditionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TypeTagEditionsConnectionSort>>;
  where?: InputMaybe<TypeTagEditionsConnectionWhere>;
};


/** Content Types */
export type TypeTagParentTagArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TypeTagOptions>;
  where?: InputMaybe<TypeTagWhere>;
};


/** Content Types */
export type TypeTagParentTagAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TypeTagWhere>;
};


/** Content Types */
export type TypeTagParentTagConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TypeTagParentTagConnectionSort>>;
  where?: InputMaybe<TypeTagParentTagConnectionWhere>;
};


/** Content Types */
export type TypeTagTagsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TypeTagOptions>;
  where?: InputMaybe<TypeTagWhere>;
};


/** Content Types */
export type TypeTagTagsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TypeTagWhere>;
};


/** Content Types */
export type TypeTagTagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TypeTagTagsConnectionSort>>;
  where?: InputMaybe<TypeTagTagsConnectionWhere>;
};


/** Content Types */
export type TypeTagWorksArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<WorkOptions>;
  where?: InputMaybe<WorkWhere>;
};


/** Content Types */
export type TypeTagWorksAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<WorkWhere>;
};


/** Content Types */
export type TypeTagWorksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TypeTagWorksConnectionSort>>;
  where?: InputMaybe<TypeTagWorksConnectionWhere>;
};

export type TypeTagAggregateSelection = {
  __typename?: 'TypeTagAggregateSelection';
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type TypeTagCertificationRequestCertificationRequestsAggregationSelection = {
  __typename?: 'TypeTagCertificationRequestCertificationRequestsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<TypeTagCertificationRequestCertificationRequestsNodeAggregateSelection>;
};

export type TypeTagCertificationRequestCertificationRequestsNodeAggregateSelection = {
  __typename?: 'TypeTagCertificationRequestCertificationRequestsNodeAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  channelDescription: StringAggregateSelectionNullable;
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  email: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  name: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  requestSignature: StringAggregateSelectionNullable;
  result: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type TypeTagCertificationRequestsAggregateInput = {
  AND?: InputMaybe<Array<TypeTagCertificationRequestsAggregateInput>>;
  NOT?: InputMaybe<TypeTagCertificationRequestsAggregateInput>;
  OR?: InputMaybe<Array<TypeTagCertificationRequestsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<TypeTagCertificationRequestsNodeAggregationWhereInput>;
};

export type TypeTagCertificationRequestsConnectFieldInput = {
  connect?: InputMaybe<Array<CertificationRequestConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CertificationRequestConnectWhere>;
};

export type TypeTagCertificationRequestsConnectOrCreateFieldInput = {
  onCreate: TypeTagCertificationRequestsConnectOrCreateFieldInputOnCreate;
  where: CertificationRequestConnectOrCreateWhere;
};

export type TypeTagCertificationRequestsConnectOrCreateFieldInputOnCreate = {
  node: CertificationRequestOnCreateInput;
};

export type TypeTagCertificationRequestsConnection = {
  __typename?: 'TypeTagCertificationRequestsConnection';
  edges: Array<TypeTagCertificationRequestsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TypeTagCertificationRequestsConnectionSort = {
  node?: InputMaybe<CertificationRequestSort>;
};

export type TypeTagCertificationRequestsConnectionWhere = {
  AND?: InputMaybe<Array<TypeTagCertificationRequestsConnectionWhere>>;
  NOT?: InputMaybe<TypeTagCertificationRequestsConnectionWhere>;
  OR?: InputMaybe<Array<TypeTagCertificationRequestsConnectionWhere>>;
  node?: InputMaybe<CertificationRequestWhere>;
};

export type TypeTagCertificationRequestsCreateFieldInput = {
  node: CertificationRequestCreateInput;
};

export type TypeTagCertificationRequestsDeleteFieldInput = {
  delete?: InputMaybe<CertificationRequestDeleteInput>;
  where?: InputMaybe<TypeTagCertificationRequestsConnectionWhere>;
};

export type TypeTagCertificationRequestsDisconnectFieldInput = {
  disconnect?: InputMaybe<CertificationRequestDisconnectInput>;
  where?: InputMaybe<TypeTagCertificationRequestsConnectionWhere>;
};

export type TypeTagCertificationRequestsFieldInput = {
  connect?: InputMaybe<Array<TypeTagCertificationRequestsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TypeTagCertificationRequestsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TypeTagCertificationRequestsCreateFieldInput>>;
};

export type TypeTagCertificationRequestsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TypeTagCertificationRequestsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TypeTagCertificationRequestsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TypeTagCertificationRequestsNodeAggregationWhereInput>>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  result_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TypeTagCertificationRequestsRelationship = {
  __typename?: 'TypeTagCertificationRequestsRelationship';
  cursor: Scalars['String']['output'];
  node: CertificationRequest;
};

export type TypeTagCertificationRequestsUpdateConnectionInput = {
  node?: InputMaybe<CertificationRequestUpdateInput>;
};

export type TypeTagCertificationRequestsUpdateFieldInput = {
  connect?: InputMaybe<Array<TypeTagCertificationRequestsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TypeTagCertificationRequestsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TypeTagCertificationRequestsCreateFieldInput>>;
  delete?: InputMaybe<Array<TypeTagCertificationRequestsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TypeTagCertificationRequestsDisconnectFieldInput>>;
  update?: InputMaybe<TypeTagCertificationRequestsUpdateConnectionInput>;
  where?: InputMaybe<TypeTagCertificationRequestsConnectionWhere>;
};

export type TypeTagChannelChannelsAggregationSelection = {
  __typename?: 'TypeTagChannelChannelsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<TypeTagChannelChannelsNodeAggregateSelection>;
};

export type TypeTagChannelChannelsNodeAggregateSelection = {
  __typename?: 'TypeTagChannelChannelsNodeAggregateSelection';
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type TypeTagChannelsAggregateInput = {
  AND?: InputMaybe<Array<TypeTagChannelsAggregateInput>>;
  NOT?: InputMaybe<TypeTagChannelsAggregateInput>;
  OR?: InputMaybe<Array<TypeTagChannelsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<TypeTagChannelsNodeAggregationWhereInput>;
};

export type TypeTagChannelsConnectFieldInput = {
  connect?: InputMaybe<Array<ChannelConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<ChannelConnectWhere>;
};

export type TypeTagChannelsConnectOrCreateFieldInput = {
  onCreate: TypeTagChannelsConnectOrCreateFieldInputOnCreate;
  where: ChannelConnectOrCreateWhere;
};

export type TypeTagChannelsConnectOrCreateFieldInputOnCreate = {
  node: ChannelOnCreateInput;
};

export type TypeTagChannelsConnection = {
  __typename?: 'TypeTagChannelsConnection';
  edges: Array<TypeTagChannelsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TypeTagChannelsConnectionSort = {
  node?: InputMaybe<ChannelSort>;
};

export type TypeTagChannelsConnectionWhere = {
  AND?: InputMaybe<Array<TypeTagChannelsConnectionWhere>>;
  NOT?: InputMaybe<TypeTagChannelsConnectionWhere>;
  OR?: InputMaybe<Array<TypeTagChannelsConnectionWhere>>;
  node?: InputMaybe<ChannelWhere>;
};

export type TypeTagChannelsCreateFieldInput = {
  node: ChannelCreateInput;
};

export type TypeTagChannelsDeleteFieldInput = {
  delete?: InputMaybe<ChannelDeleteInput>;
  where?: InputMaybe<TypeTagChannelsConnectionWhere>;
};

export type TypeTagChannelsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChannelDisconnectInput>;
  where?: InputMaybe<TypeTagChannelsConnectionWhere>;
};

export type TypeTagChannelsFieldInput = {
  connect?: InputMaybe<Array<TypeTagChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TypeTagChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TypeTagChannelsCreateFieldInput>>;
};

export type TypeTagChannelsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TypeTagChannelsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TypeTagChannelsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TypeTagChannelsNodeAggregationWhereInput>>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TypeTagChannelsRelationship = {
  __typename?: 'TypeTagChannelsRelationship';
  cursor: Scalars['String']['output'];
  node: Channel;
};

export type TypeTagChannelsUpdateConnectionInput = {
  node?: InputMaybe<ChannelUpdateInput>;
};

export type TypeTagChannelsUpdateFieldInput = {
  connect?: InputMaybe<Array<TypeTagChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TypeTagChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TypeTagChannelsCreateFieldInput>>;
  delete?: InputMaybe<Array<TypeTagChannelsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TypeTagChannelsDisconnectFieldInput>>;
  update?: InputMaybe<TypeTagChannelsUpdateConnectionInput>;
  where?: InputMaybe<TypeTagChannelsConnectionWhere>;
};

export type TypeTagConnectInput = {
  certificationRequests?: InputMaybe<Array<TypeTagCertificationRequestsConnectFieldInput>>;
  channels?: InputMaybe<Array<TypeTagChannelsConnectFieldInput>>;
  editions?: InputMaybe<Array<TypeTagEditionsConnectFieldInput>>;
  parentTag?: InputMaybe<Array<TypeTagParentTagConnectFieldInput>>;
  tags?: InputMaybe<Array<TypeTagTagsConnectFieldInput>>;
  works?: InputMaybe<Array<TypeTagWorksConnectFieldInput>>;
};

export type TypeTagConnectOrCreateInput = {
  certificationRequests?: InputMaybe<Array<TypeTagCertificationRequestsConnectOrCreateFieldInput>>;
  channels?: InputMaybe<Array<TypeTagChannelsConnectOrCreateFieldInput>>;
  editions?: InputMaybe<Array<TypeTagEditionsConnectOrCreateFieldInput>>;
  parentTag?: InputMaybe<Array<TypeTagParentTagConnectOrCreateFieldInput>>;
  tags?: InputMaybe<Array<TypeTagTagsConnectOrCreateFieldInput>>;
  works?: InputMaybe<Array<TypeTagWorksConnectOrCreateFieldInput>>;
};

export type TypeTagConnectOrCreateWhere = {
  node: TypeTagUniqueWhere;
};

export type TypeTagConnectWhere = {
  node: TypeTagWhere;
};

export type TypeTagCreateInput = {
  certificationRequests?: InputMaybe<TypeTagCertificationRequestsFieldInput>;
  channels?: InputMaybe<TypeTagChannelsFieldInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  editions?: InputMaybe<TypeTagEditionsFieldInput>;
  id: Scalars['ID']['input'];
  parentTag?: InputMaybe<TypeTagParentTagFieldInput>;
  tags?: InputMaybe<TypeTagTagsFieldInput>;
  tier?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  works?: InputMaybe<TypeTagWorksFieldInput>;
};

export type TypeTagDeleteInput = {
  certificationRequests?: InputMaybe<Array<TypeTagCertificationRequestsDeleteFieldInput>>;
  channels?: InputMaybe<Array<TypeTagChannelsDeleteFieldInput>>;
  editions?: InputMaybe<Array<TypeTagEditionsDeleteFieldInput>>;
  parentTag?: InputMaybe<Array<TypeTagParentTagDeleteFieldInput>>;
  tags?: InputMaybe<Array<TypeTagTagsDeleteFieldInput>>;
  works?: InputMaybe<Array<TypeTagWorksDeleteFieldInput>>;
};

export type TypeTagDisconnectInput = {
  certificationRequests?: InputMaybe<Array<TypeTagCertificationRequestsDisconnectFieldInput>>;
  channels?: InputMaybe<Array<TypeTagChannelsDisconnectFieldInput>>;
  editions?: InputMaybe<Array<TypeTagEditionsDisconnectFieldInput>>;
  parentTag?: InputMaybe<Array<TypeTagParentTagDisconnectFieldInput>>;
  tags?: InputMaybe<Array<TypeTagTagsDisconnectFieldInput>>;
  works?: InputMaybe<Array<TypeTagWorksDisconnectFieldInput>>;
};

export type TypeTagEdge = {
  __typename?: 'TypeTagEdge';
  cursor: Scalars['String']['output'];
  node: TypeTag;
};

export type TypeTagEditionEditionsAggregationSelection = {
  __typename?: 'TypeTagEditionEditionsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<TypeTagEditionEditionsNodeAggregateSelection>;
};

export type TypeTagEditionEditionsNodeAggregateSelection = {
  __typename?: 'TypeTagEditionEditionsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type TypeTagEditionsAggregateInput = {
  AND?: InputMaybe<Array<TypeTagEditionsAggregateInput>>;
  NOT?: InputMaybe<TypeTagEditionsAggregateInput>;
  OR?: InputMaybe<Array<TypeTagEditionsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<TypeTagEditionsNodeAggregationWhereInput>;
};

export type TypeTagEditionsConnectFieldInput = {
  connect?: InputMaybe<Array<EditionConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<EditionConnectWhere>;
};

export type TypeTagEditionsConnectOrCreateFieldInput = {
  onCreate: TypeTagEditionsConnectOrCreateFieldInputOnCreate;
  where: EditionConnectOrCreateWhere;
};

export type TypeTagEditionsConnectOrCreateFieldInputOnCreate = {
  node: EditionOnCreateInput;
};

export type TypeTagEditionsConnection = {
  __typename?: 'TypeTagEditionsConnection';
  edges: Array<TypeTagEditionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TypeTagEditionsConnectionSort = {
  node?: InputMaybe<EditionSort>;
};

export type TypeTagEditionsConnectionWhere = {
  AND?: InputMaybe<Array<TypeTagEditionsConnectionWhere>>;
  NOT?: InputMaybe<TypeTagEditionsConnectionWhere>;
  OR?: InputMaybe<Array<TypeTagEditionsConnectionWhere>>;
  node?: InputMaybe<EditionWhere>;
};

export type TypeTagEditionsCreateFieldInput = {
  node: EditionCreateInput;
};

export type TypeTagEditionsDeleteFieldInput = {
  delete?: InputMaybe<EditionDeleteInput>;
  where?: InputMaybe<TypeTagEditionsConnectionWhere>;
};

export type TypeTagEditionsDisconnectFieldInput = {
  disconnect?: InputMaybe<EditionDisconnectInput>;
  where?: InputMaybe<TypeTagEditionsConnectionWhere>;
};

export type TypeTagEditionsFieldInput = {
  connect?: InputMaybe<Array<TypeTagEditionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TypeTagEditionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TypeTagEditionsCreateFieldInput>>;
};

export type TypeTagEditionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TypeTagEditionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TypeTagEditionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TypeTagEditionsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type TypeTagEditionsRelationship = {
  __typename?: 'TypeTagEditionsRelationship';
  cursor: Scalars['String']['output'];
  node: Edition;
};

export type TypeTagEditionsUpdateConnectionInput = {
  node?: InputMaybe<EditionUpdateInput>;
};

export type TypeTagEditionsUpdateFieldInput = {
  connect?: InputMaybe<Array<TypeTagEditionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TypeTagEditionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TypeTagEditionsCreateFieldInput>>;
  delete?: InputMaybe<Array<TypeTagEditionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TypeTagEditionsDisconnectFieldInput>>;
  update?: InputMaybe<TypeTagEditionsUpdateConnectionInput>;
  where?: InputMaybe<TypeTagEditionsConnectionWhere>;
};

export type TypeTagOnCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  tier?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type TypeTagOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more TypeTagSort objects to sort TypeTags by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TypeTagSort>>;
};

export type TypeTagParentTagAggregateInput = {
  AND?: InputMaybe<Array<TypeTagParentTagAggregateInput>>;
  NOT?: InputMaybe<TypeTagParentTagAggregateInput>;
  OR?: InputMaybe<Array<TypeTagParentTagAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<TypeTagParentTagNodeAggregationWhereInput>;
};

export type TypeTagParentTagConnectFieldInput = {
  connect?: InputMaybe<Array<TypeTagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<TypeTagConnectWhere>;
};

export type TypeTagParentTagConnectOrCreateFieldInput = {
  onCreate: TypeTagParentTagConnectOrCreateFieldInputOnCreate;
  where: TypeTagConnectOrCreateWhere;
};

export type TypeTagParentTagConnectOrCreateFieldInputOnCreate = {
  node: TypeTagOnCreateInput;
};

export type TypeTagParentTagConnection = {
  __typename?: 'TypeTagParentTagConnection';
  edges: Array<TypeTagParentTagRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TypeTagParentTagConnectionSort = {
  node?: InputMaybe<TypeTagSort>;
};

export type TypeTagParentTagConnectionWhere = {
  AND?: InputMaybe<Array<TypeTagParentTagConnectionWhere>>;
  NOT?: InputMaybe<TypeTagParentTagConnectionWhere>;
  OR?: InputMaybe<Array<TypeTagParentTagConnectionWhere>>;
  node?: InputMaybe<TypeTagWhere>;
};

export type TypeTagParentTagCreateFieldInput = {
  node: TypeTagCreateInput;
};

export type TypeTagParentTagDeleteFieldInput = {
  delete?: InputMaybe<TypeTagDeleteInput>;
  where?: InputMaybe<TypeTagParentTagConnectionWhere>;
};

export type TypeTagParentTagDisconnectFieldInput = {
  disconnect?: InputMaybe<TypeTagDisconnectInput>;
  where?: InputMaybe<TypeTagParentTagConnectionWhere>;
};

export type TypeTagParentTagFieldInput = {
  connect?: InputMaybe<Array<TypeTagParentTagConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TypeTagParentTagConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TypeTagParentTagCreateFieldInput>>;
};

export type TypeTagParentTagNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TypeTagParentTagNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TypeTagParentTagNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TypeTagParentTagNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  tier_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TypeTagParentTagRelationship = {
  __typename?: 'TypeTagParentTagRelationship';
  cursor: Scalars['String']['output'];
  node: TypeTag;
};

export type TypeTagParentTagUpdateConnectionInput = {
  node?: InputMaybe<TypeTagUpdateInput>;
};

export type TypeTagParentTagUpdateFieldInput = {
  connect?: InputMaybe<Array<TypeTagParentTagConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TypeTagParentTagConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TypeTagParentTagCreateFieldInput>>;
  delete?: InputMaybe<Array<TypeTagParentTagDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TypeTagParentTagDisconnectFieldInput>>;
  update?: InputMaybe<TypeTagParentTagUpdateConnectionInput>;
  where?: InputMaybe<TypeTagParentTagConnectionWhere>;
};

export type TypeTagRelationInput = {
  certificationRequests?: InputMaybe<Array<TypeTagCertificationRequestsCreateFieldInput>>;
  channels?: InputMaybe<Array<TypeTagChannelsCreateFieldInput>>;
  editions?: InputMaybe<Array<TypeTagEditionsCreateFieldInput>>;
  parentTag?: InputMaybe<Array<TypeTagParentTagCreateFieldInput>>;
  tags?: InputMaybe<Array<TypeTagTagsCreateFieldInput>>;
  works?: InputMaybe<Array<TypeTagWorksCreateFieldInput>>;
};

/** Fields to sort TypeTags by. The order in which sorts are applied is not guaranteed when specifying many fields in one TypeTagSort object. */
export type TypeTagSort = {
  createdAt?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  tier?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type TypeTagTagsAggregateInput = {
  AND?: InputMaybe<Array<TypeTagTagsAggregateInput>>;
  NOT?: InputMaybe<TypeTagTagsAggregateInput>;
  OR?: InputMaybe<Array<TypeTagTagsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<TypeTagTagsNodeAggregationWhereInput>;
};

export type TypeTagTagsConnectFieldInput = {
  connect?: InputMaybe<Array<TypeTagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<TypeTagConnectWhere>;
};

export type TypeTagTagsConnectOrCreateFieldInput = {
  onCreate: TypeTagTagsConnectOrCreateFieldInputOnCreate;
  where: TypeTagConnectOrCreateWhere;
};

export type TypeTagTagsConnectOrCreateFieldInputOnCreate = {
  node: TypeTagOnCreateInput;
};

export type TypeTagTagsConnection = {
  __typename?: 'TypeTagTagsConnection';
  edges: Array<TypeTagTagsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TypeTagTagsConnectionSort = {
  node?: InputMaybe<TypeTagSort>;
};

export type TypeTagTagsConnectionWhere = {
  AND?: InputMaybe<Array<TypeTagTagsConnectionWhere>>;
  NOT?: InputMaybe<TypeTagTagsConnectionWhere>;
  OR?: InputMaybe<Array<TypeTagTagsConnectionWhere>>;
  node?: InputMaybe<TypeTagWhere>;
};

export type TypeTagTagsCreateFieldInput = {
  node: TypeTagCreateInput;
};

export type TypeTagTagsDeleteFieldInput = {
  delete?: InputMaybe<TypeTagDeleteInput>;
  where?: InputMaybe<TypeTagTagsConnectionWhere>;
};

export type TypeTagTagsDisconnectFieldInput = {
  disconnect?: InputMaybe<TypeTagDisconnectInput>;
  where?: InputMaybe<TypeTagTagsConnectionWhere>;
};

export type TypeTagTagsFieldInput = {
  connect?: InputMaybe<Array<TypeTagTagsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TypeTagTagsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TypeTagTagsCreateFieldInput>>;
};

export type TypeTagTagsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TypeTagTagsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TypeTagTagsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TypeTagTagsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  tier_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TypeTagTagsRelationship = {
  __typename?: 'TypeTagTagsRelationship';
  cursor: Scalars['String']['output'];
  node: TypeTag;
};

export type TypeTagTagsUpdateConnectionInput = {
  node?: InputMaybe<TypeTagUpdateInput>;
};

export type TypeTagTagsUpdateFieldInput = {
  connect?: InputMaybe<Array<TypeTagTagsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TypeTagTagsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TypeTagTagsCreateFieldInput>>;
  delete?: InputMaybe<Array<TypeTagTagsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TypeTagTagsDisconnectFieldInput>>;
  update?: InputMaybe<TypeTagTagsUpdateConnectionInput>;
  where?: InputMaybe<TypeTagTagsConnectionWhere>;
};

export type TypeTagTypeTagParentTagAggregationSelection = {
  __typename?: 'TypeTagTypeTagParentTagAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<TypeTagTypeTagParentTagNodeAggregateSelection>;
};

export type TypeTagTypeTagParentTagNodeAggregateSelection = {
  __typename?: 'TypeTagTypeTagParentTagNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type TypeTagTypeTagTagsAggregationSelection = {
  __typename?: 'TypeTagTypeTagTagsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<TypeTagTypeTagTagsNodeAggregateSelection>;
};

export type TypeTagTypeTagTagsNodeAggregateSelection = {
  __typename?: 'TypeTagTypeTagTagsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type TypeTagUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TypeTagUpdateInput = {
  certificationRequests?: InputMaybe<Array<TypeTagCertificationRequestsUpdateFieldInput>>;
  channels?: InputMaybe<Array<TypeTagChannelsUpdateFieldInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  editions?: InputMaybe<Array<TypeTagEditionsUpdateFieldInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  parentTag?: InputMaybe<Array<TypeTagParentTagUpdateFieldInput>>;
  tags?: InputMaybe<Array<TypeTagTagsUpdateFieldInput>>;
  tier?: InputMaybe<Scalars['Int']['input']>;
  tier_DECREMENT?: InputMaybe<Scalars['Int']['input']>;
  tier_INCREMENT?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  works?: InputMaybe<Array<TypeTagWorksUpdateFieldInput>>;
};

export type TypeTagWhere = {
  AND?: InputMaybe<Array<TypeTagWhere>>;
  NOT?: InputMaybe<TypeTagWhere>;
  OR?: InputMaybe<Array<TypeTagWhere>>;
  certificationRequestsAggregate?: InputMaybe<TypeTagCertificationRequestsAggregateInput>;
  /** Return TypeTags where all of the related TypeTagCertificationRequestsConnections match this filter */
  certificationRequestsConnection_ALL?: InputMaybe<TypeTagCertificationRequestsConnectionWhere>;
  /** Return TypeTags where none of the related TypeTagCertificationRequestsConnections match this filter */
  certificationRequestsConnection_NONE?: InputMaybe<TypeTagCertificationRequestsConnectionWhere>;
  /** Return TypeTags where one of the related TypeTagCertificationRequestsConnections match this filter */
  certificationRequestsConnection_SINGLE?: InputMaybe<TypeTagCertificationRequestsConnectionWhere>;
  /** Return TypeTags where some of the related TypeTagCertificationRequestsConnections match this filter */
  certificationRequestsConnection_SOME?: InputMaybe<TypeTagCertificationRequestsConnectionWhere>;
  /** Return TypeTags where all of the related CertificationRequests match this filter */
  certificationRequests_ALL?: InputMaybe<CertificationRequestWhere>;
  /** Return TypeTags where none of the related CertificationRequests match this filter */
  certificationRequests_NONE?: InputMaybe<CertificationRequestWhere>;
  /** Return TypeTags where one of the related CertificationRequests match this filter */
  certificationRequests_SINGLE?: InputMaybe<CertificationRequestWhere>;
  /** Return TypeTags where some of the related CertificationRequests match this filter */
  certificationRequests_SOME?: InputMaybe<CertificationRequestWhere>;
  channelsAggregate?: InputMaybe<TypeTagChannelsAggregateInput>;
  /** Return TypeTags where all of the related TypeTagChannelsConnections match this filter */
  channelsConnection_ALL?: InputMaybe<TypeTagChannelsConnectionWhere>;
  /** Return TypeTags where none of the related TypeTagChannelsConnections match this filter */
  channelsConnection_NONE?: InputMaybe<TypeTagChannelsConnectionWhere>;
  /** Return TypeTags where one of the related TypeTagChannelsConnections match this filter */
  channelsConnection_SINGLE?: InputMaybe<TypeTagChannelsConnectionWhere>;
  /** Return TypeTags where some of the related TypeTagChannelsConnections match this filter */
  channelsConnection_SOME?: InputMaybe<TypeTagChannelsConnectionWhere>;
  /** Return TypeTags where all of the related Channels match this filter */
  channels_ALL?: InputMaybe<ChannelWhere>;
  /** Return TypeTags where none of the related Channels match this filter */
  channels_NONE?: InputMaybe<ChannelWhere>;
  /** Return TypeTags where one of the related Channels match this filter */
  channels_SINGLE?: InputMaybe<ChannelWhere>;
  /** Return TypeTags where some of the related Channels match this filter */
  channels_SOME?: InputMaybe<ChannelWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  description_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  editionsAggregate?: InputMaybe<TypeTagEditionsAggregateInput>;
  /** Return TypeTags where all of the related TypeTagEditionsConnections match this filter */
  editionsConnection_ALL?: InputMaybe<TypeTagEditionsConnectionWhere>;
  /** Return TypeTags where none of the related TypeTagEditionsConnections match this filter */
  editionsConnection_NONE?: InputMaybe<TypeTagEditionsConnectionWhere>;
  /** Return TypeTags where one of the related TypeTagEditionsConnections match this filter */
  editionsConnection_SINGLE?: InputMaybe<TypeTagEditionsConnectionWhere>;
  /** Return TypeTags where some of the related TypeTagEditionsConnections match this filter */
  editionsConnection_SOME?: InputMaybe<TypeTagEditionsConnectionWhere>;
  /** Return TypeTags where all of the related Editions match this filter */
  editions_ALL?: InputMaybe<EditionWhere>;
  /** Return TypeTags where none of the related Editions match this filter */
  editions_NONE?: InputMaybe<EditionWhere>;
  /** Return TypeTags where one of the related Editions match this filter */
  editions_SINGLE?: InputMaybe<EditionWhere>;
  /** Return TypeTags where some of the related Editions match this filter */
  editions_SOME?: InputMaybe<EditionWhere>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  parentTagAggregate?: InputMaybe<TypeTagParentTagAggregateInput>;
  /** Return TypeTags where all of the related TypeTagParentTagConnections match this filter */
  parentTagConnection_ALL?: InputMaybe<TypeTagParentTagConnectionWhere>;
  /** Return TypeTags where none of the related TypeTagParentTagConnections match this filter */
  parentTagConnection_NONE?: InputMaybe<TypeTagParentTagConnectionWhere>;
  /** Return TypeTags where one of the related TypeTagParentTagConnections match this filter */
  parentTagConnection_SINGLE?: InputMaybe<TypeTagParentTagConnectionWhere>;
  /** Return TypeTags where some of the related TypeTagParentTagConnections match this filter */
  parentTagConnection_SOME?: InputMaybe<TypeTagParentTagConnectionWhere>;
  /** Return TypeTags where all of the related TypeTags match this filter */
  parentTag_ALL?: InputMaybe<TypeTagWhere>;
  /** Return TypeTags where none of the related TypeTags match this filter */
  parentTag_NONE?: InputMaybe<TypeTagWhere>;
  /** Return TypeTags where one of the related TypeTags match this filter */
  parentTag_SINGLE?: InputMaybe<TypeTagWhere>;
  /** Return TypeTags where some of the related TypeTags match this filter */
  parentTag_SOME?: InputMaybe<TypeTagWhere>;
  tagsAggregate?: InputMaybe<TypeTagTagsAggregateInput>;
  /** Return TypeTags where all of the related TypeTagTagsConnections match this filter */
  tagsConnection_ALL?: InputMaybe<TypeTagTagsConnectionWhere>;
  /** Return TypeTags where none of the related TypeTagTagsConnections match this filter */
  tagsConnection_NONE?: InputMaybe<TypeTagTagsConnectionWhere>;
  /** Return TypeTags where one of the related TypeTagTagsConnections match this filter */
  tagsConnection_SINGLE?: InputMaybe<TypeTagTagsConnectionWhere>;
  /** Return TypeTags where some of the related TypeTagTagsConnections match this filter */
  tagsConnection_SOME?: InputMaybe<TypeTagTagsConnectionWhere>;
  /** Return TypeTags where all of the related TypeTags match this filter */
  tags_ALL?: InputMaybe<TypeTagWhere>;
  /** Return TypeTags where none of the related TypeTags match this filter */
  tags_NONE?: InputMaybe<TypeTagWhere>;
  /** Return TypeTags where one of the related TypeTags match this filter */
  tags_SINGLE?: InputMaybe<TypeTagWhere>;
  /** Return TypeTags where some of the related TypeTags match this filter */
  tags_SOME?: InputMaybe<TypeTagWhere>;
  tier?: InputMaybe<Scalars['Int']['input']>;
  tier_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_IN?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tier_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_LTE?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  title_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  title_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  worksAggregate?: InputMaybe<TypeTagWorksAggregateInput>;
  /** Return TypeTags where all of the related TypeTagWorksConnections match this filter */
  worksConnection_ALL?: InputMaybe<TypeTagWorksConnectionWhere>;
  /** Return TypeTags where none of the related TypeTagWorksConnections match this filter */
  worksConnection_NONE?: InputMaybe<TypeTagWorksConnectionWhere>;
  /** Return TypeTags where one of the related TypeTagWorksConnections match this filter */
  worksConnection_SINGLE?: InputMaybe<TypeTagWorksConnectionWhere>;
  /** Return TypeTags where some of the related TypeTagWorksConnections match this filter */
  worksConnection_SOME?: InputMaybe<TypeTagWorksConnectionWhere>;
  /** Return TypeTags where all of the related Works match this filter */
  works_ALL?: InputMaybe<WorkWhere>;
  /** Return TypeTags where none of the related Works match this filter */
  works_NONE?: InputMaybe<WorkWhere>;
  /** Return TypeTags where one of the related Works match this filter */
  works_SINGLE?: InputMaybe<WorkWhere>;
  /** Return TypeTags where some of the related Works match this filter */
  works_SOME?: InputMaybe<WorkWhere>;
};

export type TypeTagWorkWorksAggregationSelection = {
  __typename?: 'TypeTagWorkWorksAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<TypeTagWorkWorksNodeAggregateSelection>;
};

export type TypeTagWorkWorksNodeAggregateSelection = {
  __typename?: 'TypeTagWorkWorksNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  workUrl: StringAggregateSelectionNullable;
};

export type TypeTagWorksAggregateInput = {
  AND?: InputMaybe<Array<TypeTagWorksAggregateInput>>;
  NOT?: InputMaybe<TypeTagWorksAggregateInput>;
  OR?: InputMaybe<Array<TypeTagWorksAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<TypeTagWorksNodeAggregationWhereInput>;
};

export type TypeTagWorksConnectFieldInput = {
  connect?: InputMaybe<Array<WorkConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<WorkConnectWhere>;
};

export type TypeTagWorksConnectOrCreateFieldInput = {
  onCreate: TypeTagWorksConnectOrCreateFieldInputOnCreate;
  where: WorkConnectOrCreateWhere;
};

export type TypeTagWorksConnectOrCreateFieldInputOnCreate = {
  node: WorkOnCreateInput;
};

export type TypeTagWorksConnection = {
  __typename?: 'TypeTagWorksConnection';
  edges: Array<TypeTagWorksRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TypeTagWorksConnectionSort = {
  node?: InputMaybe<WorkSort>;
};

export type TypeTagWorksConnectionWhere = {
  AND?: InputMaybe<Array<TypeTagWorksConnectionWhere>>;
  NOT?: InputMaybe<TypeTagWorksConnectionWhere>;
  OR?: InputMaybe<Array<TypeTagWorksConnectionWhere>>;
  node?: InputMaybe<WorkWhere>;
};

export type TypeTagWorksCreateFieldInput = {
  node: WorkCreateInput;
};

export type TypeTagWorksDeleteFieldInput = {
  delete?: InputMaybe<WorkDeleteInput>;
  where?: InputMaybe<TypeTagWorksConnectionWhere>;
};

export type TypeTagWorksDisconnectFieldInput = {
  disconnect?: InputMaybe<WorkDisconnectInput>;
  where?: InputMaybe<TypeTagWorksConnectionWhere>;
};

export type TypeTagWorksFieldInput = {
  connect?: InputMaybe<Array<TypeTagWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TypeTagWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TypeTagWorksCreateFieldInput>>;
};

export type TypeTagWorksNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TypeTagWorksNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TypeTagWorksNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TypeTagWorksNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  workUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type TypeTagWorksRelationship = {
  __typename?: 'TypeTagWorksRelationship';
  cursor: Scalars['String']['output'];
  node: Work;
};

export type TypeTagWorksUpdateConnectionInput = {
  node?: InputMaybe<WorkUpdateInput>;
};

export type TypeTagWorksUpdateFieldInput = {
  connect?: InputMaybe<Array<TypeTagWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TypeTagWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TypeTagWorksCreateFieldInput>>;
  delete?: InputMaybe<Array<TypeTagWorksDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TypeTagWorksDisconnectFieldInput>>;
  update?: InputMaybe<TypeTagWorksUpdateConnectionInput>;
  where?: InputMaybe<TypeTagWorksConnectionWhere>;
};

export type TypeTagsConnection = {
  __typename?: 'TypeTagsConnection';
  edges: Array<TypeTagEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UpdateAccountSubscriptionsMutationResponse = {
  __typename?: 'UpdateAccountSubscriptionsMutationResponse';
  accountSubscriptions: Array<AccountSubscription>;
  info: UpdateInfo;
};

export type UpdateAccountsMutationResponse = {
  __typename?: 'UpdateAccountsMutationResponse';
  accounts: Array<Account>;
  info: UpdateInfo;
};

export type UpdateCategoryTagRequestsMutationResponse = {
  __typename?: 'UpdateCategoryTagRequestsMutationResponse';
  categoryTagRequests: Array<CategoryTagRequest>;
  info: UpdateInfo;
};

export type UpdateCategoryTagsMutationResponse = {
  __typename?: 'UpdateCategoryTagsMutationResponse';
  categoryTags: Array<CategoryTag>;
  info: UpdateInfo;
};

export type UpdateCertificationRequestsMutationResponse = {
  __typename?: 'UpdateCertificationRequestsMutationResponse';
  certificationRequests: Array<CertificationRequest>;
  info: UpdateInfo;
};

export type UpdateCertificationResponsesMutationResponse = {
  __typename?: 'UpdateCertificationResponsesMutationResponse';
  certificationResponses: Array<CertificationResponse>;
  info: UpdateInfo;
};

export type UpdateChannelsMutationResponse = {
  __typename?: 'UpdateChannelsMutationResponse';
  channels: Array<Channel>;
  info: UpdateInfo;
};

export type UpdateContentBadgesMutationResponse = {
  __typename?: 'UpdateContentBadgesMutationResponse';
  contentBadges: Array<ContentBadge>;
  info: UpdateInfo;
};

export type UpdateCreatorContactsMutationResponse = {
  __typename?: 'UpdateCreatorContactsMutationResponse';
  creatorContacts: Array<CreatorContact>;
  info: UpdateInfo;
};

export type UpdateCreatorsMutationResponse = {
  __typename?: 'UpdateCreatorsMutationResponse';
  creators: Array<Creator>;
  info: UpdateInfo;
};

export type UpdateEditionsMutationResponse = {
  __typename?: 'UpdateEditionsMutationResponse';
  editions: Array<Edition>;
  info: UpdateInfo;
};

export type UpdateFeedbacksMutationResponse = {
  __typename?: 'UpdateFeedbacksMutationResponse';
  feedbacks: Array<Feedback>;
  info: UpdateInfo;
};

/** Information about the number of nodes and relationships created and deleted during an update mutation */
export type UpdateInfo = {
  __typename?: 'UpdateInfo';
  /** @deprecated This field has been deprecated because bookmarks are now handled by the driver. */
  bookmark?: Maybe<Scalars['String']['output']>;
  nodesCreated: Scalars['Int']['output'];
  nodesDeleted: Scalars['Int']['output'];
  relationshipsCreated: Scalars['Int']['output'];
  relationshipsDeleted: Scalars['Int']['output'];
};

export type UpdateOwnerContactsMutationResponse = {
  __typename?: 'UpdateOwnerContactsMutationResponse';
  info: UpdateInfo;
  ownerContacts: Array<OwnerContact>;
};

export type UpdateOwnersMutationResponse = {
  __typename?: 'UpdateOwnersMutationResponse';
  info: UpdateInfo;
  owners: Array<Owner>;
};

export type UpdateTagsMutationResponse = {
  __typename?: 'UpdateTagsMutationResponse';
  info: UpdateInfo;
  tags: Array<Tag>;
};

export type UpdateTypeTagsMutationResponse = {
  __typename?: 'UpdateTypeTagsMutationResponse';
  info: UpdateInfo;
  typeTags: Array<TypeTag>;
};

export type UpdateUserMediasMutationResponse = {
  __typename?: 'UpdateUserMediasMutationResponse';
  info: UpdateInfo;
  userMedias: Array<UserMedia>;
};

export type UpdateUsersMutationResponse = {
  __typename?: 'UpdateUsersMutationResponse';
  info: UpdateInfo;
  users: Array<User>;
};

export type UpdateWorksMutationResponse = {
  __typename?: 'UpdateWorksMutationResponse';
  info: UpdateInfo;
  works: Array<Work>;
};

export type UploadMediaResponse = {
  __typename?: 'UploadMediaResponse';
  error?: Maybe<Scalars['String']['output']>;
  media?: Maybe<UserMedia>;
  status?: Maybe<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  /** DEV-TEAM: since a user can access more than one account should this be a one to many? */
  account?: Maybe<Account>;
  accountAggregate?: Maybe<UserAccountAccountAggregationSelection>;
  accountConnection: UserAccountConnection;
  /** Certification Requests */
  certificationRequests: Array<CertificationRequest>;
  certificationRequestsAggregate?: Maybe<UserCertificationRequestCertificationRequestsAggregationSelection>;
  certificationRequestsConnection: UserCertificationRequestsConnection;
  /** Certification Responses */
  certificationResponses: Array<CertificationResponse>;
  certificationResponsesAggregate?: Maybe<UserCertificationResponseCertificationResponsesAggregationSelection>;
  certificationResponsesConnection: UserCertificationResponsesConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** User email */
  email?: Maybe<Scalars['String']['output']>;
  /** System generated GUID */
  id: Scalars['ID']['output'];
  /** Media created or uploaded by this user */
  media: Array<UserMedia>;
  mediaAggregate?: Maybe<UserUserMediaMediaAggregationSelection>;
  mediaConnection: UserMediaConnection;
  /** User Nickname */
  nickname?: Maybe<Scalars['String']['output']>;
  /**
   * Nutshell Contact/Person/People ID
   * Required to fetch Contact/Person/People through Nutshell API
   */
  nutshellContactId?: Maybe<Scalars['String']['output']>;
  /**
   * Nutshell Lead ID
   * Required to fetch Lead through Nutshell API
   */
  nutshellLeadId?: Maybe<Scalars['String']['output']>;
  /**
   * Nutshell Lead Number
   * Nutshell Console does not use the above ID, it shows Lead Number only, weird.
   */
  nutshellLeadNumber?: Maybe<Scalars['String']['output']>;
  /** User Picture */
  picture?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Authentication Service Unique Id */
  userId: Scalars['ID']['output'];
  /** User Type */
  userType?: Maybe<UserType>;
};


export type UserAccountArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<AccountOptions>;
  where?: InputMaybe<AccountWhere>;
};


export type UserAccountAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccountWhere>;
};


export type UserAccountConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserAccountConnectionSort>>;
  where?: InputMaybe<UserAccountConnectionWhere>;
};


export type UserCertificationRequestsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CertificationRequestOptions>;
  where?: InputMaybe<CertificationRequestWhere>;
};


export type UserCertificationRequestsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CertificationRequestWhere>;
};


export type UserCertificationRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserCertificationRequestsConnectionSort>>;
  where?: InputMaybe<UserCertificationRequestsConnectionWhere>;
};


export type UserCertificationResponsesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CertificationResponseOptions>;
  where?: InputMaybe<CertificationResponseWhere>;
};


export type UserCertificationResponsesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CertificationResponseWhere>;
};


export type UserCertificationResponsesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserCertificationResponsesConnectionSort>>;
  where?: InputMaybe<UserCertificationResponsesConnectionWhere>;
};


export type UserMediaArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserMediaOptions>;
  where?: InputMaybe<UserMediaWhere>;
};


export type UserMediaAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserMediaWhere>;
};


export type UserMediaConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserMediaConnectionSort>>;
  where?: InputMaybe<UserMediaConnectionWhere>;
};

export type UserAcceptTermsResponse = {
  __typename?: 'UserAcceptTermsResponse';
  error?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type UserAccountAccountAggregationSelection = {
  __typename?: 'UserAccountAccountAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<UserAccountAccountNodeAggregateSelection>;
};

export type UserAccountAccountNodeAggregateSelection = {
  __typename?: 'UserAccountAccountNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  joinedDate: StringAggregateSelectionNullable;
  lastActive: StringAggregateSelectionNullable;
  profileURL: StringAggregateSelectionNullable;
  taxId: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  username: StringAggregateSelectionNullable;
};

export type UserAccountAggregateInput = {
  AND?: InputMaybe<Array<UserAccountAggregateInput>>;
  NOT?: InputMaybe<UserAccountAggregateInput>;
  OR?: InputMaybe<Array<UserAccountAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<UserAccountNodeAggregationWhereInput>;
};

export type UserAccountConnectFieldInput = {
  connect?: InputMaybe<AccountConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<AccountConnectWhere>;
};

export type UserAccountConnectOrCreateFieldInput = {
  onCreate: UserAccountConnectOrCreateFieldInputOnCreate;
  where: AccountConnectOrCreateWhere;
};

export type UserAccountConnectOrCreateFieldInputOnCreate = {
  node: AccountOnCreateInput;
};

export type UserAccountConnection = {
  __typename?: 'UserAccountConnection';
  edges: Array<UserAccountRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserAccountConnectionSort = {
  node?: InputMaybe<AccountSort>;
};

export type UserAccountConnectionWhere = {
  AND?: InputMaybe<Array<UserAccountConnectionWhere>>;
  NOT?: InputMaybe<UserAccountConnectionWhere>;
  OR?: InputMaybe<Array<UserAccountConnectionWhere>>;
  node?: InputMaybe<AccountWhere>;
};

export type UserAccountCreateFieldInput = {
  node: AccountCreateInput;
};

export type UserAccountDeleteFieldInput = {
  delete?: InputMaybe<AccountDeleteInput>;
  where?: InputMaybe<UserAccountConnectionWhere>;
};

export type UserAccountDisconnectFieldInput = {
  disconnect?: InputMaybe<AccountDisconnectInput>;
  where?: InputMaybe<UserAccountConnectionWhere>;
};

export type UserAccountFieldInput = {
  connect?: InputMaybe<UserAccountConnectFieldInput>;
  connectOrCreate?: InputMaybe<UserAccountConnectOrCreateFieldInput>;
  create?: InputMaybe<UserAccountCreateFieldInput>;
};

export type UserAccountNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserAccountNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserAccountNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserAccountNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  joinedDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type UserAccountRelationship = {
  __typename?: 'UserAccountRelationship';
  cursor: Scalars['String']['output'];
  node: Account;
};

export type UserAccountUpdateConnectionInput = {
  node?: InputMaybe<AccountUpdateInput>;
};

export type UserAccountUpdateFieldInput = {
  connect?: InputMaybe<UserAccountConnectFieldInput>;
  connectOrCreate?: InputMaybe<UserAccountConnectOrCreateFieldInput>;
  create?: InputMaybe<UserAccountCreateFieldInput>;
  delete?: InputMaybe<UserAccountDeleteFieldInput>;
  disconnect?: InputMaybe<UserAccountDisconnectFieldInput>;
  update?: InputMaybe<UserAccountUpdateConnectionInput>;
  where?: InputMaybe<UserAccountConnectionWhere>;
};

export type UserCertificationRequestCertificationRequestsAggregationSelection = {
  __typename?: 'UserCertificationRequestCertificationRequestsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<UserCertificationRequestCertificationRequestsNodeAggregateSelection>;
};

export type UserCertificationRequestCertificationRequestsNodeAggregateSelection = {
  __typename?: 'UserCertificationRequestCertificationRequestsNodeAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  channelDescription: StringAggregateSelectionNullable;
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  email: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  name: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  requestSignature: StringAggregateSelectionNullable;
  result: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type UserCertificationRequestsAggregateInput = {
  AND?: InputMaybe<Array<UserCertificationRequestsAggregateInput>>;
  NOT?: InputMaybe<UserCertificationRequestsAggregateInput>;
  OR?: InputMaybe<Array<UserCertificationRequestsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<UserCertificationRequestsNodeAggregationWhereInput>;
};

export type UserCertificationRequestsConnectFieldInput = {
  connect?: InputMaybe<Array<CertificationRequestConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CertificationRequestConnectWhere>;
};

export type UserCertificationRequestsConnectOrCreateFieldInput = {
  onCreate: UserCertificationRequestsConnectOrCreateFieldInputOnCreate;
  where: CertificationRequestConnectOrCreateWhere;
};

export type UserCertificationRequestsConnectOrCreateFieldInputOnCreate = {
  node: CertificationRequestOnCreateInput;
};

export type UserCertificationRequestsConnection = {
  __typename?: 'UserCertificationRequestsConnection';
  edges: Array<UserCertificationRequestsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserCertificationRequestsConnectionSort = {
  node?: InputMaybe<CertificationRequestSort>;
};

export type UserCertificationRequestsConnectionWhere = {
  AND?: InputMaybe<Array<UserCertificationRequestsConnectionWhere>>;
  NOT?: InputMaybe<UserCertificationRequestsConnectionWhere>;
  OR?: InputMaybe<Array<UserCertificationRequestsConnectionWhere>>;
  node?: InputMaybe<CertificationRequestWhere>;
};

export type UserCertificationRequestsCreateFieldInput = {
  node: CertificationRequestCreateInput;
};

export type UserCertificationRequestsDeleteFieldInput = {
  delete?: InputMaybe<CertificationRequestDeleteInput>;
  where?: InputMaybe<UserCertificationRequestsConnectionWhere>;
};

export type UserCertificationRequestsDisconnectFieldInput = {
  disconnect?: InputMaybe<CertificationRequestDisconnectInput>;
  where?: InputMaybe<UserCertificationRequestsConnectionWhere>;
};

export type UserCertificationRequestsFieldInput = {
  connect?: InputMaybe<Array<UserCertificationRequestsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserCertificationRequestsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserCertificationRequestsCreateFieldInput>>;
};

export type UserCertificationRequestsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserCertificationRequestsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserCertificationRequestsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserCertificationRequestsNodeAggregationWhereInput>>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  result_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserCertificationRequestsRelationship = {
  __typename?: 'UserCertificationRequestsRelationship';
  cursor: Scalars['String']['output'];
  node: CertificationRequest;
};

export type UserCertificationRequestsUpdateConnectionInput = {
  node?: InputMaybe<CertificationRequestUpdateInput>;
};

export type UserCertificationRequestsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserCertificationRequestsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserCertificationRequestsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserCertificationRequestsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserCertificationRequestsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserCertificationRequestsDisconnectFieldInput>>;
  update?: InputMaybe<UserCertificationRequestsUpdateConnectionInput>;
  where?: InputMaybe<UserCertificationRequestsConnectionWhere>;
};

export type UserCertificationResponseCertificationResponsesAggregationSelection = {
  __typename?: 'UserCertificationResponseCertificationResponsesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<UserCertificationResponseCertificationResponsesNodeAggregateSelection>;
};

export type UserCertificationResponseCertificationResponsesNodeAggregateSelection = {
  __typename?: 'UserCertificationResponseCertificationResponsesNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  result: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type UserCertificationResponsesAggregateInput = {
  AND?: InputMaybe<Array<UserCertificationResponsesAggregateInput>>;
  NOT?: InputMaybe<UserCertificationResponsesAggregateInput>;
  OR?: InputMaybe<Array<UserCertificationResponsesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<UserCertificationResponsesNodeAggregationWhereInput>;
};

export type UserCertificationResponsesConnectFieldInput = {
  connect?: InputMaybe<Array<CertificationResponseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CertificationResponseConnectWhere>;
};

export type UserCertificationResponsesConnectOrCreateFieldInput = {
  onCreate: UserCertificationResponsesConnectOrCreateFieldInputOnCreate;
  where: CertificationResponseConnectOrCreateWhere;
};

export type UserCertificationResponsesConnectOrCreateFieldInputOnCreate = {
  node: CertificationResponseOnCreateInput;
};

export type UserCertificationResponsesConnection = {
  __typename?: 'UserCertificationResponsesConnection';
  edges: Array<UserCertificationResponsesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserCertificationResponsesConnectionSort = {
  node?: InputMaybe<CertificationResponseSort>;
};

export type UserCertificationResponsesConnectionWhere = {
  AND?: InputMaybe<Array<UserCertificationResponsesConnectionWhere>>;
  NOT?: InputMaybe<UserCertificationResponsesConnectionWhere>;
  OR?: InputMaybe<Array<UserCertificationResponsesConnectionWhere>>;
  node?: InputMaybe<CertificationResponseWhere>;
};

export type UserCertificationResponsesCreateFieldInput = {
  node: CertificationResponseCreateInput;
};

export type UserCertificationResponsesDeleteFieldInput = {
  delete?: InputMaybe<CertificationResponseDeleteInput>;
  where?: InputMaybe<UserCertificationResponsesConnectionWhere>;
};

export type UserCertificationResponsesDisconnectFieldInput = {
  disconnect?: InputMaybe<CertificationResponseDisconnectInput>;
  where?: InputMaybe<UserCertificationResponsesConnectionWhere>;
};

export type UserCertificationResponsesFieldInput = {
  connect?: InputMaybe<Array<UserCertificationResponsesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserCertificationResponsesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserCertificationResponsesCreateFieldInput>>;
};

export type UserCertificationResponsesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserCertificationResponsesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserCertificationResponsesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserCertificationResponsesNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  result_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  result_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserCertificationResponsesRelationship = {
  __typename?: 'UserCertificationResponsesRelationship';
  cursor: Scalars['String']['output'];
  node: CertificationResponse;
};

export type UserCertificationResponsesUpdateConnectionInput = {
  node?: InputMaybe<CertificationResponseUpdateInput>;
};

export type UserCertificationResponsesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserCertificationResponsesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserCertificationResponsesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserCertificationResponsesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserCertificationResponsesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserCertificationResponsesDisconnectFieldInput>>;
  update?: InputMaybe<UserCertificationResponsesUpdateConnectionInput>;
  where?: InputMaybe<UserCertificationResponsesConnectionWhere>;
};

export type UserConnectInput = {
  account?: InputMaybe<UserAccountConnectFieldInput>;
  certificationRequests?: InputMaybe<Array<UserCertificationRequestsConnectFieldInput>>;
  certificationResponses?: InputMaybe<Array<UserCertificationResponsesConnectFieldInput>>;
  media?: InputMaybe<Array<UserMediaConnectFieldInput>>;
};

export type UserConnectOrCreateInput = {
  account?: InputMaybe<UserAccountConnectOrCreateFieldInput>;
  certificationRequests?: InputMaybe<Array<UserCertificationRequestsConnectOrCreateFieldInput>>;
  certificationResponses?: InputMaybe<Array<UserCertificationResponsesConnectOrCreateFieldInput>>;
  media?: InputMaybe<Array<UserMediaConnectOrCreateFieldInput>>;
};

export type UserConnectOrCreateWhere = {
  node: UserUniqueWhere;
};

export type UserConnectWhere = {
  node: UserWhere;
};

export type UserCreateInput = {
  account?: InputMaybe<UserAccountFieldInput>;
  certificationRequests?: InputMaybe<UserCertificationRequestsFieldInput>;
  certificationResponses?: InputMaybe<UserCertificationResponsesFieldInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<UserMediaFieldInput>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  userType?: InputMaybe<UserType>;
};

export type UserDeleteInput = {
  account?: InputMaybe<UserAccountDeleteFieldInput>;
  certificationRequests?: InputMaybe<Array<UserCertificationRequestsDeleteFieldInput>>;
  certificationResponses?: InputMaybe<Array<UserCertificationResponsesDeleteFieldInput>>;
  media?: InputMaybe<Array<UserMediaDeleteFieldInput>>;
};

export type UserDisconnectInput = {
  account?: InputMaybe<UserAccountDisconnectFieldInput>;
  certificationRequests?: InputMaybe<Array<UserCertificationRequestsDisconnectFieldInput>>;
  certificationResponses?: InputMaybe<Array<UserCertificationResponsesDisconnectFieldInput>>;
  media?: InputMaybe<Array<UserMediaDisconnectFieldInput>>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

/** User uploaded media and screenshots */
export type UserMedia = {
  __typename?: 'UserMedia';
  /** Auto Correct Tools Usage */
  autoCorrectTools?: Maybe<Array<AutoCorrectToolsTypes>>;
  /** Auto Correct Tools Explanation */
  autoCorrectToolsExplanation?: Maybe<Scalars['String']['output']>;
  /** Caption of media */
  caption?: Maybe<Scalars['String']['output']>;
  /** Certification Requests using this media item */
  certificationRequests: Array<CertificationRequest>;
  certificationRequestsAggregate?: Maybe<UserMediaCertificationRequestCertificationRequestsAggregationSelection>;
  certificationRequestsConnection: UserMediaCertificationRequestsConnection;
  /** Certification Responses linking to this media item */
  certificationResponses: Array<CertificationResponse>;
  certificationResponsesAggregate?: Maybe<UserMediaCertificationResponseCertificationResponsesAggregationSelection>;
  certificationResponsesConnection: UserMediaCertificationResponsesConnection;
  /** Channels using this media item */
  channels: Array<Channel>;
  channelsAggregate?: Maybe<UserMediaChannelChannelsAggregationSelection>;
  channelsConnection: UserMediaChannelsConnection;
  /** Size in byte */
  contentLength?: Maybe<Scalars['Int']['output']>;
  /** Content type expressed as mimetype */
  contentType?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** User who created this media */
  createdBy?: Maybe<User>;
  createdByAggregate?: Maybe<UserMediaUserCreatedByAggregationSelection>;
  createdByConnection: UserMediaCreatedByConnection;
  /** encoding */
  encoding?: Maybe<Scalars['String']['output']>;
  /** Extension of media, optional. If present, will be used to construct public URL */
  extension?: Maybe<Scalars['String']['output']>;
  /** Generative AI Usage */
  generativeAi?: Maybe<Array<GenerativeAiTypes>>;
  /** Generative AI Explanation */
  generativeAiExplanation?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  /** System generated GUID */
  id: Scalars['ID']['output'];
  /** Machine Learning Tools Usage */
  machineLearningTools?: Maybe<Array<MachineLearningToolsTypes>>;
  /** Machine Learning Tools Explanation */
  machineLearningToolsExplanation?: Maybe<Scalars['String']['output']>;
  /** Other AI Tools Usage */
  otherAiTools?: Maybe<Array<OtherAiToolsTypes>>;
  /** Other AI Tools Explanation */
  otherAiToolsExplanation?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  /** Source of the media */
  sourceType?: Maybe<MediaSourceType>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Dimensions, in pixels */
  width?: Maybe<Scalars['Int']['output']>;
  /** Works using this media item */
  works: Array<Work>;
  worksAggregate?: Maybe<UserMediaWorkWorksAggregationSelection>;
  worksConnection: UserMediaWorksConnection;
};


/** User uploaded media and screenshots */
export type UserMediaCertificationRequestsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CertificationRequestOptions>;
  where?: InputMaybe<CertificationRequestWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaCertificationRequestsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CertificationRequestWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaCertificationRequestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserMediaCertificationRequestsConnectionSort>>;
  where?: InputMaybe<UserMediaCertificationRequestsConnectionWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaCertificationResponsesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CertificationResponseOptions>;
  where?: InputMaybe<CertificationResponseWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaCertificationResponsesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CertificationResponseWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaCertificationResponsesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserMediaCertificationResponsesConnectionSort>>;
  where?: InputMaybe<UserMediaCertificationResponsesConnectionWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaChannelsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<ChannelOptions>;
  where?: InputMaybe<ChannelWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaChannelsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ChannelWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserMediaChannelsConnectionSort>>;
  where?: InputMaybe<UserMediaChannelsConnectionWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaCreatedByArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaCreatedByAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaCreatedByConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserMediaCreatedByConnectionSort>>;
  where?: InputMaybe<UserMediaCreatedByConnectionWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaWorksArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<WorkOptions>;
  where?: InputMaybe<WorkWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaWorksAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<WorkWhere>;
};


/** User uploaded media and screenshots */
export type UserMediaWorksConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserMediaWorksConnectionSort>>;
  where?: InputMaybe<UserMediaWorksConnectionWhere>;
};

export type UserMediaAggregateInput = {
  AND?: InputMaybe<Array<UserMediaAggregateInput>>;
  NOT?: InputMaybe<UserMediaAggregateInput>;
  OR?: InputMaybe<Array<UserMediaAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<UserMediaNodeAggregationWhereInput>;
};

export type UserMediaAggregateSelection = {
  __typename?: 'UserMediaAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  caption: StringAggregateSelectionNullable;
  contentLength: IntAggregateSelectionNullable;
  contentType: StringAggregateSelectionNullable;
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  encoding: StringAggregateSelectionNullable;
  extension: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  height: IntAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  source: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  width: IntAggregateSelectionNullable;
};

export type UserMediaCertificationRequestCertificationRequestsAggregationSelection = {
  __typename?: 'UserMediaCertificationRequestCertificationRequestsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<UserMediaCertificationRequestCertificationRequestsNodeAggregateSelection>;
};

export type UserMediaCertificationRequestCertificationRequestsNodeAggregateSelection = {
  __typename?: 'UserMediaCertificationRequestCertificationRequestsNodeAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  channelDescription: StringAggregateSelectionNullable;
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  email: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  name: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  requestSignature: StringAggregateSelectionNullable;
  result: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type UserMediaCertificationRequestsAggregateInput = {
  AND?: InputMaybe<Array<UserMediaCertificationRequestsAggregateInput>>;
  NOT?: InputMaybe<UserMediaCertificationRequestsAggregateInput>;
  OR?: InputMaybe<Array<UserMediaCertificationRequestsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<UserMediaCertificationRequestsNodeAggregationWhereInput>;
};

export type UserMediaCertificationRequestsConnectFieldInput = {
  connect?: InputMaybe<Array<CertificationRequestConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CertificationRequestConnectWhere>;
};

export type UserMediaCertificationRequestsConnectOrCreateFieldInput = {
  onCreate: UserMediaCertificationRequestsConnectOrCreateFieldInputOnCreate;
  where: CertificationRequestConnectOrCreateWhere;
};

export type UserMediaCertificationRequestsConnectOrCreateFieldInputOnCreate = {
  node: CertificationRequestOnCreateInput;
};

export type UserMediaCertificationRequestsConnection = {
  __typename?: 'UserMediaCertificationRequestsConnection';
  edges: Array<UserMediaCertificationRequestsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserMediaCertificationRequestsConnectionSort = {
  node?: InputMaybe<CertificationRequestSort>;
};

export type UserMediaCertificationRequestsConnectionWhere = {
  AND?: InputMaybe<Array<UserMediaCertificationRequestsConnectionWhere>>;
  NOT?: InputMaybe<UserMediaCertificationRequestsConnectionWhere>;
  OR?: InputMaybe<Array<UserMediaCertificationRequestsConnectionWhere>>;
  node?: InputMaybe<CertificationRequestWhere>;
};

export type UserMediaCertificationRequestsCreateFieldInput = {
  node: CertificationRequestCreateInput;
};

export type UserMediaCertificationRequestsDeleteFieldInput = {
  delete?: InputMaybe<CertificationRequestDeleteInput>;
  where?: InputMaybe<UserMediaCertificationRequestsConnectionWhere>;
};

export type UserMediaCertificationRequestsDisconnectFieldInput = {
  disconnect?: InputMaybe<CertificationRequestDisconnectInput>;
  where?: InputMaybe<UserMediaCertificationRequestsConnectionWhere>;
};

export type UserMediaCertificationRequestsFieldInput = {
  connect?: InputMaybe<Array<UserMediaCertificationRequestsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserMediaCertificationRequestsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserMediaCertificationRequestsCreateFieldInput>>;
};

export type UserMediaCertificationRequestsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserMediaCertificationRequestsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserMediaCertificationRequestsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserMediaCertificationRequestsNodeAggregationWhereInput>>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  requestSignature_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  requestSignature_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  result_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserMediaCertificationRequestsRelationship = {
  __typename?: 'UserMediaCertificationRequestsRelationship';
  cursor: Scalars['String']['output'];
  node: CertificationRequest;
};

export type UserMediaCertificationRequestsUpdateConnectionInput = {
  node?: InputMaybe<CertificationRequestUpdateInput>;
};

export type UserMediaCertificationRequestsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserMediaCertificationRequestsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserMediaCertificationRequestsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserMediaCertificationRequestsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserMediaCertificationRequestsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserMediaCertificationRequestsDisconnectFieldInput>>;
  update?: InputMaybe<UserMediaCertificationRequestsUpdateConnectionInput>;
  where?: InputMaybe<UserMediaCertificationRequestsConnectionWhere>;
};

export type UserMediaCertificationResponseCertificationResponsesAggregationSelection = {
  __typename?: 'UserMediaCertificationResponseCertificationResponsesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<UserMediaCertificationResponseCertificationResponsesNodeAggregateSelection>;
};

export type UserMediaCertificationResponseCertificationResponsesNodeAggregateSelection = {
  __typename?: 'UserMediaCertificationResponseCertificationResponsesNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  result: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type UserMediaCertificationResponsesAggregateInput = {
  AND?: InputMaybe<Array<UserMediaCertificationResponsesAggregateInput>>;
  NOT?: InputMaybe<UserMediaCertificationResponsesAggregateInput>;
  OR?: InputMaybe<Array<UserMediaCertificationResponsesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<UserMediaCertificationResponsesNodeAggregationWhereInput>;
};

export type UserMediaCertificationResponsesConnectFieldInput = {
  connect?: InputMaybe<Array<CertificationResponseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CertificationResponseConnectWhere>;
};

export type UserMediaCertificationResponsesConnectOrCreateFieldInput = {
  onCreate: UserMediaCertificationResponsesConnectOrCreateFieldInputOnCreate;
  where: CertificationResponseConnectOrCreateWhere;
};

export type UserMediaCertificationResponsesConnectOrCreateFieldInputOnCreate = {
  node: CertificationResponseOnCreateInput;
};

export type UserMediaCertificationResponsesConnection = {
  __typename?: 'UserMediaCertificationResponsesConnection';
  edges: Array<UserMediaCertificationResponsesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserMediaCertificationResponsesConnectionSort = {
  node?: InputMaybe<CertificationResponseSort>;
};

export type UserMediaCertificationResponsesConnectionWhere = {
  AND?: InputMaybe<Array<UserMediaCertificationResponsesConnectionWhere>>;
  NOT?: InputMaybe<UserMediaCertificationResponsesConnectionWhere>;
  OR?: InputMaybe<Array<UserMediaCertificationResponsesConnectionWhere>>;
  node?: InputMaybe<CertificationResponseWhere>;
};

export type UserMediaCertificationResponsesCreateFieldInput = {
  node: CertificationResponseCreateInput;
};

export type UserMediaCertificationResponsesDeleteFieldInput = {
  delete?: InputMaybe<CertificationResponseDeleteInput>;
  where?: InputMaybe<UserMediaCertificationResponsesConnectionWhere>;
};

export type UserMediaCertificationResponsesDisconnectFieldInput = {
  disconnect?: InputMaybe<CertificationResponseDisconnectInput>;
  where?: InputMaybe<UserMediaCertificationResponsesConnectionWhere>;
};

export type UserMediaCertificationResponsesFieldInput = {
  connect?: InputMaybe<Array<UserMediaCertificationResponsesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserMediaCertificationResponsesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserMediaCertificationResponsesCreateFieldInput>>;
};

export type UserMediaCertificationResponsesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserMediaCertificationResponsesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserMediaCertificationResponsesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserMediaCertificationResponsesNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  result_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  result_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  result_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  result_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserMediaCertificationResponsesRelationship = {
  __typename?: 'UserMediaCertificationResponsesRelationship';
  cursor: Scalars['String']['output'];
  node: CertificationResponse;
};

export type UserMediaCertificationResponsesUpdateConnectionInput = {
  node?: InputMaybe<CertificationResponseUpdateInput>;
};

export type UserMediaCertificationResponsesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserMediaCertificationResponsesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserMediaCertificationResponsesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserMediaCertificationResponsesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserMediaCertificationResponsesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserMediaCertificationResponsesDisconnectFieldInput>>;
  update?: InputMaybe<UserMediaCertificationResponsesUpdateConnectionInput>;
  where?: InputMaybe<UserMediaCertificationResponsesConnectionWhere>;
};

export type UserMediaChannelChannelsAggregationSelection = {
  __typename?: 'UserMediaChannelChannelsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<UserMediaChannelChannelsNodeAggregateSelection>;
};

export type UserMediaChannelChannelsNodeAggregateSelection = {
  __typename?: 'UserMediaChannelChannelsNodeAggregateSelection';
  channelName: StringAggregateSelectionNullable;
  channelUrl: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type UserMediaChannelsAggregateInput = {
  AND?: InputMaybe<Array<UserMediaChannelsAggregateInput>>;
  NOT?: InputMaybe<UserMediaChannelsAggregateInput>;
  OR?: InputMaybe<Array<UserMediaChannelsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<UserMediaChannelsNodeAggregationWhereInput>;
};

export type UserMediaChannelsConnectFieldInput = {
  connect?: InputMaybe<Array<ChannelConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<ChannelConnectWhere>;
};

export type UserMediaChannelsConnectOrCreateFieldInput = {
  onCreate: UserMediaChannelsConnectOrCreateFieldInputOnCreate;
  where: ChannelConnectOrCreateWhere;
};

export type UserMediaChannelsConnectOrCreateFieldInputOnCreate = {
  node: ChannelOnCreateInput;
};

export type UserMediaChannelsConnection = {
  __typename?: 'UserMediaChannelsConnection';
  edges: Array<UserMediaChannelsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserMediaChannelsConnectionSort = {
  node?: InputMaybe<ChannelSort>;
};

export type UserMediaChannelsConnectionWhere = {
  AND?: InputMaybe<Array<UserMediaChannelsConnectionWhere>>;
  NOT?: InputMaybe<UserMediaChannelsConnectionWhere>;
  OR?: InputMaybe<Array<UserMediaChannelsConnectionWhere>>;
  node?: InputMaybe<ChannelWhere>;
};

export type UserMediaChannelsCreateFieldInput = {
  node: ChannelCreateInput;
};

export type UserMediaChannelsDeleteFieldInput = {
  delete?: InputMaybe<ChannelDeleteInput>;
  where?: InputMaybe<UserMediaChannelsConnectionWhere>;
};

export type UserMediaChannelsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChannelDisconnectInput>;
  where?: InputMaybe<UserMediaChannelsConnectionWhere>;
};

export type UserMediaChannelsFieldInput = {
  connect?: InputMaybe<Array<UserMediaChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserMediaChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserMediaChannelsCreateFieldInput>>;
};

export type UserMediaChannelsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserMediaChannelsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserMediaChannelsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserMediaChannelsNodeAggregationWhereInput>>;
  channelName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  channelUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  channelUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserMediaChannelsRelationship = {
  __typename?: 'UserMediaChannelsRelationship';
  cursor: Scalars['String']['output'];
  node: Channel;
};

export type UserMediaChannelsUpdateConnectionInput = {
  node?: InputMaybe<ChannelUpdateInput>;
};

export type UserMediaChannelsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserMediaChannelsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserMediaChannelsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserMediaChannelsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserMediaChannelsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserMediaChannelsDisconnectFieldInput>>;
  update?: InputMaybe<UserMediaChannelsUpdateConnectionInput>;
  where?: InputMaybe<UserMediaChannelsConnectionWhere>;
};

export type UserMediaConnectFieldInput = {
  connect?: InputMaybe<Array<UserMediaConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserMediaConnectWhere>;
};

export type UserMediaConnectInput = {
  certificationRequests?: InputMaybe<Array<UserMediaCertificationRequestsConnectFieldInput>>;
  certificationResponses?: InputMaybe<Array<UserMediaCertificationResponsesConnectFieldInput>>;
  channels?: InputMaybe<Array<UserMediaChannelsConnectFieldInput>>;
  createdBy?: InputMaybe<UserMediaCreatedByConnectFieldInput>;
  works?: InputMaybe<Array<UserMediaWorksConnectFieldInput>>;
};

export type UserMediaConnectOrCreateFieldInput = {
  onCreate: UserMediaConnectOrCreateFieldInputOnCreate;
  where: UserMediaConnectOrCreateWhere;
};

export type UserMediaConnectOrCreateFieldInputOnCreate = {
  node: UserMediaOnCreateInput;
};

export type UserMediaConnectOrCreateInput = {
  certificationRequests?: InputMaybe<Array<UserMediaCertificationRequestsConnectOrCreateFieldInput>>;
  certificationResponses?: InputMaybe<Array<UserMediaCertificationResponsesConnectOrCreateFieldInput>>;
  channels?: InputMaybe<Array<UserMediaChannelsConnectOrCreateFieldInput>>;
  createdBy?: InputMaybe<UserMediaCreatedByConnectOrCreateFieldInput>;
  works?: InputMaybe<Array<UserMediaWorksConnectOrCreateFieldInput>>;
};

export type UserMediaConnectOrCreateWhere = {
  node: UserMediaUniqueWhere;
};

export type UserMediaConnectWhere = {
  node: UserMediaWhere;
};

export type UserMediaConnection = {
  __typename?: 'UserMediaConnection';
  edges: Array<UserMediaRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserMediaConnectionSort = {
  node?: InputMaybe<UserMediaSort>;
};

export type UserMediaConnectionWhere = {
  AND?: InputMaybe<Array<UserMediaConnectionWhere>>;
  NOT?: InputMaybe<UserMediaConnectionWhere>;
  OR?: InputMaybe<Array<UserMediaConnectionWhere>>;
  node?: InputMaybe<UserMediaWhere>;
};

export type UserMediaCreateFieldInput = {
  node: UserMediaCreateInput;
};

export type UserMediaCreateInput = {
  autoCorrectTools?: InputMaybe<Array<AutoCorrectToolsTypes>>;
  autoCorrectToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  certificationRequests?: InputMaybe<UserMediaCertificationRequestsFieldInput>;
  certificationResponses?: InputMaybe<UserMediaCertificationResponsesFieldInput>;
  channels?: InputMaybe<UserMediaChannelsFieldInput>;
  contentLength?: InputMaybe<Scalars['Int']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<UserMediaCreatedByFieldInput>;
  encoding?: InputMaybe<Scalars['String']['input']>;
  extension?: InputMaybe<Scalars['String']['input']>;
  generativeAi?: InputMaybe<Array<GenerativeAiTypes>>;
  generativeAiExplanation?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  machineLearningTools?: InputMaybe<Array<MachineLearningToolsTypes>>;
  machineLearningToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  otherAiTools?: InputMaybe<Array<OtherAiToolsTypes>>;
  otherAiToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<MediaSourceType>;
  width?: InputMaybe<Scalars['Int']['input']>;
  works?: InputMaybe<UserMediaWorksFieldInput>;
};

export type UserMediaCreatedByAggregateInput = {
  AND?: InputMaybe<Array<UserMediaCreatedByAggregateInput>>;
  NOT?: InputMaybe<UserMediaCreatedByAggregateInput>;
  OR?: InputMaybe<Array<UserMediaCreatedByAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<UserMediaCreatedByNodeAggregationWhereInput>;
};

export type UserMediaCreatedByConnectFieldInput = {
  connect?: InputMaybe<UserConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserConnectWhere>;
};

export type UserMediaCreatedByConnectOrCreateFieldInput = {
  onCreate: UserMediaCreatedByConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type UserMediaCreatedByConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type UserMediaCreatedByConnection = {
  __typename?: 'UserMediaCreatedByConnection';
  edges: Array<UserMediaCreatedByRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserMediaCreatedByConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type UserMediaCreatedByConnectionWhere = {
  AND?: InputMaybe<Array<UserMediaCreatedByConnectionWhere>>;
  NOT?: InputMaybe<UserMediaCreatedByConnectionWhere>;
  OR?: InputMaybe<Array<UserMediaCreatedByConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type UserMediaCreatedByCreateFieldInput = {
  node: UserCreateInput;
};

export type UserMediaCreatedByDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<UserMediaCreatedByConnectionWhere>;
};

export type UserMediaCreatedByDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<UserMediaCreatedByConnectionWhere>;
};

export type UserMediaCreatedByFieldInput = {
  connect?: InputMaybe<UserMediaCreatedByConnectFieldInput>;
  connectOrCreate?: InputMaybe<UserMediaCreatedByConnectOrCreateFieldInput>;
  create?: InputMaybe<UserMediaCreatedByCreateFieldInput>;
};

export type UserMediaCreatedByNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserMediaCreatedByNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserMediaCreatedByNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserMediaCreatedByNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nickname_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nickname_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nickname_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nickname_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  picture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  picture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  picture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  picture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  picture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserMediaCreatedByRelationship = {
  __typename?: 'UserMediaCreatedByRelationship';
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserMediaCreatedByUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type UserMediaCreatedByUpdateFieldInput = {
  connect?: InputMaybe<UserMediaCreatedByConnectFieldInput>;
  connectOrCreate?: InputMaybe<UserMediaCreatedByConnectOrCreateFieldInput>;
  create?: InputMaybe<UserMediaCreatedByCreateFieldInput>;
  delete?: InputMaybe<UserMediaCreatedByDeleteFieldInput>;
  disconnect?: InputMaybe<UserMediaCreatedByDisconnectFieldInput>;
  update?: InputMaybe<UserMediaCreatedByUpdateConnectionInput>;
  where?: InputMaybe<UserMediaCreatedByConnectionWhere>;
};

export type UserMediaDeleteFieldInput = {
  delete?: InputMaybe<UserMediaDeleteInput>;
  where?: InputMaybe<UserMediaConnectionWhere>;
};

export type UserMediaDeleteInput = {
  certificationRequests?: InputMaybe<Array<UserMediaCertificationRequestsDeleteFieldInput>>;
  certificationResponses?: InputMaybe<Array<UserMediaCertificationResponsesDeleteFieldInput>>;
  channels?: InputMaybe<Array<UserMediaChannelsDeleteFieldInput>>;
  createdBy?: InputMaybe<UserMediaCreatedByDeleteFieldInput>;
  works?: InputMaybe<Array<UserMediaWorksDeleteFieldInput>>;
};

export type UserMediaDisconnectFieldInput = {
  disconnect?: InputMaybe<UserMediaDisconnectInput>;
  where?: InputMaybe<UserMediaConnectionWhere>;
};

export type UserMediaDisconnectInput = {
  certificationRequests?: InputMaybe<Array<UserMediaCertificationRequestsDisconnectFieldInput>>;
  certificationResponses?: InputMaybe<Array<UserMediaCertificationResponsesDisconnectFieldInput>>;
  channels?: InputMaybe<Array<UserMediaChannelsDisconnectFieldInput>>;
  createdBy?: InputMaybe<UserMediaCreatedByDisconnectFieldInput>;
  works?: InputMaybe<Array<UserMediaWorksDisconnectFieldInput>>;
};

export type UserMediaEdge = {
  __typename?: 'UserMediaEdge';
  cursor: Scalars['String']['output'];
  node: UserMedia;
};

export type UserMediaFieldInput = {
  connect?: InputMaybe<Array<UserMediaConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserMediaConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserMediaCreateFieldInput>>;
};

export type UserMediaNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserMediaNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserMediaNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserMediaNodeAggregationWhereInput>>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  caption_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  encoding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  extension_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  height_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  source_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  width_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  width_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type UserMediaOnCreateInput = {
  autoCorrectTools?: InputMaybe<Array<AutoCorrectToolsTypes>>;
  autoCorrectToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  contentLength?: InputMaybe<Scalars['Int']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  encoding?: InputMaybe<Scalars['String']['input']>;
  extension?: InputMaybe<Scalars['String']['input']>;
  generativeAi?: InputMaybe<Array<GenerativeAiTypes>>;
  generativeAiExplanation?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  machineLearningTools?: InputMaybe<Array<MachineLearningToolsTypes>>;
  machineLearningToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  otherAiTools?: InputMaybe<Array<OtherAiToolsTypes>>;
  otherAiToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<MediaSourceType>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UserMediaOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more UserMediaSort objects to sort UserMedias by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserMediaSort>>;
};

export type UserMediaRelationInput = {
  certificationRequests?: InputMaybe<Array<UserMediaCertificationRequestsCreateFieldInput>>;
  certificationResponses?: InputMaybe<Array<UserMediaCertificationResponsesCreateFieldInput>>;
  channels?: InputMaybe<Array<UserMediaChannelsCreateFieldInput>>;
  createdBy?: InputMaybe<UserMediaCreatedByCreateFieldInput>;
  works?: InputMaybe<Array<UserMediaWorksCreateFieldInput>>;
};

export type UserMediaRelationship = {
  __typename?: 'UserMediaRelationship';
  cursor: Scalars['String']['output'];
  node: UserMedia;
};

/** Fields to sort UserMedias by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserMediaSort object. */
export type UserMediaSort = {
  autoCorrectToolsExplanation?: InputMaybe<SortDirection>;
  caption?: InputMaybe<SortDirection>;
  contentLength?: InputMaybe<SortDirection>;
  contentType?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  encoding?: InputMaybe<SortDirection>;
  extension?: InputMaybe<SortDirection>;
  generativeAiExplanation?: InputMaybe<SortDirection>;
  height?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  machineLearningToolsExplanation?: InputMaybe<SortDirection>;
  otherAiToolsExplanation?: InputMaybe<SortDirection>;
  source?: InputMaybe<SortDirection>;
  sourceType?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
  width?: InputMaybe<SortDirection>;
};

export type UserMediaUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UserMediaUpdateConnectionInput = {
  node?: InputMaybe<UserMediaUpdateInput>;
};

export type UserMediaUpdateFieldInput = {
  connect?: InputMaybe<Array<UserMediaConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserMediaConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserMediaCreateFieldInput>>;
  delete?: InputMaybe<Array<UserMediaDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserMediaDisconnectFieldInput>>;
  update?: InputMaybe<UserMediaUpdateConnectionInput>;
  where?: InputMaybe<UserMediaConnectionWhere>;
};

export type UserMediaUpdateInput = {
  autoCorrectTools?: InputMaybe<Array<AutoCorrectToolsTypes>>;
  autoCorrectToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  certificationRequests?: InputMaybe<Array<UserMediaCertificationRequestsUpdateFieldInput>>;
  certificationResponses?: InputMaybe<Array<UserMediaCertificationResponsesUpdateFieldInput>>;
  channels?: InputMaybe<Array<UserMediaChannelsUpdateFieldInput>>;
  contentLength?: InputMaybe<Scalars['Int']['input']>;
  contentLength_DECREMENT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_INCREMENT?: InputMaybe<Scalars['Int']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserMediaCreatedByUpdateFieldInput>;
  encoding?: InputMaybe<Scalars['String']['input']>;
  extension?: InputMaybe<Scalars['String']['input']>;
  generativeAi?: InputMaybe<Array<GenerativeAiTypes>>;
  generativeAiExplanation?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_DECREMENT?: InputMaybe<Scalars['Int']['input']>;
  height_INCREMENT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningTools?: InputMaybe<Array<MachineLearningToolsTypes>>;
  machineLearningToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  otherAiTools?: InputMaybe<Array<OtherAiToolsTypes>>;
  otherAiToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<MediaSourceType>;
  width?: InputMaybe<Scalars['Int']['input']>;
  width_DECREMENT?: InputMaybe<Scalars['Int']['input']>;
  width_INCREMENT?: InputMaybe<Scalars['Int']['input']>;
  works?: InputMaybe<Array<UserMediaWorksUpdateFieldInput>>;
};

export type UserMediaUserCreatedByAggregationSelection = {
  __typename?: 'UserMediaUserCreatedByAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<UserMediaUserCreatedByNodeAggregateSelection>;
};

export type UserMediaUserCreatedByNodeAggregateSelection = {
  __typename?: 'UserMediaUserCreatedByNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  email: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  nickname: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  picture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  userId: IdAggregateSelectionNonNullable;
};

export type UserMediaWhere = {
  AND?: InputMaybe<Array<UserMediaWhere>>;
  NOT?: InputMaybe<UserMediaWhere>;
  OR?: InputMaybe<Array<UserMediaWhere>>;
  autoCorrectTools?: InputMaybe<Array<AutoCorrectToolsTypes>>;
  autoCorrectToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  autoCorrectToolsExplanation_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  autoCorrectToolsExplanation_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  autoCorrectToolsExplanation_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  autoCorrectToolsExplanation_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  autoCorrectTools_INCLUDES?: InputMaybe<AutoCorrectToolsTypes>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  caption_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  caption_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  certificationRequestsAggregate?: InputMaybe<UserMediaCertificationRequestsAggregateInput>;
  /** Return UserMedias where all of the related UserMediaCertificationRequestsConnections match this filter */
  certificationRequestsConnection_ALL?: InputMaybe<UserMediaCertificationRequestsConnectionWhere>;
  /** Return UserMedias where none of the related UserMediaCertificationRequestsConnections match this filter */
  certificationRequestsConnection_NONE?: InputMaybe<UserMediaCertificationRequestsConnectionWhere>;
  /** Return UserMedias where one of the related UserMediaCertificationRequestsConnections match this filter */
  certificationRequestsConnection_SINGLE?: InputMaybe<UserMediaCertificationRequestsConnectionWhere>;
  /** Return UserMedias where some of the related UserMediaCertificationRequestsConnections match this filter */
  certificationRequestsConnection_SOME?: InputMaybe<UserMediaCertificationRequestsConnectionWhere>;
  /** Return UserMedias where all of the related CertificationRequests match this filter */
  certificationRequests_ALL?: InputMaybe<CertificationRequestWhere>;
  /** Return UserMedias where none of the related CertificationRequests match this filter */
  certificationRequests_NONE?: InputMaybe<CertificationRequestWhere>;
  /** Return UserMedias where one of the related CertificationRequests match this filter */
  certificationRequests_SINGLE?: InputMaybe<CertificationRequestWhere>;
  /** Return UserMedias where some of the related CertificationRequests match this filter */
  certificationRequests_SOME?: InputMaybe<CertificationRequestWhere>;
  certificationResponsesAggregate?: InputMaybe<UserMediaCertificationResponsesAggregateInput>;
  /** Return UserMedias where all of the related UserMediaCertificationResponsesConnections match this filter */
  certificationResponsesConnection_ALL?: InputMaybe<UserMediaCertificationResponsesConnectionWhere>;
  /** Return UserMedias where none of the related UserMediaCertificationResponsesConnections match this filter */
  certificationResponsesConnection_NONE?: InputMaybe<UserMediaCertificationResponsesConnectionWhere>;
  /** Return UserMedias where one of the related UserMediaCertificationResponsesConnections match this filter */
  certificationResponsesConnection_SINGLE?: InputMaybe<UserMediaCertificationResponsesConnectionWhere>;
  /** Return UserMedias where some of the related UserMediaCertificationResponsesConnections match this filter */
  certificationResponsesConnection_SOME?: InputMaybe<UserMediaCertificationResponsesConnectionWhere>;
  /** Return UserMedias where all of the related CertificationResponses match this filter */
  certificationResponses_ALL?: InputMaybe<CertificationResponseWhere>;
  /** Return UserMedias where none of the related CertificationResponses match this filter */
  certificationResponses_NONE?: InputMaybe<CertificationResponseWhere>;
  /** Return UserMedias where one of the related CertificationResponses match this filter */
  certificationResponses_SINGLE?: InputMaybe<CertificationResponseWhere>;
  /** Return UserMedias where some of the related CertificationResponses match this filter */
  certificationResponses_SOME?: InputMaybe<CertificationResponseWhere>;
  channelsAggregate?: InputMaybe<UserMediaChannelsAggregateInput>;
  /** Return UserMedias where all of the related UserMediaChannelsConnections match this filter */
  channelsConnection_ALL?: InputMaybe<UserMediaChannelsConnectionWhere>;
  /** Return UserMedias where none of the related UserMediaChannelsConnections match this filter */
  channelsConnection_NONE?: InputMaybe<UserMediaChannelsConnectionWhere>;
  /** Return UserMedias where one of the related UserMediaChannelsConnections match this filter */
  channelsConnection_SINGLE?: InputMaybe<UserMediaChannelsConnectionWhere>;
  /** Return UserMedias where some of the related UserMediaChannelsConnections match this filter */
  channelsConnection_SOME?: InputMaybe<UserMediaChannelsConnectionWhere>;
  /** Return UserMedias where all of the related Channels match this filter */
  channels_ALL?: InputMaybe<ChannelWhere>;
  /** Return UserMedias where none of the related Channels match this filter */
  channels_NONE?: InputMaybe<ChannelWhere>;
  /** Return UserMedias where one of the related Channels match this filter */
  channels_SINGLE?: InputMaybe<ChannelWhere>;
  /** Return UserMedias where some of the related Channels match this filter */
  channels_SOME?: InputMaybe<ChannelWhere>;
  contentLength?: InputMaybe<Scalars['Int']['input']>;
  contentLength_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_IN?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contentLength_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentType_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  contentType_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  contentType_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentType_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserWhere>;
  createdByAggregate?: InputMaybe<UserMediaCreatedByAggregateInput>;
  createdByConnection?: InputMaybe<UserMediaCreatedByConnectionWhere>;
  createdByConnection_NOT?: InputMaybe<UserMediaCreatedByConnectionWhere>;
  createdBy_NOT?: InputMaybe<UserWhere>;
  encoding?: InputMaybe<Scalars['String']['input']>;
  encoding_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  encoding_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  encoding_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  encoding_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  extension?: InputMaybe<Scalars['String']['input']>;
  extension_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  extension_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  extension_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  extension_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  generativeAi?: InputMaybe<Array<GenerativeAiTypes>>;
  generativeAiExplanation?: InputMaybe<Scalars['String']['input']>;
  generativeAiExplanation_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  generativeAiExplanation_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  generativeAiExplanation_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  generativeAiExplanation_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  generativeAi_INCLUDES?: InputMaybe<GenerativeAiTypes>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_GT?: InputMaybe<Scalars['Int']['input']>;
  height_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_IN?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  height_LT?: InputMaybe<Scalars['Int']['input']>;
  height_LTE?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  machineLearningTools?: InputMaybe<Array<MachineLearningToolsTypes>>;
  machineLearningToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  machineLearningToolsExplanation_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  machineLearningToolsExplanation_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  machineLearningToolsExplanation_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  machineLearningToolsExplanation_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  machineLearningTools_INCLUDES?: InputMaybe<MachineLearningToolsTypes>;
  otherAiTools?: InputMaybe<Array<OtherAiToolsTypes>>;
  otherAiToolsExplanation?: InputMaybe<Scalars['String']['input']>;
  otherAiToolsExplanation_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  otherAiToolsExplanation_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  otherAiToolsExplanation_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  otherAiToolsExplanation_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  otherAiTools_INCLUDES?: InputMaybe<OtherAiToolsTypes>;
  source?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<MediaSourceType>;
  sourceType_IN?: InputMaybe<Array<InputMaybe<MediaSourceType>>>;
  source_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  source_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  source_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  source_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
  width_GT?: InputMaybe<Scalars['Int']['input']>;
  width_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_IN?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  width_LT?: InputMaybe<Scalars['Int']['input']>;
  width_LTE?: InputMaybe<Scalars['Int']['input']>;
  worksAggregate?: InputMaybe<UserMediaWorksAggregateInput>;
  /** Return UserMedias where all of the related UserMediaWorksConnections match this filter */
  worksConnection_ALL?: InputMaybe<UserMediaWorksConnectionWhere>;
  /** Return UserMedias where none of the related UserMediaWorksConnections match this filter */
  worksConnection_NONE?: InputMaybe<UserMediaWorksConnectionWhere>;
  /** Return UserMedias where one of the related UserMediaWorksConnections match this filter */
  worksConnection_SINGLE?: InputMaybe<UserMediaWorksConnectionWhere>;
  /** Return UserMedias where some of the related UserMediaWorksConnections match this filter */
  worksConnection_SOME?: InputMaybe<UserMediaWorksConnectionWhere>;
  /** Return UserMedias where all of the related Works match this filter */
  works_ALL?: InputMaybe<WorkWhere>;
  /** Return UserMedias where none of the related Works match this filter */
  works_NONE?: InputMaybe<WorkWhere>;
  /** Return UserMedias where one of the related Works match this filter */
  works_SINGLE?: InputMaybe<WorkWhere>;
  /** Return UserMedias where some of the related Works match this filter */
  works_SOME?: InputMaybe<WorkWhere>;
};

export type UserMediaWorkWorksAggregationSelection = {
  __typename?: 'UserMediaWorkWorksAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<UserMediaWorkWorksNodeAggregateSelection>;
};

export type UserMediaWorkWorksNodeAggregateSelection = {
  __typename?: 'UserMediaWorkWorksNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  workUrl: StringAggregateSelectionNullable;
};

export type UserMediaWorksAggregateInput = {
  AND?: InputMaybe<Array<UserMediaWorksAggregateInput>>;
  NOT?: InputMaybe<UserMediaWorksAggregateInput>;
  OR?: InputMaybe<Array<UserMediaWorksAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<UserMediaWorksNodeAggregationWhereInput>;
};

export type UserMediaWorksConnectFieldInput = {
  connect?: InputMaybe<Array<WorkConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<WorkConnectWhere>;
};

export type UserMediaWorksConnectOrCreateFieldInput = {
  onCreate: UserMediaWorksConnectOrCreateFieldInputOnCreate;
  where: WorkConnectOrCreateWhere;
};

export type UserMediaWorksConnectOrCreateFieldInputOnCreate = {
  node: WorkOnCreateInput;
};

export type UserMediaWorksConnection = {
  __typename?: 'UserMediaWorksConnection';
  edges: Array<UserMediaWorksRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserMediaWorksConnectionSort = {
  node?: InputMaybe<WorkSort>;
};

export type UserMediaWorksConnectionWhere = {
  AND?: InputMaybe<Array<UserMediaWorksConnectionWhere>>;
  NOT?: InputMaybe<UserMediaWorksConnectionWhere>;
  OR?: InputMaybe<Array<UserMediaWorksConnectionWhere>>;
  node?: InputMaybe<WorkWhere>;
};

export type UserMediaWorksCreateFieldInput = {
  node: WorkCreateInput;
};

export type UserMediaWorksDeleteFieldInput = {
  delete?: InputMaybe<WorkDeleteInput>;
  where?: InputMaybe<UserMediaWorksConnectionWhere>;
};

export type UserMediaWorksDisconnectFieldInput = {
  disconnect?: InputMaybe<WorkDisconnectInput>;
  where?: InputMaybe<UserMediaWorksConnectionWhere>;
};

export type UserMediaWorksFieldInput = {
  connect?: InputMaybe<Array<UserMediaWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserMediaWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserMediaWorksCreateFieldInput>>;
};

export type UserMediaWorksNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserMediaWorksNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserMediaWorksNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserMediaWorksNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  workUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  workUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  workUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  workUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type UserMediaWorksRelationship = {
  __typename?: 'UserMediaWorksRelationship';
  cursor: Scalars['String']['output'];
  node: Work;
};

export type UserMediaWorksUpdateConnectionInput = {
  node?: InputMaybe<WorkUpdateInput>;
};

export type UserMediaWorksUpdateFieldInput = {
  connect?: InputMaybe<Array<UserMediaWorksConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserMediaWorksConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserMediaWorksCreateFieldInput>>;
  delete?: InputMaybe<Array<UserMediaWorksDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserMediaWorksDisconnectFieldInput>>;
  update?: InputMaybe<UserMediaWorksUpdateConnectionInput>;
  where?: InputMaybe<UserMediaWorksConnectionWhere>;
};

export type UserMediasConnection = {
  __typename?: 'UserMediasConnection';
  edges: Array<UserMediaEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserOnCreateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  userType?: InputMaybe<UserType>;
};

export type UserOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more UserSort objects to sort Users by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserSort>>;
};

export type UserRelationInput = {
  account?: InputMaybe<UserAccountCreateFieldInput>;
  certificationRequests?: InputMaybe<Array<UserCertificationRequestsCreateFieldInput>>;
  certificationResponses?: InputMaybe<Array<UserCertificationResponsesCreateFieldInput>>;
  media?: InputMaybe<Array<UserMediaCreateFieldInput>>;
};

/** Fields to sort Users by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserSort object. */
export type UserSort = {
  createdAt?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  nickname?: InputMaybe<SortDirection>;
  nutshellContactId?: InputMaybe<SortDirection>;
  nutshellLeadId?: InputMaybe<SortDirection>;
  nutshellLeadNumber?: InputMaybe<SortDirection>;
  picture?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
  userId?: InputMaybe<SortDirection>;
  userType?: InputMaybe<SortDirection>;
};

export enum UserType {
  Collaborator = 'COLLABORATOR',
  Creator = 'CREATOR',
  Llm = 'LLM',
  Owner = 'OWNER'
}

export type UserUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserUpdateInput = {
  account?: InputMaybe<UserAccountUpdateFieldInput>;
  certificationRequests?: InputMaybe<Array<UserCertificationRequestsUpdateFieldInput>>;
  certificationResponses?: InputMaybe<Array<UserCertificationResponsesUpdateFieldInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<Array<UserMediaUpdateFieldInput>>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  userType?: InputMaybe<UserType>;
};

export type UserUserMediaMediaAggregationSelection = {
  __typename?: 'UserUserMediaMediaAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<UserUserMediaMediaNodeAggregateSelection>;
};

export type UserUserMediaMediaNodeAggregateSelection = {
  __typename?: 'UserUserMediaMediaNodeAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  caption: StringAggregateSelectionNullable;
  contentLength: IntAggregateSelectionNullable;
  contentType: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  encoding: StringAggregateSelectionNullable;
  extension: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  height: IntAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  source: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  width: IntAggregateSelectionNullable;
};

export type UserWhere = {
  AND?: InputMaybe<Array<UserWhere>>;
  NOT?: InputMaybe<UserWhere>;
  OR?: InputMaybe<Array<UserWhere>>;
  account?: InputMaybe<AccountWhere>;
  accountAggregate?: InputMaybe<UserAccountAggregateInput>;
  accountConnection?: InputMaybe<UserAccountConnectionWhere>;
  accountConnection_NOT?: InputMaybe<UserAccountConnectionWhere>;
  account_NOT?: InputMaybe<AccountWhere>;
  certificationRequestsAggregate?: InputMaybe<UserCertificationRequestsAggregateInput>;
  /** Return Users where all of the related UserCertificationRequestsConnections match this filter */
  certificationRequestsConnection_ALL?: InputMaybe<UserCertificationRequestsConnectionWhere>;
  /** Return Users where none of the related UserCertificationRequestsConnections match this filter */
  certificationRequestsConnection_NONE?: InputMaybe<UserCertificationRequestsConnectionWhere>;
  /** Return Users where one of the related UserCertificationRequestsConnections match this filter */
  certificationRequestsConnection_SINGLE?: InputMaybe<UserCertificationRequestsConnectionWhere>;
  /** Return Users where some of the related UserCertificationRequestsConnections match this filter */
  certificationRequestsConnection_SOME?: InputMaybe<UserCertificationRequestsConnectionWhere>;
  /** Return Users where all of the related CertificationRequests match this filter */
  certificationRequests_ALL?: InputMaybe<CertificationRequestWhere>;
  /** Return Users where none of the related CertificationRequests match this filter */
  certificationRequests_NONE?: InputMaybe<CertificationRequestWhere>;
  /** Return Users where one of the related CertificationRequests match this filter */
  certificationRequests_SINGLE?: InputMaybe<CertificationRequestWhere>;
  /** Return Users where some of the related CertificationRequests match this filter */
  certificationRequests_SOME?: InputMaybe<CertificationRequestWhere>;
  certificationResponsesAggregate?: InputMaybe<UserCertificationResponsesAggregateInput>;
  /** Return Users where all of the related UserCertificationResponsesConnections match this filter */
  certificationResponsesConnection_ALL?: InputMaybe<UserCertificationResponsesConnectionWhere>;
  /** Return Users where none of the related UserCertificationResponsesConnections match this filter */
  certificationResponsesConnection_NONE?: InputMaybe<UserCertificationResponsesConnectionWhere>;
  /** Return Users where one of the related UserCertificationResponsesConnections match this filter */
  certificationResponsesConnection_SINGLE?: InputMaybe<UserCertificationResponsesConnectionWhere>;
  /** Return Users where some of the related UserCertificationResponsesConnections match this filter */
  certificationResponsesConnection_SOME?: InputMaybe<UserCertificationResponsesConnectionWhere>;
  /** Return Users where all of the related CertificationResponses match this filter */
  certificationResponses_ALL?: InputMaybe<CertificationResponseWhere>;
  /** Return Users where none of the related CertificationResponses match this filter */
  certificationResponses_NONE?: InputMaybe<CertificationResponseWhere>;
  /** Return Users where one of the related CertificationResponses match this filter */
  certificationResponses_SINGLE?: InputMaybe<CertificationResponseWhere>;
  /** Return Users where some of the related CertificationResponses match this filter */
  certificationResponses_SOME?: InputMaybe<CertificationResponseWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  email_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  email_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  mediaAggregate?: InputMaybe<UserMediaAggregateInput>;
  /** Return Users where all of the related UserMediaConnections match this filter */
  mediaConnection_ALL?: InputMaybe<UserMediaConnectionWhere>;
  /** Return Users where none of the related UserMediaConnections match this filter */
  mediaConnection_NONE?: InputMaybe<UserMediaConnectionWhere>;
  /** Return Users where one of the related UserMediaConnections match this filter */
  mediaConnection_SINGLE?: InputMaybe<UserMediaConnectionWhere>;
  /** Return Users where some of the related UserMediaConnections match this filter */
  mediaConnection_SOME?: InputMaybe<UserMediaConnectionWhere>;
  /** Return Users where all of the related UserMedias match this filter */
  media_ALL?: InputMaybe<UserMediaWhere>;
  /** Return Users where none of the related UserMedias match this filter */
  media_NONE?: InputMaybe<UserMediaWhere>;
  /** Return Users where one of the related UserMedias match this filter */
  media_SINGLE?: InputMaybe<UserMediaWhere>;
  /** Return Users where some of the related UserMedias match this filter */
  media_SOME?: InputMaybe<UserMediaWhere>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  nickname_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nickname_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nickname_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nickname_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellContactId_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nutshellContactId_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadId_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nutshellLeadId_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  nutshellLeadNumber_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nutshellLeadNumber_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  picture_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  picture_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  picture_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  picture_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  userId_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  userId_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  userId_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  userId_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  userType?: InputMaybe<UserType>;
  userType_IN?: InputMaybe<Array<InputMaybe<UserType>>>;
};

export type UsersConnection = {
  __typename?: 'UsersConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VerifyUserIdentityResponse = {
  __typename?: 'VerifyUserIdentityResponse';
  error?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type Work = {
  __typename?: 'Work';
  /** Work's Connected Accounts */
  accounts: Array<Account>;
  accountsAggregate?: Maybe<WorkAccountAccountsAggregationSelection>;
  accountsConnection: WorkAccountsConnection;
  /** Work's Content Categories (e.g., gaming, lifestyle, tech). Multi-select options, including an Other choices. */
  categories: Array<CategoryTag>;
  categoriesAggregate?: Maybe<WorkCategoryTagCategoriesAggregationSelection>;
  categoriesConnection: WorkCategoriesConnection;
  /** Work's content badges */
  contentBadges: Array<ContentBadge>;
  contentBadgesAggregate?: Maybe<WorkContentBadgeContentBadgesAggregationSelection>;
  contentBadgesConnection: WorkContentBadgesConnection;
  /** Automatic timestamp on create */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Creators of the Work */
  creators: Array<Creator>;
  creatorsAggregate?: Maybe<WorkCreatorCreatorsAggregationSelection>;
  creatorsConnection: WorkCreatorsConnection;
  /** Description of work */
  description?: Maybe<Scalars['String']['output']>;
  /** Work's Editions */
  editions: Array<Edition>;
  editionsAggregate?: Maybe<WorkEditionEditionsAggregationSelection>;
  editionsConnection: WorkEditionsConnection;
  /** System generated GUID */
  id: Scalars['ID']['output'];
  /** User Media */
  media: Array<UserMedia>;
  mediaAggregate?: Maybe<WorkUserMediaMediaAggregationSelection>;
  mediaConnection: WorkMediaConnection;
  /** Rights Owners of the Work */
  owners: Array<Owner>;
  ownersAggregate?: Maybe<WorkOwnerOwnersAggregationSelection>;
  ownersConnection: WorkOwnersConnection;
  /** Creators Freeform Tags */
  tags: Array<Tag>;
  tagsAggregate?: Maybe<WorkTagTagsAggregationSelection>;
  tagsConnection: WorkTagsConnection;
  /** User Media as thumbnail */
  thumbnail?: Maybe<UserMedia>;
  thumbnailAggregate?: Maybe<WorkUserMediaThumbnailAggregationSelection>;
  thumbnailConnection: WorkThumbnailConnection;
  /** Work Title */
  title?: Maybe<Scalars['String']['output']>;
  /** Automatic timestamp on update */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Work's Content Types */
  workTypes: Array<TypeTag>;
  workTypesAggregate?: Maybe<WorkTypeTagWorkTypesAggregationSelection>;
  workTypesConnection: WorkWorkTypesConnection;
  /** Work's Canonical URL */
  workUrl?: Maybe<Scalars['String']['output']>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkAccountsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<AccountOptions>;
  where?: InputMaybe<AccountWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkAccountsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccountWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkAccountsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkAccountsConnectionSort>>;
  where?: InputMaybe<WorkAccountsConnectionWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkCategoriesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CategoryTagOptions>;
  where?: InputMaybe<CategoryTagWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkCategoriesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CategoryTagWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkCategoriesConnectionSort>>;
  where?: InputMaybe<WorkCategoriesConnectionWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkContentBadgesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<ContentBadgeOptions>;
  where?: InputMaybe<ContentBadgeWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkContentBadgesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ContentBadgeWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkContentBadgesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkContentBadgesConnectionSort>>;
  where?: InputMaybe<WorkContentBadgesConnectionWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkCreatorsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<CreatorOptions>;
  where?: InputMaybe<CreatorWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkCreatorsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CreatorWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkCreatorsConnectionSort>>;
  where?: InputMaybe<WorkCreatorsConnectionWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkEditionsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<EditionOptions>;
  where?: InputMaybe<EditionWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkEditionsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<EditionWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkEditionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkEditionsConnectionSort>>;
  where?: InputMaybe<WorkEditionsConnectionWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkMediaArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserMediaOptions>;
  where?: InputMaybe<UserMediaWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkMediaAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserMediaWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkMediaConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkMediaConnectionSort>>;
  where?: InputMaybe<WorkMediaConnectionWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkOwnersArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<OwnerOptions>;
  where?: InputMaybe<OwnerWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkOwnersAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<OwnerWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkOwnersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkOwnersConnectionSort>>;
  where?: InputMaybe<WorkOwnersConnectionWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkTagsArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TagOptions>;
  where?: InputMaybe<TagWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkTagsAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TagWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkTagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkTagsConnectionSort>>;
  where?: InputMaybe<WorkTagsConnectionWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkThumbnailArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<UserMediaOptions>;
  where?: InputMaybe<UserMediaWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkThumbnailAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UserMediaWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkThumbnailConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkThumbnailConnectionSort>>;
  where?: InputMaybe<WorkThumbnailConnectionWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkWorkTypesArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TypeTagOptions>;
  where?: InputMaybe<TypeTagWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkWorkTypesAggregateArgs = {
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TypeTagWhere>;
};


/** A Work can have one or more Creators and one or more Editions, each with its own URL and Rights Owners */
export type WorkWorkTypesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  directed?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WorkWorkTypesConnectionSort>>;
  where?: InputMaybe<WorkWorkTypesConnectionWhere>;
};

export type WorkAccountAccountsAggregationSelection = {
  __typename?: 'WorkAccountAccountsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<WorkAccountAccountsNodeAggregateSelection>;
};

export type WorkAccountAccountsNodeAggregateSelection = {
  __typename?: 'WorkAccountAccountsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  joinedDate: StringAggregateSelectionNullable;
  lastActive: StringAggregateSelectionNullable;
  profileURL: StringAggregateSelectionNullable;
  taxId: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  username: StringAggregateSelectionNullable;
};

export type WorkAccountsAggregateInput = {
  AND?: InputMaybe<Array<WorkAccountsAggregateInput>>;
  NOT?: InputMaybe<WorkAccountsAggregateInput>;
  OR?: InputMaybe<Array<WorkAccountsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<WorkAccountsNodeAggregationWhereInput>;
};

export type WorkAccountsConnectFieldInput = {
  connect?: InputMaybe<Array<AccountConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<AccountConnectWhere>;
};

export type WorkAccountsConnectOrCreateFieldInput = {
  onCreate: WorkAccountsConnectOrCreateFieldInputOnCreate;
  where: AccountConnectOrCreateWhere;
};

export type WorkAccountsConnectOrCreateFieldInputOnCreate = {
  node: AccountOnCreateInput;
};

export type WorkAccountsConnection = {
  __typename?: 'WorkAccountsConnection';
  edges: Array<WorkAccountsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkAccountsConnectionSort = {
  node?: InputMaybe<AccountSort>;
};

export type WorkAccountsConnectionWhere = {
  AND?: InputMaybe<Array<WorkAccountsConnectionWhere>>;
  NOT?: InputMaybe<WorkAccountsConnectionWhere>;
  OR?: InputMaybe<Array<WorkAccountsConnectionWhere>>;
  node?: InputMaybe<AccountWhere>;
};

export type WorkAccountsCreateFieldInput = {
  node: AccountCreateInput;
};

export type WorkAccountsDeleteFieldInput = {
  delete?: InputMaybe<AccountDeleteInput>;
  where?: InputMaybe<WorkAccountsConnectionWhere>;
};

export type WorkAccountsDisconnectFieldInput = {
  disconnect?: InputMaybe<AccountDisconnectInput>;
  where?: InputMaybe<WorkAccountsConnectionWhere>;
};

export type WorkAccountsFieldInput = {
  connect?: InputMaybe<Array<WorkAccountsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkAccountsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkAccountsCreateFieldInput>>;
};

export type WorkAccountsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WorkAccountsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WorkAccountsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WorkAccountsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  joinedDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  joinedDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  joinedDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastActive_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastActive_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastActive_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  taxId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  taxId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  taxId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkAccountsRelationship = {
  __typename?: 'WorkAccountsRelationship';
  cursor: Scalars['String']['output'];
  node: Account;
};

export type WorkAccountsUpdateConnectionInput = {
  node?: InputMaybe<AccountUpdateInput>;
};

export type WorkAccountsUpdateFieldInput = {
  connect?: InputMaybe<Array<WorkAccountsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkAccountsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkAccountsCreateFieldInput>>;
  delete?: InputMaybe<Array<WorkAccountsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WorkAccountsDisconnectFieldInput>>;
  update?: InputMaybe<WorkAccountsUpdateConnectionInput>;
  where?: InputMaybe<WorkAccountsConnectionWhere>;
};

export type WorkAggregateSelection = {
  __typename?: 'WorkAggregateSelection';
  count: Scalars['Int']['output'];
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  workUrl: StringAggregateSelectionNullable;
};

export type WorkCategoriesAggregateInput = {
  AND?: InputMaybe<Array<WorkCategoriesAggregateInput>>;
  NOT?: InputMaybe<WorkCategoriesAggregateInput>;
  OR?: InputMaybe<Array<WorkCategoriesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<WorkCategoriesNodeAggregationWhereInput>;
};

export type WorkCategoriesConnectFieldInput = {
  connect?: InputMaybe<Array<CategoryTagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CategoryTagConnectWhere>;
};

export type WorkCategoriesConnectOrCreateFieldInput = {
  onCreate: WorkCategoriesConnectOrCreateFieldInputOnCreate;
  where: CategoryTagConnectOrCreateWhere;
};

export type WorkCategoriesConnectOrCreateFieldInputOnCreate = {
  node: CategoryTagOnCreateInput;
};

export type WorkCategoriesConnection = {
  __typename?: 'WorkCategoriesConnection';
  edges: Array<WorkCategoriesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkCategoriesConnectionSort = {
  node?: InputMaybe<CategoryTagSort>;
};

export type WorkCategoriesConnectionWhere = {
  AND?: InputMaybe<Array<WorkCategoriesConnectionWhere>>;
  NOT?: InputMaybe<WorkCategoriesConnectionWhere>;
  OR?: InputMaybe<Array<WorkCategoriesConnectionWhere>>;
  node?: InputMaybe<CategoryTagWhere>;
};

export type WorkCategoriesCreateFieldInput = {
  node: CategoryTagCreateInput;
};

export type WorkCategoriesDeleteFieldInput = {
  delete?: InputMaybe<CategoryTagDeleteInput>;
  where?: InputMaybe<WorkCategoriesConnectionWhere>;
};

export type WorkCategoriesDisconnectFieldInput = {
  disconnect?: InputMaybe<CategoryTagDisconnectInput>;
  where?: InputMaybe<WorkCategoriesConnectionWhere>;
};

export type WorkCategoriesFieldInput = {
  connect?: InputMaybe<Array<WorkCategoriesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkCategoriesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkCategoriesCreateFieldInput>>;
};

export type WorkCategoriesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WorkCategoriesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WorkCategoriesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WorkCategoriesNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  icon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  icon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  icon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  icon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  icon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  tier_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WorkCategoriesRelationship = {
  __typename?: 'WorkCategoriesRelationship';
  cursor: Scalars['String']['output'];
  node: CategoryTag;
};

export type WorkCategoriesUpdateConnectionInput = {
  node?: InputMaybe<CategoryTagUpdateInput>;
};

export type WorkCategoriesUpdateFieldInput = {
  connect?: InputMaybe<Array<WorkCategoriesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkCategoriesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkCategoriesCreateFieldInput>>;
  delete?: InputMaybe<Array<WorkCategoriesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WorkCategoriesDisconnectFieldInput>>;
  update?: InputMaybe<WorkCategoriesUpdateConnectionInput>;
  where?: InputMaybe<WorkCategoriesConnectionWhere>;
};

export type WorkCategoryTagCategoriesAggregationSelection = {
  __typename?: 'WorkCategoryTagCategoriesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<WorkCategoryTagCategoriesNodeAggregateSelection>;
};

export type WorkCategoryTagCategoriesNodeAggregateSelection = {
  __typename?: 'WorkCategoryTagCategoriesNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  icon: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type WorkConnectInput = {
  accounts?: InputMaybe<Array<WorkAccountsConnectFieldInput>>;
  categories?: InputMaybe<Array<WorkCategoriesConnectFieldInput>>;
  contentBadges?: InputMaybe<Array<WorkContentBadgesConnectFieldInput>>;
  creators?: InputMaybe<Array<WorkCreatorsConnectFieldInput>>;
  editions?: InputMaybe<Array<WorkEditionsConnectFieldInput>>;
  media?: InputMaybe<Array<WorkMediaConnectFieldInput>>;
  owners?: InputMaybe<Array<WorkOwnersConnectFieldInput>>;
  tags?: InputMaybe<Array<WorkTagsConnectFieldInput>>;
  thumbnail?: InputMaybe<WorkThumbnailConnectFieldInput>;
  workTypes?: InputMaybe<Array<WorkWorkTypesConnectFieldInput>>;
};

export type WorkConnectOrCreateInput = {
  accounts?: InputMaybe<Array<WorkAccountsConnectOrCreateFieldInput>>;
  categories?: InputMaybe<Array<WorkCategoriesConnectOrCreateFieldInput>>;
  contentBadges?: InputMaybe<Array<WorkContentBadgesConnectOrCreateFieldInput>>;
  creators?: InputMaybe<Array<WorkCreatorsConnectOrCreateFieldInput>>;
  editions?: InputMaybe<Array<WorkEditionsConnectOrCreateFieldInput>>;
  media?: InputMaybe<Array<WorkMediaConnectOrCreateFieldInput>>;
  owners?: InputMaybe<Array<WorkOwnersConnectOrCreateFieldInput>>;
  tags?: InputMaybe<Array<WorkTagsConnectOrCreateFieldInput>>;
  thumbnail?: InputMaybe<WorkThumbnailConnectOrCreateFieldInput>;
  workTypes?: InputMaybe<Array<WorkWorkTypesConnectOrCreateFieldInput>>;
};

export type WorkConnectOrCreateWhere = {
  node: WorkUniqueWhere;
};

export type WorkConnectWhere = {
  node: WorkWhere;
};

export type WorkContentBadgeContentBadgesAggregationSelection = {
  __typename?: 'WorkContentBadgeContentBadgesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<WorkContentBadgeContentBadgesNodeAggregateSelection>;
};

export type WorkContentBadgeContentBadgesNodeAggregateSelection = {
  __typename?: 'WorkContentBadgeContentBadgesNodeAggregateSelection';
  acronym: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  label: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type WorkContentBadgesAggregateInput = {
  AND?: InputMaybe<Array<WorkContentBadgesAggregateInput>>;
  NOT?: InputMaybe<WorkContentBadgesAggregateInput>;
  OR?: InputMaybe<Array<WorkContentBadgesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<WorkContentBadgesNodeAggregationWhereInput>;
};

export type WorkContentBadgesConnectFieldInput = {
  connect?: InputMaybe<Array<ContentBadgeConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<ContentBadgeConnectWhere>;
};

export type WorkContentBadgesConnectOrCreateFieldInput = {
  onCreate: WorkContentBadgesConnectOrCreateFieldInputOnCreate;
  where: ContentBadgeConnectOrCreateWhere;
};

export type WorkContentBadgesConnectOrCreateFieldInputOnCreate = {
  node: ContentBadgeOnCreateInput;
};

export type WorkContentBadgesConnection = {
  __typename?: 'WorkContentBadgesConnection';
  edges: Array<WorkContentBadgesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkContentBadgesConnectionSort = {
  node?: InputMaybe<ContentBadgeSort>;
};

export type WorkContentBadgesConnectionWhere = {
  AND?: InputMaybe<Array<WorkContentBadgesConnectionWhere>>;
  NOT?: InputMaybe<WorkContentBadgesConnectionWhere>;
  OR?: InputMaybe<Array<WorkContentBadgesConnectionWhere>>;
  node?: InputMaybe<ContentBadgeWhere>;
};

export type WorkContentBadgesCreateFieldInput = {
  node: ContentBadgeCreateInput;
};

export type WorkContentBadgesDeleteFieldInput = {
  delete?: InputMaybe<ContentBadgeDeleteInput>;
  where?: InputMaybe<WorkContentBadgesConnectionWhere>;
};

export type WorkContentBadgesDisconnectFieldInput = {
  disconnect?: InputMaybe<ContentBadgeDisconnectInput>;
  where?: InputMaybe<WorkContentBadgesConnectionWhere>;
};

export type WorkContentBadgesFieldInput = {
  connect?: InputMaybe<Array<WorkContentBadgesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkContentBadgesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkContentBadgesCreateFieldInput>>;
};

export type WorkContentBadgesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WorkContentBadgesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WorkContentBadgesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WorkContentBadgesNodeAggregationWhereInput>>;
  acronym_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  acronym_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  acronym_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  acronym_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  acronym_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  acronym_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  acronym_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  acronym_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  acronym_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  acronym_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  acronym_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  acronym_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  acronym_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  acronym_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  acronym_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WorkContentBadgesRelationship = {
  __typename?: 'WorkContentBadgesRelationship';
  cursor: Scalars['String']['output'];
  node: ContentBadge;
};

export type WorkContentBadgesUpdateConnectionInput = {
  node?: InputMaybe<ContentBadgeUpdateInput>;
};

export type WorkContentBadgesUpdateFieldInput = {
  connect?: InputMaybe<Array<WorkContentBadgesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkContentBadgesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkContentBadgesCreateFieldInput>>;
  delete?: InputMaybe<Array<WorkContentBadgesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WorkContentBadgesDisconnectFieldInput>>;
  update?: InputMaybe<WorkContentBadgesUpdateConnectionInput>;
  where?: InputMaybe<WorkContentBadgesConnectionWhere>;
};

export type WorkCreateInput = {
  accounts?: InputMaybe<WorkAccountsFieldInput>;
  categories?: InputMaybe<WorkCategoriesFieldInput>;
  contentBadges?: InputMaybe<WorkContentBadgesFieldInput>;
  creators?: InputMaybe<WorkCreatorsFieldInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  editions?: InputMaybe<WorkEditionsFieldInput>;
  media?: InputMaybe<WorkMediaFieldInput>;
  owners?: InputMaybe<WorkOwnersFieldInput>;
  tags?: InputMaybe<WorkTagsFieldInput>;
  thumbnail?: InputMaybe<WorkThumbnailFieldInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  workTypes?: InputMaybe<WorkWorkTypesFieldInput>;
  workUrl?: InputMaybe<Scalars['String']['input']>;
};

export type WorkCreatorCreatorsAggregationSelection = {
  __typename?: 'WorkCreatorCreatorsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<WorkCreatorCreatorsNodeAggregateSelection>;
};

export type WorkCreatorCreatorsNodeAggregateSelection = {
  __typename?: 'WorkCreatorCreatorsNodeAggregateSelection';
  bannerImage: StringAggregateSelectionNullable;
  bio: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  creatorID: StringAggregateSelectionNullable;
  dateOfBirth: StringAggregateSelectionNullable;
  firstName: StringAggregateSelectionNullable;
  firstNameLC: StringAggregateSelectionNullable;
  genderOther: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  lastName: StringAggregateSelectionNullable;
  lastNameLC: StringAggregateSelectionNullable;
  middleName: StringAggregateSelectionNullable;
  nationality: StringAggregateSelectionNullable;
  nicknames: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  pronouns: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type WorkCreatorsAggregateInput = {
  AND?: InputMaybe<Array<WorkCreatorsAggregateInput>>;
  NOT?: InputMaybe<WorkCreatorsAggregateInput>;
  OR?: InputMaybe<Array<WorkCreatorsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<WorkCreatorsNodeAggregationWhereInput>;
};

export type WorkCreatorsConnectFieldInput = {
  connect?: InputMaybe<Array<CreatorConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<CreatorConnectWhere>;
};

export type WorkCreatorsConnectOrCreateFieldInput = {
  onCreate: WorkCreatorsConnectOrCreateFieldInputOnCreate;
  where: CreatorConnectOrCreateWhere;
};

export type WorkCreatorsConnectOrCreateFieldInputOnCreate = {
  node: CreatorOnCreateInput;
};

export type WorkCreatorsConnection = {
  __typename?: 'WorkCreatorsConnection';
  edges: Array<WorkCreatorsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkCreatorsConnectionSort = {
  node?: InputMaybe<CreatorSort>;
};

export type WorkCreatorsConnectionWhere = {
  AND?: InputMaybe<Array<WorkCreatorsConnectionWhere>>;
  NOT?: InputMaybe<WorkCreatorsConnectionWhere>;
  OR?: InputMaybe<Array<WorkCreatorsConnectionWhere>>;
  node?: InputMaybe<CreatorWhere>;
};

export type WorkCreatorsCreateFieldInput = {
  node: CreatorCreateInput;
};

export type WorkCreatorsDeleteFieldInput = {
  delete?: InputMaybe<CreatorDeleteInput>;
  where?: InputMaybe<WorkCreatorsConnectionWhere>;
};

export type WorkCreatorsDisconnectFieldInput = {
  disconnect?: InputMaybe<CreatorDisconnectInput>;
  where?: InputMaybe<WorkCreatorsConnectionWhere>;
};

export type WorkCreatorsFieldInput = {
  connect?: InputMaybe<Array<WorkCreatorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkCreatorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkCreatorsCreateFieldInput>>;
};

export type WorkCreatorsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WorkCreatorsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WorkCreatorsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WorkCreatorsNodeAggregationWhereInput>>;
  bannerImage_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  bannerImage_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bannerImage_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bio_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  bio_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  bio_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bio_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  bio_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creatorID_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  creatorID_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  creatorID_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  creatorID_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  dateOfBirth_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  firstNameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstNameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  firstName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  firstName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  firstName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  genderOther_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  genderOther_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  genderOther_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastNameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastNameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  lastName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  lastName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  lastName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  middleName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  middleName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  middleName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  middleName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nationality_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nationality_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nationality_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nationality_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nicknames_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nicknames_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nicknames_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  pronouns_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  pronouns_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  pronouns_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WorkCreatorsRelationship = {
  __typename?: 'WorkCreatorsRelationship';
  cursor: Scalars['String']['output'];
  node: Creator;
};

export type WorkCreatorsUpdateConnectionInput = {
  node?: InputMaybe<CreatorUpdateInput>;
};

export type WorkCreatorsUpdateFieldInput = {
  connect?: InputMaybe<Array<WorkCreatorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkCreatorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkCreatorsCreateFieldInput>>;
  delete?: InputMaybe<Array<WorkCreatorsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WorkCreatorsDisconnectFieldInput>>;
  update?: InputMaybe<WorkCreatorsUpdateConnectionInput>;
  where?: InputMaybe<WorkCreatorsConnectionWhere>;
};

export type WorkDeleteInput = {
  accounts?: InputMaybe<Array<WorkAccountsDeleteFieldInput>>;
  categories?: InputMaybe<Array<WorkCategoriesDeleteFieldInput>>;
  contentBadges?: InputMaybe<Array<WorkContentBadgesDeleteFieldInput>>;
  creators?: InputMaybe<Array<WorkCreatorsDeleteFieldInput>>;
  editions?: InputMaybe<Array<WorkEditionsDeleteFieldInput>>;
  media?: InputMaybe<Array<WorkMediaDeleteFieldInput>>;
  owners?: InputMaybe<Array<WorkOwnersDeleteFieldInput>>;
  tags?: InputMaybe<Array<WorkTagsDeleteFieldInput>>;
  thumbnail?: InputMaybe<WorkThumbnailDeleteFieldInput>;
  workTypes?: InputMaybe<Array<WorkWorkTypesDeleteFieldInput>>;
};

export type WorkDisconnectInput = {
  accounts?: InputMaybe<Array<WorkAccountsDisconnectFieldInput>>;
  categories?: InputMaybe<Array<WorkCategoriesDisconnectFieldInput>>;
  contentBadges?: InputMaybe<Array<WorkContentBadgesDisconnectFieldInput>>;
  creators?: InputMaybe<Array<WorkCreatorsDisconnectFieldInput>>;
  editions?: InputMaybe<Array<WorkEditionsDisconnectFieldInput>>;
  media?: InputMaybe<Array<WorkMediaDisconnectFieldInput>>;
  owners?: InputMaybe<Array<WorkOwnersDisconnectFieldInput>>;
  tags?: InputMaybe<Array<WorkTagsDisconnectFieldInput>>;
  thumbnail?: InputMaybe<WorkThumbnailDisconnectFieldInput>;
  workTypes?: InputMaybe<Array<WorkWorkTypesDisconnectFieldInput>>;
};

export type WorkEdge = {
  __typename?: 'WorkEdge';
  cursor: Scalars['String']['output'];
  node: Work;
};

export type WorkEditionEditionsAggregationSelection = {
  __typename?: 'WorkEditionEditionsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<WorkEditionEditionsNodeAggregateSelection>;
};

export type WorkEditionEditionsNodeAggregateSelection = {
  __typename?: 'WorkEditionEditionsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type WorkEditionsAggregateInput = {
  AND?: InputMaybe<Array<WorkEditionsAggregateInput>>;
  NOT?: InputMaybe<WorkEditionsAggregateInput>;
  OR?: InputMaybe<Array<WorkEditionsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<WorkEditionsNodeAggregationWhereInput>;
};

export type WorkEditionsConnectFieldInput = {
  connect?: InputMaybe<Array<EditionConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<EditionConnectWhere>;
};

export type WorkEditionsConnectOrCreateFieldInput = {
  onCreate: WorkEditionsConnectOrCreateFieldInputOnCreate;
  where: EditionConnectOrCreateWhere;
};

export type WorkEditionsConnectOrCreateFieldInputOnCreate = {
  node: EditionOnCreateInput;
};

export type WorkEditionsConnection = {
  __typename?: 'WorkEditionsConnection';
  edges: Array<WorkEditionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkEditionsConnectionSort = {
  node?: InputMaybe<EditionSort>;
};

export type WorkEditionsConnectionWhere = {
  AND?: InputMaybe<Array<WorkEditionsConnectionWhere>>;
  NOT?: InputMaybe<WorkEditionsConnectionWhere>;
  OR?: InputMaybe<Array<WorkEditionsConnectionWhere>>;
  node?: InputMaybe<EditionWhere>;
};

export type WorkEditionsCreateFieldInput = {
  node: EditionCreateInput;
};

export type WorkEditionsDeleteFieldInput = {
  delete?: InputMaybe<EditionDeleteInput>;
  where?: InputMaybe<WorkEditionsConnectionWhere>;
};

export type WorkEditionsDisconnectFieldInput = {
  disconnect?: InputMaybe<EditionDisconnectInput>;
  where?: InputMaybe<WorkEditionsConnectionWhere>;
};

export type WorkEditionsFieldInput = {
  connect?: InputMaybe<Array<WorkEditionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkEditionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkEditionsCreateFieldInput>>;
};

export type WorkEditionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WorkEditionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WorkEditionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WorkEditionsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkEditionsRelationship = {
  __typename?: 'WorkEditionsRelationship';
  cursor: Scalars['String']['output'];
  node: Edition;
};

export type WorkEditionsUpdateConnectionInput = {
  node?: InputMaybe<EditionUpdateInput>;
};

export type WorkEditionsUpdateFieldInput = {
  connect?: InputMaybe<Array<WorkEditionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkEditionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkEditionsCreateFieldInput>>;
  delete?: InputMaybe<Array<WorkEditionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WorkEditionsDisconnectFieldInput>>;
  update?: InputMaybe<WorkEditionsUpdateConnectionInput>;
  where?: InputMaybe<WorkEditionsConnectionWhere>;
};

export type WorkMediaAggregateInput = {
  AND?: InputMaybe<Array<WorkMediaAggregateInput>>;
  NOT?: InputMaybe<WorkMediaAggregateInput>;
  OR?: InputMaybe<Array<WorkMediaAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<WorkMediaNodeAggregationWhereInput>;
};

export type WorkMediaConnectFieldInput = {
  connect?: InputMaybe<Array<UserMediaConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserMediaConnectWhere>;
};

export type WorkMediaConnectOrCreateFieldInput = {
  onCreate: WorkMediaConnectOrCreateFieldInputOnCreate;
  where: UserMediaConnectOrCreateWhere;
};

export type WorkMediaConnectOrCreateFieldInputOnCreate = {
  node: UserMediaOnCreateInput;
};

export type WorkMediaConnection = {
  __typename?: 'WorkMediaConnection';
  edges: Array<WorkMediaRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkMediaConnectionSort = {
  node?: InputMaybe<UserMediaSort>;
};

export type WorkMediaConnectionWhere = {
  AND?: InputMaybe<Array<WorkMediaConnectionWhere>>;
  NOT?: InputMaybe<WorkMediaConnectionWhere>;
  OR?: InputMaybe<Array<WorkMediaConnectionWhere>>;
  node?: InputMaybe<UserMediaWhere>;
};

export type WorkMediaCreateFieldInput = {
  node: UserMediaCreateInput;
};

export type WorkMediaDeleteFieldInput = {
  delete?: InputMaybe<UserMediaDeleteInput>;
  where?: InputMaybe<WorkMediaConnectionWhere>;
};

export type WorkMediaDisconnectFieldInput = {
  disconnect?: InputMaybe<UserMediaDisconnectInput>;
  where?: InputMaybe<WorkMediaConnectionWhere>;
};

export type WorkMediaFieldInput = {
  connect?: InputMaybe<Array<WorkMediaConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkMediaConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkMediaCreateFieldInput>>;
};

export type WorkMediaNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WorkMediaNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WorkMediaNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WorkMediaNodeAggregationWhereInput>>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  caption_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  encoding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  extension_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  height_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  source_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  width_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  width_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkMediaRelationship = {
  __typename?: 'WorkMediaRelationship';
  cursor: Scalars['String']['output'];
  node: UserMedia;
};

export type WorkMediaUpdateConnectionInput = {
  node?: InputMaybe<UserMediaUpdateInput>;
};

export type WorkMediaUpdateFieldInput = {
  connect?: InputMaybe<Array<WorkMediaConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkMediaConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkMediaCreateFieldInput>>;
  delete?: InputMaybe<Array<WorkMediaDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WorkMediaDisconnectFieldInput>>;
  update?: InputMaybe<WorkMediaUpdateConnectionInput>;
  where?: InputMaybe<WorkMediaConnectionWhere>;
};

export type WorkOnCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  workUrl?: InputMaybe<Scalars['String']['input']>;
};

export type WorkOptions = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Specify one or more WorkSort objects to sort Works by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<WorkSort>>;
};

export type WorkOwnerOwnersAggregationSelection = {
  __typename?: 'WorkOwnerOwnersAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<WorkOwnerOwnersNodeAggregateSelection>;
};

export type WorkOwnerOwnersNodeAggregateSelection = {
  __typename?: 'WorkOwnerOwnersNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  name: StringAggregateSelectionNullable;
  nameLC: StringAggregateSelectionNullable;
  nutshellContactId: StringAggregateSelectionNullable;
  nutshellLeadId: StringAggregateSelectionNullable;
  nutshellLeadNumber: StringAggregateSelectionNullable;
  profilePicture: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  url: StringAggregateSelectionNullable;
};

export type WorkOwnersAggregateInput = {
  AND?: InputMaybe<Array<WorkOwnersAggregateInput>>;
  NOT?: InputMaybe<WorkOwnersAggregateInput>;
  OR?: InputMaybe<Array<WorkOwnersAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<WorkOwnersNodeAggregationWhereInput>;
};

export type WorkOwnersConnectFieldInput = {
  connect?: InputMaybe<Array<OwnerConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<OwnerConnectWhere>;
};

export type WorkOwnersConnectOrCreateFieldInput = {
  onCreate: WorkOwnersConnectOrCreateFieldInputOnCreate;
  where: OwnerConnectOrCreateWhere;
};

export type WorkOwnersConnectOrCreateFieldInputOnCreate = {
  node: OwnerOnCreateInput;
};

export type WorkOwnersConnection = {
  __typename?: 'WorkOwnersConnection';
  edges: Array<WorkOwnersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkOwnersConnectionSort = {
  node?: InputMaybe<OwnerSort>;
};

export type WorkOwnersConnectionWhere = {
  AND?: InputMaybe<Array<WorkOwnersConnectionWhere>>;
  NOT?: InputMaybe<WorkOwnersConnectionWhere>;
  OR?: InputMaybe<Array<WorkOwnersConnectionWhere>>;
  node?: InputMaybe<OwnerWhere>;
};

export type WorkOwnersCreateFieldInput = {
  node: OwnerCreateInput;
};

export type WorkOwnersDeleteFieldInput = {
  delete?: InputMaybe<OwnerDeleteInput>;
  where?: InputMaybe<WorkOwnersConnectionWhere>;
};

export type WorkOwnersDisconnectFieldInput = {
  disconnect?: InputMaybe<OwnerDisconnectInput>;
  where?: InputMaybe<WorkOwnersConnectionWhere>;
};

export type WorkOwnersFieldInput = {
  connect?: InputMaybe<Array<WorkOwnersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkOwnersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkOwnersCreateFieldInput>>;
};

export type WorkOwnersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WorkOwnersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WorkOwnersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WorkOwnersNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  nameLC_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nameLC_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nameLC_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nameLC_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellContactId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellContactId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  nutshellLeadNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkOwnersRelationship = {
  __typename?: 'WorkOwnersRelationship';
  cursor: Scalars['String']['output'];
  node: Owner;
};

export type WorkOwnersUpdateConnectionInput = {
  node?: InputMaybe<OwnerUpdateInput>;
};

export type WorkOwnersUpdateFieldInput = {
  connect?: InputMaybe<Array<WorkOwnersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkOwnersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkOwnersCreateFieldInput>>;
  delete?: InputMaybe<Array<WorkOwnersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WorkOwnersDisconnectFieldInput>>;
  update?: InputMaybe<WorkOwnersUpdateConnectionInput>;
  where?: InputMaybe<WorkOwnersConnectionWhere>;
};

export type WorkRelationInput = {
  accounts?: InputMaybe<Array<WorkAccountsCreateFieldInput>>;
  categories?: InputMaybe<Array<WorkCategoriesCreateFieldInput>>;
  contentBadges?: InputMaybe<Array<WorkContentBadgesCreateFieldInput>>;
  creators?: InputMaybe<Array<WorkCreatorsCreateFieldInput>>;
  editions?: InputMaybe<Array<WorkEditionsCreateFieldInput>>;
  media?: InputMaybe<Array<WorkMediaCreateFieldInput>>;
  owners?: InputMaybe<Array<WorkOwnersCreateFieldInput>>;
  tags?: InputMaybe<Array<WorkTagsCreateFieldInput>>;
  thumbnail?: InputMaybe<WorkThumbnailCreateFieldInput>;
  workTypes?: InputMaybe<Array<WorkWorkTypesCreateFieldInput>>;
};

/** Fields to sort Works by. The order in which sorts are applied is not guaranteed when specifying many fields in one WorkSort object. */
export type WorkSort = {
  createdAt?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
  workUrl?: InputMaybe<SortDirection>;
};

export type WorkTagTagsAggregationSelection = {
  __typename?: 'WorkTagTagsAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<WorkTagTagsNodeAggregateSelection>;
};

export type WorkTagTagsNodeAggregateSelection = {
  __typename?: 'WorkTagTagsNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type WorkTagsAggregateInput = {
  AND?: InputMaybe<Array<WorkTagsAggregateInput>>;
  NOT?: InputMaybe<WorkTagsAggregateInput>;
  OR?: InputMaybe<Array<WorkTagsAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<WorkTagsNodeAggregationWhereInput>;
};

export type WorkTagsConnectFieldInput = {
  connect?: InputMaybe<Array<TagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<TagConnectWhere>;
};

export type WorkTagsConnectOrCreateFieldInput = {
  onCreate: WorkTagsConnectOrCreateFieldInputOnCreate;
  where: TagConnectOrCreateWhere;
};

export type WorkTagsConnectOrCreateFieldInputOnCreate = {
  node: TagOnCreateInput;
};

export type WorkTagsConnection = {
  __typename?: 'WorkTagsConnection';
  edges: Array<WorkTagsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkTagsConnectionSort = {
  node?: InputMaybe<TagSort>;
};

export type WorkTagsConnectionWhere = {
  AND?: InputMaybe<Array<WorkTagsConnectionWhere>>;
  NOT?: InputMaybe<WorkTagsConnectionWhere>;
  OR?: InputMaybe<Array<WorkTagsConnectionWhere>>;
  node?: InputMaybe<TagWhere>;
};

export type WorkTagsCreateFieldInput = {
  node: TagCreateInput;
};

export type WorkTagsDeleteFieldInput = {
  delete?: InputMaybe<TagDeleteInput>;
  where?: InputMaybe<WorkTagsConnectionWhere>;
};

export type WorkTagsDisconnectFieldInput = {
  disconnect?: InputMaybe<TagDisconnectInput>;
  where?: InputMaybe<WorkTagsConnectionWhere>;
};

export type WorkTagsFieldInput = {
  connect?: InputMaybe<Array<WorkTagsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkTagsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkTagsCreateFieldInput>>;
};

export type WorkTagsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WorkTagsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WorkTagsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WorkTagsNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WorkTagsRelationship = {
  __typename?: 'WorkTagsRelationship';
  cursor: Scalars['String']['output'];
  node: Tag;
};

export type WorkTagsUpdateConnectionInput = {
  node?: InputMaybe<TagUpdateInput>;
};

export type WorkTagsUpdateFieldInput = {
  connect?: InputMaybe<Array<WorkTagsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkTagsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkTagsCreateFieldInput>>;
  delete?: InputMaybe<Array<WorkTagsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WorkTagsDisconnectFieldInput>>;
  update?: InputMaybe<WorkTagsUpdateConnectionInput>;
  where?: InputMaybe<WorkTagsConnectionWhere>;
};

export type WorkThumbnailAggregateInput = {
  AND?: InputMaybe<Array<WorkThumbnailAggregateInput>>;
  NOT?: InputMaybe<WorkThumbnailAggregateInput>;
  OR?: InputMaybe<Array<WorkThumbnailAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<WorkThumbnailNodeAggregationWhereInput>;
};

export type WorkThumbnailConnectFieldInput = {
  connect?: InputMaybe<UserMediaConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<UserMediaConnectWhere>;
};

export type WorkThumbnailConnectOrCreateFieldInput = {
  onCreate: WorkThumbnailConnectOrCreateFieldInputOnCreate;
  where: UserMediaConnectOrCreateWhere;
};

export type WorkThumbnailConnectOrCreateFieldInputOnCreate = {
  node: UserMediaOnCreateInput;
};

export type WorkThumbnailConnection = {
  __typename?: 'WorkThumbnailConnection';
  edges: Array<WorkThumbnailRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkThumbnailConnectionSort = {
  node?: InputMaybe<UserMediaSort>;
};

export type WorkThumbnailConnectionWhere = {
  AND?: InputMaybe<Array<WorkThumbnailConnectionWhere>>;
  NOT?: InputMaybe<WorkThumbnailConnectionWhere>;
  OR?: InputMaybe<Array<WorkThumbnailConnectionWhere>>;
  node?: InputMaybe<UserMediaWhere>;
};

export type WorkThumbnailCreateFieldInput = {
  node: UserMediaCreateInput;
};

export type WorkThumbnailDeleteFieldInput = {
  delete?: InputMaybe<UserMediaDeleteInput>;
  where?: InputMaybe<WorkThumbnailConnectionWhere>;
};

export type WorkThumbnailDisconnectFieldInput = {
  disconnect?: InputMaybe<UserMediaDisconnectInput>;
  where?: InputMaybe<WorkThumbnailConnectionWhere>;
};

export type WorkThumbnailFieldInput = {
  connect?: InputMaybe<WorkThumbnailConnectFieldInput>;
  connectOrCreate?: InputMaybe<WorkThumbnailConnectOrCreateFieldInput>;
  create?: InputMaybe<WorkThumbnailCreateFieldInput>;
};

export type WorkThumbnailNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WorkThumbnailNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WorkThumbnailNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WorkThumbnailNodeAggregationWhereInput>>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  autoCorrectToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  caption_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  caption_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  caption_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  contentLength_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentLength_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  contentLength_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  contentType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  contentType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  contentType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  encoding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  encoding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  encoding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  encoding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  extension_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  extension_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  extension_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  generativeAiExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  height_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  height_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  height_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  height_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  machineLearningToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  otherAiToolsExplanation_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  source_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  source_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  source_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  width_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  width_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  width_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  width_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  width_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkThumbnailRelationship = {
  __typename?: 'WorkThumbnailRelationship';
  cursor: Scalars['String']['output'];
  node: UserMedia;
};

export type WorkThumbnailUpdateConnectionInput = {
  node?: InputMaybe<UserMediaUpdateInput>;
};

export type WorkThumbnailUpdateFieldInput = {
  connect?: InputMaybe<WorkThumbnailConnectFieldInput>;
  connectOrCreate?: InputMaybe<WorkThumbnailConnectOrCreateFieldInput>;
  create?: InputMaybe<WorkThumbnailCreateFieldInput>;
  delete?: InputMaybe<WorkThumbnailDeleteFieldInput>;
  disconnect?: InputMaybe<WorkThumbnailDisconnectFieldInput>;
  update?: InputMaybe<WorkThumbnailUpdateConnectionInput>;
  where?: InputMaybe<WorkThumbnailConnectionWhere>;
};

export type WorkTypeTagWorkTypesAggregationSelection = {
  __typename?: 'WorkTypeTagWorkTypesAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<WorkTypeTagWorkTypesNodeAggregateSelection>;
};

export type WorkTypeTagWorkTypesNodeAggregateSelection = {
  __typename?: 'WorkTypeTagWorkTypesNodeAggregateSelection';
  createdAt: DateTimeAggregateSelectionNullable;
  description: StringAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  tier: IntAggregateSelectionNullable;
  title: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
};

export type WorkUniqueWhere = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type WorkUpdateInput = {
  accounts?: InputMaybe<Array<WorkAccountsUpdateFieldInput>>;
  categories?: InputMaybe<Array<WorkCategoriesUpdateFieldInput>>;
  contentBadges?: InputMaybe<Array<WorkContentBadgesUpdateFieldInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creators?: InputMaybe<Array<WorkCreatorsUpdateFieldInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  editions?: InputMaybe<Array<WorkEditionsUpdateFieldInput>>;
  media?: InputMaybe<Array<WorkMediaUpdateFieldInput>>;
  owners?: InputMaybe<Array<WorkOwnersUpdateFieldInput>>;
  tags?: InputMaybe<Array<WorkTagsUpdateFieldInput>>;
  thumbnail?: InputMaybe<WorkThumbnailUpdateFieldInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  workTypes?: InputMaybe<Array<WorkWorkTypesUpdateFieldInput>>;
  workUrl?: InputMaybe<Scalars['String']['input']>;
};

export type WorkUserMediaMediaAggregationSelection = {
  __typename?: 'WorkUserMediaMediaAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<WorkUserMediaMediaNodeAggregateSelection>;
};

export type WorkUserMediaMediaNodeAggregateSelection = {
  __typename?: 'WorkUserMediaMediaNodeAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  caption: StringAggregateSelectionNullable;
  contentLength: IntAggregateSelectionNullable;
  contentType: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  encoding: StringAggregateSelectionNullable;
  extension: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  height: IntAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  source: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  width: IntAggregateSelectionNullable;
};

export type WorkUserMediaThumbnailAggregationSelection = {
  __typename?: 'WorkUserMediaThumbnailAggregationSelection';
  count: Scalars['Int']['output'];
  node?: Maybe<WorkUserMediaThumbnailNodeAggregateSelection>;
};

export type WorkUserMediaThumbnailNodeAggregateSelection = {
  __typename?: 'WorkUserMediaThumbnailNodeAggregateSelection';
  autoCorrectToolsExplanation: StringAggregateSelectionNullable;
  caption: StringAggregateSelectionNullable;
  contentLength: IntAggregateSelectionNullable;
  contentType: StringAggregateSelectionNullable;
  createdAt: DateTimeAggregateSelectionNullable;
  encoding: StringAggregateSelectionNullable;
  extension: StringAggregateSelectionNullable;
  generativeAiExplanation: StringAggregateSelectionNullable;
  height: IntAggregateSelectionNullable;
  id: IdAggregateSelectionNonNullable;
  machineLearningToolsExplanation: StringAggregateSelectionNullable;
  otherAiToolsExplanation: StringAggregateSelectionNullable;
  source: StringAggregateSelectionNullable;
  updatedAt: DateTimeAggregateSelectionNullable;
  width: IntAggregateSelectionNullable;
};

export type WorkWhere = {
  AND?: InputMaybe<Array<WorkWhere>>;
  NOT?: InputMaybe<WorkWhere>;
  OR?: InputMaybe<Array<WorkWhere>>;
  accountsAggregate?: InputMaybe<WorkAccountsAggregateInput>;
  /** Return Works where all of the related WorkAccountsConnections match this filter */
  accountsConnection_ALL?: InputMaybe<WorkAccountsConnectionWhere>;
  /** Return Works where none of the related WorkAccountsConnections match this filter */
  accountsConnection_NONE?: InputMaybe<WorkAccountsConnectionWhere>;
  /** Return Works where one of the related WorkAccountsConnections match this filter */
  accountsConnection_SINGLE?: InputMaybe<WorkAccountsConnectionWhere>;
  /** Return Works where some of the related WorkAccountsConnections match this filter */
  accountsConnection_SOME?: InputMaybe<WorkAccountsConnectionWhere>;
  /** Return Works where all of the related Accounts match this filter */
  accounts_ALL?: InputMaybe<AccountWhere>;
  /** Return Works where none of the related Accounts match this filter */
  accounts_NONE?: InputMaybe<AccountWhere>;
  /** Return Works where one of the related Accounts match this filter */
  accounts_SINGLE?: InputMaybe<AccountWhere>;
  /** Return Works where some of the related Accounts match this filter */
  accounts_SOME?: InputMaybe<AccountWhere>;
  categoriesAggregate?: InputMaybe<WorkCategoriesAggregateInput>;
  /** Return Works where all of the related WorkCategoriesConnections match this filter */
  categoriesConnection_ALL?: InputMaybe<WorkCategoriesConnectionWhere>;
  /** Return Works where none of the related WorkCategoriesConnections match this filter */
  categoriesConnection_NONE?: InputMaybe<WorkCategoriesConnectionWhere>;
  /** Return Works where one of the related WorkCategoriesConnections match this filter */
  categoriesConnection_SINGLE?: InputMaybe<WorkCategoriesConnectionWhere>;
  /** Return Works where some of the related WorkCategoriesConnections match this filter */
  categoriesConnection_SOME?: InputMaybe<WorkCategoriesConnectionWhere>;
  /** Return Works where all of the related CategoryTags match this filter */
  categories_ALL?: InputMaybe<CategoryTagWhere>;
  /** Return Works where none of the related CategoryTags match this filter */
  categories_NONE?: InputMaybe<CategoryTagWhere>;
  /** Return Works where one of the related CategoryTags match this filter */
  categories_SINGLE?: InputMaybe<CategoryTagWhere>;
  /** Return Works where some of the related CategoryTags match this filter */
  categories_SOME?: InputMaybe<CategoryTagWhere>;
  contentBadgesAggregate?: InputMaybe<WorkContentBadgesAggregateInput>;
  /** Return Works where all of the related WorkContentBadgesConnections match this filter */
  contentBadgesConnection_ALL?: InputMaybe<WorkContentBadgesConnectionWhere>;
  /** Return Works where none of the related WorkContentBadgesConnections match this filter */
  contentBadgesConnection_NONE?: InputMaybe<WorkContentBadgesConnectionWhere>;
  /** Return Works where one of the related WorkContentBadgesConnections match this filter */
  contentBadgesConnection_SINGLE?: InputMaybe<WorkContentBadgesConnectionWhere>;
  /** Return Works where some of the related WorkContentBadgesConnections match this filter */
  contentBadgesConnection_SOME?: InputMaybe<WorkContentBadgesConnectionWhere>;
  /** Return Works where all of the related ContentBadges match this filter */
  contentBadges_ALL?: InputMaybe<ContentBadgeWhere>;
  /** Return Works where none of the related ContentBadges match this filter */
  contentBadges_NONE?: InputMaybe<ContentBadgeWhere>;
  /** Return Works where one of the related ContentBadges match this filter */
  contentBadges_SINGLE?: InputMaybe<ContentBadgeWhere>;
  /** Return Works where some of the related ContentBadges match this filter */
  contentBadges_SOME?: InputMaybe<ContentBadgeWhere>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  creatorsAggregate?: InputMaybe<WorkCreatorsAggregateInput>;
  /** Return Works where all of the related WorkCreatorsConnections match this filter */
  creatorsConnection_ALL?: InputMaybe<WorkCreatorsConnectionWhere>;
  /** Return Works where none of the related WorkCreatorsConnections match this filter */
  creatorsConnection_NONE?: InputMaybe<WorkCreatorsConnectionWhere>;
  /** Return Works where one of the related WorkCreatorsConnections match this filter */
  creatorsConnection_SINGLE?: InputMaybe<WorkCreatorsConnectionWhere>;
  /** Return Works where some of the related WorkCreatorsConnections match this filter */
  creatorsConnection_SOME?: InputMaybe<WorkCreatorsConnectionWhere>;
  /** Return Works where all of the related Creators match this filter */
  creators_ALL?: InputMaybe<CreatorWhere>;
  /** Return Works where none of the related Creators match this filter */
  creators_NONE?: InputMaybe<CreatorWhere>;
  /** Return Works where one of the related Creators match this filter */
  creators_SINGLE?: InputMaybe<CreatorWhere>;
  /** Return Works where some of the related Creators match this filter */
  creators_SOME?: InputMaybe<CreatorWhere>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  description_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  editionsAggregate?: InputMaybe<WorkEditionsAggregateInput>;
  /** Return Works where all of the related WorkEditionsConnections match this filter */
  editionsConnection_ALL?: InputMaybe<WorkEditionsConnectionWhere>;
  /** Return Works where none of the related WorkEditionsConnections match this filter */
  editionsConnection_NONE?: InputMaybe<WorkEditionsConnectionWhere>;
  /** Return Works where one of the related WorkEditionsConnections match this filter */
  editionsConnection_SINGLE?: InputMaybe<WorkEditionsConnectionWhere>;
  /** Return Works where some of the related WorkEditionsConnections match this filter */
  editionsConnection_SOME?: InputMaybe<WorkEditionsConnectionWhere>;
  /** Return Works where all of the related Editions match this filter */
  editions_ALL?: InputMaybe<EditionWhere>;
  /** Return Works where none of the related Editions match this filter */
  editions_NONE?: InputMaybe<EditionWhere>;
  /** Return Works where one of the related Editions match this filter */
  editions_SINGLE?: InputMaybe<EditionWhere>;
  /** Return Works where some of the related Editions match this filter */
  editions_SOME?: InputMaybe<EditionWhere>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_CONTAINS?: InputMaybe<Scalars['ID']['input']>;
  id_ENDS_WITH?: InputMaybe<Scalars['ID']['input']>;
  id_IN?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_STARTS_WITH?: InputMaybe<Scalars['ID']['input']>;
  mediaAggregate?: InputMaybe<WorkMediaAggregateInput>;
  /** Return Works where all of the related WorkMediaConnections match this filter */
  mediaConnection_ALL?: InputMaybe<WorkMediaConnectionWhere>;
  /** Return Works where none of the related WorkMediaConnections match this filter */
  mediaConnection_NONE?: InputMaybe<WorkMediaConnectionWhere>;
  /** Return Works where one of the related WorkMediaConnections match this filter */
  mediaConnection_SINGLE?: InputMaybe<WorkMediaConnectionWhere>;
  /** Return Works where some of the related WorkMediaConnections match this filter */
  mediaConnection_SOME?: InputMaybe<WorkMediaConnectionWhere>;
  /** Return Works where all of the related UserMedias match this filter */
  media_ALL?: InputMaybe<UserMediaWhere>;
  /** Return Works where none of the related UserMedias match this filter */
  media_NONE?: InputMaybe<UserMediaWhere>;
  /** Return Works where one of the related UserMedias match this filter */
  media_SINGLE?: InputMaybe<UserMediaWhere>;
  /** Return Works where some of the related UserMedias match this filter */
  media_SOME?: InputMaybe<UserMediaWhere>;
  ownersAggregate?: InputMaybe<WorkOwnersAggregateInput>;
  /** Return Works where all of the related WorkOwnersConnections match this filter */
  ownersConnection_ALL?: InputMaybe<WorkOwnersConnectionWhere>;
  /** Return Works where none of the related WorkOwnersConnections match this filter */
  ownersConnection_NONE?: InputMaybe<WorkOwnersConnectionWhere>;
  /** Return Works where one of the related WorkOwnersConnections match this filter */
  ownersConnection_SINGLE?: InputMaybe<WorkOwnersConnectionWhere>;
  /** Return Works where some of the related WorkOwnersConnections match this filter */
  ownersConnection_SOME?: InputMaybe<WorkOwnersConnectionWhere>;
  /** Return Works where all of the related Owners match this filter */
  owners_ALL?: InputMaybe<OwnerWhere>;
  /** Return Works where none of the related Owners match this filter */
  owners_NONE?: InputMaybe<OwnerWhere>;
  /** Return Works where one of the related Owners match this filter */
  owners_SINGLE?: InputMaybe<OwnerWhere>;
  /** Return Works where some of the related Owners match this filter */
  owners_SOME?: InputMaybe<OwnerWhere>;
  tagsAggregate?: InputMaybe<WorkTagsAggregateInput>;
  /** Return Works where all of the related WorkTagsConnections match this filter */
  tagsConnection_ALL?: InputMaybe<WorkTagsConnectionWhere>;
  /** Return Works where none of the related WorkTagsConnections match this filter */
  tagsConnection_NONE?: InputMaybe<WorkTagsConnectionWhere>;
  /** Return Works where one of the related WorkTagsConnections match this filter */
  tagsConnection_SINGLE?: InputMaybe<WorkTagsConnectionWhere>;
  /** Return Works where some of the related WorkTagsConnections match this filter */
  tagsConnection_SOME?: InputMaybe<WorkTagsConnectionWhere>;
  /** Return Works where all of the related Tags match this filter */
  tags_ALL?: InputMaybe<TagWhere>;
  /** Return Works where none of the related Tags match this filter */
  tags_NONE?: InputMaybe<TagWhere>;
  /** Return Works where one of the related Tags match this filter */
  tags_SINGLE?: InputMaybe<TagWhere>;
  /** Return Works where some of the related Tags match this filter */
  tags_SOME?: InputMaybe<TagWhere>;
  thumbnail?: InputMaybe<UserMediaWhere>;
  thumbnailAggregate?: InputMaybe<WorkThumbnailAggregateInput>;
  thumbnailConnection?: InputMaybe<WorkThumbnailConnectionWhere>;
  thumbnailConnection_NOT?: InputMaybe<WorkThumbnailConnectionWhere>;
  thumbnail_NOT?: InputMaybe<UserMediaWhere>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  title_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  title_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_IN?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  workTypesAggregate?: InputMaybe<WorkWorkTypesAggregateInput>;
  /** Return Works where all of the related WorkWorkTypesConnections match this filter */
  workTypesConnection_ALL?: InputMaybe<WorkWorkTypesConnectionWhere>;
  /** Return Works where none of the related WorkWorkTypesConnections match this filter */
  workTypesConnection_NONE?: InputMaybe<WorkWorkTypesConnectionWhere>;
  /** Return Works where one of the related WorkWorkTypesConnections match this filter */
  workTypesConnection_SINGLE?: InputMaybe<WorkWorkTypesConnectionWhere>;
  /** Return Works where some of the related WorkWorkTypesConnections match this filter */
  workTypesConnection_SOME?: InputMaybe<WorkWorkTypesConnectionWhere>;
  /** Return Works where all of the related TypeTags match this filter */
  workTypes_ALL?: InputMaybe<TypeTagWhere>;
  /** Return Works where none of the related TypeTags match this filter */
  workTypes_NONE?: InputMaybe<TypeTagWhere>;
  /** Return Works where one of the related TypeTags match this filter */
  workTypes_SINGLE?: InputMaybe<TypeTagWhere>;
  /** Return Works where some of the related TypeTags match this filter */
  workTypes_SOME?: InputMaybe<TypeTagWhere>;
  workUrl?: InputMaybe<Scalars['String']['input']>;
  workUrl_CONTAINS?: InputMaybe<Scalars['String']['input']>;
  workUrl_ENDS_WITH?: InputMaybe<Scalars['String']['input']>;
  workUrl_IN?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  workUrl_STARTS_WITH?: InputMaybe<Scalars['String']['input']>;
};

export type WorkWorkTypesAggregateInput = {
  AND?: InputMaybe<Array<WorkWorkTypesAggregateInput>>;
  NOT?: InputMaybe<WorkWorkTypesAggregateInput>;
  OR?: InputMaybe<Array<WorkWorkTypesAggregateInput>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  count_GT?: InputMaybe<Scalars['Int']['input']>;
  count_GTE?: InputMaybe<Scalars['Int']['input']>;
  count_LT?: InputMaybe<Scalars['Int']['input']>;
  count_LTE?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<WorkWorkTypesNodeAggregationWhereInput>;
};

export type WorkWorkTypesConnectFieldInput = {
  connect?: InputMaybe<Array<TypeTagConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars['Boolean']['input'];
  where?: InputMaybe<TypeTagConnectWhere>;
};

export type WorkWorkTypesConnectOrCreateFieldInput = {
  onCreate: WorkWorkTypesConnectOrCreateFieldInputOnCreate;
  where: TypeTagConnectOrCreateWhere;
};

export type WorkWorkTypesConnectOrCreateFieldInputOnCreate = {
  node: TypeTagOnCreateInput;
};

export type WorkWorkTypesConnection = {
  __typename?: 'WorkWorkTypesConnection';
  edges: Array<WorkWorkTypesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkWorkTypesConnectionSort = {
  node?: InputMaybe<TypeTagSort>;
};

export type WorkWorkTypesConnectionWhere = {
  AND?: InputMaybe<Array<WorkWorkTypesConnectionWhere>>;
  NOT?: InputMaybe<WorkWorkTypesConnectionWhere>;
  OR?: InputMaybe<Array<WorkWorkTypesConnectionWhere>>;
  node?: InputMaybe<TypeTagWhere>;
};

export type WorkWorkTypesCreateFieldInput = {
  node: TypeTagCreateInput;
};

export type WorkWorkTypesDeleteFieldInput = {
  delete?: InputMaybe<TypeTagDeleteInput>;
  where?: InputMaybe<WorkWorkTypesConnectionWhere>;
};

export type WorkWorkTypesDisconnectFieldInput = {
  disconnect?: InputMaybe<TypeTagDisconnectInput>;
  where?: InputMaybe<WorkWorkTypesConnectionWhere>;
};

export type WorkWorkTypesFieldInput = {
  connect?: InputMaybe<Array<WorkWorkTypesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkWorkTypesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkWorkTypesCreateFieldInput>>;
};

export type WorkWorkTypesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WorkWorkTypesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WorkWorkTypesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WorkWorkTypesNodeAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_AVERAGE_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_GTE?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LT?: InputMaybe<Scalars['Float']['input']>;
  tier_AVERAGE_LTE?: InputMaybe<Scalars['Float']['input']>;
  tier_MAX_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MAX_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_MIN_LTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_GTE?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LT?: InputMaybe<Scalars['Int']['input']>;
  tier_SUM_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars['Float']['input']>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars['Float']['input']>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars['Int']['input']>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars['Int']['input']>;
  updatedAt_MAX_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MAX_LTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_EQUAL?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_GTE?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LT?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_MIN_LTE?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WorkWorkTypesRelationship = {
  __typename?: 'WorkWorkTypesRelationship';
  cursor: Scalars['String']['output'];
  node: TypeTag;
};

export type WorkWorkTypesUpdateConnectionInput = {
  node?: InputMaybe<TypeTagUpdateInput>;
};

export type WorkWorkTypesUpdateFieldInput = {
  connect?: InputMaybe<Array<WorkWorkTypesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WorkWorkTypesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WorkWorkTypesCreateFieldInput>>;
  delete?: InputMaybe<Array<WorkWorkTypesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WorkWorkTypesDisconnectFieldInput>>;
  update?: InputMaybe<WorkWorkTypesUpdateConnectionInput>;
  where?: InputMaybe<WorkWorkTypesConnectionWhere>;
};

export type WorksConnection = {
  __typename?: 'WorksConnection';
  edges: Array<WorkEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, userId: string, userType?: UserType | null, email?: string | null, nickname?: string | null, picture?: string | null, account?: { __typename?: 'Account', id: string, onboardingStatus?: OnboardingStatus | null, accountStatus?: AccountStatus | null, subscription?: { __typename?: 'AccountSubscription', plan?: PlanTypes | null } | null, creators: Array<{ __typename?: 'Creator', id: string, profilePicture?: string | null }>, owner?: { __typename?: 'Owner', id: string, profilePicture?: string | null } | null } | null } | null };

export type CreateAccountMutationVariables = Exact<{
  input: AccountCreateInput;
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', createAccounts: { __typename?: 'CreateAccountsMutationResponse', accounts: Array<{ __typename?: 'Account', id: string }> } };

export type SubscribeAccountMutationVariables = Exact<{
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  paymentMethod: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
  plan: PlanTypes;
  displayPrice: Scalars['Float']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type SubscribeAccountMutation = { __typename?: 'Mutation', subscribeAccount: { __typename?: 'SubscribeAccountResponse', clientSecret?: string | null, error?: string | null, subscriptionId: string } };

export type CreateUserMutationVariables = Exact<{
  input: Array<UserCreateInput> | UserCreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUsers: { __typename?: 'CreateUsersMutationResponse', users: Array<{ __typename?: 'User', id: string, userType?: UserType | null, userId: string, picture?: string | null, nickname?: string | null, email?: string | null, account?: { __typename?: 'Account', id: string, accountStatus?: AccountStatus | null, joinedDate?: string | null, lastActive?: string | null, profileURL?: string | null, taxId?: string | null } | null }> } };

export type UserAcceptTermsMutationVariables = Exact<{ [key: string]: never; }>;


export type UserAcceptTermsMutation = { __typename?: 'Mutation', userAcceptTerms: { __typename?: 'UserAcceptTermsResponse', status?: string | null, error?: string | null } };

export type VerifyUserIdentityMutationVariables = Exact<{
  inquiryId: Scalars['String']['input'];
}>;


export type VerifyUserIdentityMutation = { __typename?: 'Mutation', verifyUserIdentity: { __typename?: 'VerifyUserIdentityResponse', status?: string | null, error?: string | null } };

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  data: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUsers: { __typename?: 'UpdateUsersMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number } } };

export type UpdateOnboardingStatusMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  accountStatus?: InputMaybe<AccountStatus>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
}>;


export type UpdateOnboardingStatusMutation = { __typename?: 'Mutation', updateAccounts: { __typename?: 'UpdateAccountsMutationResponse', accounts: Array<{ __typename?: 'Account', id: string }> } };

export type GetContentCountForCreatorQueryVariables = Exact<{
  creatorId: Scalars['ID']['input'];
}>;


export type GetContentCountForCreatorQuery = { __typename?: 'Query', creators: Array<{ __typename?: 'Creator', channelsAggregate?: { __typename?: 'CreatorChannelChannelsAggregationSelection', count: number } | null, worksAggregate?: { __typename?: 'CreatorWorkWorksAggregationSelection', count: number } | null }> };

export type GetContentCountForOwnerQueryVariables = Exact<{
  ownerId: Scalars['ID']['input'];
}>;


export type GetContentCountForOwnerQuery = { __typename?: 'Query', owners: Array<{ __typename?: 'Owner', channelsAggregate?: { __typename?: 'OwnerChannelChannelsAggregationSelection', count: number } | null, worksAggregate?: { __typename?: 'OwnerWorkWorksAggregationSelection', count: number } | null }> };

export type GetAccountDateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountDateQuery = { __typename?: 'Query', me?: { __typename?: 'User', userId: string, account?: { __typename?: 'Account', id: string, createdAt?: any | null } | null } | null };

export type CreateUserFeedbackMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  reaction?: InputMaybe<ReactionType>;
  feedback: Scalars['String']['input'];
}>;


export type CreateUserFeedbackMutation = { __typename?: 'Mutation', createFeedbacks: { __typename?: 'CreateFeedbacksMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, feedbacks: Array<{ __typename?: 'Feedback', id: string }> } };

export type CreateNutshellLeadMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type CreateNutshellLeadMutation = { __typename?: 'Mutation', createNutshellLead: { __typename?: 'CreateNutshellLeadResponse', status?: string | null, error?: string | null } };

export type CategoryTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoryTagsQuery = { __typename?: 'Query', categoryTags: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null, description?: string | null, tier?: number | null, tags: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null, description?: string | null, tier?: number | null }> }> };

export type NewCategoryTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type NewCategoryTagsQuery = { __typename?: 'Query', categoryTags: Array<{ __typename?: 'CategoryTag', id: string, tier?: number | null, title?: string | null, description?: string | null, icon?: string | null, parentTag: Array<{ __typename?: 'CategoryTag', id: string }> }> };

export type CertReqDetailFragment = { __typename?: 'CertificationRequest', name?: string | null, email?: string | null, channelUrl?: string | null, channelName?: string | null, channelDescription?: string | null, generativeAi: Array<GenerativeAiTypes>, generativeAiExplanation?: string | null, autoCorrectTools: Array<AutoCorrectToolsTypes>, autoCorrectToolsExplanation?: string | null, machineLearningTools: Array<MachineLearningToolsTypes>, machineLearningToolsExplanation?: string | null, otherAiTools: Array<OtherAiToolsTypes>, otherAiToolsExplanation?: string | null, requestSignature?: string | null, requestDate?: any | null, status?: RequestStatuses | null, result?: string | null, createdAt?: any | null, updatedAt?: any | null };

export type CertReqRelationsFragment = { __typename?: 'CertificationRequest', contentTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, contentCategories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }> };

export type GetAllCertReqsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCertReqsQuery = { __typename?: 'Query', certificationRequests: Array<{ __typename?: 'CertificationRequest', id: string, name?: string | null, email?: string | null, channelUrl?: string | null, channelName?: string | null, channelDescription?: string | null, generativeAi: Array<GenerativeAiTypes>, generativeAiExplanation?: string | null, autoCorrectTools: Array<AutoCorrectToolsTypes>, autoCorrectToolsExplanation?: string | null, machineLearningTools: Array<MachineLearningToolsTypes>, machineLearningToolsExplanation?: string | null, otherAiTools: Array<OtherAiToolsTypes>, otherAiToolsExplanation?: string | null, requestSignature?: string | null, requestDate?: any | null, status?: RequestStatuses | null, result?: string | null, createdAt?: any | null, updatedAt?: any | null, contentTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, contentCategories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }> }> };

export type GetCertReqsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetCertReqsQuery = { __typename?: 'Query', certificationRequests: Array<{ __typename?: 'CertificationRequest', id: string, name?: string | null, email?: string | null, channelUrl?: string | null, channelName?: string | null, channelDescription?: string | null, generativeAi: Array<GenerativeAiTypes>, generativeAiExplanation?: string | null, autoCorrectTools: Array<AutoCorrectToolsTypes>, autoCorrectToolsExplanation?: string | null, machineLearningTools: Array<MachineLearningToolsTypes>, machineLearningToolsExplanation?: string | null, otherAiTools: Array<OtherAiToolsTypes>, otherAiToolsExplanation?: string | null, requestSignature?: string | null, requestDate?: any | null, status?: RequestStatuses | null, result?: string | null, createdAt?: any | null, updatedAt?: any | null, contentTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, contentCategories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }> }> };

export type GetCertReqByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCertReqByIdQuery = { __typename?: 'Query', certificationRequests: Array<{ __typename?: 'CertificationRequest', id: string, name?: string | null, email?: string | null, channelUrl?: string | null, channelName?: string | null, channelDescription?: string | null, generativeAi: Array<GenerativeAiTypes>, generativeAiExplanation?: string | null, autoCorrectTools: Array<AutoCorrectToolsTypes>, autoCorrectToolsExplanation?: string | null, machineLearningTools: Array<MachineLearningToolsTypes>, machineLearningToolsExplanation?: string | null, otherAiTools: Array<OtherAiToolsTypes>, otherAiToolsExplanation?: string | null, requestSignature?: string | null, requestDate?: any | null, status?: RequestStatuses | null, result?: string | null, createdAt?: any | null, updatedAt?: any | null, contentTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, contentCategories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }> }> };

export type CreateCertReqMutationVariables = Exact<{
  input: CertificationRequestCreateInput;
}>;


export type CreateCertReqMutation = { __typename?: 'Mutation', createCertificationRequests: { __typename?: 'CreateCertificationRequestsMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, certificationRequests: Array<{ __typename?: 'CertificationRequest', id: string, name?: string | null, email?: string | null, channelUrl?: string | null, channelName?: string | null, channelDescription?: string | null, generativeAi: Array<GenerativeAiTypes>, generativeAiExplanation?: string | null, autoCorrectTools: Array<AutoCorrectToolsTypes>, autoCorrectToolsExplanation?: string | null, machineLearningTools: Array<MachineLearningToolsTypes>, machineLearningToolsExplanation?: string | null, otherAiTools: Array<OtherAiToolsTypes>, otherAiToolsExplanation?: string | null, requestSignature?: string | null, requestDate?: any | null, status?: RequestStatuses | null, result?: string | null, createdAt?: any | null, updatedAt?: any | null, contentTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, contentCategories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }> }> } };

export type UpdateCertReqMutationVariables = Exact<{
  where?: InputMaybe<CertificationRequestWhere>;
  update?: InputMaybe<CertificationRequestUpdateInput>;
}>;


export type UpdateCertReqMutation = { __typename?: 'Mutation', updateCertificationRequests: { __typename?: 'UpdateCertificationRequestsMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number, relationshipsCreated: number }, certificationRequests: Array<{ __typename?: 'CertificationRequest', id: string, name?: string | null, email?: string | null, channelUrl?: string | null, channelName?: string | null, channelDescription?: string | null, generativeAi: Array<GenerativeAiTypes>, generativeAiExplanation?: string | null, autoCorrectTools: Array<AutoCorrectToolsTypes>, autoCorrectToolsExplanation?: string | null, machineLearningTools: Array<MachineLearningToolsTypes>, machineLearningToolsExplanation?: string | null, otherAiTools: Array<OtherAiToolsTypes>, otherAiToolsExplanation?: string | null, requestSignature?: string | null, requestDate?: any | null, status?: RequestStatuses | null, result?: string | null, createdAt?: any | null, updatedAt?: any | null, contentTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, contentCategories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }> }> } };

export type DeleteCertReqMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCertReqMutation = { __typename?: 'Mutation', deleteCertificationRequests: { __typename?: 'DeleteInfo', nodesDeleted: number, relationshipsDeleted: number } };

export type ChannelDetailFragment = { __typename?: 'Channel', channelUrl?: string | null, channelName?: string | null, description?: string | null, createdAt?: any | null };

export type ChannelRelationsFragment = { __typename?: 'Channel', channelTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, categories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }>, contentBadges: Array<{ __typename?: 'ContentBadge', id: string }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }>, thumbnail?: { __typename?: 'UserMedia', id: string, extension?: string | null } | null, accounts: Array<{ __typename?: 'Account', id: string }> };

export type ChannelStakeholdersFragment = { __typename?: 'Channel', creators: Array<{ __typename?: 'Creator', id: string, firstName?: string | null, lastName?: string | null, bio?: string | null, profilePicture?: string | null, account?: { __typename?: 'Account', id: string } | null }>, owners: Array<{ __typename?: 'Owner', id: string, name?: string | null, profilePicture?: string | null, account?: { __typename?: 'Account', id: string } | null }> };

export type CreateChannelMutationVariables = Exact<{
  input: ChannelCreateInput;
}>;


export type CreateChannelMutation = { __typename?: 'Mutation', createChannels: { __typename?: 'CreateChannelsMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, channels: Array<{ __typename?: 'Channel', id: string, channelUrl?: string | null, channelName?: string | null, description?: string | null, createdAt?: any | null, channelTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, categories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }>, contentBadges: Array<{ __typename?: 'ContentBadge', id: string }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }>, thumbnail?: { __typename?: 'UserMedia', id: string, extension?: string | null } | null, accounts: Array<{ __typename?: 'Account', id: string }> }> } };

export type UpdateChannelMutationVariables = Exact<{
  where?: InputMaybe<ChannelWhere>;
  update?: InputMaybe<ChannelUpdateInput>;
}>;


export type UpdateChannelMutation = { __typename?: 'Mutation', updateChannels: { __typename?: 'UpdateChannelsMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number, relationshipsCreated: number }, channels: Array<{ __typename?: 'Channel', id: string, channelUrl?: string | null, channelName?: string | null, description?: string | null, createdAt?: any | null, channelTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, categories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }>, contentBadges: Array<{ __typename?: 'ContentBadge', id: string }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }>, thumbnail?: { __typename?: 'UserMedia', id: string, extension?: string | null } | null, accounts: Array<{ __typename?: 'Account', id: string }> }> } };

export type GetChannelsByCreatorQueryVariables = Exact<{
  creatorId: Scalars['ID']['input'];
}>;


export type GetChannelsByCreatorQuery = { __typename?: 'Query', channels: Array<{ __typename?: 'Channel', id: string, channelUrl?: string | null, channelName?: string | null, description?: string | null, createdAt?: any | null, channelTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, categories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }>, contentBadges: Array<{ __typename?: 'ContentBadge', id: string }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }>, thumbnail?: { __typename?: 'UserMedia', id: string, extension?: string | null } | null, accounts: Array<{ __typename?: 'Account', id: string }> }> };

export type GetChannelsByOwnerQueryVariables = Exact<{
  ownerId: Scalars['ID']['input'];
}>;


export type GetChannelsByOwnerQuery = { __typename?: 'Query', channels: Array<{ __typename?: 'Channel', id: string, channelUrl?: string | null, channelName?: string | null, description?: string | null, createdAt?: any | null, channelTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, categories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }>, contentBadges: Array<{ __typename?: 'ContentBadge', id: string }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }>, thumbnail?: { __typename?: 'UserMedia', id: string, extension?: string | null } | null, accounts: Array<{ __typename?: 'Account', id: string }> }> };

export type GetChannelByIdQueryVariables = Exact<{
  channelId: Scalars['ID']['input'];
}>;


export type GetChannelByIdQuery = { __typename?: 'Query', channels: Array<{ __typename?: 'Channel', id: string, channelUrl?: string | null, channelName?: string | null, description?: string | null, createdAt?: any | null, channelTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, categories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }>, contentBadges: Array<{ __typename?: 'ContentBadge', id: string }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }>, thumbnail?: { __typename?: 'UserMedia', id: string, extension?: string | null } | null, accounts: Array<{ __typename?: 'Account', id: string }>, creators: Array<{ __typename?: 'Creator', id: string, firstName?: string | null, lastName?: string | null, bio?: string | null, profilePicture?: string | null, account?: { __typename?: 'Account', id: string } | null }>, owners: Array<{ __typename?: 'Owner', id: string, name?: string | null, profilePicture?: string | null, account?: { __typename?: 'Account', id: string } | null }> }> };

export type DeleteChannelMutationVariables = Exact<{
  channelId: Scalars['ID']['input'];
}>;


export type DeleteChannelMutation = { __typename?: 'Mutation', deleteChannels: { __typename?: 'DeleteInfo', nodesDeleted: number, relationshipsDeleted: number } };

export type CreatorDetailFragment = { __typename?: 'Creator', firstName?: string | null, middleName?: string | null, lastName?: string | null, bio?: string | null, profilePicture?: string | null, gender?: GenderTypes | null, genderOther?: string | null, pronouns?: string | null, nationality?: string | null, dateOfBirth?: string | null, nicknames?: string | null };

export type GetCreatorByAccountQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;


export type GetCreatorByAccountQuery = { __typename?: 'Query', creators: Array<{ __typename?: 'Creator', id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, bio?: string | null, profilePicture?: string | null, gender?: GenderTypes | null, genderOther?: string | null, pronouns?: string | null, nationality?: string | null, dateOfBirth?: string | null, nicknames?: string | null, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }> }> };

export type SearchCreatorsByNameQueryVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
}>;


export type SearchCreatorsByNameQuery = { __typename?: 'Query', creators: Array<{ __typename?: 'Creator', id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, bio?: string | null, profilePicture?: string | null, gender?: GenderTypes | null, genderOther?: string | null, pronouns?: string | null, nationality?: string | null, dateOfBirth?: string | null, nicknames?: string | null, account?: { __typename?: 'Account', id: string } | null }> };

export type RecentCreatorsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type RecentCreatorsQuery = { __typename?: 'Query', creators: Array<{ __typename?: 'Creator', id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, bio?: string | null, profilePicture?: string | null, gender?: GenderTypes | null, genderOther?: string | null, pronouns?: string | null, nationality?: string | null, dateOfBirth?: string | null, nicknames?: string | null, account?: { __typename?: 'Account', id: string } | null }> };

export type AddCreatorMutationVariables = Exact<{
  input: CreatorCreateInput;
}>;


export type AddCreatorMutation = { __typename?: 'Mutation', createCreators: { __typename?: 'CreateCreatorsMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, creators: Array<{ __typename?: 'Creator', id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, bio?: string | null, profilePicture?: string | null, gender?: GenderTypes | null, genderOther?: string | null, pronouns?: string | null, nationality?: string | null, dateOfBirth?: string | null, nicknames?: string | null, account?: { __typename?: 'Account', id: string } | null, contacts: Array<{ __typename?: 'CreatorContact', emailAddress?: string | null }> }> } };

export type UpdateCreatorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: CreatorUpdateInput;
}>;


export type UpdateCreatorMutation = { __typename?: 'Mutation', updateCreators: { __typename?: 'UpdateCreatorsMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number, relationshipsCreated: number, nodesDeleted: number, relationshipsDeleted: number }, creators: Array<{ __typename?: 'Creator', id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, bio?: string | null, profilePicture?: string | null, gender?: GenderTypes | null, genderOther?: string | null, pronouns?: string | null, nationality?: string | null, dateOfBirth?: string | null, nicknames?: string | null, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }> }> } };

export type GetCreatorContactsQueryVariables = Exact<{
  creatorId: Scalars['ID']['input'];
}>;


export type GetCreatorContactsQuery = { __typename?: 'Query', creatorContacts: Array<{ __typename?: 'CreatorContact', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, stateProvince?: string | null, postalCode?: string | null, country?: string | null, emailAddress?: string | null, phoneNumber?: string | null }> };

export type CreateCreatorContactsMutationVariables = Exact<{
  input: Array<CreatorContactCreateInput> | CreatorContactCreateInput;
}>;


export type CreateCreatorContactsMutation = { __typename?: 'Mutation', createCreatorContacts: { __typename?: 'CreateCreatorContactsMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number } } };

export type DeleteCreatorContactsMutationVariables = Exact<{
  creatorId: Scalars['ID']['input'];
}>;


export type DeleteCreatorContactsMutation = { __typename?: 'Mutation', deleteCreatorContacts: { __typename?: 'DeleteInfo', nodesDeleted: number, relationshipsDeleted: number } };

export type MediaBriefFragment = { __typename?: 'UserMedia', extension?: string | null, caption?: string | null, source?: string | null, contentType?: string | null, width?: number | null, height?: number | null };

export type MediaDetailFragment = { __typename?: 'UserMedia', extension?: string | null, caption?: string | null, sourceType?: MediaSourceType | null, source?: string | null, contentType?: string | null, contentLength?: number | null, encoding?: string | null, width?: number | null, height?: number | null, generativeAi?: Array<GenerativeAiTypes> | null, generativeAiExplanation?: string | null, autoCorrectTools?: Array<AutoCorrectToolsTypes> | null, autoCorrectToolsExplanation?: string | null, machineLearningTools?: Array<MachineLearningToolsTypes> | null, machineLearningToolsExplanation?: string | null, otherAiTools?: Array<OtherAiToolsTypes> | null, otherAiToolsExplanation?: string | null, createdAt?: any | null, updatedAt?: any | null };

export type GetUserMediaByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetUserMediaByIdQuery = { __typename?: 'Query', userMedias: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, caption?: string | null, sourceType?: MediaSourceType | null, source?: string | null, contentType?: string | null, contentLength?: number | null, encoding?: string | null, width?: number | null, height?: number | null, generativeAi?: Array<GenerativeAiTypes> | null, generativeAiExplanation?: string | null, autoCorrectTools?: Array<AutoCorrectToolsTypes> | null, autoCorrectToolsExplanation?: string | null, machineLearningTools?: Array<MachineLearningToolsTypes> | null, machineLearningToolsExplanation?: string | null, otherAiTools?: Array<OtherAiToolsTypes> | null, otherAiToolsExplanation?: string | null, createdAt?: any | null, updatedAt?: any | null }> };

export type ParsePageInfoMutationVariables = Exact<{
  url: Scalars['String']['input'];
}>;


export type ParsePageInfoMutation = { __typename?: 'Mutation', parsePageFromUrl?: { __typename?: 'ParsedPage', title?: string | null, description?: string | null, keywords?: string | null, media?: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, source?: string | null, contentType?: string | null, width?: number | null, height?: number | null }> | null } | null };

export type DeleteUserMediaMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type DeleteUserMediaMutation = { __typename?: 'Mutation', deleteUserMedias: { __typename?: 'DeleteInfo', nodesDeleted: number, relationshipsDeleted: number } };

export type UpdateUserMediaMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  update: UserMediaUpdateInput;
}>;


export type UpdateUserMediaMutation = { __typename?: 'Mutation', updateUserMedias: { __typename?: 'UpdateUserMediasMutationResponse', userMedias: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, caption?: string | null, sourceType?: MediaSourceType | null, source?: string | null, contentType?: string | null, contentLength?: number | null, encoding?: string | null, width?: number | null, height?: number | null, generativeAi?: Array<GenerativeAiTypes> | null, generativeAiExplanation?: string | null, autoCorrectTools?: Array<AutoCorrectToolsTypes> | null, autoCorrectToolsExplanation?: string | null, machineLearningTools?: Array<MachineLearningToolsTypes> | null, machineLearningToolsExplanation?: string | null, otherAiTools?: Array<OtherAiToolsTypes> | null, otherAiToolsExplanation?: string | null, createdAt?: any | null, updatedAt?: any | null }> } };

export type UploadMediaMutationVariables = Exact<{
  upload: Scalars['Upload']['input'];
  length?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UploadMediaMutation = { __typename?: 'Mutation', uploadMedia: { __typename?: 'UploadMediaResponse', status?: string | null, error?: string | null, media?: { __typename?: 'UserMedia', id: string, extension?: string | null, caption?: string | null, source?: string | null, createdAt?: any | null } | null } };

export type OwnerDetailFragment = { __typename?: 'Owner', name?: string | null, type?: OwnerTypes | null, url?: string | null, profilePicture?: string | null };

export type GetOwnerByAccountQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;


export type GetOwnerByAccountQuery = { __typename?: 'Query', owners: Array<{ __typename?: 'Owner', id: string, name?: string | null, type?: OwnerTypes | null, url?: string | null, profilePicture?: string | null, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }> }> };

export type SearchOwnersByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type SearchOwnersByNameQuery = { __typename?: 'Query', owners: Array<{ __typename?: 'Owner', id: string, name?: string | null, type?: OwnerTypes | null, url?: string | null, profilePicture?: string | null, account?: { __typename?: 'Account', id: string } | null }> };

export type RecentOwnersQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type RecentOwnersQuery = { __typename?: 'Query', owners: Array<{ __typename?: 'Owner', id: string, name?: string | null, type?: OwnerTypes | null, url?: string | null, profilePicture?: string | null, account?: { __typename?: 'Account', id: string } | null }> };

export type AddOwnerMutationVariables = Exact<{
  input: OwnerCreateInput;
}>;


export type AddOwnerMutation = { __typename?: 'Mutation', createOwners: { __typename?: 'CreateOwnersMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, owners: Array<{ __typename?: 'Owner', id: string, name?: string | null, account?: { __typename?: 'Account', id: string } | null, contacts: Array<{ __typename?: 'OwnerContact', emailAddress?: string | null }> }> } };

export type UpdateOwnerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: OwnerUpdateInput;
}>;


export type UpdateOwnerMutation = { __typename?: 'Mutation', updateOwners: { __typename?: 'UpdateOwnersMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number, relationshipsCreated: number, nodesDeleted: number, relationshipsDeleted: number }, owners: Array<{ __typename?: 'Owner', id: string, name?: string | null, type?: OwnerTypes | null, url?: string | null, profilePicture?: string | null, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }> }> } };

export type GetOwnerContactsQueryVariables = Exact<{
  ownerId: Scalars['ID']['input'];
}>;


export type GetOwnerContactsQuery = { __typename?: 'Query', ownerContacts: Array<{ __typename?: 'OwnerContact', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, stateProvince?: string | null, postalCode?: string | null, country?: string | null, emailAddress?: string | null, phoneNumber?: string | null }> };

export type CreateOwnerContactsMutationVariables = Exact<{
  input: Array<OwnerContactCreateInput> | OwnerContactCreateInput;
}>;


export type CreateOwnerContactsMutation = { __typename?: 'Mutation', createOwnerContacts: { __typename?: 'CreateOwnerContactsMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number } } };

export type DeleteOwnerContactsMutationVariables = Exact<{
  ownerId: Scalars['ID']['input'];
}>;


export type DeleteOwnerContactsMutation = { __typename?: 'Mutation', deleteOwnerContacts: { __typename?: 'DeleteInfo', nodesDeleted: number, relationshipsDeleted: number } };

export type SystemStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemStatusQuery = { __typename?: 'Query', systemStatus: { __typename?: 'SystemStatus', apolloGraphql: { __typename?: 'ServerStatus', buildTime?: string | null, commitHash?: string | null } } };

export type GetTagsByTitlesQueryVariables = Exact<{
  titles: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type GetTagsByTitlesQuery = { __typename?: 'Query', tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }> };

export type CreateTagsMutationVariables = Exact<{
  input: Array<TagCreateInput> | TagCreateInput;
}>;


export type CreateTagsMutation = { __typename?: 'Mutation', createTags: { __typename?: 'CreateTagsMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number }, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null }> } };

export type CreateTagRequestMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  description: Scalars['String']['input'];
}>;


export type CreateTagRequestMutation = { __typename?: 'Mutation', createCategoryTagRequests: { __typename?: 'CreateCategoryTagRequestsMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, categoryTagRequests: Array<{ __typename?: 'CategoryTagRequest', id: string }> } };

export type GetAllTypeTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTypeTagsQuery = { __typename?: 'Query', typeTags: Array<{ __typename?: 'TypeTag', id: string, description?: string | null, tier?: number | null, title?: string | null, tags: Array<{ __typename?: 'TypeTag', id: string, description?: string | null, tier?: number | null, title?: string | null, tags: Array<{ __typename?: 'TypeTag', id: string, description?: string | null, tier?: number | null, title?: string | null }> }> }> };

export type TypeTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type TypeTagsQuery = { __typename?: 'Query', typeTags: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null, parentTag: Array<{ __typename?: 'TypeTag', id: string }>, tagsAggregate?: { __typename?: 'TypeTagTypeTagTagsAggregationSelection', count: number } | null }> };

export type WorkDetailFragment = { __typename?: 'Work', workUrl?: string | null, title?: string | null, description?: string | null, createdAt?: any | null };

export type WorkRelationsFragment = { __typename?: 'Work', workTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, categories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }>, contentBadges: Array<{ __typename?: 'ContentBadge', id: string }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }>, thumbnail?: { __typename?: 'UserMedia', id: string, extension?: string | null } | null, accounts: Array<{ __typename?: 'Account', id: string }> };

export type WorkStakeholdersFragment = { __typename?: 'Work', creators: Array<{ __typename?: 'Creator', id: string, firstName?: string | null, lastName?: string | null, bio?: string | null, profilePicture?: string | null, account?: { __typename?: 'Account', id: string } | null }>, owners: Array<{ __typename?: 'Owner', id: string, name?: string | null, profilePicture?: string | null, account?: { __typename?: 'Account', id: string } | null }> };

export type CreateWorkMutationVariables = Exact<{
  input: WorkCreateInput;
}>;


export type CreateWorkMutation = { __typename?: 'Mutation', createWorks: { __typename?: 'CreateWorksMutationResponse', info: { __typename?: 'CreateInfo', nodesCreated: number, relationshipsCreated: number }, works: Array<{ __typename?: 'Work', id: string, workUrl?: string | null, title?: string | null, description?: string | null, createdAt?: any | null, workTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, categories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }>, contentBadges: Array<{ __typename?: 'ContentBadge', id: string }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }>, thumbnail?: { __typename?: 'UserMedia', id: string, extension?: string | null } | null, accounts: Array<{ __typename?: 'Account', id: string }> }> } };

export type UpdateWorkMutationVariables = Exact<{
  where?: InputMaybe<WorkWhere>;
  update?: InputMaybe<WorkUpdateInput>;
}>;


export type UpdateWorkMutation = { __typename?: 'Mutation', updateWorks: { __typename?: 'UpdateWorksMutationResponse', info: { __typename?: 'UpdateInfo', nodesCreated: number, relationshipsCreated: number }, works: Array<{ __typename?: 'Work', id: string, workUrl?: string | null, title?: string | null, description?: string | null, createdAt?: any | null, workTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, categories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }>, contentBadges: Array<{ __typename?: 'ContentBadge', id: string }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }>, thumbnail?: { __typename?: 'UserMedia', id: string, extension?: string | null } | null, accounts: Array<{ __typename?: 'Account', id: string }> }> } };

export type GetWorksByCreatorQueryVariables = Exact<{
  creatorId: Scalars['ID']['input'];
}>;


export type GetWorksByCreatorQuery = { __typename?: 'Query', works: Array<{ __typename?: 'Work', id: string, workUrl?: string | null, title?: string | null, description?: string | null, createdAt?: any | null, workTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, categories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }>, contentBadges: Array<{ __typename?: 'ContentBadge', id: string }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }>, thumbnail?: { __typename?: 'UserMedia', id: string, extension?: string | null } | null, accounts: Array<{ __typename?: 'Account', id: string }> }> };

export type GetWorksByOwnerQueryVariables = Exact<{
  ownerId: Scalars['ID']['input'];
}>;


export type GetWorksByOwnerQuery = { __typename?: 'Query', works: Array<{ __typename?: 'Work', id: string, workUrl?: string | null, title?: string | null, description?: string | null, createdAt?: any | null, workTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, categories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }>, contentBadges: Array<{ __typename?: 'ContentBadge', id: string }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }>, thumbnail?: { __typename?: 'UserMedia', id: string, extension?: string | null } | null, accounts: Array<{ __typename?: 'Account', id: string }> }> };

export type GetWorkByIdQueryVariables = Exact<{
  workId: Scalars['ID']['input'];
}>;


export type GetWorkByIdQuery = { __typename?: 'Query', works: Array<{ __typename?: 'Work', id: string, workUrl?: string | null, title?: string | null, description?: string | null, createdAt?: any | null, workTypes: Array<{ __typename?: 'TypeTag', id: string, tier?: number | null, title?: string | null }>, categories: Array<{ __typename?: 'CategoryTag', id: string, icon?: string | null, title?: string | null }>, tags: Array<{ __typename?: 'Tag', id: string, title?: string | null, blocked?: boolean | null }>, contentBadges: Array<{ __typename?: 'ContentBadge', id: string }>, media: Array<{ __typename?: 'UserMedia', id: string, extension?: string | null, sourceType?: MediaSourceType | null, caption?: string | null }>, thumbnail?: { __typename?: 'UserMedia', id: string, extension?: string | null } | null, accounts: Array<{ __typename?: 'Account', id: string }>, creators: Array<{ __typename?: 'Creator', id: string, firstName?: string | null, lastName?: string | null, bio?: string | null, profilePicture?: string | null, account?: { __typename?: 'Account', id: string } | null }>, owners: Array<{ __typename?: 'Owner', id: string, name?: string | null, profilePicture?: string | null, account?: { __typename?: 'Account', id: string } | null }> }> };

export type DeleteWorkMutationVariables = Exact<{
  workId: Scalars['ID']['input'];
}>;


export type DeleteWorkMutation = { __typename?: 'Mutation', deleteWorks: { __typename?: 'DeleteInfo', nodesDeleted: number, relationshipsDeleted: number } };

export const CertReqDetailFragmentDoc = gql`
    fragment CertReqDetail on CertificationRequest {
  name
  email
  channelUrl
  channelName
  channelDescription
  generativeAi
  generativeAiExplanation
  autoCorrectTools
  autoCorrectToolsExplanation
  machineLearningTools
  machineLearningToolsExplanation
  otherAiTools
  otherAiToolsExplanation
  requestSignature
  requestDate
  status
  result
  createdAt
  updatedAt
}
    `;
export const CertReqRelationsFragmentDoc = gql`
    fragment CertReqRelations on CertificationRequest {
  contentTypes {
    id
    tier
    title
  }
  contentCategories {
    id
    icon
    title
  }
  media {
    id
    extension
    sourceType
    caption
  }
}
    `;
export const ChannelDetailFragmentDoc = gql`
    fragment ChannelDetail on Channel {
  channelUrl
  channelName
  description
  createdAt
}
    `;
export const ChannelRelationsFragmentDoc = gql`
    fragment ChannelRelations on Channel {
  channelTypes {
    id
    tier
    title
  }
  categories {
    id
    icon
    title
  }
  tags {
    id
    title
    blocked
  }
  contentBadges {
    id
  }
  media {
    id
    extension
    sourceType
    caption
  }
  thumbnail {
    id
    extension
  }
  accounts {
    id
  }
}
    `;
export const ChannelStakeholdersFragmentDoc = gql`
    fragment ChannelStakeholders on Channel {
  creators {
    id
    firstName
    lastName
    bio
    profilePicture
    account {
      id
    }
  }
  owners {
    id
    name
    profilePicture
    account {
      id
    }
  }
}
    `;
export const CreatorDetailFragmentDoc = gql`
    fragment CreatorDetail on Creator {
  firstName
  middleName
  lastName
  bio
  profilePicture
  gender
  genderOther
  pronouns
  nationality
  dateOfBirth
  nicknames
}
    `;
export const MediaBriefFragmentDoc = gql`
    fragment MediaBrief on UserMedia {
  extension
  caption
  source
  contentType
  width
  height
}
    `;
export const MediaDetailFragmentDoc = gql`
    fragment MediaDetail on UserMedia {
  extension
  caption
  sourceType
  source
  contentType
  contentLength
  encoding
  width
  height
  generativeAi
  generativeAiExplanation
  autoCorrectTools
  autoCorrectToolsExplanation
  machineLearningTools
  machineLearningToolsExplanation
  otherAiTools
  otherAiToolsExplanation
  createdAt
  updatedAt
}
    `;
export const OwnerDetailFragmentDoc = gql`
    fragment OwnerDetail on Owner {
  name
  type
  url
  profilePicture
}
    `;
export const WorkDetailFragmentDoc = gql`
    fragment WorkDetail on Work {
  workUrl
  title
  description
  createdAt
}
    `;
export const WorkRelationsFragmentDoc = gql`
    fragment WorkRelations on Work {
  workTypes {
    id
    tier
    title
  }
  categories {
    id
    icon
    title
  }
  tags {
    id
    title
    blocked
  }
  contentBadges {
    id
  }
  media {
    id
    extension
    sourceType
    caption
  }
  thumbnail {
    id
    extension
  }
  accounts {
    id
  }
}
    `;
export const WorkStakeholdersFragmentDoc = gql`
    fragment WorkStakeholders on Work {
  creators {
    id
    firstName
    lastName
    bio
    profilePicture
    account {
      id
    }
  }
  owners {
    id
    name
    profilePicture
    account {
      id
    }
  }
}
    `;
export const GetProfileDocument = gql`
    query GetProfile {
  me {
    id
    userId
    userType
    email
    nickname
    picture
    account {
      id
      onboardingStatus
      accountStatus
      subscription {
        plan
      }
      creators {
        id
        profilePicture
      }
      owner {
        id
        profilePicture
      }
    }
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export function useGetProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileSuspenseQueryHookResult = ReturnType<typeof useGetProfileSuspenseQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const CreateAccountDocument = gql`
    mutation CreateAccount($input: AccountCreateInput!) {
  createAccounts(input: [$input]) {
    accounts {
      id
    }
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const SubscribeAccountDocument = gql`
    mutation SubscribeAccount($name: String!, $email: String!, $paymentMethod: String!, $priceId: String!, $plan: PlanTypes!, $displayPrice: Float!, $promoCode: String) {
  subscribeAccount(
    name: $name
    email: $email
    paymentMethod: $paymentMethod
    priceId: $priceId
    plan: $plan
    displayPrice: $displayPrice
    promoCode: $promoCode
  ) {
    clientSecret
    error
    subscriptionId
  }
}
    `;
export type SubscribeAccountMutationFn = Apollo.MutationFunction<SubscribeAccountMutation, SubscribeAccountMutationVariables>;

/**
 * __useSubscribeAccountMutation__
 *
 * To run a mutation, you first call `useSubscribeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeAccountMutation, { data, loading, error }] = useSubscribeAccountMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      paymentMethod: // value for 'paymentMethod'
 *      priceId: // value for 'priceId'
 *      plan: // value for 'plan'
 *      displayPrice: // value for 'displayPrice'
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useSubscribeAccountMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeAccountMutation, SubscribeAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeAccountMutation, SubscribeAccountMutationVariables>(SubscribeAccountDocument, options);
      }
export type SubscribeAccountMutationHookResult = ReturnType<typeof useSubscribeAccountMutation>;
export type SubscribeAccountMutationResult = Apollo.MutationResult<SubscribeAccountMutation>;
export type SubscribeAccountMutationOptions = Apollo.BaseMutationOptions<SubscribeAccountMutation, SubscribeAccountMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: [UserCreateInput!]!) {
  createUsers(input: $input) {
    users {
      id
      userType
      userId
      picture
      nickname
      id
      email
      account {
        id
        accountStatus
        id
        joinedDate
        lastActive
        profileURL
        taxId
      }
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UserAcceptTermsDocument = gql`
    mutation UserAcceptTerms {
  userAcceptTerms {
    status
    error
  }
}
    `;
export type UserAcceptTermsMutationFn = Apollo.MutationFunction<UserAcceptTermsMutation, UserAcceptTermsMutationVariables>;

/**
 * __useUserAcceptTermsMutation__
 *
 * To run a mutation, you first call `useUserAcceptTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAcceptTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAcceptTermsMutation, { data, loading, error }] = useUserAcceptTermsMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserAcceptTermsMutation(baseOptions?: Apollo.MutationHookOptions<UserAcceptTermsMutation, UserAcceptTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserAcceptTermsMutation, UserAcceptTermsMutationVariables>(UserAcceptTermsDocument, options);
      }
export type UserAcceptTermsMutationHookResult = ReturnType<typeof useUserAcceptTermsMutation>;
export type UserAcceptTermsMutationResult = Apollo.MutationResult<UserAcceptTermsMutation>;
export type UserAcceptTermsMutationOptions = Apollo.BaseMutationOptions<UserAcceptTermsMutation, UserAcceptTermsMutationVariables>;
export const VerifyUserIdentityDocument = gql`
    mutation VerifyUserIdentity($inquiryId: String!) {
  verifyUserIdentity(inquiryId: $inquiryId) {
    status
    error
  }
}
    `;
export type VerifyUserIdentityMutationFn = Apollo.MutationFunction<VerifyUserIdentityMutation, VerifyUserIdentityMutationVariables>;

/**
 * __useVerifyUserIdentityMutation__
 *
 * To run a mutation, you first call `useVerifyUserIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserIdentityMutation, { data, loading, error }] = useVerifyUserIdentityMutation({
 *   variables: {
 *      inquiryId: // value for 'inquiryId'
 *   },
 * });
 */
export function useVerifyUserIdentityMutation(baseOptions?: Apollo.MutationHookOptions<VerifyUserIdentityMutation, VerifyUserIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyUserIdentityMutation, VerifyUserIdentityMutationVariables>(VerifyUserIdentityDocument, options);
      }
export type VerifyUserIdentityMutationHookResult = ReturnType<typeof useVerifyUserIdentityMutation>;
export type VerifyUserIdentityMutationResult = Apollo.MutationResult<VerifyUserIdentityMutation>;
export type VerifyUserIdentityMutationOptions = Apollo.BaseMutationOptions<VerifyUserIdentityMutation, VerifyUserIdentityMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($userId: ID!, $data: UserUpdateInput!) {
  updateUsers(where: {userId: $userId}, update: $data) {
    info {
      nodesCreated
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateOnboardingStatusDocument = gql`
    mutation UpdateOnboardingStatus($accountId: ID!, $accountStatus: AccountStatus, $onboardingStatus: OnboardingStatus) {
  updateAccounts(
    where: {id: $accountId}
    update: {accountStatus: $accountStatus, onboardingStatus: $onboardingStatus}
  ) {
    accounts {
      id
    }
  }
}
    `;
export type UpdateOnboardingStatusMutationFn = Apollo.MutationFunction<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>;

/**
 * __useUpdateOnboardingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingStatusMutation, { data, loading, error }] = useUpdateOnboardingStatusMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      accountStatus: // value for 'accountStatus'
 *      onboardingStatus: // value for 'onboardingStatus'
 *   },
 * });
 */
export function useUpdateOnboardingStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>(UpdateOnboardingStatusDocument, options);
      }
export type UpdateOnboardingStatusMutationHookResult = ReturnType<typeof useUpdateOnboardingStatusMutation>;
export type UpdateOnboardingStatusMutationResult = Apollo.MutationResult<UpdateOnboardingStatusMutation>;
export type UpdateOnboardingStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>;
export const GetContentCountForCreatorDocument = gql`
    query GetContentCountForCreator($creatorId: ID!) {
  creators(where: {id: $creatorId}) {
    channelsAggregate {
      count
    }
    worksAggregate {
      count
    }
  }
}
    `;

/**
 * __useGetContentCountForCreatorQuery__
 *
 * To run a query within a React component, call `useGetContentCountForCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentCountForCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentCountForCreatorQuery({
 *   variables: {
 *      creatorId: // value for 'creatorId'
 *   },
 * });
 */
export function useGetContentCountForCreatorQuery(baseOptions: Apollo.QueryHookOptions<GetContentCountForCreatorQuery, GetContentCountForCreatorQueryVariables> & ({ variables: GetContentCountForCreatorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentCountForCreatorQuery, GetContentCountForCreatorQueryVariables>(GetContentCountForCreatorDocument, options);
      }
export function useGetContentCountForCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentCountForCreatorQuery, GetContentCountForCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentCountForCreatorQuery, GetContentCountForCreatorQueryVariables>(GetContentCountForCreatorDocument, options);
        }
export function useGetContentCountForCreatorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetContentCountForCreatorQuery, GetContentCountForCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetContentCountForCreatorQuery, GetContentCountForCreatorQueryVariables>(GetContentCountForCreatorDocument, options);
        }
export type GetContentCountForCreatorQueryHookResult = ReturnType<typeof useGetContentCountForCreatorQuery>;
export type GetContentCountForCreatorLazyQueryHookResult = ReturnType<typeof useGetContentCountForCreatorLazyQuery>;
export type GetContentCountForCreatorSuspenseQueryHookResult = ReturnType<typeof useGetContentCountForCreatorSuspenseQuery>;
export type GetContentCountForCreatorQueryResult = Apollo.QueryResult<GetContentCountForCreatorQuery, GetContentCountForCreatorQueryVariables>;
export const GetContentCountForOwnerDocument = gql`
    query GetContentCountForOwner($ownerId: ID!) {
  owners(where: {id: $ownerId}) {
    channelsAggregate {
      count
    }
    worksAggregate {
      count
    }
  }
}
    `;

/**
 * __useGetContentCountForOwnerQuery__
 *
 * To run a query within a React component, call `useGetContentCountForOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentCountForOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentCountForOwnerQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useGetContentCountForOwnerQuery(baseOptions: Apollo.QueryHookOptions<GetContentCountForOwnerQuery, GetContentCountForOwnerQueryVariables> & ({ variables: GetContentCountForOwnerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentCountForOwnerQuery, GetContentCountForOwnerQueryVariables>(GetContentCountForOwnerDocument, options);
      }
export function useGetContentCountForOwnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentCountForOwnerQuery, GetContentCountForOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentCountForOwnerQuery, GetContentCountForOwnerQueryVariables>(GetContentCountForOwnerDocument, options);
        }
export function useGetContentCountForOwnerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetContentCountForOwnerQuery, GetContentCountForOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetContentCountForOwnerQuery, GetContentCountForOwnerQueryVariables>(GetContentCountForOwnerDocument, options);
        }
export type GetContentCountForOwnerQueryHookResult = ReturnType<typeof useGetContentCountForOwnerQuery>;
export type GetContentCountForOwnerLazyQueryHookResult = ReturnType<typeof useGetContentCountForOwnerLazyQuery>;
export type GetContentCountForOwnerSuspenseQueryHookResult = ReturnType<typeof useGetContentCountForOwnerSuspenseQuery>;
export type GetContentCountForOwnerQueryResult = Apollo.QueryResult<GetContentCountForOwnerQuery, GetContentCountForOwnerQueryVariables>;
export const GetAccountDateDocument = gql`
    query GetAccountDate {
  me {
    userId
    account {
      id
      createdAt
    }
  }
}
    `;

/**
 * __useGetAccountDateQuery__
 *
 * To run a query within a React component, call `useGetAccountDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountDateQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountDateQuery, GetAccountDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountDateQuery, GetAccountDateQueryVariables>(GetAccountDateDocument, options);
      }
export function useGetAccountDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountDateQuery, GetAccountDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountDateQuery, GetAccountDateQueryVariables>(GetAccountDateDocument, options);
        }
export function useGetAccountDateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAccountDateQuery, GetAccountDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAccountDateQuery, GetAccountDateQueryVariables>(GetAccountDateDocument, options);
        }
export type GetAccountDateQueryHookResult = ReturnType<typeof useGetAccountDateQuery>;
export type GetAccountDateLazyQueryHookResult = ReturnType<typeof useGetAccountDateLazyQuery>;
export type GetAccountDateSuspenseQueryHookResult = ReturnType<typeof useGetAccountDateSuspenseQuery>;
export type GetAccountDateQueryResult = Apollo.QueryResult<GetAccountDateQuery, GetAccountDateQueryVariables>;
export const CreateUserFeedbackDocument = gql`
    mutation CreateUserFeedback($userId: ID!, $reaction: ReactionType, $feedback: String!) {
  createFeedbacks(
    input: [{reaction: $reaction, feedback: $feedback, createdBy: {connect: {where: {node: {id: $userId}}}}}]
  ) {
    info {
      nodesCreated
      relationshipsCreated
    }
    feedbacks {
      id
    }
  }
}
    `;
export type CreateUserFeedbackMutationFn = Apollo.MutationFunction<CreateUserFeedbackMutation, CreateUserFeedbackMutationVariables>;

/**
 * __useCreateUserFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateUserFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFeedbackMutation, { data, loading, error }] = useCreateUserFeedbackMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      reaction: // value for 'reaction'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useCreateUserFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserFeedbackMutation, CreateUserFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserFeedbackMutation, CreateUserFeedbackMutationVariables>(CreateUserFeedbackDocument, options);
      }
export type CreateUserFeedbackMutationHookResult = ReturnType<typeof useCreateUserFeedbackMutation>;
export type CreateUserFeedbackMutationResult = Apollo.MutationResult<CreateUserFeedbackMutation>;
export type CreateUserFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateUserFeedbackMutation, CreateUserFeedbackMutationVariables>;
export const CreateNutshellLeadDocument = gql`
    mutation CreateNutshellLead($userId: String!, $name: String!, $email: String!) {
  createNutshellLead(userId: $userId, name: $name, email: $email) {
    status
    error
  }
}
    `;
export type CreateNutshellLeadMutationFn = Apollo.MutationFunction<CreateNutshellLeadMutation, CreateNutshellLeadMutationVariables>;

/**
 * __useCreateNutshellLeadMutation__
 *
 * To run a mutation, you first call `useCreateNutshellLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNutshellLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNutshellLeadMutation, { data, loading, error }] = useCreateNutshellLeadMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateNutshellLeadMutation(baseOptions?: Apollo.MutationHookOptions<CreateNutshellLeadMutation, CreateNutshellLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNutshellLeadMutation, CreateNutshellLeadMutationVariables>(CreateNutshellLeadDocument, options);
      }
export type CreateNutshellLeadMutationHookResult = ReturnType<typeof useCreateNutshellLeadMutation>;
export type CreateNutshellLeadMutationResult = Apollo.MutationResult<CreateNutshellLeadMutation>;
export type CreateNutshellLeadMutationOptions = Apollo.BaseMutationOptions<CreateNutshellLeadMutation, CreateNutshellLeadMutationVariables>;
export const CategoryTagsDocument = gql`
    query CategoryTags {
  categoryTags(where: {tier: 0}, options: {sort: {title: ASC}}) {
    id
    icon
    title
    description
    tier
    tags(options: {sort: {title: ASC}}) {
      id
      icon
      title
      description
      tier
    }
  }
}
    `;

/**
 * __useCategoryTagsQuery__
 *
 * To run a query within a React component, call `useCategoryTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoryTagsQuery(baseOptions?: Apollo.QueryHookOptions<CategoryTagsQuery, CategoryTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryTagsQuery, CategoryTagsQueryVariables>(CategoryTagsDocument, options);
      }
export function useCategoryTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryTagsQuery, CategoryTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryTagsQuery, CategoryTagsQueryVariables>(CategoryTagsDocument, options);
        }
export function useCategoryTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CategoryTagsQuery, CategoryTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoryTagsQuery, CategoryTagsQueryVariables>(CategoryTagsDocument, options);
        }
export type CategoryTagsQueryHookResult = ReturnType<typeof useCategoryTagsQuery>;
export type CategoryTagsLazyQueryHookResult = ReturnType<typeof useCategoryTagsLazyQuery>;
export type CategoryTagsSuspenseQueryHookResult = ReturnType<typeof useCategoryTagsSuspenseQuery>;
export type CategoryTagsQueryResult = Apollo.QueryResult<CategoryTagsQuery, CategoryTagsQueryVariables>;
export const NewCategoryTagsDocument = gql`
    query NewCategoryTags {
  categoryTags(options: {sort: {title: ASC}}) {
    id
    tier
    title
    description
    icon
    parentTag {
      id
    }
  }
}
    `;

/**
 * __useNewCategoryTagsQuery__
 *
 * To run a query within a React component, call `useNewCategoryTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewCategoryTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCategoryTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewCategoryTagsQuery(baseOptions?: Apollo.QueryHookOptions<NewCategoryTagsQuery, NewCategoryTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewCategoryTagsQuery, NewCategoryTagsQueryVariables>(NewCategoryTagsDocument, options);
      }
export function useNewCategoryTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewCategoryTagsQuery, NewCategoryTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewCategoryTagsQuery, NewCategoryTagsQueryVariables>(NewCategoryTagsDocument, options);
        }
export function useNewCategoryTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NewCategoryTagsQuery, NewCategoryTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NewCategoryTagsQuery, NewCategoryTagsQueryVariables>(NewCategoryTagsDocument, options);
        }
export type NewCategoryTagsQueryHookResult = ReturnType<typeof useNewCategoryTagsQuery>;
export type NewCategoryTagsLazyQueryHookResult = ReturnType<typeof useNewCategoryTagsLazyQuery>;
export type NewCategoryTagsSuspenseQueryHookResult = ReturnType<typeof useNewCategoryTagsSuspenseQuery>;
export type NewCategoryTagsQueryResult = Apollo.QueryResult<NewCategoryTagsQuery, NewCategoryTagsQueryVariables>;
export const GetAllCertReqsDocument = gql`
    query GetAllCertReqs {
  certificationRequests {
    id
    ...CertReqDetail
    ...CertReqRelations
  }
}
    ${CertReqDetailFragmentDoc}
${CertReqRelationsFragmentDoc}`;

/**
 * __useGetAllCertReqsQuery__
 *
 * To run a query within a React component, call `useGetAllCertReqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertReqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertReqsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCertReqsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCertReqsQuery, GetAllCertReqsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCertReqsQuery, GetAllCertReqsQueryVariables>(GetAllCertReqsDocument, options);
      }
export function useGetAllCertReqsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCertReqsQuery, GetAllCertReqsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCertReqsQuery, GetAllCertReqsQueryVariables>(GetAllCertReqsDocument, options);
        }
export function useGetAllCertReqsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllCertReqsQuery, GetAllCertReqsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllCertReqsQuery, GetAllCertReqsQueryVariables>(GetAllCertReqsDocument, options);
        }
export type GetAllCertReqsQueryHookResult = ReturnType<typeof useGetAllCertReqsQuery>;
export type GetAllCertReqsLazyQueryHookResult = ReturnType<typeof useGetAllCertReqsLazyQuery>;
export type GetAllCertReqsSuspenseQueryHookResult = ReturnType<typeof useGetAllCertReqsSuspenseQuery>;
export type GetAllCertReqsQueryResult = Apollo.QueryResult<GetAllCertReqsQuery, GetAllCertReqsQueryVariables>;
export const GetCertReqsDocument = gql`
    query GetCertReqs($userId: ID!) {
  certificationRequests(where: {createdBy: {id: $userId}}) {
    id
    ...CertReqDetail
    ...CertReqRelations
  }
}
    ${CertReqDetailFragmentDoc}
${CertReqRelationsFragmentDoc}`;

/**
 * __useGetCertReqsQuery__
 *
 * To run a query within a React component, call `useGetCertReqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertReqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertReqsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCertReqsQuery(baseOptions: Apollo.QueryHookOptions<GetCertReqsQuery, GetCertReqsQueryVariables> & ({ variables: GetCertReqsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertReqsQuery, GetCertReqsQueryVariables>(GetCertReqsDocument, options);
      }
export function useGetCertReqsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertReqsQuery, GetCertReqsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertReqsQuery, GetCertReqsQueryVariables>(GetCertReqsDocument, options);
        }
export function useGetCertReqsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCertReqsQuery, GetCertReqsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCertReqsQuery, GetCertReqsQueryVariables>(GetCertReqsDocument, options);
        }
export type GetCertReqsQueryHookResult = ReturnType<typeof useGetCertReqsQuery>;
export type GetCertReqsLazyQueryHookResult = ReturnType<typeof useGetCertReqsLazyQuery>;
export type GetCertReqsSuspenseQueryHookResult = ReturnType<typeof useGetCertReqsSuspenseQuery>;
export type GetCertReqsQueryResult = Apollo.QueryResult<GetCertReqsQuery, GetCertReqsQueryVariables>;
export const GetCertReqByIdDocument = gql`
    query GetCertReqById($id: ID!) {
  certificationRequests(where: {id: $id}) {
    id
    ...CertReqDetail
    ...CertReqRelations
  }
}
    ${CertReqDetailFragmentDoc}
${CertReqRelationsFragmentDoc}`;

/**
 * __useGetCertReqByIdQuery__
 *
 * To run a query within a React component, call `useGetCertReqByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertReqByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertReqByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCertReqByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCertReqByIdQuery, GetCertReqByIdQueryVariables> & ({ variables: GetCertReqByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertReqByIdQuery, GetCertReqByIdQueryVariables>(GetCertReqByIdDocument, options);
      }
export function useGetCertReqByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertReqByIdQuery, GetCertReqByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertReqByIdQuery, GetCertReqByIdQueryVariables>(GetCertReqByIdDocument, options);
        }
export function useGetCertReqByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCertReqByIdQuery, GetCertReqByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCertReqByIdQuery, GetCertReqByIdQueryVariables>(GetCertReqByIdDocument, options);
        }
export type GetCertReqByIdQueryHookResult = ReturnType<typeof useGetCertReqByIdQuery>;
export type GetCertReqByIdLazyQueryHookResult = ReturnType<typeof useGetCertReqByIdLazyQuery>;
export type GetCertReqByIdSuspenseQueryHookResult = ReturnType<typeof useGetCertReqByIdSuspenseQuery>;
export type GetCertReqByIdQueryResult = Apollo.QueryResult<GetCertReqByIdQuery, GetCertReqByIdQueryVariables>;
export const CreateCertReqDocument = gql`
    mutation CreateCertReq($input: CertificationRequestCreateInput!) {
  createCertificationRequests(input: [$input]) {
    info {
      nodesCreated
      relationshipsCreated
    }
    certificationRequests {
      id
      ...CertReqDetail
      ...CertReqRelations
    }
  }
}
    ${CertReqDetailFragmentDoc}
${CertReqRelationsFragmentDoc}`;
export type CreateCertReqMutationFn = Apollo.MutationFunction<CreateCertReqMutation, CreateCertReqMutationVariables>;

/**
 * __useCreateCertReqMutation__
 *
 * To run a mutation, you first call `useCreateCertReqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCertReqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCertReqMutation, { data, loading, error }] = useCreateCertReqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCertReqMutation(baseOptions?: Apollo.MutationHookOptions<CreateCertReqMutation, CreateCertReqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCertReqMutation, CreateCertReqMutationVariables>(CreateCertReqDocument, options);
      }
export type CreateCertReqMutationHookResult = ReturnType<typeof useCreateCertReqMutation>;
export type CreateCertReqMutationResult = Apollo.MutationResult<CreateCertReqMutation>;
export type CreateCertReqMutationOptions = Apollo.BaseMutationOptions<CreateCertReqMutation, CreateCertReqMutationVariables>;
export const UpdateCertReqDocument = gql`
    mutation UpdateCertReq($where: CertificationRequestWhere, $update: CertificationRequestUpdateInput) {
  updateCertificationRequests(where: $where, update: $update) {
    info {
      nodesCreated
      relationshipsCreated
    }
    certificationRequests {
      id
      ...CertReqDetail
      ...CertReqRelations
    }
  }
}
    ${CertReqDetailFragmentDoc}
${CertReqRelationsFragmentDoc}`;
export type UpdateCertReqMutationFn = Apollo.MutationFunction<UpdateCertReqMutation, UpdateCertReqMutationVariables>;

/**
 * __useUpdateCertReqMutation__
 *
 * To run a mutation, you first call `useUpdateCertReqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCertReqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCertReqMutation, { data, loading, error }] = useUpdateCertReqMutation({
 *   variables: {
 *      where: // value for 'where'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateCertReqMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCertReqMutation, UpdateCertReqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCertReqMutation, UpdateCertReqMutationVariables>(UpdateCertReqDocument, options);
      }
export type UpdateCertReqMutationHookResult = ReturnType<typeof useUpdateCertReqMutation>;
export type UpdateCertReqMutationResult = Apollo.MutationResult<UpdateCertReqMutation>;
export type UpdateCertReqMutationOptions = Apollo.BaseMutationOptions<UpdateCertReqMutation, UpdateCertReqMutationVariables>;
export const DeleteCertReqDocument = gql`
    mutation DeleteCertReq($id: ID!) {
  deleteCertificationRequests(where: {id: $id}) {
    nodesDeleted
    relationshipsDeleted
  }
}
    `;
export type DeleteCertReqMutationFn = Apollo.MutationFunction<DeleteCertReqMutation, DeleteCertReqMutationVariables>;

/**
 * __useDeleteCertReqMutation__
 *
 * To run a mutation, you first call `useDeleteCertReqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertReqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertReqMutation, { data, loading, error }] = useDeleteCertReqMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCertReqMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCertReqMutation, DeleteCertReqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCertReqMutation, DeleteCertReqMutationVariables>(DeleteCertReqDocument, options);
      }
export type DeleteCertReqMutationHookResult = ReturnType<typeof useDeleteCertReqMutation>;
export type DeleteCertReqMutationResult = Apollo.MutationResult<DeleteCertReqMutation>;
export type DeleteCertReqMutationOptions = Apollo.BaseMutationOptions<DeleteCertReqMutation, DeleteCertReqMutationVariables>;
export const CreateChannelDocument = gql`
    mutation CreateChannel($input: ChannelCreateInput!) {
  createChannels(input: [$input]) {
    info {
      nodesCreated
      relationshipsCreated
    }
    channels {
      id
      ...ChannelDetail
      ...ChannelRelations
    }
  }
}
    ${ChannelDetailFragmentDoc}
${ChannelRelationsFragmentDoc}`;
export type CreateChannelMutationFn = Apollo.MutationFunction<CreateChannelMutation, CreateChannelMutationVariables>;

/**
 * __useCreateChannelMutation__
 *
 * To run a mutation, you first call `useCreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelMutation, { data, loading, error }] = useCreateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelMutation, CreateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChannelMutation, CreateChannelMutationVariables>(CreateChannelDocument, options);
      }
export type CreateChannelMutationHookResult = ReturnType<typeof useCreateChannelMutation>;
export type CreateChannelMutationResult = Apollo.MutationResult<CreateChannelMutation>;
export type CreateChannelMutationOptions = Apollo.BaseMutationOptions<CreateChannelMutation, CreateChannelMutationVariables>;
export const UpdateChannelDocument = gql`
    mutation UpdateChannel($where: ChannelWhere, $update: ChannelUpdateInput) {
  updateChannels(where: $where, update: $update) {
    info {
      nodesCreated
      relationshipsCreated
    }
    channels {
      id
      ...ChannelDetail
      ...ChannelRelations
    }
  }
}
    ${ChannelDetailFragmentDoc}
${ChannelRelationsFragmentDoc}`;
export type UpdateChannelMutationFn = Apollo.MutationFunction<UpdateChannelMutation, UpdateChannelMutationVariables>;

/**
 * __useUpdateChannelMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMutation, { data, loading, error }] = useUpdateChannelMutation({
 *   variables: {
 *      where: // value for 'where'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMutation, UpdateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelMutation, UpdateChannelMutationVariables>(UpdateChannelDocument, options);
      }
export type UpdateChannelMutationHookResult = ReturnType<typeof useUpdateChannelMutation>;
export type UpdateChannelMutationResult = Apollo.MutationResult<UpdateChannelMutation>;
export type UpdateChannelMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMutation, UpdateChannelMutationVariables>;
export const GetChannelsByCreatorDocument = gql`
    query GetChannelsByCreator($creatorId: ID!) {
  channels(where: {creators_SOME: {id: $creatorId}}) {
    id
    ...ChannelDetail
    ...ChannelRelations
  }
}
    ${ChannelDetailFragmentDoc}
${ChannelRelationsFragmentDoc}`;

/**
 * __useGetChannelsByCreatorQuery__
 *
 * To run a query within a React component, call `useGetChannelsByCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelsByCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelsByCreatorQuery({
 *   variables: {
 *      creatorId: // value for 'creatorId'
 *   },
 * });
 */
export function useGetChannelsByCreatorQuery(baseOptions: Apollo.QueryHookOptions<GetChannelsByCreatorQuery, GetChannelsByCreatorQueryVariables> & ({ variables: GetChannelsByCreatorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelsByCreatorQuery, GetChannelsByCreatorQueryVariables>(GetChannelsByCreatorDocument, options);
      }
export function useGetChannelsByCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelsByCreatorQuery, GetChannelsByCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelsByCreatorQuery, GetChannelsByCreatorQueryVariables>(GetChannelsByCreatorDocument, options);
        }
export function useGetChannelsByCreatorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetChannelsByCreatorQuery, GetChannelsByCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetChannelsByCreatorQuery, GetChannelsByCreatorQueryVariables>(GetChannelsByCreatorDocument, options);
        }
export type GetChannelsByCreatorQueryHookResult = ReturnType<typeof useGetChannelsByCreatorQuery>;
export type GetChannelsByCreatorLazyQueryHookResult = ReturnType<typeof useGetChannelsByCreatorLazyQuery>;
export type GetChannelsByCreatorSuspenseQueryHookResult = ReturnType<typeof useGetChannelsByCreatorSuspenseQuery>;
export type GetChannelsByCreatorQueryResult = Apollo.QueryResult<GetChannelsByCreatorQuery, GetChannelsByCreatorQueryVariables>;
export const GetChannelsByOwnerDocument = gql`
    query GetChannelsByOwner($ownerId: ID!) {
  channels(where: {owners_SOME: {id: $ownerId}}) {
    id
    ...ChannelDetail
    ...ChannelRelations
  }
}
    ${ChannelDetailFragmentDoc}
${ChannelRelationsFragmentDoc}`;

/**
 * __useGetChannelsByOwnerQuery__
 *
 * To run a query within a React component, call `useGetChannelsByOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelsByOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelsByOwnerQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useGetChannelsByOwnerQuery(baseOptions: Apollo.QueryHookOptions<GetChannelsByOwnerQuery, GetChannelsByOwnerQueryVariables> & ({ variables: GetChannelsByOwnerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelsByOwnerQuery, GetChannelsByOwnerQueryVariables>(GetChannelsByOwnerDocument, options);
      }
export function useGetChannelsByOwnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelsByOwnerQuery, GetChannelsByOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelsByOwnerQuery, GetChannelsByOwnerQueryVariables>(GetChannelsByOwnerDocument, options);
        }
export function useGetChannelsByOwnerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetChannelsByOwnerQuery, GetChannelsByOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetChannelsByOwnerQuery, GetChannelsByOwnerQueryVariables>(GetChannelsByOwnerDocument, options);
        }
export type GetChannelsByOwnerQueryHookResult = ReturnType<typeof useGetChannelsByOwnerQuery>;
export type GetChannelsByOwnerLazyQueryHookResult = ReturnType<typeof useGetChannelsByOwnerLazyQuery>;
export type GetChannelsByOwnerSuspenseQueryHookResult = ReturnType<typeof useGetChannelsByOwnerSuspenseQuery>;
export type GetChannelsByOwnerQueryResult = Apollo.QueryResult<GetChannelsByOwnerQuery, GetChannelsByOwnerQueryVariables>;
export const GetChannelByIdDocument = gql`
    query GetChannelById($channelId: ID!) {
  channels(where: {id: $channelId}) {
    id
    ...ChannelDetail
    ...ChannelRelations
    ...ChannelStakeholders
  }
}
    ${ChannelDetailFragmentDoc}
${ChannelRelationsFragmentDoc}
${ChannelStakeholdersFragmentDoc}`;

/**
 * __useGetChannelByIdQuery__
 *
 * To run a query within a React component, call `useGetChannelByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelByIdQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetChannelByIdQuery(baseOptions: Apollo.QueryHookOptions<GetChannelByIdQuery, GetChannelByIdQueryVariables> & ({ variables: GetChannelByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelByIdQuery, GetChannelByIdQueryVariables>(GetChannelByIdDocument, options);
      }
export function useGetChannelByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelByIdQuery, GetChannelByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelByIdQuery, GetChannelByIdQueryVariables>(GetChannelByIdDocument, options);
        }
export function useGetChannelByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetChannelByIdQuery, GetChannelByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetChannelByIdQuery, GetChannelByIdQueryVariables>(GetChannelByIdDocument, options);
        }
export type GetChannelByIdQueryHookResult = ReturnType<typeof useGetChannelByIdQuery>;
export type GetChannelByIdLazyQueryHookResult = ReturnType<typeof useGetChannelByIdLazyQuery>;
export type GetChannelByIdSuspenseQueryHookResult = ReturnType<typeof useGetChannelByIdSuspenseQuery>;
export type GetChannelByIdQueryResult = Apollo.QueryResult<GetChannelByIdQuery, GetChannelByIdQueryVariables>;
export const DeleteChannelDocument = gql`
    mutation DeleteChannel($channelId: ID!) {
  deleteChannels(where: {id: $channelId}) {
    nodesDeleted
    relationshipsDeleted
  }
}
    `;
export type DeleteChannelMutationFn = Apollo.MutationFunction<DeleteChannelMutation, DeleteChannelMutationVariables>;

/**
 * __useDeleteChannelMutation__
 *
 * To run a mutation, you first call `useDeleteChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChannelMutation, { data, loading, error }] = useDeleteChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useDeleteChannelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChannelMutation, DeleteChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChannelMutation, DeleteChannelMutationVariables>(DeleteChannelDocument, options);
      }
export type DeleteChannelMutationHookResult = ReturnType<typeof useDeleteChannelMutation>;
export type DeleteChannelMutationResult = Apollo.MutationResult<DeleteChannelMutation>;
export type DeleteChannelMutationOptions = Apollo.BaseMutationOptions<DeleteChannelMutation, DeleteChannelMutationVariables>;
export const GetCreatorByAccountDocument = gql`
    query GetCreatorByAccount($accountId: ID!) {
  creators(where: {account: {id: $accountId}}, options: {limit: 1}) {
    id
    ...CreatorDetail
    tags {
      id
      title
      blocked
    }
  }
}
    ${CreatorDetailFragmentDoc}`;

/**
 * __useGetCreatorByAccountQuery__
 *
 * To run a query within a React component, call `useGetCreatorByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatorByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatorByAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetCreatorByAccountQuery(baseOptions: Apollo.QueryHookOptions<GetCreatorByAccountQuery, GetCreatorByAccountQueryVariables> & ({ variables: GetCreatorByAccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCreatorByAccountQuery, GetCreatorByAccountQueryVariables>(GetCreatorByAccountDocument, options);
      }
export function useGetCreatorByAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCreatorByAccountQuery, GetCreatorByAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCreatorByAccountQuery, GetCreatorByAccountQueryVariables>(GetCreatorByAccountDocument, options);
        }
export function useGetCreatorByAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCreatorByAccountQuery, GetCreatorByAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCreatorByAccountQuery, GetCreatorByAccountQueryVariables>(GetCreatorByAccountDocument, options);
        }
export type GetCreatorByAccountQueryHookResult = ReturnType<typeof useGetCreatorByAccountQuery>;
export type GetCreatorByAccountLazyQueryHookResult = ReturnType<typeof useGetCreatorByAccountLazyQuery>;
export type GetCreatorByAccountSuspenseQueryHookResult = ReturnType<typeof useGetCreatorByAccountSuspenseQuery>;
export type GetCreatorByAccountQueryResult = Apollo.QueryResult<GetCreatorByAccountQuery, GetCreatorByAccountQueryVariables>;
export const SearchCreatorsByNameDocument = gql`
    query SearchCreatorsByName($firstName: String, $lastName: String!) {
  creators(
    where: {firstNameLC_STARTS_WITH: $firstName, lastNameLC_STARTS_WITH: $lastName}
  ) {
    id
    ...CreatorDetail
    account {
      id
    }
  }
}
    ${CreatorDetailFragmentDoc}`;

/**
 * __useSearchCreatorsByNameQuery__
 *
 * To run a query within a React component, call `useSearchCreatorsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCreatorsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCreatorsByNameQuery({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useSearchCreatorsByNameQuery(baseOptions: Apollo.QueryHookOptions<SearchCreatorsByNameQuery, SearchCreatorsByNameQueryVariables> & ({ variables: SearchCreatorsByNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCreatorsByNameQuery, SearchCreatorsByNameQueryVariables>(SearchCreatorsByNameDocument, options);
      }
export function useSearchCreatorsByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCreatorsByNameQuery, SearchCreatorsByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCreatorsByNameQuery, SearchCreatorsByNameQueryVariables>(SearchCreatorsByNameDocument, options);
        }
export function useSearchCreatorsByNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchCreatorsByNameQuery, SearchCreatorsByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCreatorsByNameQuery, SearchCreatorsByNameQueryVariables>(SearchCreatorsByNameDocument, options);
        }
export type SearchCreatorsByNameQueryHookResult = ReturnType<typeof useSearchCreatorsByNameQuery>;
export type SearchCreatorsByNameLazyQueryHookResult = ReturnType<typeof useSearchCreatorsByNameLazyQuery>;
export type SearchCreatorsByNameSuspenseQueryHookResult = ReturnType<typeof useSearchCreatorsByNameSuspenseQuery>;
export type SearchCreatorsByNameQueryResult = Apollo.QueryResult<SearchCreatorsByNameQuery, SearchCreatorsByNameQueryVariables>;
export const RecentCreatorsDocument = gql`
    query RecentCreators($ids: [ID!]!) {
  creators(where: {id_IN: $ids}) {
    id
    ...CreatorDetail
    account {
      id
    }
  }
}
    ${CreatorDetailFragmentDoc}`;

/**
 * __useRecentCreatorsQuery__
 *
 * To run a query within a React component, call `useRecentCreatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentCreatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentCreatorsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRecentCreatorsQuery(baseOptions: Apollo.QueryHookOptions<RecentCreatorsQuery, RecentCreatorsQueryVariables> & ({ variables: RecentCreatorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentCreatorsQuery, RecentCreatorsQueryVariables>(RecentCreatorsDocument, options);
      }
export function useRecentCreatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentCreatorsQuery, RecentCreatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentCreatorsQuery, RecentCreatorsQueryVariables>(RecentCreatorsDocument, options);
        }
export function useRecentCreatorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RecentCreatorsQuery, RecentCreatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecentCreatorsQuery, RecentCreatorsQueryVariables>(RecentCreatorsDocument, options);
        }
export type RecentCreatorsQueryHookResult = ReturnType<typeof useRecentCreatorsQuery>;
export type RecentCreatorsLazyQueryHookResult = ReturnType<typeof useRecentCreatorsLazyQuery>;
export type RecentCreatorsSuspenseQueryHookResult = ReturnType<typeof useRecentCreatorsSuspenseQuery>;
export type RecentCreatorsQueryResult = Apollo.QueryResult<RecentCreatorsQuery, RecentCreatorsQueryVariables>;
export const AddCreatorDocument = gql`
    mutation AddCreator($input: CreatorCreateInput!) {
  createCreators(input: [$input]) {
    info {
      nodesCreated
      relationshipsCreated
    }
    creators {
      id
      ...CreatorDetail
      account {
        id
      }
      contacts {
        emailAddress
      }
    }
  }
}
    ${CreatorDetailFragmentDoc}`;
export type AddCreatorMutationFn = Apollo.MutationFunction<AddCreatorMutation, AddCreatorMutationVariables>;

/**
 * __useAddCreatorMutation__
 *
 * To run a mutation, you first call `useAddCreatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCreatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCreatorMutation, { data, loading, error }] = useAddCreatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCreatorMutation(baseOptions?: Apollo.MutationHookOptions<AddCreatorMutation, AddCreatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCreatorMutation, AddCreatorMutationVariables>(AddCreatorDocument, options);
      }
export type AddCreatorMutationHookResult = ReturnType<typeof useAddCreatorMutation>;
export type AddCreatorMutationResult = Apollo.MutationResult<AddCreatorMutation>;
export type AddCreatorMutationOptions = Apollo.BaseMutationOptions<AddCreatorMutation, AddCreatorMutationVariables>;
export const UpdateCreatorDocument = gql`
    mutation UpdateCreator($id: ID!, $data: CreatorUpdateInput!) {
  updateCreators(where: {id: $id}, update: $data) {
    info {
      nodesCreated
      relationshipsCreated
      nodesDeleted
      relationshipsDeleted
    }
    creators {
      id
      ...CreatorDetail
      tags {
        id
        title
        blocked
      }
    }
  }
}
    ${CreatorDetailFragmentDoc}`;
export type UpdateCreatorMutationFn = Apollo.MutationFunction<UpdateCreatorMutation, UpdateCreatorMutationVariables>;

/**
 * __useUpdateCreatorMutation__
 *
 * To run a mutation, you first call `useUpdateCreatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreatorMutation, { data, loading, error }] = useUpdateCreatorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCreatorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCreatorMutation, UpdateCreatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCreatorMutation, UpdateCreatorMutationVariables>(UpdateCreatorDocument, options);
      }
export type UpdateCreatorMutationHookResult = ReturnType<typeof useUpdateCreatorMutation>;
export type UpdateCreatorMutationResult = Apollo.MutationResult<UpdateCreatorMutation>;
export type UpdateCreatorMutationOptions = Apollo.BaseMutationOptions<UpdateCreatorMutation, UpdateCreatorMutationVariables>;
export const GetCreatorContactsDocument = gql`
    query GetCreatorContacts($creatorId: ID!) {
  creatorContacts(where: {creator: {id: $creatorId}}) {
    addressLine1
    addressLine2
    city
    stateProvince
    postalCode
    country
    emailAddress
    phoneNumber
  }
}
    `;

/**
 * __useGetCreatorContactsQuery__
 *
 * To run a query within a React component, call `useGetCreatorContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatorContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatorContactsQuery({
 *   variables: {
 *      creatorId: // value for 'creatorId'
 *   },
 * });
 */
export function useGetCreatorContactsQuery(baseOptions: Apollo.QueryHookOptions<GetCreatorContactsQuery, GetCreatorContactsQueryVariables> & ({ variables: GetCreatorContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCreatorContactsQuery, GetCreatorContactsQueryVariables>(GetCreatorContactsDocument, options);
      }
export function useGetCreatorContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCreatorContactsQuery, GetCreatorContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCreatorContactsQuery, GetCreatorContactsQueryVariables>(GetCreatorContactsDocument, options);
        }
export function useGetCreatorContactsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCreatorContactsQuery, GetCreatorContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCreatorContactsQuery, GetCreatorContactsQueryVariables>(GetCreatorContactsDocument, options);
        }
export type GetCreatorContactsQueryHookResult = ReturnType<typeof useGetCreatorContactsQuery>;
export type GetCreatorContactsLazyQueryHookResult = ReturnType<typeof useGetCreatorContactsLazyQuery>;
export type GetCreatorContactsSuspenseQueryHookResult = ReturnType<typeof useGetCreatorContactsSuspenseQuery>;
export type GetCreatorContactsQueryResult = Apollo.QueryResult<GetCreatorContactsQuery, GetCreatorContactsQueryVariables>;
export const CreateCreatorContactsDocument = gql`
    mutation CreateCreatorContacts($input: [CreatorContactCreateInput!]!) {
  createCreatorContacts(input: $input) {
    info {
      nodesCreated
      relationshipsCreated
    }
  }
}
    `;
export type CreateCreatorContactsMutationFn = Apollo.MutationFunction<CreateCreatorContactsMutation, CreateCreatorContactsMutationVariables>;

/**
 * __useCreateCreatorContactsMutation__
 *
 * To run a mutation, you first call `useCreateCreatorContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreatorContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreatorContactsMutation, { data, loading, error }] = useCreateCreatorContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreatorContactsMutation(baseOptions?: Apollo.MutationHookOptions<CreateCreatorContactsMutation, CreateCreatorContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCreatorContactsMutation, CreateCreatorContactsMutationVariables>(CreateCreatorContactsDocument, options);
      }
export type CreateCreatorContactsMutationHookResult = ReturnType<typeof useCreateCreatorContactsMutation>;
export type CreateCreatorContactsMutationResult = Apollo.MutationResult<CreateCreatorContactsMutation>;
export type CreateCreatorContactsMutationOptions = Apollo.BaseMutationOptions<CreateCreatorContactsMutation, CreateCreatorContactsMutationVariables>;
export const DeleteCreatorContactsDocument = gql`
    mutation DeleteCreatorContacts($creatorId: ID!) {
  deleteCreatorContacts(where: {creator: {id: $creatorId}}) {
    nodesDeleted
    relationshipsDeleted
  }
}
    `;
export type DeleteCreatorContactsMutationFn = Apollo.MutationFunction<DeleteCreatorContactsMutation, DeleteCreatorContactsMutationVariables>;

/**
 * __useDeleteCreatorContactsMutation__
 *
 * To run a mutation, you first call `useDeleteCreatorContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreatorContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreatorContactsMutation, { data, loading, error }] = useDeleteCreatorContactsMutation({
 *   variables: {
 *      creatorId: // value for 'creatorId'
 *   },
 * });
 */
export function useDeleteCreatorContactsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCreatorContactsMutation, DeleteCreatorContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCreatorContactsMutation, DeleteCreatorContactsMutationVariables>(DeleteCreatorContactsDocument, options);
      }
export type DeleteCreatorContactsMutationHookResult = ReturnType<typeof useDeleteCreatorContactsMutation>;
export type DeleteCreatorContactsMutationResult = Apollo.MutationResult<DeleteCreatorContactsMutation>;
export type DeleteCreatorContactsMutationOptions = Apollo.BaseMutationOptions<DeleteCreatorContactsMutation, DeleteCreatorContactsMutationVariables>;
export const GetUserMediaByIdDocument = gql`
    query GetUserMediaById($id: ID!) {
  userMedias(where: {id: $id}) {
    id
    ...MediaDetail
  }
}
    ${MediaDetailFragmentDoc}`;

/**
 * __useGetUserMediaByIdQuery__
 *
 * To run a query within a React component, call `useGetUserMediaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMediaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMediaByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserMediaByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserMediaByIdQuery, GetUserMediaByIdQueryVariables> & ({ variables: GetUserMediaByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserMediaByIdQuery, GetUserMediaByIdQueryVariables>(GetUserMediaByIdDocument, options);
      }
export function useGetUserMediaByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserMediaByIdQuery, GetUserMediaByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserMediaByIdQuery, GetUserMediaByIdQueryVariables>(GetUserMediaByIdDocument, options);
        }
export function useGetUserMediaByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserMediaByIdQuery, GetUserMediaByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserMediaByIdQuery, GetUserMediaByIdQueryVariables>(GetUserMediaByIdDocument, options);
        }
export type GetUserMediaByIdQueryHookResult = ReturnType<typeof useGetUserMediaByIdQuery>;
export type GetUserMediaByIdLazyQueryHookResult = ReturnType<typeof useGetUserMediaByIdLazyQuery>;
export type GetUserMediaByIdSuspenseQueryHookResult = ReturnType<typeof useGetUserMediaByIdSuspenseQuery>;
export type GetUserMediaByIdQueryResult = Apollo.QueryResult<GetUserMediaByIdQuery, GetUserMediaByIdQueryVariables>;
export const ParsePageInfoDocument = gql`
    mutation ParsePageInfo($url: String!) {
  parsePageFromUrl(url: $url) {
    title
    description
    keywords
    media {
      id
      extension
      source
      contentType
      width
      height
    }
  }
}
    `;
export type ParsePageInfoMutationFn = Apollo.MutationFunction<ParsePageInfoMutation, ParsePageInfoMutationVariables>;

/**
 * __useParsePageInfoMutation__
 *
 * To run a mutation, you first call `useParsePageInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParsePageInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parsePageInfoMutation, { data, loading, error }] = useParsePageInfoMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useParsePageInfoMutation(baseOptions?: Apollo.MutationHookOptions<ParsePageInfoMutation, ParsePageInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ParsePageInfoMutation, ParsePageInfoMutationVariables>(ParsePageInfoDocument, options);
      }
export type ParsePageInfoMutationHookResult = ReturnType<typeof useParsePageInfoMutation>;
export type ParsePageInfoMutationResult = Apollo.MutationResult<ParsePageInfoMutation>;
export type ParsePageInfoMutationOptions = Apollo.BaseMutationOptions<ParsePageInfoMutation, ParsePageInfoMutationVariables>;
export const DeleteUserMediaDocument = gql`
    mutation DeleteUserMedia($ids: [ID!]!) {
  deleteUserMedias(where: {id_IN: $ids}) {
    nodesDeleted
    relationshipsDeleted
  }
}
    `;
export type DeleteUserMediaMutationFn = Apollo.MutationFunction<DeleteUserMediaMutation, DeleteUserMediaMutationVariables>;

/**
 * __useDeleteUserMediaMutation__
 *
 * To run a mutation, you first call `useDeleteUserMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMediaMutation, { data, loading, error }] = useDeleteUserMediaMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteUserMediaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMediaMutation, DeleteUserMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMediaMutation, DeleteUserMediaMutationVariables>(DeleteUserMediaDocument, options);
      }
export type DeleteUserMediaMutationHookResult = ReturnType<typeof useDeleteUserMediaMutation>;
export type DeleteUserMediaMutationResult = Apollo.MutationResult<DeleteUserMediaMutation>;
export type DeleteUserMediaMutationOptions = Apollo.BaseMutationOptions<DeleteUserMediaMutation, DeleteUserMediaMutationVariables>;
export const UpdateUserMediaDocument = gql`
    mutation UpdateUserMedia($id: ID!, $update: UserMediaUpdateInput!) {
  updateUserMedias(where: {id: $id}, update: $update) {
    userMedias {
      id
      ...MediaDetail
    }
  }
}
    ${MediaDetailFragmentDoc}`;
export type UpdateUserMediaMutationFn = Apollo.MutationFunction<UpdateUserMediaMutation, UpdateUserMediaMutationVariables>;

/**
 * __useUpdateUserMediaMutation__
 *
 * To run a mutation, you first call `useUpdateUserMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMediaMutation, { data, loading, error }] = useUpdateUserMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateUserMediaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMediaMutation, UpdateUserMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMediaMutation, UpdateUserMediaMutationVariables>(UpdateUserMediaDocument, options);
      }
export type UpdateUserMediaMutationHookResult = ReturnType<typeof useUpdateUserMediaMutation>;
export type UpdateUserMediaMutationResult = Apollo.MutationResult<UpdateUserMediaMutation>;
export type UpdateUserMediaMutationOptions = Apollo.BaseMutationOptions<UpdateUserMediaMutation, UpdateUserMediaMutationVariables>;
export const UploadMediaDocument = gql`
    mutation UploadMedia($upload: Upload!, $length: Int) {
  uploadMedia(upload: $upload, contentLength: $length) {
    status
    error
    media {
      id
      extension
      caption
      source
      createdAt
    }
  }
}
    `;
export type UploadMediaMutationFn = Apollo.MutationFunction<UploadMediaMutation, UploadMediaMutationVariables>;

/**
 * __useUploadMediaMutation__
 *
 * To run a mutation, you first call `useUploadMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMediaMutation, { data, loading, error }] = useUploadMediaMutation({
 *   variables: {
 *      upload: // value for 'upload'
 *      length: // value for 'length'
 *   },
 * });
 */
export function useUploadMediaMutation(baseOptions?: Apollo.MutationHookOptions<UploadMediaMutation, UploadMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadMediaMutation, UploadMediaMutationVariables>(UploadMediaDocument, options);
      }
export type UploadMediaMutationHookResult = ReturnType<typeof useUploadMediaMutation>;
export type UploadMediaMutationResult = Apollo.MutationResult<UploadMediaMutation>;
export type UploadMediaMutationOptions = Apollo.BaseMutationOptions<UploadMediaMutation, UploadMediaMutationVariables>;
export const GetOwnerByAccountDocument = gql`
    query GetOwnerByAccount($accountId: ID!) {
  owners(where: {account: {id: $accountId}}, options: {limit: 1}) {
    id
    ...OwnerDetail
    tags {
      id
      title
      blocked
    }
  }
}
    ${OwnerDetailFragmentDoc}`;

/**
 * __useGetOwnerByAccountQuery__
 *
 * To run a query within a React component, call `useGetOwnerByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerByAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetOwnerByAccountQuery(baseOptions: Apollo.QueryHookOptions<GetOwnerByAccountQuery, GetOwnerByAccountQueryVariables> & ({ variables: GetOwnerByAccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnerByAccountQuery, GetOwnerByAccountQueryVariables>(GetOwnerByAccountDocument, options);
      }
export function useGetOwnerByAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerByAccountQuery, GetOwnerByAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnerByAccountQuery, GetOwnerByAccountQueryVariables>(GetOwnerByAccountDocument, options);
        }
export function useGetOwnerByAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOwnerByAccountQuery, GetOwnerByAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOwnerByAccountQuery, GetOwnerByAccountQueryVariables>(GetOwnerByAccountDocument, options);
        }
export type GetOwnerByAccountQueryHookResult = ReturnType<typeof useGetOwnerByAccountQuery>;
export type GetOwnerByAccountLazyQueryHookResult = ReturnType<typeof useGetOwnerByAccountLazyQuery>;
export type GetOwnerByAccountSuspenseQueryHookResult = ReturnType<typeof useGetOwnerByAccountSuspenseQuery>;
export type GetOwnerByAccountQueryResult = Apollo.QueryResult<GetOwnerByAccountQuery, GetOwnerByAccountQueryVariables>;
export const SearchOwnersByNameDocument = gql`
    query SearchOwnersByName($name: String!) {
  owners(where: {nameLC_STARTS_WITH: $name}) {
    id
    ...OwnerDetail
    account {
      id
    }
  }
}
    ${OwnerDetailFragmentDoc}`;

/**
 * __useSearchOwnersByNameQuery__
 *
 * To run a query within a React component, call `useSearchOwnersByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOwnersByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOwnersByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSearchOwnersByNameQuery(baseOptions: Apollo.QueryHookOptions<SearchOwnersByNameQuery, SearchOwnersByNameQueryVariables> & ({ variables: SearchOwnersByNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchOwnersByNameQuery, SearchOwnersByNameQueryVariables>(SearchOwnersByNameDocument, options);
      }
export function useSearchOwnersByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchOwnersByNameQuery, SearchOwnersByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchOwnersByNameQuery, SearchOwnersByNameQueryVariables>(SearchOwnersByNameDocument, options);
        }
export function useSearchOwnersByNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchOwnersByNameQuery, SearchOwnersByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchOwnersByNameQuery, SearchOwnersByNameQueryVariables>(SearchOwnersByNameDocument, options);
        }
export type SearchOwnersByNameQueryHookResult = ReturnType<typeof useSearchOwnersByNameQuery>;
export type SearchOwnersByNameLazyQueryHookResult = ReturnType<typeof useSearchOwnersByNameLazyQuery>;
export type SearchOwnersByNameSuspenseQueryHookResult = ReturnType<typeof useSearchOwnersByNameSuspenseQuery>;
export type SearchOwnersByNameQueryResult = Apollo.QueryResult<SearchOwnersByNameQuery, SearchOwnersByNameQueryVariables>;
export const RecentOwnersDocument = gql`
    query RecentOwners($ids: [ID!]!) {
  owners(where: {id_IN: $ids}) {
    id
    ...OwnerDetail
    account {
      id
    }
  }
}
    ${OwnerDetailFragmentDoc}`;

/**
 * __useRecentOwnersQuery__
 *
 * To run a query within a React component, call `useRecentOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentOwnersQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRecentOwnersQuery(baseOptions: Apollo.QueryHookOptions<RecentOwnersQuery, RecentOwnersQueryVariables> & ({ variables: RecentOwnersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentOwnersQuery, RecentOwnersQueryVariables>(RecentOwnersDocument, options);
      }
export function useRecentOwnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentOwnersQuery, RecentOwnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentOwnersQuery, RecentOwnersQueryVariables>(RecentOwnersDocument, options);
        }
export function useRecentOwnersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RecentOwnersQuery, RecentOwnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecentOwnersQuery, RecentOwnersQueryVariables>(RecentOwnersDocument, options);
        }
export type RecentOwnersQueryHookResult = ReturnType<typeof useRecentOwnersQuery>;
export type RecentOwnersLazyQueryHookResult = ReturnType<typeof useRecentOwnersLazyQuery>;
export type RecentOwnersSuspenseQueryHookResult = ReturnType<typeof useRecentOwnersSuspenseQuery>;
export type RecentOwnersQueryResult = Apollo.QueryResult<RecentOwnersQuery, RecentOwnersQueryVariables>;
export const AddOwnerDocument = gql`
    mutation AddOwner($input: OwnerCreateInput!) {
  createOwners(input: [$input]) {
    info {
      nodesCreated
      relationshipsCreated
    }
    owners {
      id
      name
      account {
        id
      }
      contacts {
        emailAddress
      }
    }
  }
}
    `;
export type AddOwnerMutationFn = Apollo.MutationFunction<AddOwnerMutation, AddOwnerMutationVariables>;

/**
 * __useAddOwnerMutation__
 *
 * To run a mutation, you first call `useAddOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOwnerMutation, { data, loading, error }] = useAddOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOwnerMutation(baseOptions?: Apollo.MutationHookOptions<AddOwnerMutation, AddOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOwnerMutation, AddOwnerMutationVariables>(AddOwnerDocument, options);
      }
export type AddOwnerMutationHookResult = ReturnType<typeof useAddOwnerMutation>;
export type AddOwnerMutationResult = Apollo.MutationResult<AddOwnerMutation>;
export type AddOwnerMutationOptions = Apollo.BaseMutationOptions<AddOwnerMutation, AddOwnerMutationVariables>;
export const UpdateOwnerDocument = gql`
    mutation UpdateOwner($id: ID!, $data: OwnerUpdateInput!) {
  updateOwners(where: {id: $id}, update: $data) {
    info {
      nodesCreated
      relationshipsCreated
      nodesDeleted
      relationshipsDeleted
    }
    owners {
      id
      ...OwnerDetail
      tags {
        id
        title
        blocked
      }
    }
  }
}
    ${OwnerDetailFragmentDoc}`;
export type UpdateOwnerMutationFn = Apollo.MutationFunction<UpdateOwnerMutation, UpdateOwnerMutationVariables>;

/**
 * __useUpdateOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerMutation, { data, loading, error }] = useUpdateOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOwnerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOwnerMutation, UpdateOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOwnerMutation, UpdateOwnerMutationVariables>(UpdateOwnerDocument, options);
      }
export type UpdateOwnerMutationHookResult = ReturnType<typeof useUpdateOwnerMutation>;
export type UpdateOwnerMutationResult = Apollo.MutationResult<UpdateOwnerMutation>;
export type UpdateOwnerMutationOptions = Apollo.BaseMutationOptions<UpdateOwnerMutation, UpdateOwnerMutationVariables>;
export const GetOwnerContactsDocument = gql`
    query GetOwnerContacts($ownerId: ID!) {
  ownerContacts(where: {owner: {id: $ownerId}}) {
    addressLine1
    addressLine2
    city
    stateProvince
    postalCode
    country
    emailAddress
    phoneNumber
  }
}
    `;

/**
 * __useGetOwnerContactsQuery__
 *
 * To run a query within a React component, call `useGetOwnerContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerContactsQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useGetOwnerContactsQuery(baseOptions: Apollo.QueryHookOptions<GetOwnerContactsQuery, GetOwnerContactsQueryVariables> & ({ variables: GetOwnerContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnerContactsQuery, GetOwnerContactsQueryVariables>(GetOwnerContactsDocument, options);
      }
export function useGetOwnerContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerContactsQuery, GetOwnerContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnerContactsQuery, GetOwnerContactsQueryVariables>(GetOwnerContactsDocument, options);
        }
export function useGetOwnerContactsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOwnerContactsQuery, GetOwnerContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOwnerContactsQuery, GetOwnerContactsQueryVariables>(GetOwnerContactsDocument, options);
        }
export type GetOwnerContactsQueryHookResult = ReturnType<typeof useGetOwnerContactsQuery>;
export type GetOwnerContactsLazyQueryHookResult = ReturnType<typeof useGetOwnerContactsLazyQuery>;
export type GetOwnerContactsSuspenseQueryHookResult = ReturnType<typeof useGetOwnerContactsSuspenseQuery>;
export type GetOwnerContactsQueryResult = Apollo.QueryResult<GetOwnerContactsQuery, GetOwnerContactsQueryVariables>;
export const CreateOwnerContactsDocument = gql`
    mutation CreateOwnerContacts($input: [OwnerContactCreateInput!]!) {
  createOwnerContacts(input: $input) {
    info {
      nodesCreated
      relationshipsCreated
    }
  }
}
    `;
export type CreateOwnerContactsMutationFn = Apollo.MutationFunction<CreateOwnerContactsMutation, CreateOwnerContactsMutationVariables>;

/**
 * __useCreateOwnerContactsMutation__
 *
 * To run a mutation, you first call `useCreateOwnerContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOwnerContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOwnerContactsMutation, { data, loading, error }] = useCreateOwnerContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOwnerContactsMutation(baseOptions?: Apollo.MutationHookOptions<CreateOwnerContactsMutation, CreateOwnerContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOwnerContactsMutation, CreateOwnerContactsMutationVariables>(CreateOwnerContactsDocument, options);
      }
export type CreateOwnerContactsMutationHookResult = ReturnType<typeof useCreateOwnerContactsMutation>;
export type CreateOwnerContactsMutationResult = Apollo.MutationResult<CreateOwnerContactsMutation>;
export type CreateOwnerContactsMutationOptions = Apollo.BaseMutationOptions<CreateOwnerContactsMutation, CreateOwnerContactsMutationVariables>;
export const DeleteOwnerContactsDocument = gql`
    mutation DeleteOwnerContacts($ownerId: ID!) {
  deleteOwnerContacts(where: {owner: {id: $ownerId}}) {
    nodesDeleted
    relationshipsDeleted
  }
}
    `;
export type DeleteOwnerContactsMutationFn = Apollo.MutationFunction<DeleteOwnerContactsMutation, DeleteOwnerContactsMutationVariables>;

/**
 * __useDeleteOwnerContactsMutation__
 *
 * To run a mutation, you first call `useDeleteOwnerContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnerContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnerContactsMutation, { data, loading, error }] = useDeleteOwnerContactsMutation({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useDeleteOwnerContactsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOwnerContactsMutation, DeleteOwnerContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOwnerContactsMutation, DeleteOwnerContactsMutationVariables>(DeleteOwnerContactsDocument, options);
      }
export type DeleteOwnerContactsMutationHookResult = ReturnType<typeof useDeleteOwnerContactsMutation>;
export type DeleteOwnerContactsMutationResult = Apollo.MutationResult<DeleteOwnerContactsMutation>;
export type DeleteOwnerContactsMutationOptions = Apollo.BaseMutationOptions<DeleteOwnerContactsMutation, DeleteOwnerContactsMutationVariables>;
export const SystemStatusDocument = gql`
    query SystemStatus {
  systemStatus {
    apolloGraphql {
      buildTime
      commitHash
    }
  }
}
    `;

/**
 * __useSystemStatusQuery__
 *
 * To run a query within a React component, call `useSystemStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemStatusQuery(baseOptions?: Apollo.QueryHookOptions<SystemStatusQuery, SystemStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemStatusQuery, SystemStatusQueryVariables>(SystemStatusDocument, options);
      }
export function useSystemStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemStatusQuery, SystemStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemStatusQuery, SystemStatusQueryVariables>(SystemStatusDocument, options);
        }
export function useSystemStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SystemStatusQuery, SystemStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SystemStatusQuery, SystemStatusQueryVariables>(SystemStatusDocument, options);
        }
export type SystemStatusQueryHookResult = ReturnType<typeof useSystemStatusQuery>;
export type SystemStatusLazyQueryHookResult = ReturnType<typeof useSystemStatusLazyQuery>;
export type SystemStatusSuspenseQueryHookResult = ReturnType<typeof useSystemStatusSuspenseQuery>;
export type SystemStatusQueryResult = Apollo.QueryResult<SystemStatusQuery, SystemStatusQueryVariables>;
export const GetTagsByTitlesDocument = gql`
    query GetTagsByTitles($titles: [String!]!) {
  tags(where: {title_IN: $titles}) {
    id
    title
    blocked
  }
}
    `;

/**
 * __useGetTagsByTitlesQuery__
 *
 * To run a query within a React component, call `useGetTagsByTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsByTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsByTitlesQuery({
 *   variables: {
 *      titles: // value for 'titles'
 *   },
 * });
 */
export function useGetTagsByTitlesQuery(baseOptions: Apollo.QueryHookOptions<GetTagsByTitlesQuery, GetTagsByTitlesQueryVariables> & ({ variables: GetTagsByTitlesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsByTitlesQuery, GetTagsByTitlesQueryVariables>(GetTagsByTitlesDocument, options);
      }
export function useGetTagsByTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsByTitlesQuery, GetTagsByTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsByTitlesQuery, GetTagsByTitlesQueryVariables>(GetTagsByTitlesDocument, options);
        }
export function useGetTagsByTitlesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTagsByTitlesQuery, GetTagsByTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTagsByTitlesQuery, GetTagsByTitlesQueryVariables>(GetTagsByTitlesDocument, options);
        }
export type GetTagsByTitlesQueryHookResult = ReturnType<typeof useGetTagsByTitlesQuery>;
export type GetTagsByTitlesLazyQueryHookResult = ReturnType<typeof useGetTagsByTitlesLazyQuery>;
export type GetTagsByTitlesSuspenseQueryHookResult = ReturnType<typeof useGetTagsByTitlesSuspenseQuery>;
export type GetTagsByTitlesQueryResult = Apollo.QueryResult<GetTagsByTitlesQuery, GetTagsByTitlesQueryVariables>;
export const CreateTagsDocument = gql`
    mutation CreateTags($input: [TagCreateInput!]!) {
  createTags(input: $input) {
    info {
      nodesCreated
    }
    tags {
      id
      title
    }
  }
}
    `;
export type CreateTagsMutationFn = Apollo.MutationFunction<CreateTagsMutation, CreateTagsMutationVariables>;

/**
 * __useCreateTagsMutation__
 *
 * To run a mutation, you first call `useCreateTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagsMutation, { data, loading, error }] = useCreateTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagsMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagsMutation, CreateTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagsMutation, CreateTagsMutationVariables>(CreateTagsDocument, options);
      }
export type CreateTagsMutationHookResult = ReturnType<typeof useCreateTagsMutation>;
export type CreateTagsMutationResult = Apollo.MutationResult<CreateTagsMutation>;
export type CreateTagsMutationOptions = Apollo.BaseMutationOptions<CreateTagsMutation, CreateTagsMutationVariables>;
export const CreateTagRequestDocument = gql`
    mutation CreateTagRequest($userId: ID!, $title: String!, $description: String!) {
  createCategoryTagRequests(
    input: [{title: $title, description: $description, requestedBy: {connect: {where: {node: {id: $userId}}}}}]
  ) {
    info {
      nodesCreated
      relationshipsCreated
    }
    categoryTagRequests {
      id
    }
  }
}
    `;
export type CreateTagRequestMutationFn = Apollo.MutationFunction<CreateTagRequestMutation, CreateTagRequestMutationVariables>;

/**
 * __useCreateTagRequestMutation__
 *
 * To run a mutation, you first call `useCreateTagRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagRequestMutation, { data, loading, error }] = useCreateTagRequestMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateTagRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagRequestMutation, CreateTagRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagRequestMutation, CreateTagRequestMutationVariables>(CreateTagRequestDocument, options);
      }
export type CreateTagRequestMutationHookResult = ReturnType<typeof useCreateTagRequestMutation>;
export type CreateTagRequestMutationResult = Apollo.MutationResult<CreateTagRequestMutation>;
export type CreateTagRequestMutationOptions = Apollo.BaseMutationOptions<CreateTagRequestMutation, CreateTagRequestMutationVariables>;
export const GetAllTypeTagsDocument = gql`
    query GetAllTypeTags {
  typeTags {
    id
    description
    tier
    title
    tags(options: {sort: {title: ASC}}) {
      id
      description
      tier
      title
      tags {
        id
        description
        tier
        title
      }
    }
  }
}
    `;

/**
 * __useGetAllTypeTagsQuery__
 *
 * To run a query within a React component, call `useGetAllTypeTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTypeTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTypeTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTypeTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTypeTagsQuery, GetAllTypeTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTypeTagsQuery, GetAllTypeTagsQueryVariables>(GetAllTypeTagsDocument, options);
      }
export function useGetAllTypeTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTypeTagsQuery, GetAllTypeTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTypeTagsQuery, GetAllTypeTagsQueryVariables>(GetAllTypeTagsDocument, options);
        }
export function useGetAllTypeTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllTypeTagsQuery, GetAllTypeTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllTypeTagsQuery, GetAllTypeTagsQueryVariables>(GetAllTypeTagsDocument, options);
        }
export type GetAllTypeTagsQueryHookResult = ReturnType<typeof useGetAllTypeTagsQuery>;
export type GetAllTypeTagsLazyQueryHookResult = ReturnType<typeof useGetAllTypeTagsLazyQuery>;
export type GetAllTypeTagsSuspenseQueryHookResult = ReturnType<typeof useGetAllTypeTagsSuspenseQuery>;
export type GetAllTypeTagsQueryResult = Apollo.QueryResult<GetAllTypeTagsQuery, GetAllTypeTagsQueryVariables>;
export const TypeTagsDocument = gql`
    query TypeTags {
  typeTags(options: {sort: {title: ASC}}) {
    id
    tier
    title
    parentTag {
      id
    }
    tagsAggregate {
      count
    }
  }
}
    `;

/**
 * __useTypeTagsQuery__
 *
 * To run a query within a React component, call `useTypeTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTypeTagsQuery(baseOptions?: Apollo.QueryHookOptions<TypeTagsQuery, TypeTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypeTagsQuery, TypeTagsQueryVariables>(TypeTagsDocument, options);
      }
export function useTypeTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypeTagsQuery, TypeTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypeTagsQuery, TypeTagsQueryVariables>(TypeTagsDocument, options);
        }
export function useTypeTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TypeTagsQuery, TypeTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TypeTagsQuery, TypeTagsQueryVariables>(TypeTagsDocument, options);
        }
export type TypeTagsQueryHookResult = ReturnType<typeof useTypeTagsQuery>;
export type TypeTagsLazyQueryHookResult = ReturnType<typeof useTypeTagsLazyQuery>;
export type TypeTagsSuspenseQueryHookResult = ReturnType<typeof useTypeTagsSuspenseQuery>;
export type TypeTagsQueryResult = Apollo.QueryResult<TypeTagsQuery, TypeTagsQueryVariables>;
export const CreateWorkDocument = gql`
    mutation CreateWork($input: WorkCreateInput!) {
  createWorks(input: [$input]) {
    info {
      nodesCreated
      relationshipsCreated
    }
    works {
      id
      ...WorkDetail
      ...WorkRelations
    }
  }
}
    ${WorkDetailFragmentDoc}
${WorkRelationsFragmentDoc}`;
export type CreateWorkMutationFn = Apollo.MutationFunction<CreateWorkMutation, CreateWorkMutationVariables>;

/**
 * __useCreateWorkMutation__
 *
 * To run a mutation, you first call `useCreateWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkMutation, { data, loading, error }] = useCreateWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkMutation, CreateWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkMutation, CreateWorkMutationVariables>(CreateWorkDocument, options);
      }
export type CreateWorkMutationHookResult = ReturnType<typeof useCreateWorkMutation>;
export type CreateWorkMutationResult = Apollo.MutationResult<CreateWorkMutation>;
export type CreateWorkMutationOptions = Apollo.BaseMutationOptions<CreateWorkMutation, CreateWorkMutationVariables>;
export const UpdateWorkDocument = gql`
    mutation UpdateWork($where: WorkWhere, $update: WorkUpdateInput) {
  updateWorks(where: $where, update: $update) {
    info {
      nodesCreated
      relationshipsCreated
    }
    works {
      id
      ...WorkDetail
      ...WorkRelations
    }
  }
}
    ${WorkDetailFragmentDoc}
${WorkRelationsFragmentDoc}`;
export type UpdateWorkMutationFn = Apollo.MutationFunction<UpdateWorkMutation, UpdateWorkMutationVariables>;

/**
 * __useUpdateWorkMutation__
 *
 * To run a mutation, you first call `useUpdateWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkMutation, { data, loading, error }] = useUpdateWorkMutation({
 *   variables: {
 *      where: // value for 'where'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateWorkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkMutation, UpdateWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkMutation, UpdateWorkMutationVariables>(UpdateWorkDocument, options);
      }
export type UpdateWorkMutationHookResult = ReturnType<typeof useUpdateWorkMutation>;
export type UpdateWorkMutationResult = Apollo.MutationResult<UpdateWorkMutation>;
export type UpdateWorkMutationOptions = Apollo.BaseMutationOptions<UpdateWorkMutation, UpdateWorkMutationVariables>;
export const GetWorksByCreatorDocument = gql`
    query GetWorksByCreator($creatorId: ID!) {
  works(where: {creators_SOME: {id: $creatorId}}) {
    id
    ...WorkDetail
    ...WorkRelations
  }
}
    ${WorkDetailFragmentDoc}
${WorkRelationsFragmentDoc}`;

/**
 * __useGetWorksByCreatorQuery__
 *
 * To run a query within a React component, call `useGetWorksByCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorksByCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorksByCreatorQuery({
 *   variables: {
 *      creatorId: // value for 'creatorId'
 *   },
 * });
 */
export function useGetWorksByCreatorQuery(baseOptions: Apollo.QueryHookOptions<GetWorksByCreatorQuery, GetWorksByCreatorQueryVariables> & ({ variables: GetWorksByCreatorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorksByCreatorQuery, GetWorksByCreatorQueryVariables>(GetWorksByCreatorDocument, options);
      }
export function useGetWorksByCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorksByCreatorQuery, GetWorksByCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorksByCreatorQuery, GetWorksByCreatorQueryVariables>(GetWorksByCreatorDocument, options);
        }
export function useGetWorksByCreatorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorksByCreatorQuery, GetWorksByCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWorksByCreatorQuery, GetWorksByCreatorQueryVariables>(GetWorksByCreatorDocument, options);
        }
export type GetWorksByCreatorQueryHookResult = ReturnType<typeof useGetWorksByCreatorQuery>;
export type GetWorksByCreatorLazyQueryHookResult = ReturnType<typeof useGetWorksByCreatorLazyQuery>;
export type GetWorksByCreatorSuspenseQueryHookResult = ReturnType<typeof useGetWorksByCreatorSuspenseQuery>;
export type GetWorksByCreatorQueryResult = Apollo.QueryResult<GetWorksByCreatorQuery, GetWorksByCreatorQueryVariables>;
export const GetWorksByOwnerDocument = gql`
    query GetWorksByOwner($ownerId: ID!) {
  works(where: {owners_SOME: {id: $ownerId}}) {
    id
    ...WorkDetail
    ...WorkRelations
  }
}
    ${WorkDetailFragmentDoc}
${WorkRelationsFragmentDoc}`;

/**
 * __useGetWorksByOwnerQuery__
 *
 * To run a query within a React component, call `useGetWorksByOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorksByOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorksByOwnerQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useGetWorksByOwnerQuery(baseOptions: Apollo.QueryHookOptions<GetWorksByOwnerQuery, GetWorksByOwnerQueryVariables> & ({ variables: GetWorksByOwnerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorksByOwnerQuery, GetWorksByOwnerQueryVariables>(GetWorksByOwnerDocument, options);
      }
export function useGetWorksByOwnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorksByOwnerQuery, GetWorksByOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorksByOwnerQuery, GetWorksByOwnerQueryVariables>(GetWorksByOwnerDocument, options);
        }
export function useGetWorksByOwnerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorksByOwnerQuery, GetWorksByOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWorksByOwnerQuery, GetWorksByOwnerQueryVariables>(GetWorksByOwnerDocument, options);
        }
export type GetWorksByOwnerQueryHookResult = ReturnType<typeof useGetWorksByOwnerQuery>;
export type GetWorksByOwnerLazyQueryHookResult = ReturnType<typeof useGetWorksByOwnerLazyQuery>;
export type GetWorksByOwnerSuspenseQueryHookResult = ReturnType<typeof useGetWorksByOwnerSuspenseQuery>;
export type GetWorksByOwnerQueryResult = Apollo.QueryResult<GetWorksByOwnerQuery, GetWorksByOwnerQueryVariables>;
export const GetWorkByIdDocument = gql`
    query GetWorkById($workId: ID!) {
  works(where: {id: $workId}) {
    id
    ...WorkDetail
    ...WorkRelations
    ...WorkStakeholders
  }
}
    ${WorkDetailFragmentDoc}
${WorkRelationsFragmentDoc}
${WorkStakeholdersFragmentDoc}`;

/**
 * __useGetWorkByIdQuery__
 *
 * To run a query within a React component, call `useGetWorkByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkByIdQuery({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useGetWorkByIdQuery(baseOptions: Apollo.QueryHookOptions<GetWorkByIdQuery, GetWorkByIdQueryVariables> & ({ variables: GetWorkByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkByIdQuery, GetWorkByIdQueryVariables>(GetWorkByIdDocument, options);
      }
export function useGetWorkByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkByIdQuery, GetWorkByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkByIdQuery, GetWorkByIdQueryVariables>(GetWorkByIdDocument, options);
        }
export function useGetWorkByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkByIdQuery, GetWorkByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWorkByIdQuery, GetWorkByIdQueryVariables>(GetWorkByIdDocument, options);
        }
export type GetWorkByIdQueryHookResult = ReturnType<typeof useGetWorkByIdQuery>;
export type GetWorkByIdLazyQueryHookResult = ReturnType<typeof useGetWorkByIdLazyQuery>;
export type GetWorkByIdSuspenseQueryHookResult = ReturnType<typeof useGetWorkByIdSuspenseQuery>;
export type GetWorkByIdQueryResult = Apollo.QueryResult<GetWorkByIdQuery, GetWorkByIdQueryVariables>;
export const DeleteWorkDocument = gql`
    mutation DeleteWork($workId: ID!) {
  deleteWorks(where: {id: $workId}) {
    nodesDeleted
    relationshipsDeleted
  }
}
    `;
export type DeleteWorkMutationFn = Apollo.MutationFunction<DeleteWorkMutation, DeleteWorkMutationVariables>;

/**
 * __useDeleteWorkMutation__
 *
 * To run a mutation, you first call `useDeleteWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkMutation, { data, loading, error }] = useDeleteWorkMutation({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useDeleteWorkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkMutation, DeleteWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkMutation, DeleteWorkMutationVariables>(DeleteWorkDocument, options);
      }
export type DeleteWorkMutationHookResult = ReturnType<typeof useDeleteWorkMutation>;
export type DeleteWorkMutationResult = Apollo.MutationResult<DeleteWorkMutation>;
export type DeleteWorkMutationOptions = Apollo.BaseMutationOptions<DeleteWorkMutation, DeleteWorkMutationVariables>;