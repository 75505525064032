import { USER_TYPE_VALUES } from "@/utils/options";
import { RefetchOnFocus } from "@/utils/query";
import { useAuth0 } from "@auth0/auth0-react";
import first from "lodash/first";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  useGetContentCountForCreatorQuery,
  useGetContentCountForOwnerQuery,
  useGetCreatorByAccountQuery,
  useGetOwnerByAccountQuery,
  useGetProfileQuery,
  UserType,
} from "@/generated-types";
import { formatLegalName } from "@/utils/formatter";
import { RecentContent } from "../content-page/recent-content";
import { WelcomeDialog } from "../content-page/welcome-dialog";
import { GettingStartedOverview } from "../public-home/overview";
import DashboardHead from "./header";

export default function DashboardPage() {
  const { user } = useAuth0();
  const { data: profileData, loading: loadingProfile } = useGetProfileQuery();

  const [showWelcome, setWelcome] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.search === "?welcome") setWelcome(true);
  }, [location]);

  const handleWelcomeClose = () => {
    setWelcome(false);
    navigate("/overview");
  };

  const userType = profileData?.me?.userType;
  const userCreatorId = first(profileData?.me?.account?.creators)?.id;
  const userOwnerId = profileData?.me?.account?.owner?.id;

  const { data: creatorData, loading: loadingCreator } = useGetCreatorByAccountQuery({
    variables: { accountId: profileData?.me?.account?.id || "" },
    skip: userType !== UserType.Creator,
  });

  const { data: ownerData, loading: loadingOwner } = useGetOwnerByAccountQuery({
    variables: { accountId: profileData?.me?.account?.id || "" },
    skip: !profileData || userType !== UserType.Owner,
  });

  const firstCreator = first(creatorData?.creators);
  const firstOwner = first(ownerData?.owners);
  // technically there is a maximum of one owner, however, the graphql schema is defined to return an array, even there is only one record

  const {
    data: countForCreator,
    loading: loadingCountForCreator,
    refetch: refetchCountForCreator,
  } = useGetContentCountForCreatorQuery({
    variables: { creatorId: userCreatorId! },
    skip: !userCreatorId,
  });

  const {
    data: countForOwner,
    loading: loadingCountForOwner,
    refetch: refetchCountForOwner,
  } = useGetContentCountForOwnerQuery({
    variables: { ownerId: userOwnerId! },
    skip: !userOwnerId,
  });

  const typedUser = {
    email: user?.email || "",
    name: userType === UserType.Creator ? formatLegalName(firstCreator) : firstOwner?.name,
    email_verified: user?.email_verified || false,
    nickname: user?.nickname || "",
    picture: user?.picture || "",
    sub: user?.sub || "",
    userType: USER_TYPE_VALUES[userType as UserType],
    updated_at: user?.updated_at || "",
  };

  // TODO: fix this to show count for creators as well as owners
  const channelCount =
    (first(countForCreator?.creators)?.channelsAggregate?.count || 0) +
    (first(countForOwner?.owners)?.channelsAggregate?.count || 0);

  const workCount =
    (first(countForCreator?.creators)?.worksAggregate?.count || 0) +
    (first(countForOwner?.owners)?.worksAggregate?.count || 0);

  return (
    <>
      <main className="container mx-auto mb-4 space-y-16">
        <h2 className="text-base font-bold text-gray-900 dark:text-white md:text-xl lg:text-2xl">
          Overview
        </h2>

        <GettingStartedOverview />

        {/* divider */}
        {/* <div className="mb-2 mt-8 border-t-2 border-dashed border-gray-900/10 dark:border-gray-400" /> */}

        <DashboardHead
          user={typedUser}
          channelCount={channelCount}
          workCount={workCount}
          className="container shadow-md"
          loading={
            loadingProfile ||
            loadingCreator ||
            loadingOwner ||
            loadingCountForCreator ||
            loadingCountForOwner
          }
        />

        {/* <ContentLibrary profile={profileData?.me} /> */}
        <RecentContent profile={profileData?.me} limit={3} />

        {/* <ActivityFeed activities={["created", "profile"]} className="container" /> */}
        <RefetchOnFocus refetchers={[refetchCountForCreator, refetchCountForOwner]} />

        <WelcomeDialog open={showWelcome} onClose={handleWelcomeClose} />
      </main>
    </>
  );
}
