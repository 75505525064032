import { MediaSourceType, UserMedia } from "@/generated-types";
import { makeMediaURL } from "@/utils/formatter";

type MediaItem = Pick<UserMedia, "id" | "extension" | "sourceType">;

type ContentAvatarProps = {
  channel?: {
    channelName?: string | null;
    thumbnail?: MediaItem | null;
  };
  work?: {
    title?: string | null;
    thumbnail?: MediaItem | null;
  };
  media: MediaItem[] | undefined;
};

function chooseThumbnail(
  media: MediaItem[] = [],
  thumbnail: MediaItem | undefined | null
): MediaItem | undefined {
  if (thumbnail) return thumbnail;
  let selected: MediaItem | undefined = undefined;
  for (const item of media) {
    if (item.extension !== "txt") selected = item;
    if (item.sourceType === MediaSourceType.OpenGraph) break;
  }
  return selected;
}

export function ContentAvatar({ channel, work, media }: ContentAvatarProps) {
  const thumbnail = chooseThumbnail(media, channel?.thumbnail || work?.thumbnail);
  const name = channel?.channelName || work?.title || " ";

  function makeInitials(name: string) {
    const words = name.split(/\s/g).slice(0, 3);
    return words.map((word) => word.charAt(0)).join("");
  }

  return (
    <div className="grow">
      {thumbnail ? (
        <img
          className="mx-6 mt-6 aspect-square size-36 object-scale-down"
          src={makeMediaURL(thumbnail)}
          alt=""
        />
      ) : (
        <div className="mx-12 mt-6 flex aspect-square size-24 items-center justify-center bg-primary/50 text-[2rem] font-bold uppercase text-white">
          {makeInitials(name)}
        </div>
      )}
    </div>
  );
}
