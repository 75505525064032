import { FormActions } from "@/components/forms/form-utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CertificationType, ContentBadgeType } from "@/generated-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CONTENT_BADGES, SelectBadge } from "./select-badge";
import { Undefinedable } from "./types";

const FormSchema = z.object({
  typeOfContent: z.enum(["channel", "work"]),
  contentBadges: z.array(z.string()).min(1, { message: "Select at least one badge" }),
});

export type FormValueType = z.infer<typeof FormSchema>;

type AddContentStepFormProps = {
  value: Undefinedable<FormValueType>;
  onNext: (value: FormValueType) => void;
  onBack?: () => void;
  loading: boolean;
};

export function AddContentFormStep4({ value, onBack, onNext, loading }: AddContentStepFormProps) {
  const form = useForm<FormValueType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      contentBadges: [],
    },
    mode: "onChange",
  });

  const {
    formState: { isValid, errors },
    setValue,
    watch,
  } = form;

  // populate the typeOfContent field with values passed down from earlier steps
  // can't use reset() because it would wipe out the contentBadges field
  useEffect(() => {
    value.typeOfContent && setValue("typeOfContent", value.typeOfContent);
  }, [value.typeOfContent, setValue]);

  const { typeOfContent } = watch();

  function onSubmit(data: FormValueType) {
    onNext(data);
  }

  // console.log("add content step 4", {
  //   errors,
  //   values: watch(),
  //   isValid,
  // });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4 space-y-8">
        {/* content badges */}
        <FormField
          control={form.control}
          name="contentBadges"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                How do you best describe the origin(s) of this {typeOfContent}? *
              </FormLabel>
              <FormControl>
                <SelectBadge
                  options={CONTENT_BADGES.filter(
                    (item) =>
                      item.certification === CertificationType.Uncertified &&
                      item.type === ContentBadgeType.Origin
                  )}
                  multi
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormActions
          disabled={!isValid}
          onBack={onBack}
          className=""
          nextLabel="Save"
          loading={loading}
        />
      </form>
    </Form>
  );
}
