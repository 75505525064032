import { Bookmark, Hash, Images, Loader2, Table } from "lucide-react";
import { useState } from "react";
import { z } from "zod";

import { FormActions } from "@/components/forms/form-utils";
import { Button } from "@/components/ui/button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useDeleteUserMediaMutation, useParsePageInfoMutation, UserMedia } from "@/generated-types";
import { toast } from "sonner";
import { makeMediaURL } from "@/utils/formatter";

export type SiteMeta = {
  name: string;
  description: string;
  keywords: string;
  media: Pick<UserMedia, "id" | "source" | "width" | "height">[];
};

type UrlAnalyzerProps = {
  url: string | undefined;
  typeOfContent: "channel" | "work";
  editing?: boolean;
  onUse: (meta: SiteMeta) => void;
  onCancel?: () => void;
};

export function UrlAnalyzer({ url, typeOfContent, editing, onUse, onCancel }: UrlAnalyzerProps) {
  const [meta, setMeta] = useState<SiteMeta>();
  const [fetchUrl, { loading }] = useParsePageInfoMutation();
  const [deleteUserMedia] = useDeleteUserMediaMutation();

  const urlSchema = z.string().url();

  const validateUrl = (url: string) => {
    const result = urlSchema.safeParse(url);
    return result.success;
  };

  async function handleOpenChange(open: boolean) {
    if (open && url) {
      try {
        const data = await fetchUrl({ variables: { url }, fetchPolicy: "no-cache" });
        setMeta({
          name: data?.data?.parsePageFromUrl?.title || "",
          description: data?.data?.parsePageFromUrl?.description || "",
          keywords: data?.data?.parsePageFromUrl?.keywords || "",
          media: data?.data?.parsePageFromUrl?.media || [],
        });
      } catch (e) {
        toast.error(`Failed to access web address: ${url}`);
      }
    } else {
      setMeta(undefined);
    }
  }

  function handleReject() {
    if (meta?.media && meta.media.length > 0) {
      // deleteUserMedias({ variables: { ids: meta.media.map((item) => item.id) } });
      // TODO: delete user media through a custom mutation so we delete the file on S3 as well
      setMeta(undefined);
      onCancel && onCancel();
    }
  }

  function handleAccept() {
    meta && onUse(meta);
    setMeta(undefined);
    if (editing) toast.success(`Done. Press Save to update your ${typeOfContent}.`);
  }

  return (
    <Dialog open={Boolean(meta)} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          disabled={!url || !validateUrl(url) || loading}
          className="border-primary"
        >
          {loading && <Loader2 className="mr-2 size-4 animate-spin" />}
          Analyze
        </Button>
      </DialogTrigger>
      <DialogContent
        className="flex max-h-full max-w-full flex-col p-0 sm:max-w-xl lg:max-w-2xl"
        // prevent the dialog being closed when the user clicks outside
        onInteractOutside={(event) => event?.preventDefault()}
      >
        <DialogHeader className="px-6 pt-6 sm:px-8 sm:pt-8">
          <DialogTitle className="mb-1 text-2xl">Web site analyzed</DialogTitle>
          <DialogDescription>
            We pulled the following information from this web address.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4 overflow-auto px-8">
          <div className="flex items-start space-x-4">
            <Bookmark className="w-5 min-w-5" />
            <div className="mt-1 space-y-1">
              <p className="text-sm font-medium leading-none">Name</p>
              <p className="text-sm text-muted-foreground">{meta?.name}</p>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <Table className="w-5 min-w-5" />
            <div className="mt-1 space-y-1">
              <p className="text-sm font-medium leading-none">Description</p>
              <p className="text-sm text-muted-foreground">{meta?.description}</p>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <Hash className="w-5 min-w-5" />
            <div className="mt-1 space-y-1">
              <p className="text-sm font-medium leading-none">Keywords</p>
              <p className="text-sm text-muted-foreground">{meta?.keywords}</p>
            </div>
          </div>
          {meta?.media && meta?.media.length > 0 && (
            <>
              <div className="flex items-start space-x-4">
                <Images className="w-5 min-w-5" />
                <p className="mt-1 text-sm font-medium leading-none">Thumbnail & Screenshot</p>
              </div>
              <Carousel className="mx-auto w-[calc(100%_-_6rem)] pb-4">
                <CarouselContent>
                  {meta?.media.map((image, index) => (
                    <CarouselItem key={image.id}>
                      <img
                        src={makeMediaURL(image)}
                        data-src={makeMediaURL(image)}
                        alt=""
                        className="aspect-square w-full object-scale-down"
                        onError={(event) => {
                          const imgElement = event.target as HTMLImageElement;
                          imgElement.src = "/favicon64.png";
                          // CloudFront isn't ready yet, retry after a short delay
                          setTimeout(() => {
                            const realSrc = imgElement.getAttribute("data-src");
                            if (realSrc) imgElement.src = realSrc;
                          }, 500);
                        }}
                      />
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
              </Carousel>
            </>
          )}
        </div>
        <DialogFooter className="px-6 pb-6 sm:px-8 sm:pb-8">
          <FormActions
            backLabel="No, thanks"
            nextLabel="Use it"
            onBack={handleReject}
            onNext={handleAccept}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
