import {
  useGetCreatorByAccountQuery,
  useGetCreatorContactsQuery,
  useGetProfileQuery,
  useUpdateCreatorMutation,
} from "@/generated-types";
import { RefetchOnFocus } from "@/utils/query";
import first from "lodash/first";
import { useNavigate } from "react-router-dom";
import { ContentLibrary } from "../content-page/content-library";
import { useTagEditor } from "./tags-editor";
import { ViewCreatorProfile } from "./view-creator-profile";

export default function CreatorProfilePage() {
  const navigate = useNavigate();

  // mutation hooks
  const [updateCreator, { loading: creatorUpdating }] = useUpdateCreatorMutation();
  const { handleUpdateTags } = useTagEditor();

  // query hooks
  const {
    data: profileData,
    loading: loadingProfile,
    refetch: refectchProfile,
  } = useGetProfileQuery();

  const {
    data: creatorData,
    loading: loadingCreator,
    refetch: refectchCreator,
  } = useGetCreatorByAccountQuery({
    variables: { accountId: profileData?.me?.account?.id || "" },
    skip: !profileData,
  });

  const subscriptionPlan = profileData?.me?.account?.subscription?.plan;

  const creator = first(creatorData?.creators);

  const { data: contactsData, loading: loadingContacts } = useGetCreatorContactsQuery({
    variables: { creatorId: creator?.id || "" },
    skip: !creator,
  });

  /* load address from firstContact on load, then on update it should requery on update */
  const firstContact = first(contactsData?.creatorContacts);

  const handlePicture = async (picture: string) => {
    await updateCreator({
      variables: {
        id: creator?.id!,
        data: {
          profilePicture: picture,
        },
      },
    });
  };

  const handleUpdateCreator = async (field: string, value: string | undefined) => {
    const result = await updateCreator({
      variables: {
        id: creator?.id!, // ID must exist
        data: {
          [field]: value,
        },
      },
    });
    const updatedCreator =
      result.data?.updateCreators.creators && result.data?.updateCreators.creators.length > 0
        ? result.data?.updateCreators.creators[0]
        : undefined;

    return updatedCreator
      ? String(updatedCreator[field as keyof typeof updatedCreator]) || undefined
      : undefined;
  };

  const handleSaveTags = async (tags: string | undefined) => {
    const titles = tags
      ? tags
          .split(", ")
          .map((tag) => tag.trim().toLowerCase())
          .filter((tag) => tag.length > 1)
      : [];

    const tagsAfterUpdate = await handleUpdateTags("creator", creator?.id!, titles, creator?.tags);
    return tagsAfterUpdate
      ?.filter((tag) => !tag.blocked)
      .map((tag) => tag.title)
      .join(", ");
  };

  return (
    <>
      <RefetchOnFocus refetchers={[refectchProfile, refectchCreator]} />
      <main className="container mx-auto">
        <h1 id="page-title" className="text-3xl font-bold tracking-tight text-gray-900">
          Your Creator Information
        </h1>
        <ViewCreatorProfile
          loading={loadingProfile || loadingCreator}
          user={profileData?.me}
          plan={subscriptionPlan}
          creator={creator}
          contact={firstContact}
          onSavePicture={handlePicture}
          onSaveField={handleUpdateCreator}
          onSaveTags={handleSaveTags}
        />
        <ContentLibrary profile={profileData?.me} />
      </main>
    </>
  );
}
