import { cn } from "@/utils";
import { CheckIcon } from "lucide-react";
import { useEffect, useState } from "react";

export const CONTENT_BADGES = [
  {
    id: "hcc-uncertified",
    label: "Human-Composed Creation",
    acronym: "HCC",
    description:
      "The creative work was human-created, possibly using insightful AI. Any creation made before November 2022 falls under this.",
    type: "ORIGIN",
    certification: "UNCERTIFIED",
  },
  {
    id: "hcc-certified",
    label: "Human-Composed Creation",
    acronym: "HCC",
    description:
      "The creative work was human-created, possibly using insightful AI. Any creation made before November 2022 falls under this.",
    type: "ORIGIN",
    certification: "CERTIFIED",
  },
  {
    id: "aac-uncertified",
    label: "AI-Assisted Creation",
    acronym: "AAC",
    description: "Generative AI tools have been used to refine the work of a human creator.",
    type: "ORIGIN",
    certification: "UNCERTIFIED",
  },
  {
    id: "aac-certified",
    label: "AI-Assisted Creation",
    acronym: "AAC",
    description: "Generative AI tools have been used to refine the work of a human creator.",
    type: "ORIGIN",
    certification: "CERTIFIED",
  },
  {
    id: "acc-uncertified",
    label: "AI-Composed Creation",
    acronym: "ACC",
    description: "The creative work was primarily created by a generative AI.",
    type: "ORIGIN",
    certification: "UNCERTIFIED",
  },
  {
    id: "esc-uncertified",
    label: "Ethically-Sourced Content",
    acronym: "ESC",
    description: "",
    type: "SOURCING",
    certification: "UNCERTIFIED",
  },
  {
    id: "esc-certified",
    label: "Ethically-Sourced Content",
    acronym: "ESC",
    description: "",
    type: "SOURCING",
    certification: "CERTIFIED",
  },
  {
    id: "gpc-uncertified",
    label: "Guardrail-Protected Content",
    acronym: "GPC",
    description: "",
    type: "SOURCING",
    certification: "UNCERTIFIED",
  },
  {
    id: "gpc-certified",
    label: "Guardrail-Protected Content",
    acronym: "GPC",
    description: "",
    type: "SOURCING",
    certification: "CERTIFIED",
  },
  {
    id: "smc-uncertified",
    label: "Self-Managed Content",
    acronym: "SMC",
    description: "",
    type: "SOURCING",
    certification: "UNCERTIFIED",
  },
];

function makeBadgeURL(acronym: string) {
  // return `https://assets.credtent.org/${acronym}.svg`;
  return `/assets/badges-v25.1/${acronym}.svg`;
}

type SelectBadgeProps = {
  options: typeof CONTENT_BADGES;
  multi: boolean;
  value: string[];
  onChange: (newValue: string[]) => void;
  formMessage?: JSX.Element;
};

export function SelectBadge({
  options,
  value = [],
  multi,
  onChange,
  formMessage,
}: SelectBadgeProps) {
  const [hoverTooltip, setHoverTooltip] = useState<string>();
  const [valueTooltip, setValueTooltip] = useState<string>();

  useEffect(() => {
    if (value.length === 1) {
      // only show value tooltip when there is one selected badge
      const firstOption = options.find((opt) => opt.id === value[0]);
      if (firstOption) setValueTooltip(firstOption.description);
    } else {
      setValueTooltip(undefined);
    }
  }, [options, value]);

  function handleToggle(id: string, select: boolean) {
    if (multi) {
      if (select) onChange([...value, id]);
      else onChange(value.filter((item) => item !== id));
    } else {
      if (select) onChange([id]);
      else onChange([]);
    }
  }

  const tooltip = hoverTooltip || valueTooltip;

  return (
    <div>
      <div className="flex gap-4">
        {options.map((item) => {
          const selected = value.includes(item.id);
          return (
            <div
              key={item.id}
              onClick={() => handleToggle(item.id, !selected)}
              className={cn("relative size-32 shrink-0 cursor-pointer hover:border-primary/80", {
                "[&_div]:block": selected,
              })}
              onMouseEnter={() => setHoverTooltip(item.description)}
              onMouseLeave={() => setHoverTooltip(undefined)}
            >
              <img src={makeBadgeURL(item.acronym)} alt="" className="size-full" />
              <div className="absolute left-1 top-1 hidden bg-white">
                <CheckIcon size={16} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-2 h-6">
        {tooltip ? <p className="text-sm text-muted-foreground">{tooltip}</p> : formMessage}
      </div>
    </div>
  );
}
