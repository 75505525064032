import { PlanTypes } from "@/generated-types";
import { cn } from "@/utils";
import { CheckIcon } from "@heroicons/react/20/solid";
import React from "react";

import { ShieldCheckIcon as ShieldCheckOutline } from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";

export const subscriptionPlans = [
  {
    id: PlanTypes.Dnt,
    name: "Opt out of AI Training",
    href: "#",
    listPrice: 20,
    salePrice: 20,
    description: "Exclude your content from AI.",
    features: [
      "Broadcasts Opt-out Preference",
      "Monitors Compliance",
      "Basic analytics",
      "Membership in the Credtent Community",
    ],
    mostPopular: true,
    priceId: process.env.REACT_APP_STRIPE_PRICE_DNT,
  },
  {
    id: PlanTypes.Standard,
    name: "Standard License",
    href: "#",
    listPrice: 30,
    salePrice: 20,
    description: "Get paid when your content is used for AI training. Rates set by market.",
    features: [
      "Broadcasts Opt-out Preference",
      "Monitors Compliance",
      "Monetize your Content",
      "Full Analytics Package",
      "Advanced Timeline Reporting",
      "Membership in the Credtent Community",
    ],
    mostPopular: false,
    // priceId: process.env.REACT_APP_STRIPE_PRICE_STANDARD,
    priceId: process.env.REACT_APP_STRIPE_PRICE_DNT,
  },
  {
    id: PlanTypes.Premium,
    name: "Premium License",
    href: "#",
    listPrice: 100,
    salePrice: 20,
    description: "License your content with prices set by you.",
    features: [
      "Broadcasts Opt-out Preference",
      "Monitors Compliance",
      "Monetize your Content",
      "Full Analytics Package",
      "Advanced Timeline Reporting",
      "Membership in the Credtent Community",
    ],
    mostPopular: false,
    // priceId: process.env.REACT_APP_STRIPE_PRICE_PREMIUM,
    priceId: process.env.REACT_APP_STRIPE_PRICE_DNT,
  },
];

interface PriceSheetProps {
  onChoosePlan: (plan: { id: string; name: string }) => void;
}

export const PriceSheet: React.FC<PriceSheetProps> = ({ onChoosePlan }) => {
  return (
    <div className="bg-white pb-8 pt-24 dark:bg-gray-900 dark:text-white lg:pt-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-primary dark:text-gray-300">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
            Low Cost, High Tech: Your AI Problems Solved.
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600 dark:text-gray-400">
          Register all of your content with Credtent for one budget-friendly annual fee and we'll
          take care of the rest.
        </p>
        <div className="isolate mx-auto mt-4 grid max-w-md grid-cols-1 gap-y-8 sm:mt-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {subscriptionPlans.map((tier, tierIdx) => (
            <div
              key={tier.id}
              className={cn(
                tier.mostPopular ? "lg:z-10 lg:rounded-none lg:rounded-t" : "lg:mt-8",
                "flex flex-col justify-between rounded-none rounded-t bg-white p-8 ring-1 ring-gray-200 dark:bg-gray-900 dark:ring-gray-600 xl:p-10"
              )}
            >
              <div>
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={tier.id}
                    className={cn(
                      tier.mostPopular ? "text-primary" : "text-gray-900",
                      "text-lg font-semibold leading-8 dark:text-gray-300"
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-primary/10 px-2.5 py-1 text-xs font-semibold leading-5 text-primary dark:bg-gray-500">
                      Most popular
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600 dark:text-gray-400">
                  {tier.description}
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-300">
                    ${tier.listPrice}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600 dark:text-gray-400">
                    /year
                  </span>
                </p>
                <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600 dark:text-gray-400">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-primary dark:text-gray-300"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <button
                onClick={() => onChoosePlan(tier)}
                aria-describedby={tier.id}
                className={cn(
                  tier.mostPopular
                    ? "bg-primary text-white shadow-sm hover:bg-primary/80 dark:bg-gray-500 dark:text-gray-900"
                    : "ring-1 ring-inset ring-primary hover:ring-primary/50 dark:text-gray-300",
                  "mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                )}
              >
                Continue
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

type PlanListProps = {
  plan: PlanTypes | undefined | null;
  className?: string;
};
export function PlanList({ plan, className }: PlanListProps) {
  const currentPlan = plan || PlanTypes.Dnt;
  return (
    <>
      {subscriptionPlans.map((planItem) => (
        <div key={planItem.id} className={cn("flex flex-row items-center gap-2", className)}>
          {currentPlan === planItem.id ? (
            <ShieldCheckIcon className="size-5 text-primary" />
          ) : (
            <ShieldCheckOutline className="size-5 text-primary" />
          )}
          {planItem.name}
        </div>
      ))}
    </>
  );
}
