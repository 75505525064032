import { BoltIcon } from "@heroicons/react/24/outline";
import first from "lodash/first";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { VerticalStepper } from "@/components/ui/VerticalStepper";
import {
  useCreateChannelMutation,
  useCreateWorkMutation,
  useGetProfileQuery,
} from "@/generated-types";
import { resolveConnection } from "@/utils/tag-helpers";
import { useTagEditor } from "../ProfilePage/tags-editor";
import {
  QuickAddContentFormStep1,
  FormValueType as ValueTypeStep1,
} from "./quick-add-content-step1-form";
import {
  QuickAddContentFormStep2,
  FormValueType as ValueTypeStep2,
} from "./quick-add-content-step2-form";
import {
  QuickAddContentFormStep3,
  FormValueType as ValueTypeStep3,
} from "./quick-add-content-step3-form";
import { Undefinedable } from "./types";

type CombinedValueType = Undefinedable<ValueTypeStep1 & ValueTypeStep2 & ValueTypeStep3>;

export type QuickAddContentStepFormProps = {
  value: Undefinedable<CombinedValueType>;
  onBack?: () => void;
  onNext?: (value: CombinedValueType) => void;
  onSaveAndClose?: (value: CombinedValueType) => Promise<void>;
  onSaveAndNew?: (value: CombinedValueType) => Promise<void>;
  loading?: boolean;
  addNew?: boolean;
};

type AddContentDialogProps = {
  smallButton?: boolean;
};

export function QuickAddContentDialog({ smallButton }: AddContentDialogProps) {
  const navigate = useNavigate();

  const [completedStep, setCompletedStep] = useState(0);
  const [activeStep, setActiveStep] = useState(1); // we count from 1 to match with the displayed step number
  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(true); // support "Add Another" with one modal session

  const { data: profileData, loading: loadingProfile } = useGetProfileQuery();
  const [combinedValue, setCombinedValue] = useState<CombinedValueType>({});

  const [createChannel, { loading: creatingChannel }] = useCreateChannelMutation({
    refetchQueries: ["GetChannelsByCreator", "GetChannelsByOwner"],
  });
  const [createWork, { loading: creatingWork }] = useCreateWorkMutation({
    refetchQueries: ["GetWorksByCreator", "GetWorksByOwner"],
  });

  const { resolveTags } = useTagEditor();

  useEffect(() => {
    if (!isOpen) {
      setActiveStep(1);
      setCompletedStep(0);
      setCombinedValue({}); // clean up previous entered form values when the dialog closes
    }
  }, [isOpen]);

  // current user's Creator ID or Rights Owner ID
  // One of them should have value
  const userCreatorId = first(profileData?.me?.account?.creators)?.id;
  const userOwnerId = profileData?.me?.account?.owner?.id;

  function stepVariant(sn: number) {
    return sn === activeStep ? "active" : sn <= completedStep ? "completed" : "default";
  }

  function handleAddAnother() {
    // setIsOpen(false);
    setActiveStep(1);
    setCompletedStep(0);
    setCombinedValue({}); // clean up previous entered form values when the dialog closes
    setIsNew(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleBack() {
    activeStep > 1 && setActiveStep(activeStep - 1);
  }

  function handleNext() {
    setCompletedStep(activeStep);
    setActiveStep(activeStep + 1);
  }

  async function handleCreateContent(data: typeof combinedValue) {
    const {
      typeOfContent,
      name,
      description,
      canonicalUrl,
      // typeTag,
      // secondaryTypeTag,
      // tertiaryTypeTag,
      // categories = [],
      contentBadges = [],
      keywords = [],
    } = data;

    // const typeTagIds: string[] = [typeTag!]; // typeTag can't be undefined if the form passed validation
    // if (secondaryTypeTag) typeTagIds.push(secondaryTypeTag);
    // if (tertiaryTypeTag) typeTagIds.push(tertiaryTypeTag);

    if (typeOfContent === "channel") {
      const result = await createChannel({
        variables: {
          input: {
            channelUrl: canonicalUrl,
            channelName: name,
            description,
            // channelTypes: resolveConnection(typeTagIds),
            // categories: resolveConnection(categories),
            contentBadges: resolveConnection(contentBadges),
            tags: resolveTags(keywords),
            // media: resolveConnection(media),
            creators: resolveConnection([{ id: userCreatorId }]),
            owners: resolveConnection([{ id: userOwnerId }]),
            accounts: { connect: [{ where: { node: { id: profileData?.me?.account?.id! } } }] },
          },
        },
      });

      const newChannelId = first(result.data?.createChannels.channels)?.id!;
    } else {
      const result = await createWork({
        variables: {
          input: {
            workUrl: canonicalUrl,
            title: name,
            description,
            // workTypes: resolveConnection(typeTagIds),
            // categories: resolveConnection(categories),
            contentBadges: resolveConnection(contentBadges),
            tags: resolveTags(keywords),
            // media: resolveConnection(media),
            creators: resolveConnection([{ id: userCreatorId }]),
            owners: resolveConnection([{ id: userOwnerId }]),
            accounts: { connect: [{ where: { node: { id: profileData?.me?.account?.id! } } }] },
          },
        },
      });

      const newWorkId = first(result.data?.createWorks.works)?.id!;
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        {smallButton ? (
          <Button size="sm" variant="default" className="flex h-[30px] gap-1.5 text-xs font-normal">
            <BoltIcon className="size-4" /> Opt Out of AI Training
          </Button>
        ) : (
          <Button className="button mt-6 flex gap-2">
            <BoltIcon className="size-5 dark:text-gray-400" aria-hidden="true" /> Opt Out of AI
            Training
          </Button>
        )}
      </DialogTrigger>
      <DialogContent
        className="max-w-full sm:max-w-2xl sm:p-8 lg:max-w-3xl"
        onInteractOutside={(event) => event?.preventDefault()}
        // prevent the dialog being closed when the user clicks outside
      >
        <DialogHeader>
          <DialogTitle className="mb-1 text-2xl">Opt Out of AI Training</DialogTitle>
          {/* <DialogDescription></DialogDescription> */}
        </DialogHeader>
        {/* <MetadataForm profile={profileData?.me} creating onComplete={handleComplete} /> */}

        {/* stepper component */}
        <VerticalStepper>
          <VerticalStepper.Step
            variant={stepVariant(1)}
            stepNumber="1"
            label="The Essentials"
            firstStep
          >
            <QuickAddContentFormStep1
              addNew={isNew}
              value={combinedValue}
              onNext={(value) => {
                setCombinedValue(value);
                setIsNew(false);
                handleNext();
              }}
            />
          </VerticalStepper.Step>

          <VerticalStepper.Step variant={stepVariant(2)} stepNumber="2" label="Creative Origin">
            <QuickAddContentFormStep2
              addNew={isNew}
              value={combinedValue}
              onBack={handleBack}
              onNext={(value) => {
                setCombinedValue({ ...combinedValue, ...value });
                setIsNew(false);
                handleNext();
              }}
            />
          </VerticalStepper.Step>

          <VerticalStepper.Step
            variant={stepVariant(3)}
            stepNumber="3"
            label="Optional Information"
          >
            <QuickAddContentFormStep3
              addNew={isNew}
              value={combinedValue}
              onBack={handleBack}
              onSaveAndClose={async (value) => {
                await handleCreateContent({ ...combinedValue, ...value });
                handleClose();
              }}
              onSaveAndNew={async (value) => {
                await handleCreateContent({ ...combinedValue, ...value });
                handleAddAnother();
              }}
              loading={creatingChannel || creatingWork}
            />
          </VerticalStepper.Step>
        </VerticalStepper>
      </DialogContent>
    </Dialog>
  );
}
