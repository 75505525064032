import { FormMultiActions } from "@/components/forms/form-utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { TagsEditor } from "../ProfilePage/tags-editor";
import type { QuickAddContentStepFormProps } from "./quick-add-content-dialog";

export const FormSchema = z.object({
  description: z.string().optional(),
  keywords: z.array(z.string()).optional(),
});

export type FormValueType = z.infer<typeof FormSchema>;

export function QuickAddContentFormStep3({
  onBack,
  onSaveAndClose,
  onSaveAndNew,
  loading,
  addNew,
}: QuickAddContentStepFormProps) {
  const [lastSubmit, setLastSubmit] = useState(0);

  const form = useForm<FormValueType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      description: "",
      keywords: [],
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (addNew)
      form.reset({
        description: "",
        keywords: [],
      });
  }, [addNew, form]);

  function handleSaveNClose() {
    setLastSubmit(0);
    form.handleSubmit(onSaveAndClose!)();
  }

  function handleSaveNAnother() {
    setLastSubmit(1);
    form.handleSubmit(onSaveAndNew!)();
  }

  return (
    <Form {...form}>
      <form className="mt-4 space-y-8">
        {/* description */}
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* keywords */}
        <FormField
          control={form.control}
          name="keywords"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Keywords</FormLabel>
              <FormControl>
                <TagsEditor
                  value={field.value}
                  placeholder="Keywords separated by ,"
                  onChange={field.onChange}
                  className="flex bg-background ring-offset-background focus-visible:outline-none [&.tagify--focus]:ring-2 [&.tagify--focus]:ring-ring [&.tagify--focus]:ring-offset-2"
                  // trying to mimic classes used by a standard textarea
                />
              </FormControl>
              {/* <FormDescription>
                Using only relevant keywords will make your {typeOfContent} easier to discover and
                license. Not necessary if you choose to opt out this {typeOfContent} from AI training.
              </FormDescription> */}
              <FormMessage />
            </FormItem>
          )}
        />

        <FormMultiActions
          actions={[
            {
              type: "secondary",
              label: "Back",
              onClick: onBack,
            },
            {
              type: "primary",
              disabled: !form.formState.isValid,
              label: "Save & Close",
              loading: loading && lastSubmit === 0,
              onClick: handleSaveNClose,
            },
            {
              type: "primary",
              disabled: !form.formState.isValid,
              label: "Save & Add Another",
              loading: loading && lastSubmit === 1,
              onClick: handleSaveNAnother,
            },
          ]}
          className="justify-end"
        />
      </form>
    </Form>
  );
}
