import { Main } from "@/components/main";
import { NavBar } from "@/components/navigation";
import { SideBar } from "@/components/sidebar";
import { Outlet } from "react-router-dom";

export default function ProtectedLayout() {
  return (
    <div className="h-dvh overflow-auto">
      <NavBar logoLink="/overview" />
      <div id="content" className="top-16 flex size-full flex-row">
        <SideBar />
        <Main>
          <>
            <Outlet />
            {/* push the copyright footer to the bottom in case the main content is too short */}
            <div className="flex-1"></div>
            <div className="-mb-4 -mr-4 mt-4 text-xs">
              &copy; Credtent, Inc. {new Date().getFullYear()}
            </div>
          </>
        </Main>
      </div>
    </div>
  );
}
