import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2Icon, SearchIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Divider } from "./content-stakeholder-form";

import {
  useSearchCreatorsByNameLazyQuery,
  useSearchOwnersByNameLazyQuery,
} from "@/generated-types";
import { StakeholderStubForm } from "./add-stakeholder-stub-form";
import { RecentCreators, useRecentStakeholders } from "./recent-stakeholders";
import { StakeholderItem } from "./stakeholder-list";
import type { SearchFilter, Stakeholder, TypeOfStakeholder } from "./types";

type SelectStakeholderSheetProps = {
  type: TypeOfStakeholder;
  trigger: React.ReactElement;
  onSelect: (item: Stakeholder) => void;
};

export function SelectStakeholderSheet({
  type: typeOfStakeholder,
  trigger,
  onSelect,
}: SelectStakeholderSheetProps) {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<"search" | "create">("search");
  // we save the search filter so if the user wants to create a stub, we'll populate the creation form
  // with the search value
  const [searchFilter, setSearchFilter] = useState<SearchFilter>();

  const [showResults, setShowResults] = useState(false);

  const [searchCreators, { data: creatorsData, loading: searchingCreators }] =
    useSearchCreatorsByNameLazyQuery();

  const [searchOwners, { data: ownersData, loading: searchingOwners }] =
    useSearchOwnersByNameLazyQuery();

  useEffect(() => {
    if (!open) {
      // when the sheet closes, clean up state to go back to a clean slate
      setShowResults(false);
      setSearchFilter(undefined);
      setMode("search");
    }
  }, [open]);

  const { addToRecent } = useRecentStakeholders();

  function handleSearch(filter: SearchFilter) {
    const { firstName, lastName } = filter;
    if (typeOfStakeholder === "creator") {
      searchCreators({
        variables: { firstName: firstName?.toLowerCase(), lastName: lastName?.toLowerCase() },
      });
    } else {
      searchOwners({ variables: { name: lastName.toLowerCase() } });
    }
    setShowResults(true);
    setSearchFilter(filter);
  }

  function handleSelect(item: Stakeholder) {
    // console.log("selected", item);
    onSelect(item);
    addToRecent(typeOfStakeholder, item.id);
    setOpen(false);
  }

  function renderSearchResult() {
    if (mode !== "search" || !showResults) return null;
    return (
      <div className="-mt-4 space-y-4 divide-y">
        {creatorsData?.creators.map((item) => (
          <StakeholderItem key={item.id} item={item} onSelect={handleSelect} className="pt-4" />
        ))}
        {ownersData?.owners.map((item) => (
          <StakeholderItem key={item.id} item={item} onSelect={handleSelect} className="pt-4" />
        ))}
      </div>
    );
  }

  function handleAddStub(item: Stakeholder) {
    onSelect(item);
    setOpen(false);
  }

  function handleCancelAddSub() {
    setOpen(false);
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>{trigger}</SheetTrigger>
      <SheetContent className="w-full md:min-w-[75%] xl:min-w-[50%]">
        <SheetHeader>
          <SheetTitle>Add a {typeOfStakeholder} to your content</SheetTitle>
          <SheetDescription>
            Try search for the {typeOfStakeholder} by name. If you can't find them on the system,
            you can invite them to join.
          </SheetDescription>
        </SheetHeader>

        <div className="space-y-8 py-8">
          <RecentCreators typeOfStakeholder={typeOfStakeholder} onSelect={handleSelect} />

          <Divider />

          {mode === "search" ? (
            <SearchForm
              type={typeOfStakeholder}
              loading={searchingCreators || searchingOwners}
              onSearch={handleSearch}
            />
          ) : (
            <StakeholderStubForm
              type={typeOfStakeholder}
              filter={searchFilter}
              onSave={handleAddStub}
              onCancel={handleCancelAddSub}
            />
          )}
          {renderSearchResult()}
          {mode === "search" && showResults && (
            <div>
              <Label>
                Not finding the {typeOfStakeholder} you're looking for?
                <Button
                  variant="outline"
                  size="sm"
                  className="ml-2"
                  onClick={() => setMode("create")}
                >
                  Add {typeOfStakeholder}
                </Button>
              </Label>
            </div>
          )}
        </div>

        {/* <SheetFooter className="flex-row justify-end gap-2 [&_*]:grow sm:[&_*]:grow-0">
          <SheetClose asChild>
            <Button>Cancel</Button>
          </SheetClose>
        </SheetFooter> */}
      </SheetContent>
    </Sheet>
  );
}

type SearchFormProps = {
  type: TypeOfStakeholder;
  loading: boolean;
  onSearch: (filter: SearchFilter) => void;
};

function SearchForm({ type: typeOfStakeholder, loading, onSearch }: SearchFormProps) {
  const FormSchema = z.object({
    firstName: z.string().optional(),
    lastName: z.string().min(2, "Enter at least 2 letters"),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
    },
    mode: "onChange",
  });

  const {
    formState: { isValid },
    reset,
  } = form;

  const showFirstNameField = typeOfStakeholder === "creator";
  const lastNameFieldLabel = typeOfStakeholder === "creator" ? "Last name" : "Name";

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSearch)} className="w-full space-y-2">
        <Label>Search {typeOfStakeholder}s by name:</Label>

        <div className="flex w-full gap-2">
          {/* first name */}
          {showFirstNameField && (
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormControl>
                    <Input
                      {...field}
                      autoCapitalize="words"
                      placeholder="First name starts with..."
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          {/* last name of creator or name of rights owner */}
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormControl>
                  <Input
                    {...field}
                    autoCapitalize="words"
                    placeholder={`${lastNameFieldLabel} starts with...`}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* search button */}
          <Button size="icon" disabled={!isValid}>
            {loading ? (
              <Loader2Icon className="size-4 animate-spin" />
            ) : (
              <SearchIcon className="size-4" />
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
}
