import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { FolderPlusIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/button";
import { AddContentDialog } from "../content-page/add-content-dialog";

export const GettingStartedOverview = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <div id="overview">
      {/* <header>
        <div className="max-w-7xl px-4 sm:px-6 lg:px-8">
          <h2 className="text-xl font-extrabold text-gray-900 dark:text-white md:text-2xl lg:text-3xl">
            Getting Started with <span className="text-purple-500">Credtent</span>
          </h2>
        </div>
      </header> */}
      <main>
        <section className="container bg-white dark:bg-gray-900">
          <div className="mt-4 grid px-4 lg:h-[400px] lg:grid-cols-12 lg:gap-8 xl:gap-0">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="mb-4 max-w-2xl text-2xl font-extrabold leading-none tracking-tight dark:text-white md:text-4xl xl:text-5xl">
                Credtent is on your side
              </h1>
              <p className="mb-6 max-w-2xl font-light text-gray-500 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
                Credtent enables creators to exclude their work from AI tools or to profit by
                setting fair licensing terms for responsible companies seeking credible, unbiased
                training data.
              </p>
              {isAuthenticated ? (
                <AddContentDialog />
              ) : (
                <Link to="/library">
                  <Button className="button mt-6 flex gap-2">
                    <FolderPlusIcon className="size-5 dark:text-gray-400" aria-hidden="true" /> Add
                    Creation
                  </Button>
                </Link>
              )}
            </div>
            <div className="hidden max-h-[400px] lg:col-span-5 lg:mt-0 lg:flex">
              <img
                src="/assets/credtent_collage_design_10_720.png"
                className="object-contain"
                alt=""
              />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
