import { Dialog, DialogContent, DialogDescription, DialogTitle } from "@/components/ui/dialog";
import { Volume2Icon, VolumeXIcon } from "lucide-react";
import { useRef, useState } from "react";

import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

const VIDEO_FILE = "/assets/video/welcome-25v1-720p.mp4";
type WelcomeDialogProps = {
  open: boolean;
  onClose: () => void;
};
export function WelcomeDialog({ open, onClose }: WelcomeDialogProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(true); // must be muted otherwise autoPlay won't work

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted); // Sync state with video
    }
  };

  function handleOpenChange(open: boolean) {
    onClose();
  }

  const handleVideoEnd = () => {
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogContent
        className="max-h-full max-w-full border-none p-0 sm:max-w-xl lg:max-w-2xl"
        // prevent the dialog being closed when the user clicks outside
        onInteractOutside={(event) => event?.preventDefault()}
      >
        <VisuallyHidden asChild>
          <DialogTitle>Welcome Video</DialogTitle>
        </VisuallyHidden>
        <VisuallyHidden asChild>
          <DialogDescription>This dialog shows the Credtent welcome video.</DialogDescription>
        </VisuallyHidden>
        <video
          ref={videoRef}
          autoPlay
          muted={isMuted}
          onEnded={handleVideoEnd}
          width="100%"
          className="rounded"
        >
          <source src={VIDEO_FILE} type="video/mp4" />
        </video>
        <button
          onClick={toggleMute}
          className="absolute bottom-4 right-4 opacity-70 hover:opacity-100 focus:outline-none"
        >
          {isMuted ? <VolumeXIcon size={16} /> : <Volume2Icon size={16} />}
        </button>
      </DialogContent>
    </Dialog>
  );
}
