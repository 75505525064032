import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { GettingStartedOverview } from "./overview";
import { HomePageNav } from "./public-home-nav";

type PublicHomePageProps = {
  action?: "login" | "signup" | "logout";
};

export default function PublicHomePage({ action }: PublicHomePageProps) {
  // TODO: the following are duplicated from login-button and signup-button, will refactor later
  const { loginWithRedirect, logout } = useAuth0();
  const client = useApolloClient();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/overview",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/onboarding/terms",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  const handleLogout = async () => {
    localStorage.clear();
    await client.clearStore();
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  useEffect(() => {
    if (action === "login") handleLogin();
    else if (action === "signup") handleSignUp();
    else if (action === "logout") handleLogout();
  }, [action]); // eslint-disable-line react-hooks/exhaustive-deps

  return action === "login" || action === "signup" ? (
    <div className="fixed left-0 top-0 z-[100] size-full bg-black" />
  ) : (
    <>
      <HomePageNav />
      <GettingStartedOverview />
    </>
  );
}
