import { FolderPlusIcon } from "@heroicons/react/24/outline";
import first from "lodash/first";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { VerticalStepper } from "@/components/ui/VerticalStepper";
import {
  useCreateChannelMutation,
  useCreateWorkMutation,
  useGetProfileQuery,
} from "@/generated-types";
import { resolveConnection } from "@/utils/tag-helpers";
import { useTagEditor } from "../ProfilePage/tags-editor";
import { AddContentFormStep1, FormValueType as ValueTypeStep1 } from "./add-content-step1-form";
import { AddContentFormStep2, FormValueType as ValueTypeStep2 } from "./add-content-step2-form";
import { AddContentFormStep3, FormValueType as ValueTypeStep3 } from "./add-content-step3-form";
import { AddContentFormStep4, FormValueType as ValueTypeStep4 } from "./add-content-step4-form";
import { Undefinedable } from "./types";

type AddContentDialogProps = {
  smallButton?: boolean;
  onComplete?: (type: "channel" | "work", id: string) => void;
};

export function AddContentDialog({ smallButton, onComplete }: AddContentDialogProps) {
  const navigate = useNavigate();

  const [completedStep, setCompletedStep] = useState(0);
  const [activeStep, setActiveStep] = useState(1); // we count from 1 to match with the displayed step number
  const [isOpen, setIsOpen] = useState(false);

  const { data: profileData, loading: loadingProfile } = useGetProfileQuery();
  const [combinedValue, setCombinedValue] = useState<
    Undefinedable<ValueTypeStep1 & ValueTypeStep2 & ValueTypeStep3 & ValueTypeStep4>
  >({});

  const [createChannel, { loading: creatingChannel }] = useCreateChannelMutation({
    refetchQueries: ["GetChannelsByCreator", "GetChannelsByOwner"],
  });
  const [createWork, { loading: creatingWork }] = useCreateWorkMutation({
    refetchQueries: ["GetWorksByCreator", "GetWorksByOwner"],
  });

  const { resolveTags } = useTagEditor();

  useEffect(() => {
    if (!isOpen) {
      setActiveStep(1);
      setCompletedStep(0);
      setCombinedValue({}); // clean up previous entered form values when the dialog closes
    }
  }, [isOpen]);

  // current user's Creator ID or Rights Owner ID
  // One of them should have value
  const userCreatorId = first(profileData?.me?.account?.creators)?.id;
  const userOwnerId = profileData?.me?.account?.owner?.id;

  function stepVariant(sn: number) {
    return sn === activeStep ? "active" : sn <= completedStep ? "completed" : "default";
  }

  function handleComplete(type: "channel" | "work", id: string) {
    navigate(`/library/${type.charAt(0)}$${id}/creators-rights-owners`);
    onComplete && onComplete(type, id);
  }

  function handleBack() {
    activeStep > 1 && setActiveStep(activeStep - 1);
  }

  function handleNext() {
    setCompletedStep(activeStep);
    setActiveStep(activeStep + 1);
  }

  async function handleCreateContent(data: typeof combinedValue) {
    const {
      typeOfContent,
      name,
      description,
      canonicalUrl,
      typeTag,
      secondaryTypeTag,
      tertiaryTypeTag,
      categories = [],
      contentBadges = [],
      keywords = [],
      media = [],
    } = data;

    const typeTagIds: string[] = [typeTag!]; // typeTag can't be undefined if the form passed validation
    if (secondaryTypeTag) typeTagIds.push(secondaryTypeTag);
    if (tertiaryTypeTag) typeTagIds.push(tertiaryTypeTag);

    if (typeOfContent === "channel") {
      const result = await createChannel({
        variables: {
          input: {
            channelUrl: canonicalUrl,
            channelName: name,
            description,
            channelTypes: resolveConnection(typeTagIds),
            categories: resolveConnection(categories),
            contentBadges: resolveConnection(contentBadges),
            tags: resolveTags(keywords),
            media: resolveConnection(media),
            creators: resolveConnection([{ id: userCreatorId }]),
            owners: resolveConnection([{ id: userOwnerId }]),
            accounts: { connect: [{ where: { node: { id: profileData?.me?.account?.id! } } }] },
          },
        },
      });

      const newChannelId = first(result.data?.createChannels.channels)?.id!;
      handleComplete("channel", newChannelId);
    } else {
      const result = await createWork({
        variables: {
          input: {
            workUrl: canonicalUrl,
            title: name,
            description,
            workTypes: resolveConnection(typeTagIds),
            categories: resolveConnection(categories),
            contentBadges: resolveConnection(contentBadges),
            tags: resolveTags(keywords),
            media: resolveConnection(media),
            creators: resolveConnection([{ id: userCreatorId }]),
            owners: resolveConnection([{ id: userOwnerId }]),
            accounts: { connect: [{ where: { node: { id: profileData?.me?.account?.id! } } }] },
          },
        },
      });

      const newWorkId = first(result.data?.createWorks.works)?.id!;
      handleComplete("work", newWorkId);
    }
    setIsOpen(false);
  }

  return (
    // <h1 className="mb-8 text-3xl font-bold tracking-tight">Add Creation</h1>

    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        {smallButton ? (
          <Button size="sm" variant="default" className="flex h-[30px] gap-1.5 text-xs font-normal">
            <FolderPlusIcon className="size-4" /> Add Creation
          </Button>
        ) : (
          <Button className="button mt-6 flex gap-2">
            <FolderPlusIcon className="size-5 dark:text-gray-400" aria-hidden="true" /> Add Creation
          </Button>
        )}
      </DialogTrigger>
      <DialogContent
        className="max-w-full sm:max-w-2xl sm:p-8 lg:max-w-3xl"
        onInteractOutside={(event) => event?.preventDefault()}
        // prevent the dialog being closed when the user clicks outside
      >
        <DialogHeader>
          <DialogTitle className="mb-1 text-2xl">Add Creation</DialogTitle>
          <DialogDescription>
            Enter information about your creation. If you decide to license your creation for AI
            training, having accurate information will likely make it stand out in discovery, and
            improve its relevancy.
          </DialogDescription>
        </DialogHeader>
        {/* <MetadataForm profile={profileData?.me} creating onComplete={handleComplete} /> */}

        {/* stepper component */}
        <VerticalStepper>
          <VerticalStepper.Step
            variant={stepVariant(1)}
            stepNumber="1"
            label="Channel or Work"
            firstStep
          >
            <AddContentFormStep1
              onNext={(value) => {
                setCombinedValue(value);
                handleNext();
              }}
            />
          </VerticalStepper.Step>

          <VerticalStepper.Step
            variant={stepVariant(2)}
            stepNumber="2"
            label="Name, Description, and Keywords"
          >
            <AddContentFormStep2
              value={combinedValue}
              onBack={handleBack}
              onNext={(value) => {
                setCombinedValue({ ...combinedValue, ...value });
                handleNext();
              }}
            />
          </VerticalStepper.Step>

          <VerticalStepper.Step variant={stepVariant(3)} stepNumber="3" label="Type and Categories">
            <AddContentFormStep3
              value={combinedValue}
              onBack={handleBack}
              onNext={(value) => {
                setCombinedValue({ ...combinedValue, ...value });
                handleNext();
              }}
            />
          </VerticalStepper.Step>

          <VerticalStepper.Step
            variant={stepVariant(4)}
            stepNumber="4"
            label="Creative Origin"
            lastStep
          >
            <AddContentFormStep4
              value={combinedValue}
              onBack={handleBack}
              onNext={(value) => {
                handleCreateContent({ ...combinedValue, ...value });
              }}
              loading={creatingChannel || creatingWork}
            />
          </VerticalStepper.Step>
        </VerticalStepper>
      </DialogContent>
    </Dialog>
  );
}
