import first from "lodash/first";
import sortBy from "lodash/sortBy";

import {
  GetProfileQuery,
  useGetChannelsByCreatorQuery,
  useGetChannelsByOwnerQuery,
  useGetWorksByCreatorQuery,
  useGetWorksByOwnerQuery,
} from "@/generated-types";
import { Link } from "react-router-dom";

import ContentListItem from "@/components/lists/content-item";

type RecentContentProps = {
  profile: GetProfileQuery["me"];
  limit: number;
};

export function RecentContent({ profile, limit }: RecentContentProps) {
  const userCreatorId = first(profile?.account?.creators)?.id;
  const userOwnerId = profile?.account?.owner?.id;

  const { data: creatorChannels, loading: loadingCreatorChannels } = useGetChannelsByCreatorQuery({
    variables: { creatorId: userCreatorId! },
    skip: !userCreatorId,
  });

  const { data: creatorWorks, loading: loadingCreatorWorks } = useGetWorksByCreatorQuery({
    variables: { creatorId: userCreatorId! },
    skip: !userCreatorId,
  });

  const { data: ownerChannels, loading: loadingOwnerChannels } = useGetChannelsByOwnerQuery({
    variables: { ownerId: userOwnerId! },
    skip: !userOwnerId,
  });

  const { data: ownerWorks, loading: loadingOwnerWorks } = useGetWorksByOwnerQuery({
    variables: { ownerId: userOwnerId! },
    skip: !userOwnerId,
  });

  const channels = creatorChannels?.channels || ownerChannels?.channels || [];
  const works = creatorWorks?.works || ownerWorks?.works || [];
  const hasContent = channels.length > 0 || works.length > 0;

  if (!hasContent) return null;

  const mergedChannels = channels.map((c) => ({
    kind: "channel",
    item: c,
    createdAt: c.createdAt,
  }));
  const mergedWorks = works.map((w) => ({ kind: "work", item: w, createdAt: w.createdAt }));
  const mergedList = [...mergedChannels, ...mergedWorks];
  const sortedList = sortBy(mergedList, "createdAt").reverse().slice(0, limit);

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <h1 className="grow text-2xl font-semibold tracking-tight text-gray-900">
          New to your Creative Library
        </h1>
      </div>

      <div className="w-full overflow-hidden rounded-md bg-primary/20 p-4">
        <ul className="flex flex-col gap-2">
          {sortedList.map((item) =>
            item.kind === "channel" ? (
              <ContentListItem
                key={item.item.id}
                channel={item.item}
                types={item.item.__typename === "Channel" ? item.item.channelTypes : []}
                categories={item.item.categories}
                media={item.item.media}
              />
            ) : (
              <ContentListItem
                key={item.item.id}
                work={item.item}
                types={item.item.__typename === "Work" ? item.item.workTypes : []}
                categories={item.item.categories}
                media={item.item.media}
              />
            )
          )}
        </ul>
      </div>
      {mergedList.length > limit && (
        <div>
          <Link to="/library">{mergedList.length - limit} More</Link>
        </div>
      )}
    </div>
  );
}
