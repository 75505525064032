import first from "lodash/first";
import { useNavigate, Link, useParams } from "react-router-dom";

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { FolderPlusIcon } from "lucide-react";
import upperFirst from "lodash/upperFirst";

import { ContentPageParams } from "@/app-routes";
import { PageLoader } from "@/components/page-loader";
import { useGetChannelByIdQuery, useGetProfileQuery, useGetWorkByIdQuery } from "@/generated-types";
import { LicensingForm } from "./content-licensing-form";
import { MetadataForm } from "./content-metadata-form";
import { StakeholderForm } from "./content-stakeholder-form";
import { MediaGrid } from "./content-media-grid";

type ContentPageProps = {
  tab: "basic-info" | "creators-rights-owners" | "licensing" | "editions" | "related" | "media";
};

export default function ContentPage({ tab }: ContentPageProps) {
  const { data: profileData, loading: loadingProfile } = useGetProfileQuery();
  const { contentId } = useParams<ContentPageParams>();
  const navigate = useNavigate();

  const handleTabChange = (value: string) => {
    navigate(`../${value}`);
  };

  const typeOfContent = contentId?.charAt(0) === "c" ? "channel" : "work";
  const entityId = contentId?.substring(2);

  const { data: channelData, loading: loadingChannel } = useGetChannelByIdQuery({
    variables: { channelId: entityId! },
    skip: typeOfContent !== "channel" || !entityId,
  });

  const { data: workData, loading: loadingWork } = useGetWorkByIdQuery({
    variables: { workId: entityId! },
    skip: typeOfContent !== "work" || !entityId,
  });

  const title = first(channelData?.channels)?.channelName || first(workData?.works)?.title;

  return (
    <div className="max-w-4xl">
      <PageLoader overlay loading={loadingProfile || loadingChannel || loadingWork} />

      <div className="mb-8 flex items-end gap-4">
        <h1 className="grow text-3xl font-normal tracking-tight">
          <span className="font-bold capitalize">{typeOfContent}: </span>
          {title}
        </h1>
        <Link to={`/library/add-similar/${contentId}`}>
          <Button className="flex gap-2" variant="outline" size="sm">
            <FolderPlusIcon className="size-4" aria-hidden="true" /> Add Similar
          </Button>
        </Link>
      </div>

      <Tabs onValueChange={handleTabChange} value={tab} className="">
        <TabsList className="w-full gap-4">
          <TabsTrigger value="basic-info">Basic Creation Info</TabsTrigger>
          <TabsTrigger value="creators-rights-owners">Creators & Rights Owners</TabsTrigger>
          <TabsTrigger value="media">Thumbnails</TabsTrigger>
          <TabsTrigger value="licensing">Licensing</TabsTrigger>
          {/* <TabsTrigger disabled value="related">
            Similar/Related
          </TabsTrigger> */}
          {/* <TabsTrigger disabled value="performance">
            Performance
          </TabsTrigger> */}
        </TabsList>
        <TabsContent value="basic-info">
          <Card className="bg-muted">
            <CardHeader>
              <CardTitle>Basic Creation Info</CardTitle>
              <CardDescription>
                Enter information about your creation here. If you decide to license your creation
                for AI training, having accurate information will likely make it stand out in
                discovery, and improve its relevancy.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <MetadataForm
                profile={profileData?.me}
                channel={first(channelData?.channels)}
                work={first(workData?.works)}
              />
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="creators-rights-owners">
          <Card>
            <CardHeader>
              <CardTitle>Creators & Rights Owners</CardTitle>
              <CardDescription>
                Tell us and the potential licensees who created this {typeOfContent}, and who owns
                it.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <StakeholderForm
                typeOfContent={typeOfContent}
                profile={profileData?.me}
                channel={first(channelData?.channels)}
                work={first(workData?.works)}
              />
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="licensing">
          <Card className="bg-muted">
            <CardHeader>
              <CardTitle>Licensing</CardTitle>
              <CardDescription>
                How would you like to license this {typeOfContent} to AI company for training
                purposes?
              </CardDescription>
            </CardHeader>
            <CardContent>
              <LicensingForm
                typeOfContent={typeOfContent}
                profile={profileData?.me}
                channel={first(channelData?.channels)}
                work={first(workData?.works)}
              />
              <CardDescription className="mt-8">
                At this time, all Credtent registered content is being opted out of AI training to
                protect your work. Our Standard and Premium License options will be available later
                this year. We will notify you for setup when those options are ready.
              </CardDescription>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="media">
          <Card className="bg-muted">
            <CardHeader>
              <CardTitle>Photos & Screenshots</CardTitle>
              {/* <CardDescription>
                How would you like to license this {typeOfContent} to AI company for training
                purposes?
              </CardDescription> */}
            </CardHeader>
            <CardContent>
              <MediaGrid
                typeOfContent={typeOfContent}
                content={first(channelData?.channels) || first(workData?.works)}
                media={first(channelData?.channels)?.media || first(workData?.works)?.media}
              />
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}
