import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { FormActions } from "@/components/forms/form-utils";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { SiteMeta, UrlAnalyzer } from "./url-analyzer";

const FormSchema = z
  .object({
    typeOfContent: z.enum(["channel", "work"]),
    hasCanonicalUrl: z.boolean(),
    canonicalUrl: z.string().url("Invalid web address").or(z.literal("")),
    name: z.string().optional(),
    description: z.string().optional(),
    keywords: z.array(z.string()),
    media: z
      .array(
        z.object({
          id: z.string(),
        })
      )
      .optional(),
  })
  .refine(
    // if hasCanonicalURl is true, then the user must enter a non empty url
    (data) => {
      if (data.hasCanonicalUrl && !data.canonicalUrl) return false;
      else return true;
    },
    { message: "Web address is required", path: ["canonicalUrl"] }
  );

export type FormValueType = z.infer<typeof FormSchema>;

type AddContentStepFormProps = {
  onNext: (value: FormValueType) => void;
  onBack?: () => void;
};

export function AddContentFormStep1({ onBack, onNext }: AddContentStepFormProps) {
  const form = useForm<FormValueType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      typeOfContent: "channel",
      hasCanonicalUrl: true,
      canonicalUrl: "",
      keywords: [],
    },
    mode: "onChange",
  });

  const {
    formState: { isValid, errors },
    setValue,
    watch,
  } = form;

  const { typeOfContent, hasCanonicalUrl, canonicalUrl } = watch();

  useEffect(() => {
    // if cannonicalUrl doesn't start with http:// or https://, append https://
    if (
      canonicalUrl &&
      !canonicalUrl.startsWith("http://") &&
      !canonicalUrl.startsWith("https://") &&
      !canonicalUrl.match(/^h?t?t?p?s?:?\/?\/?$/) // the user is trying to type h t t p s : / /, don't interrupt, lol
    ) {
      setValue("canonicalUrl", "https://" + canonicalUrl, { shouldValidate: true });
    }
  }, [canonicalUrl, setValue]);

  const showHasUrlField = typeOfContent === "work";
  const showUrlField = typeOfContent === "channel" || hasCanonicalUrl;

  function onSubmit(data: FormValueType) {
    onNext(data);
  }

  function onUseUrlAnalyzerResult(siteMeta: SiteMeta) {
    const { name, description, keywords, media } = siteMeta;

    if (name !== "") setValue("name", name);
    if (description !== "") setValue("description", description);
    const newKeywords = keywords
      .split(",")
      .map((kw) => kw.trim().toLowerCase().replace(/\s+/g, "_"))
      .filter((kw) => kw.length > 0);
    if (newKeywords.length > 0) setValue("keywords", newKeywords);
    if (media.length > 0) setValue("media", media);

    // proceed to the next step
    form.handleSubmit(onSubmit)();
  }

  function onIgnoreUrlAnalyzerResult() {
    // proceed to the next step
    form.handleSubmit(onSubmit)();
  }

  // console.log("add content step 1", {
  //   errors,
  //   values: watch(),
  //   isValid,
  // });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4 space-y-8">
        {/* type of content */}
        <FormField
          control={form.control}
          name="typeOfContent"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>This creation is a:</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="channel" />
                    </FormControl>
                    <FormLabel className="flex-1 font-normal leading-tight">
                      <span className="font-semibold">Channel</span> (e.g. blog, podcast, or any
                      other medium which you contribute new material into indefinitely)
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="work" />
                    </FormControl>
                    <FormLabel className="flex-1 font-normal leading-tight">
                      <span className="font-semibold">Work</span> (e.g. a book, a painting, a film,
                      or any other format that you can "complete")
                    </FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* has url */}
        {showHasUrlField && (
          <FormField
            control={form.control}
            name="hasCanonicalUrl"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>This work has a canonical website</FormLabel>
                </div>
              </FormItem>
            )}
          />
        )}

        {/* url */}
        {showUrlField && (
          <FormField
            control={form.control}
            name="canonicalUrl"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Web address of this {typeOfContent}?</FormLabel>
                <FormControl>
                  <div className="flex w-full items-center space-x-4">
                    <Input {...field} placeholder="https:// or http://" autoComplete="no" />
                    <UrlAnalyzer
                      url={canonicalUrl}
                      typeOfContent={typeOfContent}
                      onUse={onUseUrlAnalyzerResult}
                      onCancel={onIgnoreUrlAnalyzerResult}
                    />
                  </div>
                </FormControl>
                <FormDescription>
                  Enter the canonical web address of your {typeOfContent}, then press Analyze, we’ll
                  try to fill in the rest of the information for you.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <FormActions disabled={!isValid} onBack={onBack} className="" />
      </form>
    </Form>
  );
}
