import { zodResolver } from "@hookform/resolvers/zod";
import { EllipsisIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-photo-view/dist/react-photo-view.css";
import { z } from "zod";

import { FormActions } from "@/components/forms/form-utils";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Textarea } from "@/components/ui/textarea";
import { UserMedia } from "@/generated-types";

type MediaItem = Pick<UserMedia, "id" | "extension" | "sourceType" | "caption">;

type MediaFormProps = {
  typeOfContent: "channel" | "work";
  item: MediaItem;
  asThumbnail: boolean;
  onDelete: (id: string) => Promise<void> | void;
  onSetThumbnail: (id: string | undefined) => Promise<void> | void;
  onUpdate: (values: MediaItem) => Promise<void> | void;
};

export function MediaForm({
  typeOfContent,
  item,
  asThumbnail,
  onDelete,
  onSetThumbnail,
  onUpdate,
}: MediaFormProps) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const FormSchema = z.object({
    caption: z.string().optional(),
    asThumbnail: z.boolean(),
  });

  type FormValueType = z.infer<typeof FormSchema>;

  const form = useForm<FormValueType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      caption: "",
      asThumbnail: true,
    },
    mode: "onChange",
  });

  const {
    formState: { isValid, isDirty, errors },
    reset,
  } = form;

  useEffect(() => {
    reset({
      caption: item.caption || "",
      asThumbnail,
    });
  }, [item, asThumbnail, reset]);

  async function onSubmit(data: FormValueType) {
    console.log(data);
    setLoading(true);
    if (data.caption !== item.caption)
      await onUpdate({
        id: item.id,
        caption: data.caption,
      });
    if (data.asThumbnail && !asThumbnail) await onSetThumbnail(item.id);
    else await onSetThumbnail(undefined);
    setLoading(false);
    setOpen(false);
  }

  function handleDelete() {
    console.log("delete");
    onDelete(item.id);
    setOpen(false);
  }

  return (
    <Popover open={isOpen} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          size="icon"
          variant="outline"
          className="absolute bottom-2 right-2 size-8 rounded-full"
        >
          <EllipsisIcon size={16} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="max-w-full md:w-[30rem]">
        <Form {...form}>
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Image Details</h4>
          </div>
          <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4 space-y-4">
            {/* caption */}
            <FormField
              control={form.control}
              name="caption"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Caption</FormLabel>
                  <FormControl>
                    <Textarea {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="asThumbnail"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      disabled={item.extension === "txt"}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Default thumbnail</FormLabel>
                  </div>
                </FormItem>
              )}
            />

            <FormActions
              onDelete={handleDelete}
              disabled={!isValid || !isDirty}
              nextLabel="Save"
              loading={loading}
              className=""
            />
          </form>
        </Form>
      </PopoverContent>
    </Popover>
  );
}
