import { FolderPlusIcon, LinkIcon, BlocksIcon, TagsIcon } from "lucide-react";
import { FC } from "react";
import { Link } from "react-router-dom";

import { Channel, UserMedia, Work } from "@/generated-types";
import { Button } from "../ui/button";
import { CategoryIconWithLabel } from "./category-icon";
import { ContentAvatar } from "./content-avatar";

type MediaItem = Pick<UserMedia, "id" | "extension" | "sourceType">;

type ContentListItemProps = {
  channel?: Pick<Channel, "id" | "channelName" | "description" | "channelUrl"> & {
    thumbnail?: Pick<UserMedia, "id" | "extension" | "sourceType"> | null;
  };
  work?: Pick<Work, "id" | "title" | "description" | "workUrl"> & {
    thumbnail?: Pick<UserMedia, "id" | "extension" | "sourceType"> | null;
  };
  types: { title?: string | null | undefined }[];
  categories: {
    id: string;
    title?: string | null | undefined;
    icon?: string | null | undefined;
  }[];
  media: MediaItem[] | undefined;
};

const ContentListItem: FC<ContentListItemProps> = ({ channel, work, types, categories, media }) => {
  const itemId = channel ? "c$" + channel.id : work ? "w$" + work.id : "";
  const typesText = types?.map((type) => type.title).join(", ");
  const categoriesText = categories?.map((category) => category.title).join(", ");
  const title = channel
    ? channel.channelName || channel.description
    : work
      ? work.title || work.description
      : "(no title)";
  const subtitle = channel
    ? channel.channelName
      ? channel.description
      : undefined
    : work
      ? work.title
        ? work.description
        : undefined
      : undefined;
  const url = channel?.channelUrl || work?.workUrl;

  return (
    <li key={itemId}>
      <div className="w-full lg:flex">
        {/* don't combine rounded-tl and rounded-tr, it'll cause problem */}
        {/* eslint-disable-next-line */}
        <div className="flex h-48 flex-col items-center overflow-hidden rounded-tl rounded-tr border bg-white lg:h-auto lg:w-48 lg:min-w-48 lg:rounded-bl lg:rounded-tr-none">
          <ContentAvatar channel={channel} work={work} media={media} />
          <Link to={`/library/add-similar/${itemId}`}>
            <Button variant="ghost" size="sm" className="mb-2 flex gap-2 text-sm">
              <FolderPlusIcon className="size-4" aria-hidden="true" /> Add Similar
            </Button>
          </Link>
        </div>
        <div className="flex w-full flex-col rounded-b border-x border-b bg-white p-4 leading-normal lg:rounded-b-none lg:rounded-r lg:border-l-0 lg:border-t">
          {/* name */}
          <div className="mb-0 text-xl font-bold text-black">
            <Link to={`/library/${itemId}/basic-info`}>{title}</Link>
          </div>

          {/* description, if any */}
          {subtitle ? <p className="text-base">{subtitle}</p> : null}

          {/* category icons */}
          {categories && categories.length > 0 && (
            <div className="mb-2 mt-4 flex flex-row flex-wrap gap-x-0.5 gap-y-2">
              {categories.map((cat) => (
                <CategoryIconWithLabel key={cat.id} cat={cat} className="w-20" />
              ))}
            </div>
          )}

          {/* content types */}
          {typesText && (
            <div className="mt-2 flex items-center gap-1 text-sm">
              <BlocksIcon className="-ml-1 h-4" /> {typesText}
            </div>
          )}

          {/* categories in text */}
          {categories && categories.length > 0 && (
            <div className="mt-2 flex items-center gap-1 text-sm">
              {/* lock icon */}
              {/* <div className="inline-block">
                <svg className="mr-2 size-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
                </svg>
              </div> */}
              <TagsIcon className="-ml-1 h-4" />
              {categoriesText}
            </div>
          )}

          {/* channel address */}
          {url && (
            <div className="mt-2 flex items-center gap-1 text-sm">
              <LinkIcon className="-ml-1 h-4" />
              <a target="_blank" href={url || "#"} rel="noreferrer" className="hover:underline">
                {url}
              </a>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default ContentListItem;
