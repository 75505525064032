import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import React from "react";
import { HomePageNav } from "./public-home-nav";

type FaqItem = {
  question: string;
  answer: string | React.ReactNode;
};

const faqs: FaqItem[] = [
  {
    question: "What is Credtent.org?",
    answer:
      "Credtent protects creators by empowering them to opt-out of AI training while also enabling AI tools to do a better job of ethically sourcing credible content so they do not spread disinformation at enormous scale through their apps.",
  },
  {
    question: "How does Credtent.org help creators, artists, and makers in the age of AI?",
    answer: (
      <>
        <p>
          Credtent enables creators to notify the AI industry of their wishes regarding their
          content in one step. They make one simple choice: Opt Out (from AI training) or Pay a
          License Fee (for my work). AI Tools are notified by Credtent and pay for ongoing access to
          our API so they can prevent use of content that isn't ethically sourced and to prevent
          users from referencing work that the creator has restricted.
        </p>
        <p className="mt-4">
          Credtent effectively provides a crowdsourced, single guardrails list for all of the AI
          tools to use rather than each one of them trying to patchwork together something. When AI
          tools like Large Language Models play by the rules, they can use our Ethical Sourcing
          Badges. Our tools will also monitor and audit these tools to ensure they do not allow the
          guardrails to be broken over time.
        </p>
      </>
    ),
  },
  {
    question: "How does Credtent solve problem for AI tools and Large Language Models?",
    answer:
      "Credtent provides a solution to two emerging problems in AI development: compensating content creators when their works are used to train AI systems, and ensuring models are built responsibly through ethical, accurate, and impartial content. Creators can stop suing companies and AI tools can stop getting sued because they use content they should not.",
  },
];

export default function FAQPage() {
  return (
    <>
      <HomePageNav />
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-32">
          <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
            <div className="max-w-7xl sm:px-6 lg:px-8">
              <h2 className="text-xl font-extrabold text-gray-900 dark:text-white md:text-2xl lg:text-3xl">
                Frequently Asked <span className="text-purple-500">Questions</span>
              </h2>
            </div>
            <dl className="mt-16 space-y-6 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <Disclosure key={faq.question} as="div" className="pt-6">
                  <dt>
                    <DisclosureButton className="group flex w-full items-start justify-between bg-white text-left text-gray-900">
                      <span className="text-base font-semibold leading-7">{faq.question}</span>
                      <span className="ml-6 flex h-7 items-center">
                        <PlusIcon aria-hidden="true" className="size-5 group-data-[open]:hidden" />
                        <MinusIcon
                          aria-hidden="true"
                          className="size-5 [.group:not([data-open])_&]:hidden"
                        />
                      </span>
                    </DisclosureButton>
                  </dt>
                  <DisclosurePanel as="dd" className="mt-2 pr-12">
                    <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                  </DisclosurePanel>
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
