import { FormActions } from "@/components/forms/form-utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { CertificationType, ContentBadgeType } from "@/generated-types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import type { QuickAddContentStepFormProps } from "./quick-add-content-dialog";
import { CONTENT_BADGES, SelectBadge } from "./select-badge";

export const FormSchema = z.object({
  contentBadges: z.array(z.string()).min(1, { message: "Select at least one badge" }),
});

export type FormValueType = z.infer<typeof FormSchema>;

export function QuickAddContentFormStep2({
  value,
  onBack,
  onNext,
  addNew,
}: QuickAddContentStepFormProps) {
  const form = useForm<FormValueType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      contentBadges: [],
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (addNew)
      form.reset({
        contentBadges: [],
      });
  }, [addNew, form]);

  function onSubmit(data: FormValueType) {
    onNext && onNext(data);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4 space-y-8">
        {/* content badges */}
        <FormField
          control={form.control}
          name="contentBadges"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                How do you best describe the origin(s) of this {value.typeOfContent}? *
              </FormLabel>
              <FormControl>
                <SelectBadge
                  options={CONTENT_BADGES.filter(
                    (item) =>
                      item.certification === CertificationType.Uncertified &&
                      item.type === ContentBadgeType.Origin
                  )}
                  multi
                  value={field.value}
                  onChange={field.onChange}
                  formMessage={<FormMessage />}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormActions disabled={!form.formState.isValid} onBack={onBack} className="" />
      </form>
    </Form>
  );
}
