import tw from "tailwind-styled-components";
import { DateTime } from "luxon";

import { useSystemStatusQuery } from "@/generated-types";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export default function StatusPage() {
  const { data } = useSystemStatusQuery();

  const values = {
    frontend: {
      buildTime: process.env.REACT_APP_BUILD_TIME
        ? DateTime.fromISO(process.env.REACT_APP_BUILD_TIME)
        : undefined,
      hash: process.env.REACT_APP_COMMIT_HASH,
    },
    backend: {
      buildTime: data?.systemStatus?.apolloGraphql.buildTime
        ? DateTime.fromISO(data?.systemStatus?.apolloGraphql.buildTime)
        : undefined,
      hash: data?.systemStatus?.apolloGraphql.commitHash,
    },
  };

  return (
    <main className="container space-y-8 py-8">
      <h1 className="text-2xl font-bold">System Status</h1>
      <div className="flex w-full gap-4">
        {Object.entries(values).map(([key, status]) => (
          <Card className="min-w-80" key={key}>
            <Title>{key}</Title>
            <CardContent className="flex gap-4 p-4">
              <div className="grid items-center gap-4">
                <DataPoint>
                  <Label>Build date/time</Label>
                  <Value>
                    {status.buildTime && status.buildTime.isValid
                      ? status.buildTime.toLocaleString(DateTime.DATETIME_SHORT)
                      : "N/A"}
                  </Value>
                </DataPoint>
                <DataPoint>
                  <Label>Commit hash</Label>
                  <Value>{status.hash}</Value>
                </DataPoint>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </main>
  );
}

const Title = tw.div`pt-4 px-4 font-bold text-sm capitalize`;
const DataPoint = tw.div`grid flex-1 auto-rows-min gap-0.5`;
const Label = tw.div`text-sm text-muted-foreground`;
const Value = tw.div`flex items-baseline gap-1 text-xl font-semibold leading-none`;
const Unit = tw.span`text-sm font-normal text-muted-foreground`;
