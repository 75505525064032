import {
  BuildingLibraryIcon,
  CommandLineIcon,
  GlobeEuropeAfricaIcon,
  NewspaperIcon,
  UserIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { cn } from "@/utils";
import { Link } from "react-router-dom";
import { Button } from "../ui/button";
import { useLocation } from "react-router-dom";

import { FeedbackDialog } from "./feedback-form";

export const SideBar: React.FC = () => {
  const location = useLocation();

  const mainItems = [
    {
      icon: CommandLineIcon,
      label: "Overview",
      route: "/overview",
    },
    {
      icon: BuildingLibraryIcon,
      label: "Creative Library",
      route: "/library",
    },
    {
      icon: UserIcon,
      label: "Profile",
      route: "/profile",
    },
  ];

  const extraItems = [
    {
      icon: NewspaperIcon,
      label: "Blog",
      route: "https://medium.com/credtent-on-content",
    },
    {
      icon: GlobeEuropeAfricaIcon,
      label: "Community",
      route: "https://app.heartbeat.chat/credtent/",
    },
  ];

  return (
    <div
      id="app-sidebar"
      className="sticky mt-16 hidden h-full w-[250px] flex-col justify-between gap-y-5 bg-white p-6 ring-1 ring-gray-900/10 dark:bg-gray-900 dark:ring-gray-700 sm:flex"
    >
      <nav className="flex-1">
        <ul className="flex flex-col gap-y-2">
          {mainItems.map(({ icon: Icon, label, route }) => {
            const isActive = location.pathname.startsWith(route);
            return (
              <li key={route}>
                <Link
                  to={route}
                  className={cn(
                    "flex flex-row items-center gap-2 rounded-lg p-2 text-gray-700 dark:text-gray-400",
                    { "bg-primary/5": isActive }
                  )}
                >
                  <Icon className="size-5 dark:text-gray-400" aria-hidden="true" />
                  {label}
                </Link>
              </li>
            );
          })}
          {/* <li><a className="flex flex-row items-center gap-2 text-gray-700 dark:text-gray-400" href="#"><CreditCardIcon className="h-5 w-5 dark:text-gray-400" aria-hidden="true" /> Balance</a></li> */}
        </ul>
      </nav>

      <div className="-mx-6 my-3 border-t border-gray-900/10 dark:border-gray-400" />

      <nav>
        <ul className="flex flex-col gap-y-7">
          {extraItems.map(({ icon: Icon, label, route }) => (
            <li key={route}>
              <Link
                to={route}
                target="_blank"
                className="flex flex-row items-center gap-2 text-gray-700 dark:text-gray-400"
              >
                <Icon className="size-5 dark:text-gray-400" aria-hidden="true" />
                {label}
              </Link>
            </li>
          ))}
          <li>
            <FeedbackDialog
              trigger={
                <div className="flex cursor-pointer flex-row items-center gap-2 text-gray-700 dark:text-gray-400">
                  <ChatBubbleBottomCenterTextIcon
                    className="size-5 dark:text-gray-400"
                    aria-hidden="true"
                  />
                  Feedback
                </div>
              }
            />
          </li>
        </ul>
      </nav>
    </div>
  );
};
