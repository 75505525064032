import first from "lodash/first";
import sortBy from "lodash/sortBy";
import { useEffect, useState, ReactNode } from "react";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  GetChannelsByCreatorQuery,
  GetProfileQuery,
  GetWorksByCreatorQuery,
  useGetChannelsByCreatorQuery,
  useGetChannelsByOwnerQuery,
  useGetWorksByCreatorQuery,
  useGetWorksByOwnerQuery,
} from "@/generated-types";
import { AddContentDialog } from "./add-content-dialog";
import { QuickAddContentDialog } from "./quick-add-content-dialog";

import ContentListItem from "@/components/lists/content-item";

type ContentLibraryProps = {
  profile: GetProfileQuery["me"];
  showHeader?: boolean;
  emptyView?: ReactNode;
};

export function ContentLibrary({ profile, showHeader, emptyView }: ContentLibraryProps) {
  const { currentSortKey, sortChannels, sortWorks, onSortChange } = useSort();
  const userCreatorId = first(profile?.account?.creators)?.id;
  const userOwnerId = profile?.account?.owner?.id;

  const { data: creatorChannels, loading: loadingCreatorChannels } = useGetChannelsByCreatorQuery({
    variables: { creatorId: userCreatorId! },
    skip: !userCreatorId,
  });

  const { data: creatorWorks, loading: loadingCreatorWorks } = useGetWorksByCreatorQuery({
    variables: { creatorId: userCreatorId! },
    skip: !userCreatorId,
  });

  const { data: ownerChannels, loading: loadingOwnerChannels } = useGetChannelsByOwnerQuery({
    variables: { ownerId: userOwnerId! },
    skip: !userOwnerId,
  });

  const { data: ownerWorks, loading: loadingOwnerWorks } = useGetWorksByOwnerQuery({
    variables: { ownerId: userOwnerId! },
    skip: !userOwnerId,
  });

  const channels = creatorChannels?.channels || ownerChannels?.channels || [];
  const works = creatorWorks?.works || ownerWorks?.works || [];
  const hasContent = channels.length > 0 || works.length > 0;

  // if (loadingCreatorChannels || loadingCreatorWorks || loadingOwnerChannels || loadingOwnerWorks)
  //   return <PageLoader overlay />;

  if (emptyView && !hasContent) return emptyView;
  else
    return (
      <div className="space-y-4">
        {(hasContent || showHeader) && (
          <div className="flex items-center gap-2">
            <h1 className="grow text-2xl font-bold tracking-tight text-gray-900">
              Creative Library
            </h1>
            <SortingSelector
              options={CHANNEL_SORT_OPTIONS}
              value={currentSortKey}
              onChange={onSortChange}
            />
            <QuickAddContentDialog smallButton />
            <AddContentDialog smallButton />
          </div>
        )}

        {hasContent && (
          <div className="w-full space-y-8 overflow-hidden rounded-md bg-primary/20 p-4">
            {channels.length > 0 && (
              <div>
                <h2 className="mb-2 text-lg font-bold">Channels</h2>
                <ul className="flex flex-col gap-2">
                  {sortChannels(channels).map((item) => (
                    <ContentListItem
                      key={item.id}
                      channel={item}
                      types={item.channelTypes}
                      categories={item.categories}
                      media={item.media}
                    />
                  ))}
                </ul>
              </div>
            )}

            {works.length > 0 && (
              <div>
                <h2 className="mb-2 text-lg font-bold">Works</h2>
                <ul className="flex flex-col gap-2">
                  {sortWorks(works).map((item) => (
                    <ContentListItem
                      key={item.id}
                      work={item}
                      types={item.workTypes}
                      categories={item.categories}
                      media={item.media}
                    />
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    );
}

const CHANNEL_SORT_OPTIONS = {
  timeUp: "Time added (oldest first",
  timeDn: "Time added (newest first)",
  titleUp: "Title (A-Z)",
  titleDn: "Title (Z-A)",
};

type SortingSelectorProps = {
  options: Record<string, string>;
  value: string;
  onChange: (key: string) => void;
};

function SortingSelector({ options, value, onChange }: SortingSelectorProps) {
  const optionList = Object.entries(options);

  return (
    <Select onValueChange={onChange} value={value}>
      <SelectTrigger className="h-8 w-60 text-sm outline-none">
        <SelectValue placeholder="Sort" />
      </SelectTrigger>
      <SelectContent>
        {optionList.map(([key, value]) => (
          <SelectItem key={key} value={key}>
            {value}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

function useSort() {
  const [sortKey, setSortKey] = useState("timeUp");

  useEffect(() => {
    const key = localStorage.getItem("content.sort");
    if (key) setSortKey(key);
  }, []);

  function sortChannels(channels: GetChannelsByCreatorQuery["channels"]) {
    switch (sortKey) {
      case "timeUp":
        return sortBy(channels, "createdAt");
      case "timeDn":
        return sortBy(channels, "createdAt").reverse();
      case "titleUp":
        return sortBy(channels, "channelName");
      case "titleDn":
        return sortBy(channels, "channelName").reverse();
      default:
        return channels;
    }
  }

  function sortWorks(works: GetWorksByCreatorQuery["works"]) {
    switch (sortKey) {
      case "timeUp":
        return sortBy(works, "createdAt");
      case "timeDn":
        return sortBy(works, "createdAt").reverse();
      case "titleUp":
        return sortBy(works, "title");
      case "titleDn":
        return sortBy(works, "title").reverse();
      default:
        return works;
    }
  }

  function onSortChange(key: string) {
    setSortKey(key);
    localStorage.setItem("content.sort", key);
  }

  return {
    currentSortKey: sortKey,
    onSortChange,
    sortChannels,
    sortWorks,
  };
}
