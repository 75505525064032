import { Spinner } from "@/components/page-loader";
import { cn } from "@/utils";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import React from "react";

interface DashboardHeadProps {
  user: {
    email: string;
    email_verified: boolean;
    name: string | undefined | null;
    nickname: string;
    picture: string;
    sub: string;
    userType: string;
    updated_at: string;
  };
  channelCount: number;
  workCount: number;
  className?: string;
  loading: boolean;
}

const DashboardHead: React.FC<DashboardHeadProps> = ({
  user,
  channelCount,
  workCount,
  className,
  loading,
}) => {
  return (
    <div className={cn("rounded-lg bg-white ring-1 ring-gray-900/5 dark:bg-gray-900", className)}>
      <div className="mx-auto max-w-7xl">
        <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-6">
          <div className="col-span-2 bg-white px-4 py-6 dark:bg-gray-900 sm:px-6 lg:px-8">
            <div className="flex gap-4 text-sm font-medium leading-6 text-gray-500 dark:text-gray-400">
              Name
              <div
                className={cn(
                  user.email_verified ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800",
                  "rounded-xl px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                )}
              >
                <EnvelopeIcon className="mr-1.5 mt-px inline size-4 min-w-4 align-text-top" />
                {user.email_verified ? "Email verified" : "Email verification needed"}
              </div>
            </div>
            <div className="mt-2 flex items-baseline gap-x-2">
              {loading ? (
                <Spinner className="size-8" />
              ) : (
                <span className="text-4xl font-semibold tracking-tight text-gray-700 dark:text-white">
                  {user.name}
                </span>
              )}
            </div>
          </div>

          <div className="col-span-2 bg-white px-4 py-6 dark:bg-gray-900 sm:px-4 xl:px-8">
            <p className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400">
              Account Type
            </p>
            <p className="mt-2 flex items-baseline gap-x-2">
              {loading ? (
                <Spinner className="size-8" />
              ) : (
                <span className="text-4xl font-semibold tracking-tight text-gray-700 dark:text-white">
                  {user.userType}
                </span>
              )}
            </p>
          </div>

          <div className="bg-white px-4 py-6 dark:bg-gray-900 sm:px-6 lg:px-8">
            <p className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400">
              Channels Added
            </p>
            <p className="mt-2 flex items-baseline gap-x-2">
              {loading ? (
                <Spinner className="size-8" />
              ) : (
                <span className="text-4xl font-semibold tracking-tight text-gray-700 dark:text-white">
                  {channelCount}
                </span>
              )}
            </p>
          </div>

          <div className="bg-white px-4 py-6 dark:bg-gray-900 sm:px-6 lg:px-8">
            <p className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400">
              Works Added
            </p>
            <p className="mt-2 flex items-baseline gap-x-2">
              {loading ? (
                <Spinner className="size-8" />
              ) : (
                <span className="text-4xl font-semibold tracking-tight text-gray-700 dark:text-white">
                  {workCount}
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHead;
