import { zodResolver } from "@hookform/resolvers/zod";
import first from "lodash/first";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { FormActions } from "@/components/forms/form-utils";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useAddCreatorMutation, useAddOwnerMutation } from "@/generated-types";
import type { SearchFilter, Stakeholder, TypeOfStakeholder } from "./types";

type StakeholderStubFormProps = {
  type: TypeOfStakeholder;
  filter: SearchFilter | undefined;
  onCancel: () => void;
  onSave: (data: Stakeholder) => void;
};

export function StakeholderStubForm({
  type: typeOfStakeholder,
  filter,
  onCancel,
  onSave,
}: StakeholderStubFormProps) {
  const [addCreator, { loading: addingCreator }] = useAddCreatorMutation({
    refetchQueries: ["SearchCreatorsByName"],
  });
  const [addOwner, { loading: addingOwner }] = useAddOwnerMutation({
    refetchQueries: ["SearchOwnersByName"],
  });

  const handleSave = async (data: z.infer<typeof FormSchema>) => {
    // await updateCreator({
    //   variables: {
    //     id: creator?.id!, // ID must exist
    //     data,
    //   },
    // });
    const { firstName, lastName, email } = data;
    if (typeOfStakeholder === "creator") {
      const addCreatorResult = await addCreator({
        variables: {
          input: {
            firstName,
            lastName,
            firstNameLC: firstName.toLowerCase(),
            lastNameLC: lastName.toLowerCase(),
            // only create CreatorContact node if the user has provided an email address
            ...(email
              ? {
                  contacts: {
                    create: [{ node: { emailAddress: email } }],
                  },
                }
              : undefined),
          },
        },
      });
      const stubCreator = first(addCreatorResult.data?.createCreators.creators);
      if (stubCreator) onSave(stubCreator);
    } else {
      try {
        const addOwnerResult = await addOwner({
          variables: {
            input: {
              name: lastName,
              nameLC: lastName.toLowerCase(),
              // only create CreatorContact node if the user has provided an email address
              ...(email
                ? {
                    contacts: {
                      create: [{ node: { emailAddress: email } }],
                    },
                  }
                : undefined),
            },
          },
        });

        const stubOwner = first(addOwnerResult.data?.createOwners.owners);
        if (stubOwner) onSave(stubOwner);
      } catch (e) {
        console.error(e);
        // report error to user using a toast etc
      }
    }
  };

  const FormSchema = z.object({
    firstName: z
      .string()
      .refine((value) => typeOfStakeholder === "rights owner" || Boolean(value), {
        // if we are adding a rights owner, first name field is not required, thus can be anything
        // otherwise it has to have value
        message: "Required",
      }),
    lastName: z.string().min(2, {
      message: "Required",
    }),
    email: z.string().email().or(z.literal("")),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstName: filter?.firstName || "",
      lastName: filter?.lastName || "",
      email: "",
    },
    mode: "onChange",
  });

  const {
    formState: { isValid, errors },
    watch,
  } = form;

  console.log({ errors, values: watch() });

  const showFirstNameField = typeOfStakeholder === "creator";
  const lastNameFieldLabel = typeOfStakeholder === "creator" ? "Last Name" : "Name";

  return (
    <Form {...form}>
      <h3>Invite the {typeOfStakeholder} to Credtent:</h3>
      <form onSubmit={form.handleSubmit(handleSave)} className="space-y-6">
        {/* first name */}
        {showFirstNameField && (
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First Name *</FormLabel>
                <FormControl>
                  <Input {...field} autoCapitalize="words" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {/* last name */}
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{lastNameFieldLabel} *</FormLabel>
              <FormControl>
                <Input {...field} autoCapitalize="words" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* email */}
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email Address</FormLabel>
              <FormControl>
                <Input {...field} autoComplete="off" />
              </FormControl>
              <FormDescription>
                If a valid email address is provided, we'll send an invite to them to complete their
                profile on Credtent.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormActions
          disabled={!isValid}
          loading={addingCreator || addingOwner}
          onBack={onCancel}
          nextLabel="Invite and link to your creation"
          backLabel="Cancel"
        />
      </form>
    </Form>
  );
}
