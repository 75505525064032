import { EyeIcon, EyeOffIcon } from "lucide-react";
import React from "react";

import { AddressForm } from "@/components/forms/address-mini-form";
import { CreatorNameForm } from "@/components/forms/creator-name-form";
import { GenderMiniForm } from "@/components/forms/gender-mini-form";
import { CreatorContact, GetCreatorByAccountQuery, PlanTypes, User } from "@/generated-types";
import { PlanList } from "@/pages/SubscribePage/onboarding-subscript-plans";
import { formatAddress, formatGender, formatLegalName } from "@/utils/formatter";
import EditPicture from "./edit-picture";
import { TagsEditor } from "./tags-editor";
import ValueBox from "./value-box";

type CreatorItemType = GetCreatorByAccountQuery["creators"][number];

type ViewProfileProps = {
  loading: boolean;
  user: Pick<User, "userId" | "email" | "picture"> | undefined | null;
  plan: PlanTypes | undefined | null;
  creator: CreatorItemType | undefined;
  contact: Partial<CreatorContact> | undefined;
  onSavePicture: (pictureData: string) => void | Promise<void>;
  onSaveField: (
    field: string,
    value: string | undefined
  ) => string | undefined | Promise<string | undefined>;
  onSaveTags: (tags: string | undefined) => string | undefined | Promise<string | undefined>;
};

export const ViewCreatorProfile: React.FC<ViewProfileProps> = ({
  loading,
  user,
  plan,
  creator,
  contact,
  onSavePicture,
  onSaveField,
  onSaveTags,
}) => {
  const creatorNames: string[] = creator?.nicknames ? creator.nicknames.split(",") : [];

  // tags are filtered before display, just in case a tag was added before it is blocked by the site admin
  const tags: string[] =
    creator?.tags
      ?.filter((tagItem) => !tagItem.blocked && tagItem.title)
      .map((tagItem) => tagItem.title!) || [];

  return (
    <div className="my-8 space-y-4">
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <div className="grid grid-cols-1 gap-6 2xl:grid-cols-2">
          {/* first column */}
          <div className="space-y-6">
            <ValueBox
              label="Your Legal Name"
              value={formatLegalName(creator)}
              publicViewable
              customEditor={<CreatorNameForm creator={creator} />}
              customActions
            />

            <ValueBox label="Email" readonly value={user?.email} />

            <ValueBox
              label="Profile"
              remarks="This profile summary helps identify you and your work."
              publicViewable
              value={creator?.bio}
              onSave={(v) => onSaveField("bio", v)}
            />
          </div>

          {/* second column */}
          <div className="space-y-6">
            <ValueBox readonly label="Credtent License Type">
              <PlanList plan={plan} />
            </ValueBox>

            <ValueBox
              label="Creator Names"
              remarks="Alternate names you use on creative channels."
              customEditor={
                <TagsEditor
                  value={creatorNames}
                  placeholder="Names separated by ,"
                  pattern={/^[a-zA-Z0-9\s!@#$%^&*()_+={}[\]:;"'<>?/\\|~`.-]{1,30}$/}
                  className="-m-2 w-[calc(100%_+_1rem)] border-none"
                />
                // allow letters, numbers, spaces, and punctuation except for commas
              }
              value={creatorNames.join(", ")}
              onSave={(v) => onSaveField("nicknames", v)}
              publicViewable
            />

            <ValueBox
              label="Creator Keywords"
              remarks="This helps us organize our creators. Some suggestions: medium you work in, topics you cover, and notable achievements."
              publicViewable
              customEditor={
                <TagsEditor
                  value={tags}
                  placeholder="Keywords seperated by COMMA"
                  pattern={/^[a-zA-Z0-9\s!@#$%^&*()_+={}[\]:;"'<>?/\\|~`.-]{1,30}$/}
                  className="-m-2 w-[calc(100%_+_1rem)] border-none"
                />
              }
              value={tags.join(", ")}
              onSave={(v) => onSaveTags(v)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 2xl:grid-cols-2">
          {/* third column */}
          <div className="space-y-6">
            <ValueBox
              label="Profile Picture"
              publicViewable
              editTrigger={
                <EditPicture onSave={onSavePicture} className="-my-1.5 cursor-pointer p-2" />
              }
            >
              <div className="flex size-full items-center justify-center">
                {loading ? (
                  <div className="size-[200px]" />
                ) : (
                  <img
                    src={
                      creator?.profilePicture ||
                      user?.picture ||
                      "/assets/avatar-placeholder-100.svg"
                    }
                    className="aspect-square max-w-full object-cover"
                    alt="profile"
                  />
                )}
              </div>
            </ValueBox>
          </div>

          {/* fourth column */}
          <div className="space-y-6">
            <ValueBox
              label="Address"
              customEditor={
                <AddressForm entity="creator" entityId={creator?.id} address={contact} />
              }
              customActions // don't render the default X and V buttons, the AddressForm renders its own Cancel and Save
            >
              {formatAddress(contact)}
            </ValueBox>

            <ValueBox
              label="Gender"
              publicViewable
              value={formatGender(creator)}
              customEditor={<GenderMiniForm creator={creator} />}
              customActions
            />

            <ValueBox
              label="Pronouns"
              publicViewable
              value={creator?.pronouns}
              onSave={(v) => onSaveField("pronouns", v)}
            />
          </div>
        </div>
      </div>

      <div className="">
        <div className="flex flex-row items-center gap-2 text-sm font-light">
          <EyeIcon className="size-4 text-gray-400" />
          information will be displayed publicly
        </div>
        <div className="flex flex-row items-center gap-2 text-sm font-light">
          <EyeOffIcon className="size-4 text-gray-400" />
          information will NOT be displayed publicly
        </div>
      </div>
    </div>
  );
};
