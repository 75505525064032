import { Plus, X } from "lucide-react";

import { CategoryIcon } from "@/components/lists/category-icon";
import { Button } from "@/components/ui/button";
import { CategoryTag } from "@/generated-types";
import { SelectCateorySheet } from "./select-category-sheet";

type CatType = Pick<CategoryTag, "id" | "title" | "icon">;
type CategoryEditorProps = {
  typeOfContent: string;
  value: CatType[];
  onChange: (value: Pick<CategoryTag, "id" | "title" | "icon">[]) => void;
  disabled?: boolean;
};

export function CategoryEditor({ typeOfContent, value, onChange, disabled }: CategoryEditorProps) {
  function handleRemove(catId: string) {
    onChange(value.filter((tag) => tag.id !== catId));
  }

  return (
    <div className="flex flex-row flex-wrap gap-4 py-2">
      {/* render list of categories */}
      {value.map((cat) => (
        <div key={cat.id} className="relative flex w-20 flex-col items-center justify-start">
          <Button
            size="icon"
            className="absolute -top-2 right-0 size-4 rounded-full"
            onClick={() => handleRemove(cat.id)}
            disabled={disabled}
          >
            <X className="size-3" stroke="white" />
          </Button>
          <CategoryIcon icon={cat.icon} size={32} className="inline align-bottom" />
          <p className="mt-4 text-center text-xs leading-tight">{cat.title}</p>
        </div>
      ))}
      {/* render plus button */}
      <SelectCateorySheet
        typeOfContent={typeOfContent}
        trigger={
          <Button
            variant="outline"
            size="icon"
            className="size-12 text-secondary-foreground"
            disabled={disabled}
          >
            <Plus className="size-8" />
          </Button>
        }
        currentValue={value}
        onDone={onChange}
      />
    </div>
  );
}
